import React, { useLayoutEffect, useState } from 'react';

export const SM = 576; // sm >= 576
export const MD = 768;
export const LG = 992;

export const _XS = 0;
export const _SM = 1;
export const _MD = 2;
export const _LG = 3;
export const _XL = 4;

export default function useWindowSize() {
  const [size, setSize] = useState([0, 0, 'lg']); // width,height,breakpoint, breakPointId
  useLayoutEffect(() => {
    function updateSize() {
      const breakPoint = getBreakPoint(window.innerWidth);
      var breakPointId = getBreakPointId(breakPoint);
      setSize([
        window.innerWidth,
        window.innerHeight,
        getBreakPoint(window.innerWidth),
        breakPointId,
      ]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function getBreakPoint(windowWidth) {
  if (windowWidth) {
    if (windowWidth < 576) return 'xs';
    else if (windowWidth < 768) return 'sm';
    else if (windowWidth < 992) return 'md';
    else if (windowWidth < 1200) return 'lg';
    else return 'xl';
  } else {
    return undefined;
  }
}

function getBreakPointId(breakPoint) {
  if (breakPoint == 'xs') return 0;
  if (breakPoint == 'sm') return 1;
  if (breakPoint == 'md') return 2;
  if (breakPoint == 'lg') return 3;
  if (breakPoint == 'xl') return 4;
  return 0;
}

// function ShowWindowDimensions(props) {
//   const [width, height] = useWindowSize();
//   return <span>Window size: {width} x {height}</span>;
// }
