import React, { useState } from 'react';
import digitalFitnessBannerStyles from '../styles/DigitalFitnessBanner.module.css';
import fitOnLogo from '../Assets/fitOn-logo.svg';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import {
  defaultSignUpUrl,
  getSignUpFullUrl,
} from '../api/integration/fitOnSignUp';
import { useAuth } from '../contexts/AuthContext';

export default function FitOnFitnessBanner() {
  const { authState } = useAuth();
  const [fitOnSingUpUrl, setFitOnSignUpUrl] = useState(defaultSignUpUrl);

  useEffect(() => {
    let alive = true;
    if (authState.user?.id) {
      getSignUpFullUrl(authState.user, 'explore').then((url) => {
        if (alive) {
          setFitOnSignUpUrl(url);
        }
      });
    }
    return () => (alive = false);
  }, [authState.user?.id]);
  return (
    <a
      className={digitalFitnessBannerStyles.container}
      href={fitOnSingUpUrl}
      target="_blank"
      rel="noreferrer"
    >
      <div className={digitalFitnessBannerStyles.iconContainerFitOn}>
        <div
          style={{ backgroundImage: `url(${fitOnLogo})` }}
          className={digitalFitnessBannerStyles.iconFitOn}
        ></div>
      </div>
      <div className={digitalFitnessBannerStyles.copyContainer}>
        <h2 className={digitalFitnessBannerStyles.title}>
          <span>FitOn: #1 Fitness App</span>
        </h2>
        <span className={digitalFitnessBannerStyles.description}>
          Signup for unlimited access to personalized workouts, meditation &
          nutrition plans. Designed for all ages & abilities.
        </span>
      </div>
    </a>
  );
}
