import React, { useState, useCallback, useEffect, useRef } from 'react';
import ExploreSearchIcon from '../../Assets/mobile/explore-search-icon.svg';
import FilterIcon from '../../Assets/mobile/filter-icon.svg';
import menuStyles from '../../styles/explore/Menu.module.css';
import { MobileModal } from '../MobileModal';
import Search from '../../Search';
import { SearchMenu_Filters } from '../../Explore/SearchMenu_Filters';
import { RecentSearches } from '../RecentSearches';
import { useSearch } from '../../contexts/SearchContext';
import { useAuth } from '../../contexts/AuthContext';

export const SearchInput = () => {
    const [showModal, setShowModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const {
        clearSearch,
        recentSearches,
        useGetLocation,
        searchState: { current_tab, has_memberships, tags, categories, has_digital, time },
        selectedPlace,
        searchKeyword,
        studiosLoading,
        sessionsLoading,
        searchState,
        setForceSearch,
    } = useSearch();
    const { hasMobileLocationPermission } = useAuth();

    const ref = useRef();
    const filtersRef = useRef();
    const [shouldForce, setShouldForce] = useState(false)
    useGetLocation();

    const handleClick = useCallback(
        (e) => {
            if (!studiosLoading && !sessionsLoading) {
                e.preventDefault();
                e.stopPropagation();
                setShowModal(true);
                return false;
            }
        },
        [studiosLoading, sessionsLoading, setShowModal]
    );

    const handleFilterClick = useCallback(
        (e: React.MouseEvent) => {
            if (!studiosLoading && !sessionsLoading) {
                e.preventDefault();
                e.stopPropagation();
                setShowFilterModal(true);
                return false;
            }
        },
        [studiosLoading, sessionsLoading, setShowFilterModal]
    );

    useEffect(() => {
        var count =
            (has_digital ? 1 : 0) +
            (has_memberships ? 1 : 0) + // has_digital is used for studioSearch & sessionSearch
            (current_tab === 1 && time ? 1 : 0);

        count += tags.length;
        count += categories.length;
        setFilterCount(count);
    }, [current_tab, tags, categories, has_digital, time, has_memberships]);

    const shouldHideNearbyPart = !hasMobileLocationPermission && !selectedPlace.description;

    return (
        <div
            style={{
                border: '1px solid #757575',
                height: 48,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: 16,
                margin: 12,
                fontSize: 14,
            }}
            onClick={handleClick}
        >
            <MobileModal
                show={showModal}
                title="Search"
                onClose={() => setShowModal(false)}
                onConfirm={() => {
                    (ref?.current as HTMLFormElement)?.submitSearch();
                    if (shouldForce) {
                        setTimeout(() => {
                            setForceSearch(true)
                        }, 500)
                        setShouldForce(false)
                    }
                    if (searchState.bounds.length === 0) {
                        setForceSearch(true)
                    }
                    setShowModal(false);
                }}
                onClear={() => {
                    clearSearch();
                    (ref?.current as HTMLFormElement)?.resetSearch();
                }}
                confirmText="Search"
                content={
                    <>
                        <Search ref={ref} />
                        <RecentSearches
                            onSelect={(item) => {
                                if (item.placeId === searchState.place_id) {
                                    setShouldForce(true)
                                } else {
                                    setShouldForce(false)
                                }
                                (ref?.current as HTMLFormElement)?.setSearchValues(item);
                            }}
                            savedSearches={recentSearches}
                        />
                    </>
                }
            />

            <MobileModal
                show={showFilterModal}
                title="Filters"
                content={<SearchMenu_Filters ref={filtersRef} />}
                onConfirm={async () => {
                    if (filtersRef && filtersRef.current) {
                        await (filtersRef.current as any).handleSubmit((filtersRef.current as any).values);
                    }
                    setShowFilterModal(false);
                }}
                onClear={() => {
                    if (filtersRef && filtersRef.current) {
                        (filtersRef.current as any).clearAllFilters();
                    }
                }}
                onClose={() => setShowFilterModal(false)}
            />
            <img src={ExploreSearchIcon} alt="search-icon" />
            <div
                style={{
                    position: 'absolute',
                    right: 22,
                    top: 0,
                    width: 100,
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingRight: 20,
                }}
                onTouchStart={() => {
                    return false;
                }}
                onClick={handleFilterClick}
            >
                <img src={FilterIcon} alt="filter-icon" />
                <div
                    className={`${filterCount === 0 ? menuStyles.hideIcon : ''} ${menuStyles.tagIcon}`}
                    data-cy="tag-icon-count"
                    style={{
                        position: 'absolute',
                        right: -5,
                        top: 17,
                    }}
                >
                    {filterCount}
                </div>
            </div>
            <div
                style={{
                    marginLeft: 10,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(100vw - 130px)',
                }}
            >
                <span>{searchKeyword || 'Studios & Gyms'}</span>
                {!shouldHideNearbyPart && (
                    <>
                        <span>&nbsp;&#8226;&nbsp;</span>
                        <span>{selectedPlace.description || 'Nearby'}</span>
                    </>
                )}
            </div>
        </div>
    );
};
