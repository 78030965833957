import React, { createContext, useContext, useState } from 'react';

export const TestContext = createContext();
export const useTest = () => useContext(TestContext);

export const TestContextProvider = (props) => {
  const [flag, setFlag] = useState(null);

  return (
    <TestContext.Provider
      value={{
        flag,
      }}
    >
      {props.children}
    </TestContext.Provider>
  );
};
