import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import LoveLetterIcon from '../../Assets/love_letter.png';
import VerificationInput from 'react-verification-input';
import inputFieldStyles from '../../styles/input/Field.module.css';
import { SubmitButton } from '../SubmitButton';
import { useAuth } from '../../contexts/AuthContext';
import {
  CHANGE_PASSWORD,
  CONTACT_US,
  DISCOVER,
  FORGOT_PASSWORD,
  REGISTER_USER,
} from '../../navigation/CONSTANTS';
import { Redirect, Link } from 'react-router-dom';
import useQuery from '../../shared/hooks/useQuery';
import {
  pinCode,
  forgotPassword,
  getChangePasswordCode,
  pinChangePasswordCode,
} from '../../api/authentication';
import { RegisterPageContainer } from '../RegisterPageContainer';
import { logAmplitudeEvent, logBrazeEvent } from '../../api/integration';
import { setPFToken } from '../../utilities/TokenHelper';
import { parseJwt } from '../../utilities/jwt';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-start',
    padding: 48,
  },
  title: {
    textAlign: 'center',
    fontSize: '3.2rem',
    color: '#222B56',
    fontWeight: 600,
  },
  subTitle: {
    textAlign: 'center',
    color: '#222B56',
    fontSize: '1.6rem',
    fontWeight: 500,
    margin: '0 0 8px',
  },
  divider: {
    height: 1,
    background: '#D0D0D0',
    width: 380,
    margin: '24px 0',
  },
  codeContainer: {
    marginBottom: 10,
    width: '200px !important',
  },
  codeCharacter: {
    borderTop: 'none !important',
    borderRight: 'none !important',
    borderLeft: 'none !important',
    fontSize: 32,
    '&.error': {
      borderColor: '#FF5252',
    },
  },
  codeCharacterInactive: {
    background: 'none !important',
  },
  codeCharacterSelected: {
    color: 'unset !important',
  },
  errorMsg: {
    color: '#FF5252',
    fontSize: 16,
    fontWeight: 500,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-start',
    marginBottom: 40,
  },
  resend: {
    textAlign: 'center',
    color: '#757575',
    width: 380,
  },
  action: {
    color: '#222b56',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  email: {
    color: '#D91E61',
  },
  trouble: {
    width: 380,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#E9EEF7',
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 4,
    marginBottom: 10,
  },
  contactUs: {
    marginLeft: 4,
    color: '#D91E61',
  },
}));
export const CheckYourEmailForm = () => {
  const classes = useStyles();
  const { authState, verifyPFToken, setChangePasswordPinCode } = useAuth();
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const codeLength = 4;
  const query = useQuery();
  const from = useMemo(() => query.get('from'), [query]);
  const [currentCode, setCurrentCode] = useState('');
  const history = useHistory();
  const email = useMemo(
    () =>
      authState.registerUser?.email ||
      authState.user?.email ||
      (authState?.token ? parseJwt(authState?.token)?.email : ''),
    [authState]
  );
  const isForgotPassword =
    from === 'forgotPassword' || from === 'forgot-password';
  const isChangePassword =
    from === 'changePassword' || from === 'change-password';

  useEffect(() => {
    if (isForgotPassword) {
      logAmplitudeEvent('Screen View: Check Your Email', {
        source: 'Forgot Password',
      });
    }
    if (isChangePassword) {
      logAmplitudeEvent('Screen View: Check Your Email', {
        source: 'Change Password',
      });
    }
  }, []);

  const handleSubmit = async () => {
    logAmplitudeEvent('Click: Check Your Email Screen', {
      Button: 'Verify',
    });
    try {
      setIsSubmitting(true);
      setHasError(false);
      const currentApi = isChangePassword ? pinChangePasswordCode : pinCode;
      const result = await currentApi({
        pin: currentCode,
        email: email,
        context: isChangePassword ? 'change-password' : 'forgot-password',
      });
      if (isForgotPassword) {
        logAmplitudeEvent('Signin: Success', {
          Source: 'Check Your Email',
        });
        logBrazeEvent('Signin: Success', {
          Source: 'Check Your Email',
        });
        setPFToken(result.data?.token);
        if (result.data?.token) {
          const { pin } = parseJwt(result.data?.token);
          setChangePasswordPinCode(pin);
        }

        const { isUnregistered } = await verifyPFToken(false, false, true);
        if (isUnregistered) {
          history.push({
            pathname: REGISTER_USER,
            search: '?type=unregistered',
          });
        }
        if (!isUnregistered) {
          history.push(CHANGE_PASSWORD);
        }
      }
      if (isChangePassword) {
        setChangePasswordPinCode(currentCode);
        history.push(CHANGE_PASSWORD);
      }
    } catch (error) {
      setHasError(true);
      logAmplitudeEvent('Signin: Failure', {
        Source: 'Check Your Email',
        Code: error?.response?.data?.Code,
        Message:
          error?.response?.data?.Errors?.[0]?.message ||
          error?.response?.data?.Message ||
          error.message,
      });
      logAmplitudeEvent('View: Error Message', {
        Screen: 'Check Your Email',
        Message:
          error?.response?.data?.Errors?.[0]?.message ||
          error?.response?.data?.Message ||
          error.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResend = async () => {
    try {
      if (isForgotPassword) {
        setIsSubmitting(true);
        await forgotPassword({
          email,
        });
      } else if (isChangePassword) {
        setIsSubmitting(true);
        await getChangePasswordCode({
          email,
        });
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!from || !email) {
    return <Redirect to={DISCOVER} />;
  }

  if (isForgotPassword && !email) {
    return <Redirect to={FORGOT_PASSWORD} />;
  }

  if (isChangePassword && !email) {
    return <Redirect to={CHANGE_PASSWORD} />;
  }

  return (
    <RegisterPageContainer>
      <div className={classes.container}>
        <div className={classes.body}>
          <img src={LoveLetterIcon} alt="" />
          <h2 className={classes.title}>Check your email!</h2>
          <p className={classes.subTitle}>
            We sent an email to <span className={classes.email}>{email}</span>
          </p>
          {isForgotPassword && (
            <>
              <p className={classes.subTitle}>
                Please check your inbox and click on the link to log in.
              </p>
              <div className={classes.divider}></div>
              <p className={classes.subTitle}>
                You can also enter the {codeLength} digit code you've received
                in your email to log in.
              </p>
            </>
          )}
          {isChangePassword && (
            <>
              <br />
              <p className={classes.subTitle}>
                Please check your inbox and enter the 4 digit code you've
                received in your email to validate.
              </p>
            </>
          )}
          <div>
            <VerificationInput
              length={codeLength}
              placeholder={''}
              classNames={{
                container: classes.codeContainer,
                character: classnames(classes.codeCharacter, {
                  error: hasError === true,
                }),
                characterInactive: classes.codeCharacterInactive,
                characterSelected: classes.codeCharacterSelected,
              }}
              onChange={(value) => {
                setCurrentCode(value);
              }}
            />
          </div>
          {hasError && (
            <span className={inputFieldStyles.errorLabel}>
              Incorrect code. Please try again
            </span>
          )}
          <br />
        </div>
        {hasError && (
          <div className={classes.trouble}>
            Having trouble logging in?{' '}
            <Link className={classes.contactUs} to={CONTACT_US}>
              Contact Us
            </Link>
          </div>
        )}
        <SubmitButton
          isRegister={true}
          style={{ width: 380 }}
          disabled={currentCode.length < codeLength && !isSubmitting}
          isSubmitting={isSubmitting}
          onClick={handleSubmit}
        >
          Verify
        </SubmitButton>
        <p className={classes.resend}>
          Didn’t get your email?{' '}
          <span className={classes.action} onClick={handleResend}>
            Resend email
          </span>
        </p>
      </div>
    </RegisterPageContainer>
  );
};
