import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { logAmplitudeEvent } from '../../../api/integration';
import studioApi from '../../../api/studio';
import forteImage from '../../../Assets/9bf5b417c9dced566e4745c2f71b0759.png';
import burnalongImage from '../../../Assets/c660f7bc64a5706dcac9d9804a67a443.png';
import defaultStudioImg from '../../../Assets/defaultStudio.jpg';
import fitOnImage from '../../../Assets/fitOn_image.png';
import { useAuth } from '../../../contexts/AuthContext';
import { useSite } from '../../../contexts/SiteContext';
import { CancelSubscriptionModal } from '../../../shared/Modal/CancelSubscriptionModal';
import useModal from '../../../shared/Modal/useModal';
import bannerStyles from '../../../styles/Banner.module.css';
import flexStyles from '../../../styles/Flex.module.css';
import reservationStyles from '../../../styles/user/Reservation.module.css';
import reservationsStyles from '../../../styles/user/Reservations.module.css';
import { GetDate } from '../../../utilities/date';

export const SubscriptionCard = ({ subscription, reload }) => {
  const { isShowing, toggle, ref } = useModal();
  const { authState } = useAuth();
  const { user } = authState || {};
  const { isMoveSite } = useSite();

  let img = defaultStudioImg;
  let title = subscription.provider_name;
  let lead_code = '';
  if (subscription.provider_name == 'FORTE') {
    img = forteImage;
    title = 'FORTE'; //"FORTË"
  }
  if (subscription.provider_name == 'BURN ALONG') {
    img = burnalongImage;
    title = 'BurnAlong';
    lead_code = isMoveSite
      ? process.env.REACT_APP_BA_LEAD_CODE_MOVE
      : process.env.REACT_APP_BA_LEAD_CODE_CORE;
  }
  if (subscription.provider_name == 'LESMILLS') {
    title = 'LMOD';
  }
  if (subscription.provider_name === 'FITON') {
    img = fitOnImage;
  }

  const [digitalProvider, setDigitalProvider] = useState({});
  useEffect(() => {
    studioApi.getDigitalProviders().then((res) => {
      const fitOn = res.results.find((x) => x.name == 'FITON');
      const forte = res.results.find((x) => x.name == 'FORTE');
      const burnAlong = res.results.find((x) => x.name == 'BURN ALONG');
      const lesmills = res.results.find((x) => x.name == 'LESMILLS');
      if (subscription.provider_name === 'FITON') {
        setDigitalProvider(fitOn);
      }
      if (subscription.provider_name === 'FORTE') {
        setDigitalProvider(forte);
      } else if (subscription.provider_name === 'LESMILLS') {
        setDigitalProvider(lesmills);
      } else if (subscription.provider_name === 'BURN ALONG') {
        setDigitalProvider(burnAlong);
      }
    });
  }, []);

  return (
    <>
      <div
        className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-4']} ${flexStyles['col-sm-6']} ${reservationsStyles.card}`}
      >
        <div className={reservationStyles.reservation}>
          <div
            className={bannerStyles.container}
            style={{ backgroundImage: `url(${img})` }}
          >
            <Link className={bannerStyles.copy} to={`#`}>
              <h3 className={bannerStyles.title}>{title}</h3>
            </Link>
          </div>
          <div className={reservationStyles.content}>
            <div className={reservationStyles.info}>
              <h4 className={reservationStyles.tiny}>Digital Subscription</h4>
              {/* <h4 className={reservationStyles.tiny}>{subscription.credits} Credits / Month</h4> */}
              {subscription.next_bill_date && (
                <h4 className={reservationStyles.tiny}>
                  Next Bill at{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {format(GetDate(subscription.next_bill_date), 'E, LLL dd')}
                  </span>
                </h4>
              )}
            </div>

            <div className={reservationStyles.actions}>
              <div className={reservationStyles.action}>
                <a
                  className={reservationStyles.button}
                  href={
                    subscription.provider_name == 'BURN ALONG'
                      ? `${subscription.base_sso_url}/${lead_code}?token=${user.email}`
                      : subscription.base_sso_url
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Go to {title}
                </a>
              </div>

              {subscription.provider_name == 'LESMILLS' && (
                <div className={reservationStyles.action}>
                  <a
                    className={reservationStyles.button}
                    href={subscription.sso_login_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Subscription
                  </a>
                </div>
              )}

              {subscription.end_date == null ? (
                <div className={reservationStyles.action}>
                  <a
                    className={reservationStyles.button}
                    onClick={() => {
                      if (subscription.provider_name === 'FITON') {
                        logAmplitudeEvent('Click: Purchase Cancel', {
                          Type: 'Digital',
                          Credits: digitalProvider?.credits,
                          'Item Name': 'FitOn Subscription',
                          Source: 'Membership',
                        });
                      } else {
                        logAmplitudeEvent('Click: Purchase Cancel', {
                          Type: 'Digital',
                          Credits: digitalProvider?.credits,
                          'Item Name': subscription?.provider_title,
                          Source: 'Membership',
                        });
                      }
                      toggle();
                    }}
                  >
                    Cancel Subscription
                  </a>
                </div>
              ) : new Date(subscription.end_date) > new Date() ? (
                <h3 className={reservationStyles.noCancel}>Not Renewing</h3>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <CancelSubscriptionModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        subscription={subscription}
        onSuccess={() => {
          if (subscription.provider_name === 'FITON') {
            logAmplitudeEvent('Click: Purchase Cancel Confirmation', {
              Type: 'Digital',
              Credits: digitalProvider?.credits,
              'Item Name': 'FitOn Subscription',
              Source: 'Membership',
            });
          } else {
            logAmplitudeEvent('Click: Purchase Cancel Confirmation', {
              Type: 'Digital',
              Credits: digitalProvider?.credits,
              'Item Name': subscription?.provider_title,
              Source: 'Membership',
            });
          }
          reload();
        }}
      />
    </>
  );
};
