import React from 'react';
import pageStyles from '../styles/cookie-policy/Page.module.css';

export default function CookiePolicy() {
  return (
    <div
      className={pageStyles.container}
      style={{ height: 'auto', position: 'relative', minHeight: '216px' }}
    >
      <div>
        <p>Last modified March 31, 2020. Effective March 31, 2020.</p>
        <h2 data-cy="privacy-policy-header">Peerfit Cookie Policy</h2>
        <p>
          At Peerfit, we believe in being transparent about how we collect and
          use data. This policy provides information about how and when we use
          cookies for these purposes. Capitalized terms used in this policy but
          not defined have the meaning set forth in our{' '}
          <a href="/privacy-policy">Privacy Policy</a>, which also includes
          additional details about the collection and use of information at
          Peerfit.
        </p>
        <h3>What is a cookie?</h3>
        <p>
          Cookies are small text files sent by us to your computer or mobile
          device, which enable Peerfit features and functionality. They are
          unique to your account or your browser. Session-based cookies last
          only while your browser is open and are automatically deleted when you
          close your browser. Persistent cookies last until you or your browser
          delete them or until they expire.
        </p>
        <p>
          To find out more about cookies, visit{' '}
          <a
            target="_blank"
            href="https://www.allaboutcookies.org/"
            rel="noreferrer"
          >
            this site
          </a>
          .
        </p>
        <h3>Does Peerfit use cookies?</h3>
        <p>
          Yes. Peerfit uses cookies and similar technologies like single-pixel
          gifs and web beacons. We use both session-based and persistent
          cookies. Peerfit sets and accesses our own cookies on the domains
          operated by Peerfit and its corporate affiliates (collectively, the
          “Sites”). In addition, we use third party cookies, like Google
          Analytics.
        </p>
        <h3 id="cookie-table">How is Peerfit using cookies?</h3>
        <p>
          Some cookies are associated with your account and personal information
          in order to remember that you are logged in. Other cookies are not
          tied to your account but are unique and allow us to carry out
          analytics and customization, among other similar things.
        </p>
        <p>
          Cookies can be used to recognize you when you visit a Site or use our
          Services, remember your preferences, and give you a personalized
          experience that’s consistent with your settings. Cookies also make
          your interactions faster and more secure. See the table below that
          explains the various categories in which we use Cookies, and their
          plain-english description.
        </p>
        <table>
          <tr>
            <th>Categories of Use</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>Authentication</td>
            <td>
              If you're signed in to our Services, cookies help us show you the
              right information and personalize your experience.
            </td>
          </tr>
          <tr>
            <td>Security</td>
            <td>
              We use cookies to enable and support our security features, and to
              help us detect malicious activity.
            </td>
          </tr>
          <tr>
            <td>Preferences, features and services</td>
            <td>
              Cookies can tell us which language you prefer and what your
              communications preferences are. They can help you fill out forms
              on our Sites more easily. They also provide you with features,
              insights, and customized content.
            </td>
          </tr>
          <tr>
            <td>Marketing</td>
            <td>
              We may use cookies to help us deliver marketing campaigns and
              track their performance (e.g., a user visited our Help Center and
              then made a purchase). Similarly, our partners may use cookies to
              provide us with information about your interactions with their
              services, but use of those third-party cookies would be subject to
              the service provider’s policies.
            </td>
          </tr>
          <tr>
            <td>Performance, Analytics and Research</td>
            <td>
              Cookies help us learn how well our Sites and Services perform. We
              also use cookies to understand, improve, and research products,
              features, and services, including to create logs and record when
              you access our Sites and Services from different devices, such as
              your work computer or your mobile device.
            </td>
          </tr>
        </table>
        <h3>What third-party cookies does Peerfit use?</h3>
        <p>
          You can find a list of the third-party cookies that Peerfit uses on
          our sites along with other relevant information in our{' '}
          <a href="#cookie-table">cookie tables</a>. While we do our best to
          keep this table updated, please note that the number and names of
          cookies, pixels and other technologies may change from time to time.
        </p>
        <h3>How are cookies used for advertising purposes?</h3>
        <p>
          Cookies and other ad technology such as beacons, pixels, and tags help
          us engage more effectively with users that we and our partners believe
          may be interested in Peerfit. They also help provide us with
          aggregated auditing, research, and reporting, and know when content
          has been shown to you.
        </p>
        <h3>
          What can you do if you don't want cookies to be set or want them to be
          removed, or if you want to opt out of interest-based targeting?
        </h3>
        <p>
          Some people prefer not to allow cookies, which is why most browsers
          give you the ability to manage cookies to suit you. In some browsers
          you can set up rules to manage cookies on a site-by-site basis, giving
          you more fine-grained control over your privacy. What this means is
          that you can disallow cookies from all sites except those that you
          trust.
        </p>
        <p>
          Browser manufacturers provide help pages relating to cookie management
          in their products. Please see below for more information.
        </p>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://support.google.com/chrome/answer/95647?hl=en-GB"
              rel="noreferrer"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://support.microsoft.com/en-us/kb/260971"
              rel="noreferrer"
            >
              Internet Explorer
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
              rel="noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://support.apple.com/en-us/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              rel="noreferrer"
            >
              Safari (Desktop)
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://support.apple.com/en-us/HT201265"
              rel="noreferrer"
            >
              Safari (Mobile)
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://support.google.com/ics/nexus/bin/answer.py?hl=en&amp;answer=2425067"
              rel="noreferrer"
            >
              Android Browser
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.opera.com/help"
              rel="noreferrer"
            >
              Opera
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.opera.com/help/mobile/android#privacy"
              rel="noreferrer"
            >
              Opera Mobile
            </a>
          </li>
        </ul>
        <p>
          For other browsers, please consult the documentation that your browser
          manufacturer provides.
        </p>
        <p>
          You can opt out of interest-based targeting provided by participating
          ad servers through the Digital Advertising Alliance (
          <a
            target="_blank"
            href="https://optout.aboutads.info/"
            rel="noreferrer"
          >
            https://optout.aboutads.info/
          </a>
          ). In addition, on your iPhone, iPad or Android, you can change your
          device settings to control whether you see online interest-based ads.
        </p>
        <p>
          If you limit the ability of websites and applications to set cookies,
          you may worsen your overall user experience and/or lose the ability to
          access the services, since it will no longer be personalized to you.
          It may also stop you from saving customized settings, like login
          information.
        </p>
        <h3>Does Peerfit respond to Do Not Track Signals?</h3>
        <p>
          Our Sites and Services do not collect personal information about your
          online activities over time and across third-party websites or online
          services. Therefore, “do not track” signals transmitted from web
          browsers do not apply to our Sites or Services, and we do not alter
          any of our data collection and use practices upon receipt of such a
          signal.
        </p>
      </div>
    </div>
  );
}
