import React, { Component, useState } from 'react';
import DatePicker from 'rmc-date-picker';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import 'rmc-picker/assets/index.css';
import 'rmc-date-picker/assets/index.css';
import styles from './index.module.css';

export const maxDate = new Date(2030, 11, 31, 23, 59, 59);
export let now = new Date();

export const CustomDatePicker = ({
  mode = 'date',
  open = false,
  setOpen,
  onTimeChange,
}) => {
  const [date, setDate] = useState(now);

  const onChange = (date) => {
    setDate(date);
  };

  const render = () => {
    return (
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div>
          <div className={styles.pickerTitle}>
            {mode == 'time' ? 'Time' : 'Date'}
          </div>
          <div className={styles.line} />
          <DatePicker
            maxDate={maxDate}
            defaultDate={now}
            mode={mode}
            use12Hours={true}
            onDateChange={onChange}
          />
          <Button
            variant="contained"
            className={styles.done}
            onClick={() => {
              onTimeChange(date);
              setOpen(false);
            }}
          >
            Done
          </Button>
        </div>
      </Drawer>
    );
  };
  return render();
};
