import React, { useEffect } from 'react';

import styles from '../styles/styles.module.css';
import errorFormStyles from '../styles/error/Form.module.css';
import pageStyles from '../styles/register/SponsoredPage.module.css';

import formStyles from '../styles/register/Form.module.css';
import loginStyles from '../styles/Login.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import loadingStyles from '../styles/Loading.module.css';
import submitStyles from '../styles/Submit.module.css';
import checkboxStyles from '../styles/Checkbox.module.css';
import contentStyles from '../styles/Content.module.css';
import fieldStyles from '../styles/input/Field.module.css';

import titledFormStyles from '../styles/TitledForm.module.css';
import registerBg from '../Assets/register-bg.png';
import pfMoveLogo from '../Assets/pf-move-logo.png';
import {
  LOGIN,
  REGISTER_SPONSORED,
  REGISTER_CONSUMER,
  REGISTER_MOVE,
  NOMINATE_EMPLOYER,
  REGISTER,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  DISCOVER,
} from '../navigation/CONSTANTS';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Field, Form, Formik, useField } from 'formik';
import { FormikDebug } from '../shared/Formik/FormikDebug';
import nextId, { setPrefix, useId } from 'react-id-generator';
// import TextField from '../shared/Formik/TextField';

import * as Yup from 'yup';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { useAuth } from '../contexts/AuthContext';
import { format } from 'date-fns';

export const Sponsored = () => {
  const history = useHistory();
  const { register } = useAuth();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required().label('Legal First Name'),
    last_name: Yup.string().required().label('Legal Last Name'),

    zipcode: Yup.string()
      .required()
      .matches(/^[0-9]{5}$/, 'Home Zip Code must be five digits. (12345)')
      .label('Home Zip Code'),

    birth_date: Yup.date()
      .required()
      .typeError(
        'Date of Birth must be a valid date, with the format of MM/DD/YYYY.'
      )
      .min(
        new Date(1000, 1, 1),
        'Date of Birth must be a valid date, with the format of MM/DD/YYYY.'
      )
      .max(
        moment().endOf('day').subtract(13, 'years'),
        'Must be at least 13 years of age.'
      )
      .label('Date of Birth'),
    email: Yup.string().required().email().label('Email'),
    password1: Yup.string().required().label('Password'),
    terms_of_use: Yup.boolean().oneOf(
      [true],
      'Please agree to the Privacy Policy and Terms of Service before proceeding.'
    ),
  });

  const handleSubmit = async (values, { setErrors }) => {
    // api call [POST] register/
    console.log('REGISTER: ', values);
    const { birth_date, ...rest } = values;

    const formatted = format(new Date(birth_date), 'yyyy-MM-dd');
    await register({ ...rest, birth_date: formatted })
      .then((res) => {
        console.log('registration success.', res);
        history.push(DISCOVER); // redirect to /discover
      })
      .catch((err) => {
        setErrors({ summary: err.response.data.errors[0].message });
      });
  };

  return (
    <div className={contentStyles.container}>
      <div className={pageStyles.formContainer}>
        <h2 className={pageStyles.title}>
          <span>Sign Up</span>
        </h2>

        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            birth_date: '',
            zipcode: '',
            email: '',
            password1: '',
            mailing_list: true,
            terms_of_use: true,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await handleSubmit(values, actions);
          }}
        >
          {(formikProps) => (
            <Form className={pageStyles.form} autoComplete="on" noValidate="">
              <fieldset>
                {formikProps.errors.summary && (
                  <div className={errorFormStyles.error} tabIndex="-1">
                    <span aria-label={formikProps.errors.summary}>
                      {formikProps.errors.summary}
                    </span>
                  </div>
                )}

                <TextField
                  name="first_name"
                  label="Legal First Name"
                  placeholder="Legal First Name"
                  autoComplete="given-name"
                  data-cy="register-firstname"
                />
                <TextField
                  name="last_name"
                  label="Legal Last Name"
                  placeholder="Legal Last Name"
                  autoComplete="family-name"
                  data-cy="register-lastname"
                />
                <TextField
                  name="display_name"
                  label="Nickname (Optional)"
                  placeholder="Nickname (Optional)"
                  autoComplete="off"
                  data-cy="register-nickname"
                />

                <DateField
                  name="birth_date"
                  label="Date of Birth"
                  placeholder="MM/DD/YYYY"
                  autoComplete="bday"
                  data-cy="register-birthdate"
                />
                <TextField
                  name="zipcode"
                  label="Home Zip Code"
                  placeholder="Home Zip Code"
                  autoComplete="off"
                  data-cy="register-zipcode"
                  ariaDescribedBy="register-zip-notes"
                />
                <span id="register-zip-notes" className={formStyles.notes}>
                  We use this information to match you with your employer. Use
                  what you have on file with your employer or benefits provider.
                </span>
                <TextField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  autoComplete="email"
                  data-cy="register-email"
                />
                <TextField
                  name="password1"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  autoComplete="current-password"
                  data-cy="register-password"
                  ariaDescribedBy="register-password-notes"
                />
                <span id="register-password-notes" className={formStyles.notes}>
                  Min. 8 characters.
                </span>

                <Checkbox
                  name="mailing_list"
                  data-cy="register-marketing"
                  label={
                    <span>
                      I'd like to receive news, feature updates, and promotions
                      from Peerfit.
                    </span>
                  }
                />
                <Checkbox
                  name="terms_of_use"
                  data-cy="terms-of-use-checkbox"
                  label={
                    <span>
                      I acknowledge that I agree to the{' '}
                      <Link target="_blank" to={TERMS_OF_SERVICE}>
                        Terms of Use
                      </Link>{' '}
                      and have read the{' '}
                      <Link target="_blank" to={PRIVACY_POLICY}>
                        Privacy Policy
                      </Link>
                      .
                    </span>
                  }
                />

                <div className={fieldStyles.field}>
                  <input
                    type="hidden"
                    name="referral_code"
                    autoComplete="off"
                    id="id-48-1"
                    placeholder=""
                    tabIndex="0"
                    className={fieldStyles.input}
                    aria-invalid="false"
                    aria-describedby=" id-48-1"
                  />
                  <div
                    id="id-48-1"
                    aria-live="polite"
                    aria-atomic="true"
                    role="status"
                  >
                    {/* DISPLAY SUMMARY ERROR HERE.  */}
                  </div>
                </div>
                <button
                  data-cy="register-submit"
                  type="submit"
                  className={`${formStyles.submit} ${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                >
                  <div
                    className={
                      formikProps.isSubmitting
                        ? `${submitStyles.spinner} ${loadingStyles.spinner}`
                        : loadingStyles.hidden
                    }
                  ></div>
                  <span
                    className={
                      formikProps.isSubmitting
                        ? submitStyles.invisible
                        : submitStyles.visible
                    }
                  >
                    <span>Sign Up</span>
                  </span>
                </button>
                {/* <FormikDebug /> */}
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export const Checkbox = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const [htmlId] = useId();
  const id1 = htmlId + '-1';
  const id2 = htmlId + '-2';
  return (
    <div className={formStyles.agreeToTerms}>
      <input
        {...field}
        id={id1}
        role="checkbox"
        type="checkbox"
        ischecked={field.value ? 'checked' : ''}
        aria-checked={field.value}
        tabIndex="0"
        className={checkboxStyles.input}
        {...props}
      />
      <label htmlFor={id1} className={checkboxStyles.labelContainer}>
        <span className={checkboxStyles.checkbox}>
          <svg
            aria-hidden="true"
            focusable="false"
            className={
              field.value ? checkboxStyles.check : checkboxStyles.noCheck
            }
            viewBox="0 0 24 24"
          >
            <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z"></path>
          </svg>
        </span>
        <span className={checkboxStyles.label}>{label}</span>
      </label>
      {meta.error && meta.touched && (
        <span
          className={`${checkboxStyles.errorLabel} ${fieldStyles.errorLabel}`}
        >
          {meta.error}
        </span>
      )}
    </div>
  );
};

export const TextField = ({
  label,
  placeholder,
  ariaDescribedBy,
  ...props
}) => {
  const [field, meta] = useField(props);

  const [htmlId] = useId();
  const id1 = htmlId + '-1';
  const id2 = htmlId + '-2';

  return (
    <div className={fieldStyles.field}>
      <label
        className={`${fieldStyles.label} ${styles.container}`}
        htmlFor={id2}
      >
        {label}
      </label>
      <input
        {...field}
        type="text"
        id={id2}
        placeholder={placeholder}
        tabIndex="0"
        className={`${
          meta.touched && meta.error ? fieldStyles.inputError : ''
        } ${fieldStyles.input}`}
        // aria-invalid="false"
        aria-describedby={`${ariaDescribedBy} ${id1}`}
        {...props}
      />
      <div id={id1} aria-live="polite" aria-atomic="true" role="status">
        {meta.touched && meta.error ? (
          <span className={fieldStyles.errorLabel}>
            <span>Error: {meta.error}</span>
          </span>
        ) : null}
      </div>
      {meta.touched && meta.error && (
        <div className={fieldStyles.errorIcon}>!</div>
      )}
    </div>
  );
};

export const DateField = ({ label, placeholder, ...props }) => {
  const [field, meta] = useField(props);

  const [htmlId] = useId();
  const id1 = htmlId + '-1';
  const id2 = htmlId + '-2';

  const handleBlur = (e) => {
    e.target.placeholder = label;
    field.onBlur(e);
  };

  return (
    <div className={fieldStyles.field}>
      <label
        className={`${fieldStyles.label} ${styles.container}`}
        htmlFor={id2}
      >
        {label}
      </label>
      <MaskedInput
        {...field}
        type="text"
        id={id2}
        tabIndex="0"
        className={`${meta.touched && meta.error && fieldStyles.inputError} ${
          fieldStyles.input
        }`}
        aria-describedby={id1}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholder={label}
        guide={true}
        onFocus={(e) => (e.target.placeholder = placeholder)}
        onBlur={handleBlur}
        {...props}
      />
      <div id={id1} aria-live="polite" aria-atomic="true" role="status">
        {meta.touched && meta.error ? (
          <span className={fieldStyles.errorLabel}>
            <span>Error: {meta.error}</span>
          </span>
        ) : null}
      </div>
      {meta.touched && meta.error && (
        <div className={fieldStyles.errorIcon}>!</div>
      )}
    </div>
  );
};
