import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import loginFormStyles from '../styles/login/Form.module.css';
import loginStyles from '../styles/Login.module.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import nextId from 'react-id-generator';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import _ from 'lodash';
import { CustomTextInput } from '../components/CustomTextInput';
import { SubmitButton } from '../components/SubmitButton';
import { TitleContent } from '../components/TitleContent';
import { RegisterPageContainer } from '../components/RegisterPageContainer';
import { ErrorBox } from '../components/ErrorBox';
import { registerGuest } from '../api/authentication';
import { Redirect, useHistory } from 'react-router-dom';
import { CONTACT_US, GUEST, DISCOVER } from '../navigation/CONSTANTS';
import { differenceInYears, format } from 'date-fns/esm';
import { logAmplitudeEvent } from '../api/integration';
import { clearSignUpType } from '../utilities/loginType';
import { setPFToken } from '../utilities/TokenHelper';

const useStyles = makeStyles(() => ({
  trouble: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 400,
  },
  contactUs: {
    marginLeft: 4,
    color: '#D91E61',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

const formatDate = (value: string) => {
  let result = value.slice(0, 10);
  if (value?.length >= 2 && value[1] !== '/' && value[2] !== '/') {
    result = insert(result, 2, '/');
  }
  if (value?.length >= 4 && value[3] !== '/' && value[4] !== '/') {
    result = insert(result, 5, '/');
  }
  return result.slice(0, 10);
};

function dateIsValid(dateStr) {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (dateStr?.match(regex) === null) {
    return false;
  }

  const dateList = dateStr?.split('/');

  const [month, day, year] = dateList || [];
  if (month && day && year) {
    const isoFormattedStr = `${year}-${month}-${day}`;

    const date = new Date(isoFormattedStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false;
    }

    return date.toISOString().startsWith(isoFormattedStr);
  } else {
    return false;
  }
}

const digitsOnly = (value) => /^\d+$/.test(value);

export const GuestInfo: React.FC<any> = () => {
  const classes = useStyles();
  const [showErrorBox, setShowErrorBox] = useState(false);
  const [showPromoCodeErrorBox, setShowPromoCodeErrorBox] = useState(false);

  const { authState, setRegistering, verifyPFToken } = useAuth();
  const history = useHistory();

  if (!authState.registerUser?.email || !authState.registerUser?.registrationType) {
    return <Redirect to={GUEST} />;
  }

  return (
    <RegisterPageContainer>
      <TitleContent title="Sign Up" subTitle="Finish signing up. If you have a Promo Code, enter it in below." />
      <Formik
        validateOnChange
        initialValues={{
          firstName: '',
          lastName: '',
          dob: '',
          zipCode: '',
          promoCode: '',
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .required()
            .matches(/^[aA-zZ\-\s\']+$/, 'Only letters, spaces and dash(-) are allowed for this field ')
            .label('First Name'),
          lastName: Yup.string()
            .required()
            .matches(/^[a-zA-Z0-9\-\s\']*$/, 'Only letters, spaces, dash(-) and numbers are allowed for this field ')
            .label('Last Name'),
          dob: Yup.string()
            .required()
            .test('isValidPass', 'Please enter the date in this format “MM/DD/YYYY”', dateIsValid)
            .test('18YearsOld', 'You must be 18 years or older to join', (value) => differenceInYears(new Date(), new Date(value)) >= 18)
            .label('Date of Birth'),
          zipCode: Yup.string()
            .required()
            .min(5, 'Please enter a valid 5-digit zip code.')
            .max(5, 'Please enter a valid 5-digit zip code.')
            .test('isValidPass', 'Please enter a valid 5-digit zip code.', digitsOnly)
            .label('Zip Code'),
        })}
        onSubmit={async (values) => {
          logAmplitudeEvent('Guest Signup: Personal Details', {
            'First Name': values.firstName,
            'Last Name': values.lastName,
            'Promo Code': values.promoCode,
          });
          const formatDob = format(new Date(values.dob), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
          try {
            setShowErrorBox(false);
            setShowPromoCodeErrorBox(false);
            const res = await registerGuest({
              email: authState.registerUser.email,
              firstName: values.firstName,
              lastName: values.lastName,
              zipcode: values.zipCode,
              birthdate: formatDob,
              registrationType: authState.registerUser.registrationType,
              promoCode: values.promoCode,
              sponsorId: parseInt(authState.registerUser.sponsorId) || undefined,
              inviteId: authState.registerUser.inviteId || undefined,
            });
            setRegistering(false);
            setPFToken(res?.data?.token);
            sessionStorage.setItem('token', res?.data?.token);
            clearSignUpType();
            await verifyPFToken(true);
            history.push(DISCOVER);
          } catch (error) {
            if (error?.response?.data?.Code === 'InvalidPromoCode') {
              setShowPromoCodeErrorBox(true);
            } else {
              setShowErrorBox(true);
            }
          }
        }}
      >
        {(props) => {
          const disabledButton = !props.values.firstName || !props.values.lastName || !props.values.dob || !props.values.zipCode || !_.isEmpty(props.errors);
          return (
            <Form className={`${loginFormStyles.form} ${loginStyles.form}`}>
              <fieldset>
                <CustomTextInput
                  type="text"
                  name="firstName"
                  label="firstName"
                  id={nextId('id-')}
                  placeholder="First Name"
                  onChange={(e) => {
                    setShowErrorBox(false);
                    props.setFieldValue('firstName', e.target.value);
                  }}
                />
                <CustomTextInput
                  type="text"
                  name="lastName"
                  label="lastName"
                  id={nextId('id-')}
                  placeholder="Last Name"
                  onChange={(e) => {
                    setShowErrorBox(false);
                    props.setFieldValue('lastName', e.target.value);
                  }}
                />
                <CustomTextInput
                  type="text"
                  name="dob"
                  label="dob"
                  onKeyDown={(e) => {
                    var ASCIICode = e.which ? e.which : e.keyCode;
                    if (ASCIICode === 37 || ASCIICode === 39) {
                      return;
                    }
                    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setShowErrorBox(false);
                    if (e.target.value.length > props.values.dob.length) {
                      let value = e.target.value.replace(/\//g, '');
                      props.setFieldValue('dob', formatDate(value));
                    } else {
                      props.setFieldValue('dob', e.target.value);
                    }
                  }}
                  onFocus={(e) => {
                    e.target.placeholder = 'MM/DD/YYYY';
                    if (props.values.dob) {
                      props.setFieldTouched('dob', true);
                    }
                  }}
                  onBlur={(e) => {
                    e.target.placeholder = 'Date of Birth';
                    props.setFieldTouched('dob', true);
                  }}
                  id={nextId('id-')}
                  placeholder="Date of Birth"
                />
                <CustomTextInput
                  type="text"
                  name="zipCode"
                  label="zipCode"
                  id={nextId('id-')}
                  placeholder="Zip Code - Home Address"
                  onChange={(e) => {
                    setShowErrorBox(false);
                    props.setFieldValue('zipCode', e.target.value);
                  }}
                />
                <CustomTextInput
                  type="text"
                  name="promoCode"
                  label="promoCode"
                  id={nextId('id-')}
                  placeholder="Promo code (Optional)"
                  onChange={(e) => {
                    setShowErrorBox(false);
                    setShowPromoCodeErrorBox(false);
                    props.setFieldValue('promoCode', e.target.value);
                  }}
                />
                <ErrorBox
                  show={showErrorBox}
                  title="Your info could not be found in our database"
                  tips={[
                    'Moved recently? Try the zip code from your previous home address',
                    'Remember to use your legal name or the name you’re insured under',
                    'Double check the DOB format: MM/DD/YYYY',
                  ]}
                />
                <ErrorBox show={showPromoCodeErrorBox} title="Invalid promotion" tips={['Promo code is optional to create an account']} />
                <SubmitButton isSubmitting={props.isSubmitting} isRegister={true} disabled={disabledButton}>
                  Submit
                </SubmitButton>
                {showErrorBox && (
                  <div className={classes.trouble}>
                    Still Having trouble?{' '}
                    <span
                      className={classes.contactUs}
                      onClick={() => {
                        history.push(CONTACT_US);
                      }}
                    >
                      Contact Us
                    </span>
                  </div>
                )}
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </RegisterPageContainer>
  );
};
