import { OverlayView } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react'
import markerStyles from '../styles/Marker.module.css';
import markerImg from '../Assets/marker.png';
import markerActiveImg from '../Assets/marker-active.png';
import { Link } from 'react-router-dom';

export default function RegionMarker({ region, mapRef, handleUpdateSearch }) {

    const [mouseOver, setMouseOver] = useState(false);
    const handleClick = (lat, lng) => {

        console.log("clicked.", lat, lng);
        // TODO: center map to region.point.coordinates AND set zoom = 8 original
        // ...
        // option a: pass mapref and use that.
        // option b: refactor to context api and use that.

        mapRef.panTo({ lat, lng })
        mapRef.setZoom(11);
        handleUpdateSearch();

    }
    const handleMouseEnter = (e) => setMouseOver(true);
    const handleMouseLeave = (e) => setMouseOver(false);

    return (

        <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{
                lat: region?.point?.coordinates[1],
                lng: region?.point?.coordinates[0]
            }}
        >
            <div
                onClick={() => { handleClick(region?.point?.coordinates[1], region?.point?.coordinates[0]) }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                title={region?.name} className={`${markerStyles.marker} focus_outline`}>
                <div className={`${mouseOver ? markerStyles.regionMarkerHover : ''} ${markerStyles.regionMarker}`}
                    style={{ transform: "scale(1.125, 1.125)" }}>{region?.total_studios > 99 ? "99+" : region?.total_studios}</div>
            </div>
        </OverlayView>
    )
}
