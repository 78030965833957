import React from 'react';
import flexStyles from '../../../styles/Flex.module.css';
import reservationsStyles from '../../../styles/user/Reservations.module.css';
import reservationStyles from '../../../styles/user/Reservation.module.css';

import bannerStyles from '../../../styles/Banner.module.css';
import useModal from '../../../shared/Modal/useModal';
import defaultStudioImg from '../../../Assets/defaultStudio.jpg';
import * as CancelReservationModalModule from '../../../shared/Modal/CancelReservationModal';

import { Link } from 'react-router-dom';
import { HoursUntil, ToTimezone } from '../../../utilities/date';
import * as InvitationModalModule from '../../../shared/Modal/InvitationModal';

const CancelReservationModal: any =
  CancelReservationModalModule.CancelReservationModal;
const InvitationModal: any = InvitationModalModule.InvitationModal;

export const ReservationCard = ({ reservation, loadUpcomingReservations }) => {
  const { isShowing, toggle, ref } = useModal();
  const {
    isShowing: invitationIsShowing,
    toggle: invitationToggle,
    ref: invitationRef,
  } = useModal();
  const { studio } = reservation;

  return (
    <>
      <div
        className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-4']} ${flexStyles['col-sm-6']} ${reservationsStyles.card}`}
      >
        <div className={reservationStyles.reservation}>
          <div
            className={bannerStyles.container}
            style={{
              backgroundImage: `url(${
                studio.banner_image || defaultStudioImg
              })`,
            }}
          >
            <Link
              className={bannerStyles.copy}
              to={`/studio/${reservation.studio.id}?from_source=Reservations`}
            >
              <h3 className={bannerStyles.title}>
                {reservation.studio.name}{' '}
                {reservation?.session.instructor?.name &&
                  `with ${reservation?.session.instructor?.name}`}
              </h3>
            </Link>
          </div>
          <div className={reservationStyles.content}>
            <div className={reservationStyles.info}>
              {/* <h3 className={`${reservationStyles.date}`} >{format(GetDate(reservation.session.start_datetime), 'h:mm a E LLL dd')}</h3> */}
              <h3 className={`${reservationStyles.date}`}>
                {ToTimezone(
                  reservation.session.start_datetime,
                  studio.timezone,
                  'h:mm A ddd MMM D z'
                )}
              </h3>
              <h4 className={reservationStyles.tiny}>
                {reservation.session.name}
              </h4>
            </div>

            <div className={reservationStyles.actions}>
              <div className={reservationStyles.action}>
                <a
                  className={reservationStyles.button}
                  href={`https://maps.google.com?q=${studio.address}, ${studio.city} ${studio.state} ${studio.zipcode}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Map Location
                </a>
              </div>

              {reservation.is_canceled ? (
                <h3 className={reservationStyles.noCancel}>Invite Friend</h3>
              ) : (
                <div className={reservationStyles.action}>
                  <a
                    onClick={invitationToggle}
                    className={reservationStyles.button}
                  >
                    Invite Friend
                  </a>
                </div>
              )}

              {reservation.cancellation_allowed &&
              HoursUntil(reservation.session.start_datetime) > 12 ? (
                <>
                  {reservation.is_canceled ? (
                    <h3 className={reservationStyles.noCancel}>Canceled</h3>
                  ) : (
                    <div className={reservationStyles.action}>
                      <a className={reservationStyles.button} onClick={toggle}>
                        Cancel
                      </a>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p className={reservationStyles.noCancel}>
                    This reservation cannot be canceled or refunded.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CancelReservationModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        reservation={reservation}
        onSuccess={loadUpcomingReservations}
      />

      <InvitationModal
        ref={invitationRef}
        isShowing={invitationIsShowing}
        hide={invitationToggle}
        reservation={reservation}
        onSuccess={loadUpcomingReservations}
      />
    </>
  );
};
