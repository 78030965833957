import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useAuth } from '../../contexts/AuthContext';
import { DISCOVER, LOGIN, REGISTER_NEW } from '../../navigation/CONSTANTS';
import { useRegisterFlowType } from '../../RegisterNew/useRegisterFlowType';
import { logAmplitudeEvent, logBrazeEvent } from '../../api/integration';
import { RegistrationType } from '../../RegisterNew/CONSTANT';

import loadingStyles from '../../styles/Loading.module.css';
import submitStyles from '../../styles/Submit.module.css';

import logoImg from './peerfit-logo.png';
import unsplashImg from './signin-bg.jpg';
import styles from './index.module.css';

export const SignIn = () => {
  const { authState, register_sponsored, setRegisterUser, login } = useAuth();
  const history = useHistory();
  const { shouldGoToNewAuthFlow } = useRegisterFlowType();

  if (shouldGoToNewAuthFlow === undefined) {
    return <div className={`${submitStyles.spinner} ${loadingStyles.spinner}`} />;
  }

  if (authState.isAuthenticated) {
    return <Redirect push to={DISCOVER} />;
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <img style={{ width: '33.87vw' }} src={logoImg} alt="" />
        </div>
        <div style={{ backgroundImage: `url(${unsplashImg})` }} className={styles.splash}></div>
        <div className={styles.title}>Redefine wellness at work</div>
      </div>
      <div className={styles.bottom}>
        <Button variant="contained" className={styles.button} onClick={login}>
          Sign in
        </Button>

        <span
          className={styles.signUpText}
          onClick={() => {
            if (shouldGoToNewAuthFlow) {
              logAmplitudeEvent('Signup: Start', {
                Source: 'Mobile',
                'Login Type': 'New',
              });
              logBrazeEvent('Signup: Start', {
                Source: 'Mobile',
                'Login Type': 'New',
              });
              setRegisterUser({
                registrationType: RegistrationType.Core,
              });
              history.push({
                pathname: REGISTER_NEW,
              });
            } else {
              logAmplitudeEvent('Signup: Start', {
                Source: 'Mobile',
                'Login Type': 'B2C',
              });
              logBrazeEvent('Signup: Start', {
                Source: 'Mobile',
                'Login Type': 'B2C',
              });
              register_sponsored();
            }
          }}
        >
          Sign Up with Employer Sponsor
        </span>
      </div>
    </div>
  );
};
