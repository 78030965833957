import React, { useEffect, useRef, useState } from 'react';
import { useSearch } from '../../contexts/SearchContext';
import SearchMenu_Sort from '../../Explore/SearchMenu_Sort';
import StudioCard from '../../Explore/StudioCard';
import Classes from '../../Explore/Sessions';

export const SearchResult = () => {
  const [show, setShow] = useState(false);
  const { studioList, searchState, sessionList, sessionsLoading, studiosLoading } = useSearch();
  const ref = useRef();

  useEffect(() => {
    const root = document.getElementById('root');
    if (show) {
      root?.setAttribute('style', 'overflow: hidden; height: 100%;');
    } else {
      root?.setAttribute('style', 'height: 100%;');
    }

    return () => {
      root?.setAttribute('style', 'height: 100%;');
    };
  }, [show]);

  const swipeHandler = (e) => {
    if ((e as any).detail.dir === 'up') {
      setShow(true);
    }
    if ((e as any).detail.dir === 'down') {
      setShow(false);
    }
  };

  useEffect(() => {
    if (ref.current) {
      (ref.current as HTMLDivElement).addEventListener('swiped', swipeHandler);
      return () => (ref.current as HTMLDivElement).removeEventListener('swiped', swipeHandler);
    }
  }, [ref.current]);

  const containerHeight = window.innerHeight > 750 ? 700 : 580;
  const containerBottom = window.innerHeight > 750 ? -480 : -360;

  return (
    <div
      style={{
        height: containerHeight,
        width: '100vw',
        position: 'absolute',
        zIndex: 10,
        bottom: show ? -20 : containerBottom,
        borderRadius: '16px 16px 0 0',
        background: '#fff',
        transition: 'bottom 0.2s',
      }}
    >
      <div
        ref={ref}
        style={{
          height: 36,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => {
          setShow(!show);
        }}
      >
        <div
          style={{
            width: 120,
            height: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: 50,
              height: 4,
              background: '#D9D9D9',
              borderRadius: 5,
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          padding: '0 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: 14,
        }}
      >
        <div
          style={{
            padding: 10,
          }}
        >
          {searchState.current_tab === 1 ? (sessionsLoading ? null : sessionList.length + ' results') : studiosLoading ? null : studioList.length + ' results'}
        </div>
        <div>
          <SearchMenu_Sort showResult={show} />
        </div>
      </div>
      <div
        style={{
          overflow: 'scroll',
          padding: '0 10px 230px',
          height: '100%',
        }}
      >
        {searchState.current_tab === 1 ? (
          <Classes />
        ) : (
          <>
            {studioList?.map((x, i) => (
              <StudioCard key={i} studio={x} source="Explore" />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
