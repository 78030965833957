import React, { useEffect, useState } from 'react';
import heroStyles from '../styles/fitness-partners/Hero.module.css';
import videoStyles from '../styles/fitness-partners/Video.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import pageStyles from '../styles/fitness-partners/Page.module.css';
import featureQuoteStyles from '../styles/fitness-partners/FeatureQuote.module.css';
import pageclampStyles from '../styles/fitness-partners/Pageclamp.module.css';
import stackingBulletsStyles from '../styles/fitness-partners/StackingBullets.module.css';
import mindBodyStyles from '../styles/fitness-partners/MindBody.module.css';
import partnersStyles from '../styles/fitness-partners/Partners.module.css';
import studioFormStyles from '../styles/fitness-partners/StudioForm.module.css';
import wellnessFormStyles from '../styles/corporate-wellness/WellnessForm.module.css';

import useWindowSize, { _MD } from '../shared/hooks/useWindowSize';
import useModal from '../shared/Modal/useModal';
import { VideoModal } from '../shared/Modal/VideoModal';

export default function CorporateWellness() {
  const { isShowing, toggle, ref } = useModal();
  const [videoId, setVideoId] = useState();
  const size = useWindowSize();

  const openVideo = (id) => {
    setVideoId(id);
    toggle();
  };

  useEffect(() => {
    if (!isShowing) setVideoId(null);
  }, [isShowing]);

  return (
    <div style={{ height: 'auto', position: 'relative', minHeight: '159px' }}>
      <div
        className={heroStyles.container}
        style={{
          backgroundImage: `url(${
            size[3] > _MD
              ? '/01f1cd90bc4b5d76c4ebb95b8f653196.jpg'
              : size[3] == _MD
              ? '/d4f2fd6b80ad39ed8098ee940b6bd309.jpg'
              : ''
          })`,
        }}
      >
        <div className={heroStyles.content}>
          <h2 className={heroStyles.title}>
            <span>Flexible fitness built for corporate wellness.</span>
          </h2>
          <p className={heroStyles.copy}>
            <span>
              Peerfit is the fitness benefit your team will actually use. Boost
              employee engagement and track usage while your team stays healthy
              and motivated.
            </span>
          </p>
          <a
            className={`${heroStyles.btn} ${btnDefaultStyles.button} focus_outline`}
            href="https://wellness.peerfit.com/"
          >
            <span>Get Connected</span>
          </a>
          <div id="how-it-works" className={heroStyles.titleAndVideo}>
            <h3 className={heroStyles.howItWorks}>
              <span>How It Works</span>
            </h3>
            <button
              onClick={() => openVideo('291502379')}
              className={`${heroStyles.videoContainer} ${videoStyles.container}`}
            >
              <img
                className={videoStyles.thumb}
                src="/9acc224e5bb5296e0d4aae992bb80997.jpg"
                alt="Smarter Wellness Spend Video Thumbnail"
              />
              <div className={videoStyles.iconContainer}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className={videoStyles.icon}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 22v-20l18 10-18 10z"></path>
                </svg>
                <span className={videoStyles.screenReadersOnly}>
                  Opens a new dialog
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <h2 className={pageStyles.quotesHeadline}>
        <span>What employers are saying</span>
      </h2>
      <div
        className={`${pageStyles.quoteContainer} ${featureQuoteStyles.container}`}
      >
        <div className={`${featureQuoteStyles.inner} ${pageclampStyles.clamp}`}>
          <svg
            aria-hidden="true"
            focusable="false"
            className="featureQuoteStyles.icon"
            viewBox="0 0 48 36"
          >
            <path d="M26,23.45 C26,13.168 33.784,2.412 46,0 L47.968,4.252 C43.538,5.922 39.642,11.736 39.208,15.744 C44.19,16.528 48,20.838 48,26.042 C48,32.406 42.832,36 37.602,36 C31.572,36 26,31.39 26,23.45 Z M0,23.45 C0,13.168 7.784,2.412 20,0 L21.968,4.252 C17.538,5.922 13.642,11.736 13.208,15.744 C18.19,16.528 22,20.838 22,26.042 C22,32.406 16.832,36 11.602,36 C5.572,36 0,31.39 0,23.45 Z"></path>
            <path d="M26,23.45 C26,13.168 33.784,2.412 46,0 L47.968,4.252 C43.538,5.922 39.642,11.736 39.208,15.744 C44.19,16.528 48,20.838 48,26.042 C48,32.406 42.832,36 37.602,36 C31.572,36 26,31.39 26,23.45 Z M0,23.45 C0,13.168 7.784,2.412 20,0 L21.968,4.252 C17.538,5.922 13.642,11.736 13.208,15.744 C18.19,16.528 22,20.838 22,26.042 C22,32.406 16.832,36 11.602,36 C5.572,36 0,31.39 0,23.45 Z"></path>
          </svg>
          <div className={featureQuoteStyles.quote}>
            "Getting a wellness program across two states that would be
            attractive to office workers, warehouse workers and retail workers
            is one of the reasons we chose Peerfit... We’ve had our employees
            reserve over 1,000 classes in the last three quarters."
          </div>
          <h3 className={featureQuoteStyles.author}>
            Kim Davis, Sr. Director of HR
          </h3>
          <h3 className={featureQuoteStyles.author}>Alex Lee, Inc.</h3>
        </div>
      </div>
      <div className={stackingBulletsStyles.container}>
        <ul className={stackingBulletsStyles.list}>
          <li className={stackingBulletsStyles.blurb}>
            <span>Receive Monthly Reports</span>
          </li>
          <li className={stackingBulletsStyles.blurb}>
            <span>Easy to Integrate</span>
          </li>
          <li className={stackingBulletsStyles.blurb}>
            <span>No Extra Admin Work</span>
          </li>
        </ul>
      </div>
      <div className={partnersStyles.container}>
        <h2 className={partnersStyles.title}>
          <span>Your employees are in good hands</span>
        </h2>
        <div>
          <img
            className={partnersStyles.studioLogo}
            src="/34120b478c3230c26519a3e46baaedc9.png"
            alt="Pure Barre Logo"
          />
          <img
            className={partnersStyles.studioLogo}
            src="/c4a7a5135724b9f22b9d67f6843462d8.png"
            alt="yogaworks Logo"
          />
          <img
            className={partnersStyles.studioLogo}
            src="/00d8d0cb0829d813f49d3b49b9a735a0.png"
            alt="YouFit Logo"
          />
          <img
            className={partnersStyles.studioLogo}
            src="/886d18e646f6e53e80622319bbf719af.png"
            alt="Title Boxing Club Logo"
          />
        </div>
      </div>
      <div className={wellnessFormStyles.container}>
        <div className={wellnessFormStyles.content}>
          <h2 className={wellnessFormStyles.title}>
            <span>Interested in Peerfit for your company?</span>
          </h2>
          <h3 className={wellnessFormStyles.instruction}>
            <span>Build a smarter wellness program for your team.</span>
          </h3>
          <a
            className={`${wellnessFormStyles.btn} ${btnDefaultStyles.button} focus_outline`}
            href="https://wellness.peerfit.com/"
          >
            <span>Get Connected</span>
          </a>
        </div>
      </div>

      <VideoModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        videoId={videoId}
      />
    </div>
  );
}
