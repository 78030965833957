import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AddCardModal } from '../../shared/Modal/AddCardModal';
import useModal from '../../shared/Modal/useModal';
import pageStyles from '../../styles/account/payment/Page.module.css';



export const AddCard = () => {
  const { isShowing, toggle, ref } = useModal();
  const handleOpen = () => {
    toggle();
  };
  const { authState, verify } = useAuth();
  return (
    <>
      <button
        className={pageStyles.addCard}
        data-cy="add-credit-card-btn"
        onClick={handleOpen}
      >
        <AddIcon fontSize="large" className={pageStyles.plus} />
        <h3>
          <span>Add Card</span>
        </h3>
      </button>
      <AddCardModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        onSuccess={verify}
      />
    </>
  );
};
