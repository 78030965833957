import React, { useState } from 'react';
import aboutStyles from '../styles/studio/About.module.css';
import linkStyles from '../styles/Link.module.css';
import useWindowSize, { _MD } from '../shared/hooks/useWindowSize';
import desktopStyles from '../styles/Desktop.module.css';
import {
  getStudioLogInfo,
  logAmplitudeEvent,
  logBrazeEvent,
} from '../api/integration';
import { postMessageToRN } from '../utilities/rn';

export default function About({ studio }) {
  const size = useWindowSize();
  const [logoExists, setLogoExists] = useState(true);
  const handleLogoError = () => setLogoExists(false);

  return (
    <>
      <div
        className={`${size[3] > _MD ? desktopStyles.about : ''} ${
          aboutStyles.container
        }`}
      >
        <p className={aboutStyles.desc}>{studio?.description}</p>
        {studio?.url && (
          <a
            rel="noopener noreferrer"
            className={`${linkStyles.link} focus_outline`}
            target="_blank"
            onClick={() => {
              postMessageToRN({ key: 'SHOW_GO_BACK' });
              const studioInfo = {
                ...getStudioLogInfo(studio),
                URL: studio.url,
              };
              logBrazeEvent('Studio: Open Website', studioInfo);
              logAmplitudeEvent('Studio: Open Website', studioInfo);
            }}
            href={studio.url}
          >
            {studio.url}
          </a>
        )}
        {studio?.special_instructions && (
          <div>
            <h3>
              <span>Pro Tip</span>
            </h3>
            <p>{studio?.special_instructions}</p>
          </div>
        )}
        {studio?.logo && logoExists && (
          <img
            src={studio.logo}
            onError={handleLogoError}
            className={aboutStyles.image}
            alt={studio?.name}
          ></img>
        )}
      </div>
    </>
  );
}
