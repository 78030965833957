export const initialAuthState = {
  isLoading: true,
  isLoggingOut: false,
  user: null,
  isAuthenticated: false,
  isAdmin: false,
  token: null,
  error: null,
  registerUser: null,
  changePasswordPinCode: '',
  registering: false,
};
