import React, { useEffect, useState } from 'react';
import heroStyles from '../styles/fitness-partners/Hero.module.css';
import videoStyles from '../styles/fitness-partners/Video.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import pageStyles from '../styles/fitness-partners/Page.module.css';
import featureQuoteStyles from '../styles/fitness-partners/FeatureQuote.module.css';
import pageclampStyles from '../styles/fitness-partners/Pageclamp.module.css';
import stackingBulletsStyles from '../styles/fitness-partners/StackingBullets.module.css';
import mindBodyStyles from '../styles/fitness-partners/MindBody.module.css';
import partnersStyles from '../styles/fitness-partners/Partners.module.css';
import studioFormStyles from '../styles/fitness-partners/StudioForm.module.css';
import accessStyles from '../styles/individual-subscription/Access.module.css';
import benefitsStyles from '../styles/individual-subscription/Benefits.module.css';
import referStyles from '../styles/individual-subscription/Refer.module.css';

import useWindowSize, { _MD } from '../shared/hooks/useWindowSize';
import useModal from '../shared/Modal/useModal';
import { VideoModal } from '../shared/Modal/VideoModal';
import { Link } from 'react-router-dom';
import { EXPLORE, REGISTER } from '../navigation/CONSTANTS';

export default function IndividualSubscription() {
  const { isShowing, toggle, ref } = useModal();
  const size = useWindowSize();

  return (
    <div style={{ height: 'auto', position: 'relative', minHeight: '159px' }}>
      <div
        className={heroStyles.container}
        style={{
          backgroundImage: `url(${
            size[3] > _MD
              ? '/d653b82dc4a6416cd50363f879a6d872.png'
              : '12f44115815a15a0e948ed459474ff58.png'
          })`,
        }}
      >
        <div className={heroStyles.content}>
          <h2 className={heroStyles.title}>
            <span>
              The flexible fitness subscription that puts you in control.
            </span>
          </h2>
          <p className={heroStyles.copy}>
            <span>
              Get access to all of your favorite fitness studios and gyms under
              one account.
            </span>
          </p>
          <Link
            className={`${heroStyles.btn} ${btnDefaultStyles.button} focus_outline`}
            to={EXPLORE}
          >
            <span>Explore</span>
          </Link>
        </div>
      </div>
      <div className={accessStyles.container}>
        <img
          src="/30c45fcd23a05da085450cfb4fa7d114.png"
          className={accessStyles.phoneImg}
          alt=""
        />
        <div className={accessStyles.column}>
          <div className={accessStyles.content}>
            <h2 className={accessStyles.title}>
              <span>Any workout, anywhere, anytime.</span>
            </h2>
            <p className={accessStyles.copy}>
              <span>
                Access the nationwide network of studios and gyms for
                $8.95/month, then pay per class reservation or gym time at a
                member rate for $3.50 per credit.
              </span>
            </p>
            <div className={accessStyles.btnContainer}>
              <Link
                className={`${accessStyles.btn} ${btnDefaultStyles.button} focus_outline`}
                to={REGISTER}
              >
                <span>Create an Account</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={accessStyles.quoteBlock}>
        <div className={`${featureQuoteStyles.inner} ${pageclampStyles.clamp}`}>
          <svg
            aria-hidden="true"
            focusable="false"
            className={featureQuoteStyles.icon}
            viewBox="0 0 48 36"
          >
            <path d="M26,23.45 C26,13.168 33.784,2.412 46,0 L47.968,4.252 C43.538,5.922 39.642,11.736 39.208,15.744 C44.19,16.528 48,20.838 48,26.042 C48,32.406 42.832,36 37.602,36 C31.572,36 26,31.39 26,23.45 Z M0,23.45 C0,13.168 7.784,2.412 20,0 L21.968,4.252 C17.538,5.922 13.642,11.736 13.208,15.744 C18.19,16.528 22,20.838 22,26.042 C22,32.406 16.832,36 11.602,36 C5.572,36 0,31.39 0,23.45 Z"></path>
            <path d="M26,23.45 C26,13.168 33.784,2.412 46,0 L47.968,4.252 C43.538,5.922 39.642,11.736 39.208,15.744 C44.19,16.528 48,20.838 48,26.042 C48,32.406 42.832,36 37.602,36 C31.572,36 26,31.39 26,23.45 Z M0,23.45 C0,13.168 7.784,2.412 20,0 L21.968,4.252 C17.538,5.922 13.642,11.736 13.208,15.744 C18.19,16.528 22,20.838 22,26.042 C22,32.406 16.832,36 11.602,36 C5.572,36 0,31.39 0,23.45 Z"></path>
          </svg>
          <div className={featureQuoteStyles.quote}>
            <span>
              "I travel a lot, and one of the reasons I enjoy Peerfit so much is
              the network of incredible, locally-owned gyms in the cities I
              visit. Peerfit has been a great way to see exciting, new sides of
              the communities I visit, and I’ve met so many cool people along
              the way!"
            </span>
          </div>
          <h3 className={`${accessStyles.author} ${featureQuoteStyles.author}`}>
            Trent P., Tampa, FL
          </h3>
        </div>
      </div>
      <div className={benefitsStyles.container}>
        <div className={benefitsStyles.column}>
          <div className={benefitsStyles.benefit}>
            <h3>
              <span>Smart</span>
            </h3>
            <p>
              <span>
                Don’t pay for fitness you aren’t using. Unlike other
                subscriptions, our pay-as-you-go model allows you to be in
                control of your spending.
              </span>
            </p>
          </div>
          <div className={benefitsStyles.benefit}>
            <h3>
              <span>Personalized</span>
            </h3>
            <p>
              <span>
                Try a new studio every week, or take your favorite class every
                day. With no limits on studio visits and no blackout dates or
                times, the choice is yours.
              </span>
            </p>
          </div>
        </div>
        <div className={benefitsStyles.column}>
          <img src="/72b2460a4ca7c045c7c0de7fbab4c453.png" alt="" />
        </div>
        <div className={benefitsStyles.column}>
          <div className={benefitsStyles.benefit}>
            <h3>
              <span>Engaging</span>
            </h3>
            <p>
              <span>
                With a built-in community to keep you motivated, Peerfit
                encourages accountability, helping you reach your personal
                fitness goals.
              </span>
            </p>
          </div>
          <div className={benefitsStyles.benefit}>
            <h3>
              <span>Simple</span>
            </h3>
            <p>
              <span>
                $8.95/month subscription and then, pay per reservation at any
                studio or gym across the nationwide Peerfit Network.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={referStyles.container}>
        <div className={referStyles.content}>
          <h2>
            <span>Recommend your company for Peerfit.</span>
          </h2>
          <p>
            <span>
              Learn how your company could be paying for your fitness.
            </span>
          </p>
        </div>
        <a
          className={`${referStyles.button} ${btnDefaultStyles.button} focus_outline`}
          href="https://wellness.peerfit.com/"
        >
          <span>Learn More</span>
        </a>
      </div>
    </div>
  );
}
