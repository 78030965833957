import React, { useEffect } from 'react';
import pageStyles from '../../styles/account/preferences/Page.module.css';
import defaultBtnStyles from '../../styles/button/Default.module.css';
import submitStyles from '../../styles/Submit.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import { useFormikContext } from 'formik';

export default function SubmitButton({ label, disabled, ...props }) {
  const { isSubmitting } = useFormikContext();
  return (
    <button
      type="submit"
      disabled={disabled || isSubmitting}
      className={`${pageStyles.submit} ${defaultBtnStyles.button} ${
        disabled ? 'disabled' : ''
      } focus_outline`}
    >
      <div
        className={`${
          isSubmitting
            ? `${submitStyles.spinner} ${loadingStyles.spinner}`
            : loadingStyles.hidden
        }`}
      ></div>
      <span
        className={`${
          isSubmitting ? submitStyles.invisible : submitStyles.visible
        }`}
      >
        <span>{label}</span>
      </span>
    </button>
  );
}
