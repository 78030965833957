import React, { useState } from 'react';
import cardStyles from '../../styles/Card.module.css';
import changePasswordStyles from '../../styles/ChangePassword.module.css';
import loginStyles from '../../styles/Login.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import submitStyles from '../../styles/Submit.module.css';

import { Card, CardContent, CardHeader } from '@material-ui/core';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/AuthContext';
import { logAmplitudeEvent } from '../../api/integration';
import { useHistory } from 'react-router-dom';
import { REGISTER_CHECK_YOUR_EMAIL } from '../../navigation/CONSTANTS';
import { getChangePasswordCode } from '../../api/authentication';
import { parseJwt } from '../../utilities/jwt';
import { ErrorBox } from '../../components/ErrorBox';
import roundedButtonStyle from '../../styles/button/Rounded.module.css';

export default function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const { password_reset, authState } = useAuth();
  const shouldGoToNewAuthFlow = authState?.user?.is_b2c === false;
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleChangePassword = async () => {
    if (shouldGoToNewAuthFlow) {
      try {
        setIsLoading(true);
        const email = parseJwt(authState.token).email;
        const res = await getChangePasswordCode({
          email,
        });
        if (res.status === 200 || res.status === 204) {
          history.push({
            pathname: REGISTER_CHECK_YOUR_EMAIL,
            search: '?from=changePassword',
          });
        } else {
          setErrorMessage(res.statusText);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      password_reset();
    }
  };

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required().label('Old Password'),
    new_password1: Yup.string().required().label('New Password'),
    new_password2: Yup.string().required().label('Confirm New Password'),
  });
  const handleSubmit = (values) => {
    console.log('submit form here. ', values);
    // TODO:
    // API CALL TO CHANGE PASSWORD
  };

  if (shouldGoToNewAuthFlow === undefined) {
    return (
      <div className={`${submitStyles.spinner} ${loadingStyles.spinner}`} />
    );
  }
  return (
    <Card classes={{ root: cardStyles.container }}>
      <CardHeader
        title={
          <h3 className={cardStyles.title}>
            <span>Password</span>
            {/* <LockOutlined fontSize="large" className={cardStyles.lockIcon}/> */}
          </h3>
        }
      />
      <CardContent>
        <div className={cardStyles.section}>
          <h5
            className={cardStyles.sectionTitle}
            style={{ margin: '-10px 0px 20px 10px' }}
          >
            <span>Change Password</span>
          </h5>
          <div className={cardStyles.sectionContent}>
            <button
              type="button"
              onClick={handleChangePassword}
              disabled={isLoading}
              style={{ margin: 0 }}
              className={`${changePasswordStyles.submit} ${loginStyles.submit} ${roundedButtonStyle['secondary-button']} ${roundedButtonStyle.button} focus_outline`}
            >
              <div
                className={`${
                  isLoading
                    ? `${submitStyles.spinner} ${loadingStyles.spinner}`
                    : loadingStyles.hidden
                }`}
              ></div>
              <span
                className={
                  isLoading ? submitStyles.invisible : submitStyles.visible
                }
              >
                <span>Change My Password</span>
              </span>
            </button>
            <ErrorBox show={!!errorMessage} title={errorMessage} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
