import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import controllerStyles from '../../styles/Controller.module.css';
import overlayDefaultStyles from '../../styles/overlay/Default.module.css';
import svgStyles from '../../styles/SvgIcon.module.css';
import reservationHeaderStyles from '../../styles/ReservationHeader.module.css';

import studioBannerImg from '../../Assets/studioBanner.jpg';

import btnDefaultStyles from '../../styles/button/Default.module.css';
import loginStyles from '../../styles/Login.module.css';
import fieldStyles from '../../styles/input/Field.module.css';

import submitStyles from '../../styles/Submit.module.css';
import errorFormStyles from '../../styles/error/Form.module.css';

import studioApi from '../../api/studio';
import { Link, useHistory } from 'react-router-dom';
import { PAYMENT, REGISTER } from '../../navigation/CONSTANTS';
import { useAuth } from '../../contexts/AuthContext';
import { Form, Formik } from 'formik';
import { Checkbox } from '../Formik/Checkbox1';
import * as Yup from 'yup';
import { ToTimezone } from '../../utilities/date';
import loadingStyles from '../../styles/Loading.module.css';
import spinnerGroupStyles from '../../styles/spinner/Group.module.css';
import userApi from '../../api/user';

export const NotificationModal = React.forwardRef(
  ({ isShowing, hide, notification, reload }, ref) => {
    const { authState, login, verify, card, hasMembership, memberships } =
      useAuth();
    const { user } = authState || {};
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();
    const handleSubmit = async (values, actions) => {
      await userApi
        .acceptInvitation(notification.data.invitation_id)
        .then(async (res) => {
          await userApi.readNotification(notification.id);

          reload();
          verify(); // pull user object to update credit count.
          hide();
          history.push(`/user/${authState.user?.id}/upcoming`);
        })
        .catch((error) => {
          console.log('ERROR ON BOOKING.', error);
          if (error.status == 400) {
            actions.setErrors({ summary: error.data.Message });
          } else {
            actions.setErrors({
              summary:
                'Unable to create reservation at the moment. Please try again later.',
            });
          }
        });
    };

    const handleClose = async () => {
      console.log('CLOSING!');
      await userApi.readNotification(notification.id).then((res) => {
        console.log('DONE READING NOTIFICATION!');
        reload();
      });
      console.log('HIDING!');
      // hide();
    };
    const [creditCost, setCreditCost] = useState(0);
    const userHasMembership = hasMembership(notification.data.studio_id);
    const [studio, setStudio] = useState({});
    const loadSession = async () => {
      await studioApi.getStudio(notification.data.studio_id).then((res) => {
        setStudio(res);
        console.log('RES:', res);
      });

      // Attempt to get session then get class.
      studioApi
        .getSessionByExternalId(notification.data.session_id)
        .then((res) => {
          if (res == null) {
            studioApi
              .getClassByExternalId(notification.data.studio_class_id)
              .then((r) => {
                console.log('CLASS INVITATION: ', r);
                const cr = hasMembership(studio.id) ? 0 : r?.credits;
                setCreditCost(cr);
              });
          } else {
            console.log('SESSION INVITATION: ', res);

            const cr = hasMembership(res?.studio?.id) ? 0 : res?.credits;
            setCreditCost(cr);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    useEffect(() => {
      console.log('notifications:', notification);
      loadSession();
    }, []);

    const validationSchema = Yup.object().shape(
      studio.cancellation_allowed
        ? {}
        : {
            agreement: Yup.boolean().oneOf(
              [true],
              'Please agree to the Cancellation Policy before proceeding.'
            ),
          }
    );

    return isShowing
      ? ReactDOM.createPortal(
          <React.Fragment>
            <div className={controllerStyles.overlay}>
              <div
                ref={ref}
                role="dialog"
                tabIndex="0"
                data-cy="Login"
                className={overlayDefaultStyles.dialog}
                aria-labelledby="reservation-header-title"
              >
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
                <div
                  data-focus-guard="true"
                  tabIndex="1"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
                <div data-focus-lock-disabled="false">
                  <button
                    className={`${overlayDefaultStyles.close} focus_outline`}
                    aria-label="Close"
                    onClick={hide}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className={`${overlayDefaultStyles.closeIcon} ${svgStyles.icon}`}
                      viewBox="0 0 17 17"
                    >
                      <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z"></path>
                    </svg>
                  </button>

                  {isLoading ? (
                    <div className={overlayDefaultStyles.content}>
                      <div
                        className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`}
                      ></div>
                    </div>
                  ) : (
                    <>
                      <div
                        className={reservationHeaderStyles.header}
                        style={{ backgroundImage: `url(${studioBannerImg})` }}
                      >
                        <div
                          data-cy="reservation-modal"
                          className={reservationHeaderStyles.content}
                        >
                          <div className={reservationHeaderStyles.textWrapper}>
                            <h3 id="reservation-header-title">
                              {authState.isAuthenticated ? (
                                <span>
                                  Invitation from{' '}
                                  {notification.data.inviter_name}
                                </span>
                              ) : (
                                <span>Sign In to Reserve</span>
                              )}
                            </h3>
                            <h4>
                              {notification.data.studio_class_name} at{' '}
                              {notification.data.studio_name}
                            </h4>
                            {/* <h4>{format(GetDate(notification.expiration), 'E, LLL do h:mm a')}</h4> */}
                            <h4>
                              {ToTimezone(
                                notification.expiration,
                                studio?.timezone,
                                'ddd, MMM Do hh:mm A'
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={overlayDefaultStyles.content}>
                        <Formik
                          initialValues={{
                            agreement: false,
                          }}
                          validationSchema={validationSchema}
                          onSubmit={handleSubmit}
                        >
                          {(formikProps) => (
                            <Form className={loginStyles.form}>
                              <fieldset>
                                {authState.isAuthenticated ? (
                                  <>
                                    {formikProps.errors.summary && (
                                      <div
                                        className={errorFormStyles.error}
                                        tabIndex="-1"
                                      >
                                        <span
                                          aria-label={
                                            formikProps.errors.summary
                                          }
                                        >
                                          {formikProps.errors.summary}
                                        </span>
                                      </div>
                                    )}

                                    <div className={fieldStyles.field}>
                                      <div>
                                        <span style={{ float: 'right' }}>
                                          {user.credits}
                                        </span>
                                        <span>Available credits</span>
                                      </div>
                                      <div>
                                        <span style={{ float: 'right' }}>
                                          {creditCost}
                                        </span>
                                        <span>
                                          Credit cost
                                          {userHasMembership &&
                                            ' (w Membership)'}
                                        </span>
                                      </div>
                                      {user.credits < creditCost ? (
                                        <div>
                                          <strong style={{ float: 'right' }}>
                                            $
                                            {(
                                              (creditCost - user.credits) *
                                              3.5
                                            ).toFixed(2)}
                                          </strong>
                                          <strong>
                                            {creditCost - user.credits} credits
                                            needed (1cr x $3.50)
                                          </strong>
                                        </div>
                                      ) : (
                                        <div>
                                          <strong style={{ float: 'right' }}>
                                            {user.credits - creditCost}
                                          </strong>
                                          <strong>Credits Remaining</strong>
                                        </div>
                                      )}
                                    </div>

                                    {user.credits < creditCost && !card ? (
                                      <>
                                        <div className={loginStyles.copy}>
                                          <span>Not enough credits.</span>
                                          <Link
                                            className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`}
                                            to={PAYMENT}
                                          >
                                            <span>Add a Card</span>
                                          </Link>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {user.credits < creditCost && card ? (
                                          <div className={fieldStyles.field}>
                                            <span>
                                              *The remainder will be charged to
                                              your card on file.
                                            </span>
                                          </div>
                                        ) : (
                                          ''
                                        )}

                                        {studio.cancellation_allowed ? (
                                          ''
                                        ) : (
                                          <Checkbox
                                            label="I understand this class can't be canceled or refunded"
                                            name="agreement"
                                          />
                                        )}

                                        <button
                                          type="submit"
                                          className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                          disabled={formikProps.isSubmitting}
                                        >
                                          <div
                                            className={
                                              formikProps.isSubmitting
                                                ? `${submitStyles.spinner} ${loadingStyles.newSpinner}`
                                                : loadingStyles.hidden
                                            }
                                          ></div>
                                          <span
                                            className={
                                              formikProps.isSubmitting
                                                ? submitStyles.invisible
                                                : submitStyles.visible
                                            }
                                          >
                                            <span>Reserve Now</span>
                                          </span>
                                        </button>
                                        {formikProps.isSubmitting ? (
                                          ''
                                        ) : (
                                          <button
                                            onClick={async () =>
                                              await handleClose()
                                            }
                                            type="button"
                                            className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                          >
                                            <span
                                              className={submitStyles.visible}
                                            >
                                              <span>No Thanks</span>
                                            </span>
                                          </button>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className={loginStyles.copy}>
                                      <span>
                                        Don't have a Peerfit account?{' '}
                                      </span>
                                      <Link
                                        className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`}
                                        to={REGISTER}
                                      >
                                        <span>Sign Up</span>
                                      </Link>
                                    </div>
                                    <div className={loginStyles.copy}>
                                      <span>Have an account? </span>
                                      <button
                                        onClick={login}
                                        className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                      >
                                        <span>Sign In</span>
                                      </button>
                                    </div>
                                  </>
                                )}
                              </fieldset>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </>
                  )}
                </div>
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
              </div>
            </div>
          </React.Fragment>,
          document.body
        )
      : null;
  }
);
