import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import studioListStyles from '../../styles/streaming/StudioList.module.css';

import paginationStyles from '../../styles/pagination/styles.module.css';
import pagination from '../../styles/Pagination.module.css';
import StudioCard from './StudioCard';

import { StudioContext } from '../../contexts/StudioContext';
import useWindowSize, { _LG, _MD } from '../../shared/hooks/useWindowSize';

function StudioList() {
  const { studios, loading, pageInfo, setPage, getStudios } =
    useContext(StudioContext);

  useEffect(() => {
    // getStudios();
  }, []);
  const size = useWindowSize();

  return (
    <div>
      <div
        className={`${studioListStyles.gridContainer} ${
          size[3] > _LG ? studioListStyles.threeCol : ''
        } ${size[3] > _LG ? studioListStyles.hideLast : ''}`}
      >
        {loading ? (
          <>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
            <div className={studioListStyles.gridItem}>
              <StudioCard empty={true} />
            </div>
          </>
        ) : (
          studios?.map((item, index) => (
            <div key={index} className={studioListStyles.gridItem}>
              <StudioCard studio={item} source="Studio" />
            </div>
          ))
        )}
      </div>

      {pageInfo.totalPages > 1 ? (
        <div
          className={`${pagination.container} ${paginationStyles.container}`}
        >
          <div
            onClick={() => setPage(pageInfo.page + 1)}
            className={`${pageInfo.page > 1 ? pagination.previous : ''} ${
              paginationStyles.action
            } ${paginationStyles.number} ${paginationStyles.ellipsis}`}
          >
            {pageInfo.page > 1 && (
              <>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className={`${paginationStyles.icon_left} ${paginationStyles.icon}`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M16.4244649,19.2868077 C16.7653329,19.6293551 16.7639715,20.1833728 16.4214241,20.5242408 C16.0788767,20.8651089 15.524859,20.8637475 15.183991,20.5212001 L7.25476309,12.5529074 C6.9164664,12.2129441 6.91488398,11.6640093 7.25121504,11.3221012 L15.1804429,3.26139014 C15.5193304,2.9168832 16.073331,2.91232773 16.417838,3.2512152 C16.7623449,3.59010267 16.7669004,4.1441033 16.4280129,4.48861023 L9.10587647,11.932163 L16.4244649,19.2868077 Z"></path>
                </svg>
                <span>Prev</span>
              </>
            )}
          </div>
          {[...Array(pageInfo.totalPages)].map((_, i) => (
            <div
              key={i}
              onClick={() => setPage(i + 1)}
              className={`${pageInfo.page == i + 1 ? pagination.current : ''} ${
                pagination.number
              } ${paginationStyles.number} ${paginationStyles.ellipsis}`}
            >
              {i + 1}
            </div>
          ))}
          <div
            onClick={() => setPage(pageInfo.page + 1)}
            className={`${
              pageInfo.page < pageInfo.totalPages ? pagination.next : ''
            } ${paginationStyles.action} ${paginationStyles.number} ${
              paginationStyles.ellipsis
            }`}
          >
            {pageInfo.page < pageInfo.totalPages && (
              <>
                <span>Next</span>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className={`${paginationStyles.icon_right} ${paginationStyles.icon}`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.25476326,19.2868077 L14.5733516,11.932163 L7.2512152,4.48861023 C6.91232773,4.1441033 6.9168832,3.59010267 7.26139014,3.2512152 C7.60589707,2.91232773 8.1598977,2.9168832 8.49878517,3.26139014 L16.4280131,11.3221012 C16.7643441,11.6640093 16.7627617,12.2129441 16.424465,12.5529074 L8.49523712,20.5212001 C8.15436907,20.8637475 7.60035138,20.8651089 7.25780398,20.5242408 C6.91525659,20.1833728 6.91389521,19.6293551 7.25476326,19.2868077 Z"></path>
                </svg>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={studioListStyles.contact}>
        <p>
          <span>Are you a studio offering virtual classes?</span>
        </p>
        <p>
          <span>Contact</span>
          <span> studios@peerfit.com </span>
          <span>to be added to the list.</span>
        </p>
      </div>
    </div>
  );
}

export default StudioList;
