import { LoadScript } from '@react-google-maps/api';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { setPrefix } from 'react-id-generator';
import { useRouteMatch } from 'react-router-dom';
import { initAmplitude, initAppBoy } from './api/integration';
import './App.css';
import { useAuth } from './contexts/AuthContext';
import { useSite } from './contexts/SiteContext';
import Footer from './footer/Footer';
import Header from './Header';
import {
  AETNA,
  DISCOVER,
  HOME,
  LOGIN,
  REGISTER,
  REGISTER_CHECK_YOUR_EMAIL,
  REGISTER_USER,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  REGISTER_NEW,
  GUEST,
  GUEST_USER,
} from './navigation/CONSTANTS';
import RouterConfig from './navigation/RouterConfig';
import PHIMigration from './components/Authentication/PHIMigration';
import bannersStyles from './styles/Banners.module.css';
import coreLayoutStyles from './styles/CoreLayout.module.css';
import fitOnBannerStyles from './styles/FitOnBanner.module.css';
import './styles/screen_readers_only.css';
import RedirectHelmet from './navigation/RedirectHelmet';
import { MobileNavigation } from './footer/MobileNavigation';
import { SearchInput } from './components/SearchInput';
import { postMessageToRN } from './utilities/rn';
import GlobalLoading from './shared/GlobalLoading';
import { getFeatureFlag } from './api/config';
import { useAutoLogin } from './navigation/useAutoLogin';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleMapsLibraries = ['places'];
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const matchExplore = useRouteMatch(['/explore/studios', '/explore/classes']);
  const matchMobileWebSite =
    !window?.ReactNativeWebView &&
    (window.location.pathname === '/' || window.location.pathname === '/corporate-wellness' || window.location.pathname === '/fitness-partners');
  const matchLogin = useRouteMatch([
    LOGIN,
    REGISTER,
    REGISTER_CHECK_YOUR_EMAIL,
    REGISTER_USER,
    FORGOT_PASSWORD,
    REGISTER_NEW,
    CHANGE_PASSWORD,
    GUEST,
    GUEST_USER,
  ]);
  const { authState, verify } = useAuth();
  const { isMoveSite, isCoreSite, isMobileApp } = useSite();
  const [isLoading, setIsLoading] = useState(true);
  setPrefix('id-'); // set template.
  const needRedirectFlag = useAutoLogin();

  useEffect(() => {
    (async () => {
      // var isFitOnBraze = await getFeatureFlag('fiton_braze');
      // await initAppBoy(isFitOnBraze);
      // initAmplitude(isFitOnBraze);
      verify();
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!authState.isLoading) {
      postMessageToRN({ key: 'HIDE_SPLASH' });
    }
  }, [authState.isLoading]);

  const matchAetna = useRouteMatch(AETNA);
  const matchHome = useRouteMatch({ path: HOME, exact: true });
  const matchDiscover = useRouteMatch({ path: DISCOVER, exact: true });
  return authState.isLoading || isLoading || needRedirectFlag ? (
    <GlobalLoading />
  ) : (
    <RedirectHelmet>
      <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={googleMapsLibraries}>
        <Elements stripe={stripePromise}>
          <PHIMigration />
          <div
            className={`${coreLayoutStyles.mouse_or_touch_user_container} mouse_or_touch_user ${coreLayoutStyles.container} ${
              matchHome ? coreLayoutStyles.staticBanner : ''
            }`}
            style={{
              minHeight: isMobileApp ? 'unset' : '907px',
              marginTop: '-1px',
              height: isMobileApp && matchExplore ? '100%' : 'auto',
              overflowY: isMobileApp && matchExplore ? 'hidden' : 'auto',
            }}
          >
            <div className={`${bannersStyles.closedContainer} ${bannersStyles.container}`}></div>
            {!isMobileApp && (matchHome || (matchDiscover && isCoreSite)) && (
              <div className={fitOnBannerStyles.container}>
                <h5 className={fitOnBannerStyles.copy}>
                  <span>
                    We are now part of the FitOn family!
                    <button>
                      <a href="https://fiton.peerfit.com" target="_blank" rel="noreferrer">
                        Learn More
                      </a>
                    </button>
                  </span>
                </h5>
              </div>
            )}
            <div className={coreLayoutStyles.page} style={{ paddingBottom: isMobileApp && !matchHome && 72 }}>
              {isMobileApp || matchAetna || matchLogin ? '' : <Header />}
              {isMobileApp && matchMobileWebSite ? <Header /> : null}
              {isMobileApp && (matchDiscover || matchExplore) && <SearchInput />}
              <div id="main-content">
                <RouterConfig />
                {isMobileApp && !matchLogin && <MobileNavigation />}
              </div>
              {isMobileApp || matchExplore || matchLogin ? '' : <Footer />}
            </div>
          </div>
        </Elements>
      </LoadScript>
    </RedirectHelmet>
  );
}

export default App;
