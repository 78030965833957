import Cleave from 'cleave.js/react';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useId } from 'react-id-generator';
import fieldStyles from '../../styles/input/Field.module.css';


export const DateField = ({ type,label,setValidDob, ...props }) => {
  const dateMask = 'MM/DD/YYYY';
  const [field, meta,helpers] = useField(props);
  const [placeholder, setPlaceholder] = useState(dateMask);

  const [htmlId] = useId();
  const id1 = htmlId + '-1';
  const id2 = htmlId + '-2';

  useEffect(() => {
    setPlaceholder(dateMask.slice(field.value.length)); // when the field is first set with date, placeholder needs to be updated. else, 12/3/2020MM/DD/YYYY
  }, [field]);

  const handleChange = (e) => {
    const str = e.target.value;
    e.target.placeholder = dateMask.slice(str.length);
  
    field.onChange(e);
  };
  let errorMessage = "Please enter a date in the format MM/DD/YYYY."
  const dateRegex = /^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/g
  const handleBlur = (e) => {
    if(!dateRegex.test(e.target.value)) {

     
      helpers.setTouched(true)
      helpers.setError(errorMessage)
    }else {
      setValidDob && setValidDob(true)
    }
    field.onBlur(e)
  };

  return (
    <div className={fieldStyles[type]}>
      <Cleave
        {...field}
        placeholder={label}
        options={{ date: true, datePattern: ['m', 'd', 'Y'] }}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`${meta.touched && meta.error && fieldStyles.inputError} ${
          fieldStyles.input
        }`}
      />

      <div id={id1} aria-live="polite" aria-atomic="true" role="status">
        {meta.touched && meta.error ? (
          <span className={`${fieldStyles.errorLabel} date-field`}>
            <span>Error: {meta.error}</span>
          </span>
        ) : null}
      </div>
    </div>

   
  );
};
