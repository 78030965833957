import React from 'react';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { useSite } from '../../contexts/SiteContext';

//styles
import cardStyles from '../../styles/Card.module.css';
import css from './register.module.css';

const UpdateResults = (props) => {
  const { isCoreSite, isMoveSite } = useSite();
  return (
    <Card className={css.update_results} data-testid="update-modal">
      <CardHeader
        title={
          <h3 className={cardStyles.title}>
            <span>Thank You!</span>
          </h3>
        }
      />
      <CardContent
        classes={{ root: `${cardStyles.hasTitle} ${cardStyles.content}` }}
      >
        <p className={css.message} data-testid="confirmation-text">
          {isCoreSite
            ? `You can now continue to enjoy Peerfit! You can update your profile at any time under your settings.`
            : `You can now continue to enjoy Peerfit Move! You can update your profile at any time under your settings.`}{' '}
        </p>

        <div className={css.modal_buttons}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            disabled={false}
            onClick={() => props.setConfirmation(false)}
            className={css.button}
            data-testid="close-confirmation-modal"
          >
            Close
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default UpdateResults;
