import React, { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import studioApi from '../../api/studio';
import { EXPLORE_CLASSES, EXPLORE_STUDIOS } from '../../navigation/CONSTANTS';
import useQuery from '../../shared/hooks/useQuery';
import { geocode, geocodeAddress, getDefaultBounds } from '../../utilities/geocode';
import { distance } from '../../utilities/mapHelper';
import { getPurgedObj } from '../../utilities/utilities';
import { GlobalContext } from '../GlobalContext';
import { CacheContext } from '../CacheContext';
import { initialSearchState } from './initialSearchState';
import {
  CLEAR_CATEGORIES,
  CLEAR_TAGS,
  CLEAR_TIME_OF_DAY,
  CLEAR_SEARCH,
  INITIALIZE,
  searchReducer,
  SET_CURRENT_TAB,
  SET_DATE,
  SET_FILTERS,
  SET_MAP_BOUNDS,
  SET_SEARCH,
  SET_SORT,
  TOGGLE_HAS_MEMBERSHIPS,
  CLEAR_HAS_MEMBERSHIPS,
  CLEAR_HAS_DIGITAL,
  SET_TAGS,
  SET_FORCE_SEARCH,
} from './searchReducer';
import { getSourceFromParams, getSourceFromPathName, logAmplitudeEvent } from '../../api/integration';
import { useRecentSearches } from '../../Search';
import { callRNFunction, isReactNative } from '../../utilities/rn';
import { useAuth } from '../AuthContext';
import { useLocalStorage } from 'react-use';

export const SearchContext = createContext<ReturnType<typeof useCreateSearchContext>>(null);
export const useSearch = () => useContext(SearchContext);

export const useCreateSearchContext = () => {
  const { authState } = useAuth();
  const [geoLocation, setGeoLocation] = useState<undefined | number[]>();
  const homeCityString = authState?.user?.home_city;

  const globalContext = useContext(GlobalContext);
  const cacheContext = useContext(CacheContext);
  const { regions, mapRegions } = cacheContext;

  const [studioListStore, setStudioListStore] = useState({});

  const [studiosLoading, setStudiosLoading] = useState(false);
  const [sessionsLoading, setSessionsLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useLocalStorage(`pf_searched_keyword_${authState?.user?.id || 'anonymous'}`, '');

  const [studioList, setStudioList] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [sessionList, setSessionList] = useState([]);
  const searchedRef = useRef({ searched: false });
  const { recentSearches, setRecentSearches } = useRecentSearches();
  const [init, setInit] = useState(false);

  const [selectedPlace, setSelectedPlace] = useLocalStorage(`pf_searched_place_id_${authState?.user?.id || 'anonymous'}`, { place_id: '', description: '' });

  const query = useQuery();
  const queryTags = query.getAll('tags');

  const [searchState, dispatch] = useReducer(searchReducer, initialSearchState) as any;
  const qKeyRef = useRef<string>();

  useEffect(() => {
    setInit(false);
  }, [authState?.user?.home_city, authState?.user?.id]);

  const initSearch = async (current_tab, center, date, has_digital, has_memberships, place_id, search, sort, tags, categories, time, home_city, bounds) => {
    if (init) return;
    // PlaceId used first, then center coordinates, then use home_city
    // finally try current searchSate

    if (sort === null) {
      sort = 'distance';
    }

    let coord = [];
    let home_coord = [];
    if (place_id) {
      const c = await geocode(place_id);
      coord = [c.lat, c.lng];
      if (!bounds?.length) {
        bounds = getDefaultBounds(coord);
      }
    }
    // console.log("coord:", { center: place_id ? [coord.lat, coord.lng] : center, });
    // console.log("home_city:", home_city)
    if (!center.length) {
      if (home_city) {
        const hc = await geocodeAddress(home_city);
        if (hc) {
          console.log('homecity set:', home_city);
          home_coord = [hc.lat, hc.lng];
        }
      }
    }

    if (coord.length) {
      // console.log('USING PLACE_ID')
      center = coord;
    } else if (home_coord.length) {
      // console.log('USING HOME_CITY')
      center = home_coord;
    }
    // get center from searchState if possible
    if (center.length === 0 && searchState?.center.length !== 0) {
      center = searchState.center;
    }

    setInit(true);
    dispatch({
      type: INITIALIZE,
      current_tab,
      center,
      bounds,
      date,
      has_digital,
      has_memberships,
      place_id,
      search,
      sort,
      tags,
      categories,
      time,
    });
  };

  const setMapBounds = (bounds, center) => {
    dispatch({
      type: SET_MAP_BOUNDS,
      bounds: bounds.map((x) => parseFloat(x.toFixed(4))),
      center: center.map((x) => parseFloat(x.toFixed(4))),
    });
  };
  const setCurrentTab = (current_tab) => dispatch({ type: SET_CURRENT_TAB, current_tab });
  const setSearchDate = (date) => dispatch({ type: SET_DATE, date });
  const toggleHasMemberships = () => dispatch({ type: TOGGLE_HAS_MEMBERSHIPS });
  const clearHasMemberships = () => dispatch({ type: CLEAR_HAS_MEMBERSHIPS });
  const clearHasDigital = () => dispatch({ type: CLEAR_HAS_DIGITAL });
  const setSort = (sort) => dispatch({ type: SET_SORT, sort });
  const setFilters = ({ tags, categories, has_digital, time }) => dispatch({ type: SET_FILTERS, tags, categories, has_digital, time });
  const clearTags = () => dispatch({ type: CLEAR_TAGS });
  const clearCategories = () => dispatch({ type: CLEAR_CATEGORIES });
  const clearTime = () => dispatch({ type: CLEAR_TIME_OF_DAY });
  const setSearch = async (search, place_id) => {
    // Priority:    PlaceId => HomeCity => ["37.0902", "-95.7129"]

    if (place_id) {
      // geocode placeId => latlng
      geocode(place_id).then((coord) => {
        const coords = [coord.lat, coord.lng];
        const bounds = getDefaultBounds(coords);
        dispatch({ type: SET_SEARCH, search, place_id, center: coords, bounds });
      });
    } else {
      dispatch({
        type: SET_SEARCH,
        search,
        place_id,
        center: searchState.center,
      });
    }
  };

  useEffect(() => {
    studioSearch();
    if (searchState.current_tab === 1) sessionSearch();
  }, [searchState]);

  useEffect(() => {
    if (queryTags?.length) {
      dispatch({ type: SET_TAGS, tags: queryTags });
    }
  }, [queryTags?.join(',')]);

  // update URL when state changes.
  const matchExplore = useRouteMatch([EXPLORE_STUDIOS, EXPLORE_CLASSES]);
  useEffect(() => {
    if (matchExplore) {
      const u = getSearchParams();
      globalContext.setExploreParams(u); // save link

      const tab = EXPLORE_STUDIOS;
    }
  }, [searchState]);

  const getSearchParams = () => {
    query.delete('bounds');
    searchState.bounds.map((x) => query.append('bounds', x));

    query.delete('center');
    searchState.center.map((x) => query.append('center', x));

    query.delete('date');
    if (searchState.date) query.set('date', searchState.date);

    query.delete('has_digital');
    if (searchState.has_digital) query.set('has_digital', searchState.has_digital);

    query.delete('has_memberships');
    if (searchState.has_memberships) query.set('hasMemberships', searchState.has_memberships);

    query.delete('place_id');
    if (searchState.place_id) query.set('place_id', searchState.place_id);

    query.delete('search');
    if (searchState.search) query.set('search', searchState.search);

    query.delete('sort');
    if (searchState.sort) query.set('sort', searchState.sort);

    query.delete('tags');
    searchState.tags.map((x) => query.append('tags', x));

    query.delete('categories');
    searchState.categories.map((x) => query.append('categories', x));

    query.delete('time');
    if (searchState.time) query.set('time', searchState.time);

    query.sort();

    return query;
  };
  //#endregion

  const getStudioParams = ({ bounds, center, search, place_id, sort, has_memberships, tags, categories, has_digital }) => {
    // note: 'distance' sort defaults to null for the api call.
    sort = ['name', 'newest', 'distance'].indexOf(sort) > -1 ? sort : null;
    // note: has_memberships is true if it has value. default to undefined.
    has_memberships = !!has_memberships;
    has_digital = !!has_digital;
    return {
      bounds,
      center,
      search,
      place_id,
      sort,
      has_memberships,
      tags,
      categories,
      has_digital,
    };
  };
  const getStudioHash = ({ bounds, center, search, place_id, sort, has_memberships, tags, categories, has_digital }) => {
    return JSON.stringify(
      getPurgedObj({
        bounds,
        center,
        search,
        place_id,
        sort,
        has_memberships,
        tags,
        categories,
        has_digital,
      })
    );
  };

  const filterStudioList = (studios, params) => {
    let filteredStudios;
    switch (params.sort) {
      case 'distance':
        filteredStudios = studios.sort((a, b) => {
          if (parseFloat(a.distance) > parseFloat(b.distance)) {
            return 1;
          } else {
            return -1;
          }
        });
        break;
      case 'name':
        filteredStudios = studios.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else {
            return -1;
          }
        });
        break;
      case 'newest':
        filteredStudios = studios
          .sort((a, b) => {
            if (a.id > b.id) {
              return 1;
            } else {
              return -1;
            }
          })
          .reverse();
      default:
        filteredStudios = studios;
    }
    return filteredStudios;
  };

  // STUDIO SEARCH
  const studioSearch = async () => {
    if (searchState.bounds.length === 0 || searchState.center.length === 0) return;

    console.log('studio search', searchState);
    const params = getStudioParams(searchState);
    const qKey = getStudioHash(params);
    qKeyRef.current = qKey;

    let sortedStudios;

    setStudiosLoading(true);
    searchedRef.current.searched = true;
    const data = await studioApi
      .studioSearch(params)
      .then((data) => {
        if (qKeyRef.current !== qKey) return;
        const studios = data.results.map((x) => ({
          ...x,
          distance: distance(params.center[0], params.center[1], x.point?.coordinates[1], x.point?.coordinates[0]),
        }));

        setPage(1);
        setTotalPages(Math.ceil(data.count / pageSize));

        sortedStudios = filterStudioList(studios, params);

        setStudioList(sortedStudios);

        return {
          count: data.count,
          results: studios,
        };
      })
      .catch((err) => {
        console.error('studioSearch error:', err);
        return undefined;
      })
      .finally(() => {
        setStudiosLoading(false);
      });

    const pages = data?.count > 0 ? Math.ceil(data.count / pageSize) : 0;
    let promises: any[] = [{ 1: data?.results }];

    for (let i = 2; i <= pages; i++) {
      // console.log(`calling page ${i} out of ${pages} pages.`)
      promises.push(
        studioApi.studioSearch(searchState, i).then((data) => {
          const studios = data.results.map((x) => ({
            ...x,
            distance: distance(params.center[0], params.center[1], x.point?.coordinates[1], x.point?.coordinates[0]),
          }));

          // this case only applies if user can changes before rest of data is loaded.
          // this should only be a concern if INTERNET IS SLOW...
          // if pageNum was changed before the api returned. then set the list.
          // ...

          return { [data.page]: studios };
        })
      );
    }

    // once all data is loaded, cache it for later.
    Promise.all(promises).then((responses) => {
      if (qKeyRef.current !== qKey) return;
      const pagedResult = responses.reduce((obj, item) => ({ ...obj, ...item }), {});
      setStudioListStore(pagedResult);
      cacheContext.setStudioResponseCache({
        ...cacheContext.studioResponseCache,
        [qKey]: pagedResult,
      });

      logAmplitudeEvent('Screen View: Explore', {
        Query: query.toString(),
        Location: query.getAll('center'),
        Digital: query.get('has_digital'),
        Activities: query.getAll('categories'),
        Category: query.getAll('tags'),
        'Membership Only': query.get('has_memberships'),
        Type: 'Studios',
        Sort: query.get('sort'),
        Results: Object.keys(pagedResult).length,
        Source: getSourceFromParams(),
      });
    });
  };

  // defaults values for search
  const getSessionParams = ({ bounds, center, search, has_digital, sort, tags, categories, time, date }) => {
    sort = ['start_datetime', 'newest', 'credits'].indexOf(sort) > -1 ? sort : 'start_datetime'; // constrain to the options, else default it.
    has_digital = !!has_digital;
    return {
      bounds,
      center,
      search,
      has_digital,
      sort,
      tags,
      categories,
      time,
      date,
    };
  };
  const getSessionHash = ({ bounds, center, search, has_digital, sort, tags, categories, time, date }) =>
    JSON.stringify(
      getPurgedObj({
        bounds,
        center,
        search,
        has_digital,
        sort,
        tags,
        categories,
        time,
        date,
      })
    );

  // SESSION SEARCH
  const sessionSearch = async () => {
    try {
      console.log('PERFORMING SESSIONSEARCH......');

      if (searchState.bounds.length === 0 || searchState.center.length === 0) return; // must of map bounds.
      if (!searchState.date) return; // must of date.

      const params = getSessionParams(searchState);
      const qKey = getSessionHash(params);
      console.log('sessionSearch key: ' + qKey);

      setSessionsLoading(true);
      const { bounds, center, search, has_digital, sort, tags, categories, time, date } = params;
      await studioApi
        .sessionSearch(bounds, center, has_digital, sort, search, date, tags, categories, time)
        .then((results) => {
          results = results.filter((x) => {
            const d1 = Date.parse(x.start_datetime + 'Z');
            const d2 = Date.parse(new Date().toString());
            // console.log("DD:",`${d1} > ${d2}`, d1 > d2);
            // console.log(`${new Date(d1)} > ${new Date(d2)}`, new Date(d1) > new Date(d2));
            return d1 > d2;
          });

          console.log('sessionSearch results: ', results.length);
          const sessions = results.map((x) => ({
            ...x,
            studio: {
              ...x.studio,
              distance: distance(center[0], center[1], x?.studio.point?.coordinates[1], x?.studio.point?.coordinates[0]),
            },
          }));

          // CACHE RESULT
          cacheContext.setSessionResponseCache({
            ...cacheContext.sessionResponseCache,
            [qKey]: sessions,
          });
          setSessionList(sessions);

          logAmplitudeEvent('Screen View: Explore', {
            Query: query.toString(),
            Location: query.getAll('center'),
            Digital: query.get('has_digital'),
            Activities: query.getAll('categories'),
            Category: query.getAll('tags'),
            'Membership Only': query.get('has_memberships'),
            Type: 'Classes',
            Sort: query.get('sort'),
            Results: sessions?.length,
            Source: getSourceFromParams(),
          });
        })
        .catch((err) => {
          // const cached = cacheContext.sessionResponseCache[qKey];
          // if (cached) {
          //   console.log('Found cached session data.');
          //   setSessionList(cached);
          //   return;
          // } else {
          //   console.log('NOT FOUND cache:', cacheContext.sessionResponseCache);
          // }
          console.error('sessionSearch error:', err);
        });
    } finally {
      setSessionsLoading(false);
    }
  };

  //#region REGION LOGIC
  const LoadRegions = async () => {
    if (regions.length > 0) return; // already loaded skip.

    const data = await studioApi.getRegions().then((res) => {
      return {
        count: res.count,
        results: res.results,
      };
    });

    const pages = Math.ceil(data.count / pageSize);
    let promises: any[] = [[...data.results]];
    for (let i = 2; i <= pages; i++) {
      // console.log(`calling regions ${i} out of ${pages} pages.`);
      promises.push(
        studioApi.getRegions(i).then((res) => {
          return res.results;
        })
      );
    }

    Promise.all(promises).then((resp) => {
      const result = resp.reduce((prev, curr) => [...prev, ...curr], []);
      cacheContext.setRegions(result);

      // out of 1216 regions, map is only displaying 173. because it is filtering only regions with greater than N.
      const filteredResult = result.filter((x) => x.total_studios >= 7); // 173 regions with studios >= 6
      cacheContext.setMapRegions(filteredResult);
    });
  };
  const [showMap, setShowMap] = useState(false);
  const toggleMap = () => setShowMap((x) => !x);
  const clearSearch = () => dispatch({ type: CLEAR_SEARCH });
  const updateRecentSearches = (searches) => {
    setRecentSearches(searches);
  };
  const getLocation = async (homeCityExistCallback) => {
    const setFallBackLocation = async () => {
      if (homeCityString) {
        homeCityExistCallback?.(homeCityString);
        const hc = await geocodeAddress(homeCityString);
        if (hc) {
          setMapBounds([], [hc.lat, hc.lng]);
          dispatch({ type: SET_SEARCH, search: '', place_id: '', center: [hc.lat, hc.lng] });
        }
      } else {
        // Default - Peerfit HQ (Tampa, FL)
        if (!searchedRef.current.searched) {
          setMapBounds([], [27.9506, -82.4572]);
          dispatch({ type: SET_SEARCH, search: '', place_id: '', center: [27.9506, -82.4572] });
        }
      }
    };
    // set default location
    if (isReactNative) {
      try {
        const crd = await callRNFunction('getCurrentPosition');
        console.log('Set Map Bounds', crd);
        setMapBounds([], [crd.latitude, crd.longitude]);
        dispatch({ type: SET_SEARCH, search: '', place_id: '', center: [crd.latitude, crd.longitude] });
        setGeoLocation([crd.latitude, crd.longitude]);
      } catch (error) {
        setFallBackLocation();
      }
    } else {
      navigator?.geolocation?.getCurrentPosition(
        (pos) => {
          const crd = pos.coords;
          console.log('Set Map Bounds', crd);
          setMapBounds([], [crd.latitude, crd.longitude]);
          dispatch({ type: SET_SEARCH, search: '', place_id: '', center: [crd.latitude, crd.longitude] });
          setGeoLocation([crd.latitude, crd.longitude]);
        },
        (error) => {
          setFallBackLocation();
          console.warn(`Get Location ERROR(${error.code}): ${error.message}`);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  };
  const useGetLocation = (homeCityExistCallback?: (str: string) => void) => {
    useEffect(() => {
      if (matchExplore && !selectedPlace?.place_id && !geoLocation) {
        getLocation(homeCityExistCallback);
      }
    }, [homeCityString, !!matchExplore, geoLocation]);
  };

  const resetLocation = (callback) => getLocation(callback);

  const setForceSearch = (shouldForceSearch) => {
    dispatch({
      type: SET_FORCE_SEARCH,
      shouldForceSearch,
    })
  }

  return {
    searchState,
    page,
    setPage,
    totalPages,

    initSearch,
    setMapBounds,
    setCurrentTab,
    setSearchDate,
    toggleHasMemberships,
    clearHasMemberships,
    setSort,
    setFilters,
    clearTags,
    clearCategories,
    clearTime,
    setSearch,
    clearSearch,
    clearHasDigital,
    recentSearches,
    updateRecentSearches,

    studiosLoading,
    sessionsLoading,
    studioList,
    studioListStore,
    sessionList,
    regions,
    mapRegions,
    LoadRegions,

    homeCityString,
    // urlParams,
    showMap,
    toggleMap,
    getSearchParams,

    searchedRef,
    useGetLocation,
    setGeoLocation,
    selectedPlace,
    setSelectedPlace,
    searchKeyword,
    setSearchKeyword,
    resetLocation,
    setForceSearch,
  };
};

export const SearchContextProvider = (props) => {
  const context = useCreateSearchContext();
  return <SearchContext.Provider value={context}>{props.children}</SearchContext.Provider>;
};
