import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import CloseModalIcon from '../../Assets/mobile/close-modal-icon.svg';
import styles from './style.module.css';

export const MobileModal = ({
  show = false,
  onClose = () => { },
  onConfirm = () => { },
  onClear = () => { },
  content = null,
  title = '',
  confirmText = 'Confirm',
  clearText = 'Clear All',
}) => {
  const renderNode = useMemo(() => {
    let modalContainer = document.getElementById('modal-element');
    if (!modalContainer) {
      modalContainer = document.createElement('div');
      modalContainer.setAttribute('id', 'modal-element');
      const body = document.getElementsByTagName('body')[0];
      body.appendChild(modalContainer);
    }
    return modalContainer;
  }, [content]);

  useEffect(() => {
    return () => {
      const modalContainer = document.getElementById('modal-element');
      if (modalContainer) {
        const body = document.getElementsByTagName('body')[0];
        body.removeChild(modalContainer);
      }
    };
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (show) {
      body.setAttribute('style', 'overflow: hidden');
    } else {
      body.removeAttribute('style');
    }
  }, [show]);

  if (!renderNode) {
    return null;
  }
  if (!show) {
    return null;
  }

  return createPortal(<div className={styles.container} onClick={e => e.stopPropagation()}>
    <div className={`modal-header ${styles.header}`}>
      <img src={CloseModalIcon} alt="close-modal-icon" className={styles['close-icon']}
        onClick={e => {
          e.stopPropagation();
          onClose();
        }}
      />
      <div className={styles['header-text']}>
        {title}
      </div>
    </div>
    <div className={styles.body}>
      {content}
    </div>
    <div className={styles.footer}>
      <span onClick={onClear} style={{
        color: '#2B4596',
        cursor: 'pointer',
        fontSize: 14,
        fontWeight: 500,
      }}>{clearText}</span>
      <button className='btn btn-default' onClick={onConfirm}>{confirmText}</button>
    </div>
  </div>, renderNode);
};
