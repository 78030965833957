import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  getStudioLogInfo,
  logAmplitudeEvent,
  logBrazeEvent,
} from '../api/integration';
import studioApi from '../api/studio';
import userApi from '../api/user';
import studioBanner from '../Assets/studioBanner.jpg';
import { StudioByIdHelmet } from '../components/Helmets';
import MobilePage from '../components/User/pages/MobilePage';
import { useAuth } from '../contexts/AuthContext';
import { useSearch } from '../contexts/SearchContext';
import { useSite } from '../contexts/SiteContext/SiteContext';
import { EXPLORE_STUDIOS } from '../navigation/CONSTANTS';
import useQuery from '../shared/hooks/useQuery';
import useWindowSize, { _MD } from '../shared/hooks/useWindowSize';
import { LoginModal } from '../shared/Modal/LoginModal';
import useModal from '../shared/Modal/useModal';
import bannerStyles from '../styles/Banner.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import desktopStyles from '../styles/Desktop.module.css';
import favoriteStyles from '../styles/Favorite.module.css';
import imageStyles from '../styles/Image.module.css';
import linkStyles from '../styles/Link.module.css';
import studioStyles from '../styles/Studio.module.css';
import svgIconStyles from '../styles/SvgIcon.module.css';
import titleStyles from '../styles/Title.module.css';
import About from './About';
import MapBanner from './MapBanner';
import Membership from './Membership';
import Schedule from './Schedule';
import { StudioMobile } from './StudioMobile';
import { ErrorMessageMap } from './constant';
import utilitiesStyle from '../styles/utilities.module.css';
import errorStyle from '../styles/Error.module.css';

export const Studio = (props) => {
  const query = useQuery();
  const { id } = useParams(); // studioId
  const [isLoading, setIsLoading] = useState(true);
  const [studio, setStudio] = useState(null);
  const [memberships, setMemberships] = useState([]);
  const [scheduleErrorType, setScheduleErrorType] = useState('');
  const { getSearchParams } = useSearch();
  /*
        get studio by id - https://apipeerfit.cm/studios/9130/
        get studio classees - https://apipeerfit.cm/studios/classes/?studio_id=9130
        get memberships - https://apipeerfit.cm/studios/members/?studio_id=9130&user_id=79570
        get studio sessions - https://apipeerfit.cm/studios/sessions/?studio_id=9130

        on page load. all this data is cached some how.
        1. blank until i get studio data.
        2. render studio name, about sections.
            a. schedule section has loading icon
        3. when classes & sessions retrieved, render schedule section.
            a. if logged in, render memberships (booked or not)

        Mindbody: 6089
        1 Memberships: 6349
        N Memberships: 5351
        NON-MB 1 classes: 16200
        NON-MB N classes: 1188
    */

  useEffect(() => {
    // get studio data
    studioApi.getStudio(id).then(async (data) => {
      setStudio(data);
      setIsLoading(false);
    });

    // get membership data
    studioApi.getMembershipPlans(id).then((data) => {
      setMemberships(data || []);
    });
  }, []);

  useEffect(() => {
    if (studio && studio.id) {
      logBrazeEvent('Screen View: Studio', {
        ...getStudioLogInfo(studio),
        Schedule: !!studio.schedule
      });
      logAmplitudeEvent('Screen View: Studio', {
        ...getStudioLogInfo(studio),
        Schedule: !!studio.schedule
      });
    }
  }, [studio]);

  const [imageExists, setImageExists] = useState(true);
  const handleImageError = () => setImageExists(false);
  const [logoExists, setLogoExists] = useState(true);
  const handleLogoError = () => setLogoExists(false);

  const { authState } = useAuth();
  const [isFavorite, setIsFavorite] = useState(false);
  const [updatingFavorite, setUpdatingFavorite] = useState(false);
  const { isShowing, toggle, ref } = useModal();
  const size = useWindowSize();
  const { isMoveSite } = useSite();
  const [isRefetching, setIsRefetching] = useState(false)

  useEffect(() => {
    console.log('authState: ', authState);
    const fav =
      authState.user?.user_studios.some((s) => s.studio_id == id) ?? false;
    setIsFavorite(fav);
  }, [authState]);

  const handleFavorite = async () => {
    if (!authState.isAuthenticated) {
      toggle();
      return;
    }

    setUpdatingFavorite(true);
    setTimeout(async () => {
      if (isFavorite) {
        // un-favorite
        await userApi
          .removeFavorite(authState.user?.id, id)
          .then(() => setIsFavorite(false));

        logBrazeEvent('Studio: Remove Favorite', getStudioLogInfo(studio));
        logAmplitudeEvent('Studio: Remove Favorite', getStudioLogInfo(studio));
      } else {
        // favorite
        await userApi
          .postFavorite(authState.user?.id, id)
          .then(() => setIsFavorite(true));

        logBrazeEvent('Studio: Add Favorite', getStudioLogInfo(studio));
        logAmplitudeEvent('Studio: Add Favorite', getStudioLogInfo(studio));
      }
      setUpdatingFavorite(false);
    }, 750);
  };

  const onViewSchedule = () => {
    setIsRefetching(true)
    studioApi.syncSchedule(studio.studio_group_id).then(() => {
      // get studio data
      studioApi.getStudio(id).then(async (data) => {
        setStudio(data);
        setIsRefetching(false)
      });
    }).catch(() => {
        setScheduleErrorType('UnknownError')
        setIsRefetching(false)
    })
  }

  return (
    <MobilePage title="Details">
      {isLoading ? (
        <>{/* <h1>LOADING</h1> */}</>
      ) : (
        <>
          {!!studio ? (
            <div
              style={{
                height: 'auto',
                position: 'relative',
                minHeight: '162px',
              }}
            >
              <StudioByIdHelmet
                name={studio?.name}
                city={studio?.city}
                state={studio?.state}
              />
              {size[3] > _MD ? (
                <div className={desktopStyles.container}>
                  <div className={desktopStyles.titleContainer}>
                    <button
                      onClick={handleFavorite}
                      className={`${desktopStyles.favorite} ${btnDefaultStyles['gray-outline-button']} ${btnDefaultStyles.button} focus_outline`}
                      aria-pressed="false"
                      aria-label={`Add ${studio?.name} in ${studio?.city}, ${studio?.state} to your saved studios`}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className={`${
                          updatingFavorite
                            ? favoriteStyles.updatingFavorite
                            : isFavorite
                            ? ''
                            : favoriteStyles.empty
                        } ${favoriteStyles.favorited}`}
                        viewBox="0 0 24 24"
                      >
                        <path d="M7.71075,4.00001328 C5.32351048,3.99460728 3,5.64026328 3,8.64401328 C3,12.1397633 10.2404741,19.986625 12,19.986625 C13.7595259,19.986625 21,12.1397633 21,8.64401328 C21,5.63576328 18.6772423,4.01294709 16.2945,4.00751328 C13.0057278,4.00001328 12.4403428,6.27840859 12,6.27840859 C11.5596572,6.27840859 11.02268,4.00751328 7.71075,4.00001328 Z"></path>
                      </svg>
                      <span>{updatingFavorite ? 'Saving' : 'Save'}</span>
                    </button>
                    <div
                      data-cy="studio-header"
                      className={desktopStyles.subHeader}
                    >
                      <span>Studio</span>
                    </div>
                    <div>
                      <h2 className={titleStyles.title}>{studio?.name}</h2>
                      {isMoveSite ? (
                        <>
                          {((studio?.categories?.length ?? 0) > 0 ||
                            (studio?.amenities?.length ?? 0) > 0) && (
                            <h3 className={titleStyles.tags}>
                              {[
                                ...(studio?.categories?.map((c) => c.name) ??
                                  []),
                                ...(studio?.amenities ?? []),
                              ].join(' • ')}
                            </h3>
                          )}
                        </>
                      ) : (
                        studio?.tags.length > 0 && (
                          <h3 className={titleStyles.tags}>
                            {studio?.tags.join(' • ')}
                          </h3>
                        )
                      )}
                      <h4 className={titleStyles.address}>
                        <a
                          rel="noopener noreferrer"
                          className={`${linkStyles.link} focus_outline`}
                          target="_blank"
                          aria-label={`Link to Google Maps for ${studio?.address}, ${studio?.city} ${studio?.state} ${studio?.zipcode}`}
                          href={`https://maps.google.com/?q=${studio?.address}, ${studio?.city} ${studio?.state} ${studio?.zipcode}`}
                        >{`${studio?.address}, ${studio?.city} ${studio?.state} ${studio?.zipcode}`}</a>
                      </h4>
                    </div>
                  </div>
                  <div className={desktopStyles.offerings}>
                    <MapBanner coordinates={studio?.point?.coordinates} />

                    {/*IF ADVERTISED MEMBERSHIP EXISTS AND user is not subscribed */}
                    {/* {memberships.filter(x => x.is_active).length > 0 ?
                                        <Membership memberships={memberships} /> : ''} */}
                    {memberships.filter((x) => x.is_active).length > 0 && (
                      <h3 className={desktopStyles.header}>
                        <span>Membership</span>
                      </h3>
                    )}
                    <Membership
                      memberships={memberships.filter((x) => x.is_active)}
                      studio={studio}
                    />

                    {/* <h3><span>Schedule</span></h3> */}
                    <Schedule studio={studio} isRefetching={isRefetching} onGetSessionError={errorCode => {
                      setScheduleErrorType(errorCode)
                    }}/>
                    {
                      scheduleErrorType !== null && ErrorMessageMap[scheduleErrorType] &&
                      <div className={errorStyle.scheduleErrorContainer}>
                        <p>{ErrorMessageMap[scheduleErrorType]}</p>
                      </div>
                    }
                    {
                      scheduleErrorType === null &&
                      <div className={errorStyle.scheduleErrorContainer}>
                        {
                          !isRefetching &&
                            <button className={`${btnDefaultStyles.button} focus_outline`} onClick={() => {
                              onViewSchedule()
                            }}>View Schedule</button>
                        }
                      </div>
                    }
                    <Link
                      className={`${desktopStyles.backtoStudios} ${utilitiesStyle.desktopOnly}`}
                      to={`${EXPLORE_STUDIOS}?${getSearchParams()}`}
                    >
                      <ArrowBackIosSharpIcon
                        className={svgIconStyles.icon}
                        style={{ verticalAlign: 'inherit' }}
                      />
                      <span>View All Studios</span>
                    </Link>
                  </div>
                  <div className={desktopStyles.aboutStudio}>
                    <div
                      className={`${desktopStyles.card} ${studioStyles.container} ${studioStyles.shadow}`}
                    >
                      <div
                        className={`${imageStyles.desktopImage} ${imageStyles.image} ${bannerStyles.container}`}
                        style={{
                          backgroundImage: `url(${
                            !imageExists ?  studioBanner : ''
                          })`,
                        }}
                      >
                        {
                          imageExists &&
                          <img src={studio.banner_image} alt="" />
                        }
                        <div className={bannerStyles.copy}></div>
                      </div>
                      <img
                        hidden
                        src={studio.banner_image ?? 'undefined'}
                        onError={handleImageError}
                      />
                    </div>
                    <h3 className={desktopStyles.header}>
                      <span>About</span>
                    </h3>
                    <About studio={studio} />
                  </div>
                </div>
              ) : (
                <>
                  <StudioMobile studio={studio} memberships={memberships} />
                </>
              )}
            </div>
          ) : (
            <h1>STUDIO NOT FOUND</h1>
          )}
          <LoginModal
            ref={ref}
            isShowing={isShowing}
            hide={toggle}
            studio={studio}
          />
        </>
      )}
    </MobilePage>
  );
};
