import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

import btnDefaultStyles from '../styles/button/Default.module.css';
import utilities from '../styles/utilities.module.css';
import buyLineStyles from '../styles/home/BuyLine.module.css';
import heroStyles from '../styles/Hero.module.css';

import phoneImg from '../Assets/7033a235e2a369e7d60c1fc1ba18801c.png';
import wellnessBg from '../Assets/87a49a7c9c6a9c94adcc8bf9de96c5a3.png';
import { CORPORATE_WELLNESS } from '../navigation/CONSTANTS';

export default function WellnessPrograms() {
  return (
    <div
      className={buyLineStyles.container}
      style={{ backgroundImage: `url(${wellnessBg})` }}
      data-testid="wellness-programs"
    >
      <div
        className={`${buyLineStyles.content} ${utilities['page-container']}`}
      >
        <div className={buyLineStyles.copy}>
          <h2>
            <span>Wellness shouldn’t be one-size-fits-all.</span>
          </h2>
          <p>
            <span>
              Peerfit makes it easy for your team to personalize their fitness
              journeys, build social connections and communities, and stay
              motivated to live healthfully, together.
            </span>
          </p>
          <Link
            className={`${buyLineStyles.button} ${heroStyles.btn} ${btnDefaultStyles.button} focus_outline`}
            aria-label="Learn more about corporate wellness"
            to={CORPORATE_WELLNESS}
          >
            <span>Learn more</span>
          </Link>
        </div>
        <img src={phoneImg} className={buyLineStyles.phone} alt="" />
      </div>
    </div>
  );
}
