import React from 'react';
import roundedButtonStyle from '../../../styles/button/Rounded.module.css';
import flexStyles from '../../../styles/Flex.module.css';

import reservationsStyles from '../../../styles/user/Reservations.module.css';
import reservationStyles from '../../../styles/user/Reservation.module.css';
import bannerStyles from '../../../styles/Banner.module.css';
import useModal from '../../../shared/Modal/useModal';
import defaultStudioImg from '../../../Assets/defaultStudio.jpg';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { CancelMembershipModal } from '../../../shared/Modal/CancelMembershipModal';
import { GetDate } from '../../../utilities/date';
import loginStyles from '../../../styles/Login.module.css';
import { useSite } from '../../../contexts/SiteContext';

export const FitOnMembershipCard = ({ membership, reload }) => {
  const { isShowing, toggle, ref } = useModal();

  const { studio } = membership || {};
  const { isMoveSite } = useSite();
  return (
    <>
      <div
        className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-4']} ${flexStyles['col-sm-6']} ${reservationsStyles.card}`}
      >
        <div className={reservationStyles.reservation}>
          <div
            className={bannerStyles.container}
            style={{ backgroundImage: `url(${defaultStudioImg})` }}
          >
            <Link
              className={bannerStyles.copy}
              to={`/studio/${studio.id}?from_source=Memberships`}
            >
              <h3 className={bannerStyles.title}>{membership.studio.name}</h3>
            </Link>
          </div>
          <div className={reservationStyles.content}>
            <div className={reservationStyles.info}>
              <h4 className={reservationStyles.tiny}>
                {membership.membershipplan.name}
              </h4>
              <h4 className={reservationStyles.tiny}>
                {membership.membershipplan.credits} Credits / Month
              </h4>
              {!membership.endedat && membership.nextbillat && (
                <h4 className={reservationStyles.tiny}>
                  Next Bill at{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {format(GetDate(membership.nextbillat), 'E, LLL dd')}
                  </span>
                </h4>
              )}
            </div>

            <div className={reservationStyles.actions}>
              {isMoveSite ? (
                <a
                  className={`${loginStyles.submit} ${roundedButtonStyle.button} focus_outline`}
                  href="https://fiton.app"
                  target="__blank"
                >
                  <span>
                    <span>Open FitOn</span>
                  </span>
                </a>
              ) : (
                <div className={reservationStyles.action}>
                  <a
                    className={reservationStyles.button}
                    href="https://fiton.app"
                    target="__blank"
                  >
                    Open FitOn
                  </a>
                </div>
              )}

              {membership.endedat ? (
                <h3 className={reservationStyles.noCancel}>Not Renewing</h3>
              ) : (
                <div className={reservationStyles.action}>
                  <a className={reservationStyles.button} onClick={toggle}>
                    Cancel Membership
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <CancelMembershipModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        membership={membership}
        onSuccess={reload}
      />
    </>
  );
};
