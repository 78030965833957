import React from 'react';

import styles from '../../styles/account/styles.module.css';
import cardStyles from '../../styles/Card.module.css';
import utilities from '../../styles/utilities.module.css';
import defaultBtnStyles from '../../styles/button/Default.module.css';
import redeemStyles from '../../styles/redeem/styles.module.css';
import useModal from '../../shared/Modal/useModal';
import * as PromoModalModule from '../../shared/Modal/PromoModal';
import { useAuth } from '../../contexts/AuthContext';
import utilitiesStyle from '../../styles/utilities.module.css';
import { Link } from 'react-router-dom';
import { EXPLORE } from '../../navigation/CONSTANTS';
import btnDefaultStyles from '../../styles/button/Default.module.css';
import roundedButtonStyle from '../../styles/button/Rounded.module.css';

const PromoModal: any = PromoModalModule.PromoModal;

export default function Credit({ ...props }) {
  const { authState } = useAuth();
  const { user } = authState || {};
  const { sponsorship } = user || {};
  const { sponsor, subsidy_tier } = sponsorship || {};

  const { isShowing, toggle, ref } = useModal();
  const { isShowing: promoIsShowing, toggle: promoToggle } = useModal();

  return (
    <>
      <div className={cardStyles.container}>
        <h3 className={cardStyles.title}>
          <span>Credits</span>
        </h3>
        <div className={`${cardStyles.hasTitle} ${cardStyles.content}`}>
          <div className={styles.container}>
            <div className={`${styles.digest} ${utilities['clear-fix']}`}>
              <div>
                <h3 className={styles.title}>
                  <svg
                    className={styles.coins}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3766 10.6022C21.4693 10.3057 21.52 9.99778 21.5273 9.68727V5.38182C21.5273 2.31418 16.8989 0 10.7636 0C4.62836 0 0 2.31418 0 5.38182V9.68727C0.0593337 10.4044 0.30246 11.094 0.705925 11.6898C1.10939 12.2856 1.65958 12.7673 2.30342 13.0886C2.20459 13.3798 2.1537 13.6852 2.15273 13.9927V18.2982C2.15273 21.3658 6.78109 23.68 12.9164 23.68C19.0516 23.68 23.68 21.3658 23.68 18.2982V13.9927C23.622 13.2767 23.3792 12.5879 22.9755 11.9936C22.5718 11.3994 22.0209 10.92 21.3766 10.6022ZM10.7636 15.0691C14.9076 15.0691 18.3628 13.9927 20.1711 12.3782C21.0321 12.8948 21.5273 13.4545 21.5273 13.9927C21.5273 15.3059 18.169 17.2218 12.9164 17.2218C7.66371 17.2218 4.40233 15.3597 4.30545 14.0465C6.38343 14.756 8.56814 15.1019 10.7636 15.0691ZM10.7636 2.15273C16.0163 2.15273 19.3745 4.06865 19.3745 5.38182C19.3745 6.69498 16.0163 8.61091 10.7636 8.61091C5.51098 8.61091 2.15273 6.69498 2.15273 5.38182C2.15273 4.06865 5.51098 2.15273 10.7636 2.15273ZM2.15273 8.67549C4.77064 10.1647 7.75437 10.8883 10.7636 10.7636C13.7729 10.8883 16.7566 10.1647 19.3745 8.67549V9.68727C19.3745 11.0004 16.0163 12.9164 10.7636 12.9164C5.51098 12.9164 2.15273 11.0004 2.15273 9.68727V8.67549ZM12.9164 21.5273C7.66371 21.5273 4.30545 19.6113 4.30545 18.2982V17.2864C6.92337 18.7756 9.90709 19.4992 12.9164 19.3745C15.9256 19.4992 18.9094 18.7756 21.5273 17.2864V18.2982C21.5273 19.6113 18.169 21.5273 12.9164 21.5273Z"
                      fill="#1F274C"
                    />
                  </svg>
                  <span>You have {user.credits} credits.</span>
                  <p className={styles.desc}>
                    You can purchase additional credits, if needed, while making
                    a reservation.
                  </p>
                  <div
                    className={`${redeemStyles.redeemBlock}  ${utilitiesStyle.desktopOnly}`}
                  >
                    <button
                      className={`${redeemStyles.redeemButton} ${defaultBtnStyles.button_link} focus_outline`}
                      onClick={promoToggle}
                    >
                      <span>Redeem Gift Code</span>
                    </button>
                  </div>
                </h3>
                {/* {sponsorship && <h4>Your Peerfit Subscription is sponsored by your employer, and you will have access to the network as long you remain an eligible employee.</h4>} */}
                {sponsorship && sponsorship.sponsor_id > 0 && (
                  <h4>
                    <span>
                      {sponsor.name} is providing you with{' '}
                      {subsidy_tier?.credits ?? 0} Credits per month. You can
                      purchase additional credits, if needed, while making a
                      reservation.
                    </span>
                  </h4>
                )}
                <Link
                  style={{ margin: 0 }}
                  className={`${btnDefaultStyles['secondary-button']} ${btnDefaultStyles.button}  ${roundedButtonStyle['secondary-button']} ${roundedButtonStyle.button} ${utilities.mobileOnly} focus_outline`}
                  to={EXPLORE}
                >
                  <span>Search Studios</span>
                </Link>
                <div
                  className={`${redeemStyles.redeemBlockMobile} ${utilitiesStyle.mobileOnly}`}
                >
                  <button
                    className={`${redeemStyles.redeemButton} ${defaultBtnStyles.button_link} focus_outline`}
                    onClick={promoToggle}
                  >
                    <span>Redeem Gift Code</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PromoModal ref={ref} isShowing={promoIsShowing} hide={promoToggle} />
    </>
  );
}
