import React, { useState } from 'react';
import ImageJoinedFitOn from './image-joined-fiton.png';
import IconCheckedBlue from './icon-checked-blue.svg';
import styles from './index.module.css';
import { callRNFunction } from '../../utilities/rn';

const DataList = [
  'Exclusive workout programs',
  'Personalized meal plans',
  '500+ exclusive recipes',
  'Unlimited offline downloads',
  'Fitness tracker integration*',
  'Premium music from top artists',
];

export const JoinedFitOn = () => {
  return (
    <div className={styles.container}>
      <h3>
        Unlimited Workouts,
        <br /> any time, anywhere
      </h3>
      <img src={ImageJoinedFitOn} alt="" className={styles.image} />
      <ul>
        {DataList.map((text, index) => (
          <li key={index}>
            <img src={IconCheckedBlue} alt="" className={styles.imageChecked} />
            {text}
          </li>
        ))}
      </ul>
      <button
        onClick={() => {
          callRNFunction('openLink', 'https://fiton.app');
        }}
        className={styles.button}
      >
        OPEN APP
      </button>
    </div>
  );
};
