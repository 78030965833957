export const initialSearchState = {
  bounds: [], // [lng,lat,lng,lat]
  center: [], // [lat,lng]
  date: null,
  has_digital: null,
  has_memberships: null,
  place_id: null,
  search: null,
  sort: null, // [distance (empty), name, -id], [start_datetime, -id, credits]
  tags: [],
  categories: [],
  time: null, // [early_morning, morning, midday, afternoon, early_evening, evening]
  current_tab: 0,
  shouldForceSearch: false,
};
