import React, { useContext } from 'react';
import { AuthContext, useAuth } from './contexts/AuthContext';
import { AcceptedNotificationModal } from './shared/Modal/AcceptedNotificationModal';
import { NotificationModal } from './shared/Modal/NotificationModal';
import useModal from './shared/Modal/useModal';
import notificationsStyles from './styles/Notifications.module.css';

export const NotificationItem = ({ closeMenu, notification, reload }) => {
  const { isShowing, toggle, ref } = useModal();
  const {
    isShowing: isShowingAccepted,
    toggle: toggleAccepted,
    ref: refAccepted,
  } = useModal();

  const readMail = (e) => {
    closeMenu(e);
    if (notification.category == 1) toggle();
    if (notification.category == 2) toggleAccepted();
  };

  return (
    <>
      <div onClick={readMail} className={`${notificationsStyles.viewAll}`}>
        {notification.category == 1 && (
          <>
            <div className={notificationsStyles.dropdownHeader}>
              <h2 data-testid="notification-inviter-name">
                {notification.data.inviter_name}
              </h2>
            </div>
            <div className={notificationsStyles.dropdownLabel}>
              <span>sent you an invitation!</span>
            </div>
          </>
        )}
        {notification.category == 2 && (
          <div className={notificationsStyles.dropdownHeader}>
            <h2>{notification.data.accepter_name}</h2>
            <div className={notificationsStyles.dropdownLabel}>
              <span>accepted your invitation!!</span>
            </div>
          </div>
        )}
      </div>
      {isShowing && (
        <NotificationModal
          ref={ref}
          isShowing={isShowing}
          hide={toggle}
          notification={notification}
          reload={reload}
        />
      )}
      {isShowingAccepted && (
        <AcceptedNotificationModal
          ref={refAccepted}
          isShowing={isShowingAccepted}
          hide={toggleAccepted}
          notification={notification}
          reload={reload}
        />
      )}
    </>
  );
};
