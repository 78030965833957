import appboy from '@braze/web-sdk';
import amplitude from 'amplitude-js';
import { getIsMoveSite } from '../../utilities/siteType';
// import { getSignUpFullUrl } from './fitOnSignUp';
import { capitalize, uniq } from 'lodash-es';
import { format } from 'date-fns';
import { isReactNative } from '../../utilities/rn';

export const RegistrationIntent = {
  1: 'EMPLOYEE_SPONSORED',
  2: 'CONSUMER',
  3: 'MEDICARE',
  4: 'OFFLINE',
};

export const SponsorType = {
  0: 'Sponsor Consumer Users',
  1: 'Sponsor Core Users',
  2: 'Sponsor Move Users',
};

export const initAmplitude = (isFitOnBraze) => {
  const isMoveSite = getIsMoveSite();
  const amplitudeKey = isFitOnBraze
    ? process.env.REACT_APP_AMPLITUDE_API_KEY_FITON
    : isMoveSite
    ? process.env.REACT_APP_AMPLITUDE_API_KEY_MOVE
    : process.env.REACT_APP_AMPLITUDE_API_KEY_CORE;

  amplitude.getInstance().init(amplitudeKey);
  amplitude.getInstance().setVersionName(isReactNative ? window.__PF_APP_VERSION__ : 'web #0');
};

export const initAppBoy = async (isFitOnBraze) => {
  const isMoveSite = getIsMoveSite();
  const brazeAPIKey = isFitOnBraze
    ? process.env.REACT_APP_BRAZE_API_KEY_FITON
    : isMoveSite
    ? process.env.REACT_APP_BRAZE_API_KEY_MOVE
    : process.env.REACT_APP_BRAZE_API_KEY_CORE;

  await appboy.initialize(brazeAPIKey, {
    baseUrl: isFitOnBraze ? process.env.REACT_APP_BRAZE_SDK_ENDPOINT_FITON : process.env.REACT_APP_BRAZE_SDK_ENDPOINT,
    allowUserSuppliedJavascript: true,
    enableLogging: true,
  });
};

let subscribeId;

export const subscribeInAppMessage = (user) => {
  return;
  // appboy.removeSubscription(subscribeId);
  // if (appboy.isPushSupported()) {
  //   logAmplitudeEvent('Permission: Push');
  //   appboy.registerAppboyPushMessages(
  //     () => {
  //       logAmplitudeEvent('Permission: Push', { Permission: 'Allow' });
  //     },
  //     () => {
  //       logAmplitudeEvent('Permission: Push', { Permission: 'Deny' });
  //     }
  //   );
  // }
  // subscribeId = appboy.subscribeToInAppMessage(async function (inAppMessage: any) {
  //   // Display the in-app message. You could defer display here by pushing this message to code within your own application.
  //   // If you don't want to use Braze's built-in display capabilities, you could alternatively pass the in-app message to your own display code here.
  //   const signUpUrl = await getSignUpFullUrl(user, 'iam');
  //   if (inAppMessage.message) {
  //     inAppMessage.message = inAppMessage.message.replace(/\[\[signUpUrl\]\]/g, signUpUrl);
  //   }
  //   appboy.display.showInAppMessage(inAppMessage);
  //   console.log('fire inAppMessage', inAppMessage);
  // });
};

export const logUserProfile = (user) => {
  return;
  // if (!user?.id) return;
  // try {
  //   runOnceBy(() => logAmplitudeEvent('Session Start'), user.id);
  //   subscribeInAppMessage(user);
  //   amplitude.getInstance().setUserId(user.id);
  //   appboy.changeUser(user.id);
  //   appboy.openSession();

  //   const abUser = appboy.getUser();
  //   const identify = new amplitude.Identify();

  //   const birthDate = new Date(user.birth_date);
  //   const userType = RegistrationIntent[user.registration_intent];

  //   abUser.setFirstName(user.first_name);
  //   abUser.setLastName(user.last_name);
  //   abUser.setEmail(user.email);
  //   abUser.setGender(user.gender);
  //   abUser.setHomeCity(user.home_city);
  //   abUser.setCustomUserAttribute('User Type', userType);
  //   // abUser.setCustomUserAttribute('Eligible', !!user.sponsor);

  //   identify.set('First Name', user.firstName);
  //   identify.set('Last Name', user.lastName);
  //   identify.set('Email', user.email);
  //   identify.set('Gender', user.gender);
  //   identify.set('User Type', userType);
  //   identify.set('Home City', user.home_city);

  //   if (!isNaN(+birthDate)) {
  //     abUser.setDateOfBirth(birthDate.getFullYear(), birthDate.getMonth() + 1, birthDate.getDate());
  //     identify.set('BirthDate', user.birthDate);
  //   }

  //   if (user?.sponsorship?.id) {
  //     abUser.setCustomUserAttribute('Sponsor ID', user.sponsorship.sponsor.id);
  //     abUser.setCustomUserAttribute('Sponsor Name', user.sponsorship.sponsor.name);
  //     abUser.setCustomUserAttribute('Sponsor Type', user.sponsorship.sponsor.sponsor_type_name);

  //     identify.set('Sponsor ID', user.sponsorship.sponsor.id);
  //     identify.set('Sponsor Name', user.sponsorship.sponsor.name);
  //     identify.set('Sponsor Type', user.sponsorship.sponsor.sponsor_type_name);
  //     identify.set('Credits Remaining', user.credits);
  //   }

  //   appboy.requestImmediateDataFlush();
  //   amplitude.getInstance().identify(identify);
  // } catch (error) {
  //   console.error('update user profile error', error);
  // }
};

export const logCustomUserAttribute = (key, value) => {
  return;
  // console.log('logCustomUserAttribute', key, value);
  // const abUser = appboy.getUser();
  // const identify = new amplitude.Identify();
  // abUser.setCustomUserAttribute(key, value);
  // identify.set(key, value);
  // appboy.requestImmediateDataFlush();
  // amplitude.getInstance().identify(identify);
};

export const logBrazeEvent = (eventName, data?: any) => {
  return;
  // console.log('logBrazeEvent', eventName, data);
  // appboy.logCustomEvent(eventName, data);
};

export const logAmplitudeEvent = (eventName, data?: any) => {
  return;
  // console.log('logAmplitudeEvent', eventName, data);
  // amplitude.getInstance().logEvent(eventName, data);
};

export const logAdminAmplitudeEvent = (eventName, data?: any) => {
  console.log('logAdminAmplitudeEvent', eventName, data);
  amplitude.getInstance().logEvent(eventName, data);
};

export const logEventToAllPlatform = (eventName, data?: any) => {
  logBrazeEvent(eventName, data);
  logAmplitudeEvent(eventName, data);
};

export const getStudioLogInfo = (studio) => {
  return {
    ID: studio.id,
    Name: studio.name,
    Affiliation: studio.tags.join(', '),
    Type: undefined,
    Mindbody: studio.is_mindbody,
    Category: getStudioCategories(studio),
    Source: getSourceFromParams(),
  };
};

const getStudioCategories = (studio) => {
  const arr = [...(studio?.categories || []), ...(studio?.tags || [])];
  return uniq(arr?.map((item) => item.name ?? item));
};

export const getPurchaseLogInfo = (params) => {
  const { studio, cls, membership, session, time, source } = params;
  const item = {
    'Studio ID': studio?.id,
    'Studio Name': studio?.name,
    'Studio Affiliation': studio?.tags,
    Mindbody: studio?.is_mindbody,
    'Class Day': time ? format(new Date(time), 'iiii') : '',
    'Class Time': time ? format(new Date(time), 'pp') : '',
    Source: source ?? 'Studio',
    Category: getStudioCategories(studio),
  };
  if (cls) {
    Object.assign(item, {
      Type: 'Class',
      Credits: cls?.credits,
      'Item ID': cls?.id,
      'Item Name': cls?.name,
      Category: getStudioCategories(cls),
    });
  }
  if (session) {
    Object.assign(item, {
      Type: 'Class',
      Credits: session?.credits,
      'Item ID': session?.id,
      'Item Name': session?.name,
      Category: getStudioCategories(session),
    });
  }
  if (membership) {
    Object.assign(item, {
      Type: 'Membership',
      Credits: membership?.credits,
      'Item ID': membership?.id,
      'Item Name': membership?.name,
      Category: getStudioCategories(studio),
    });
  }
  return item;
};

const ran = {};

export const runOnceBy = (fn, key) => {
  if (!ran[key]) {
    fn();
    ran[key] = true;
  }
};

export const getSourceFromPathName = () => {
  return capitalize(window.location.pathname.replace('/', '')).replace(/\//g, ' ');
};

export const getSourceFromParams = (keepSource = false) => {
  const url = new URL(window.location.href);
  const source = url.searchParams.get('from_source');
  if (!keepSource) {
    setTimeout(() => {
      url.searchParams.delete('from_source');
      window.history.replaceState({}, '', url);
    }, 0);
  } else {
    window.history.replaceState({}, '', url);
  }
  return source;
};
