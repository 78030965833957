import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import pageStyles from '../../../styles/studios/Page.module.css';
import streamingStudiosStyles from '../../../styles/StreamingStudios.module.css';
import { DISCOVER } from '../../../navigation/CONSTANTS';
// import studioListStyles from '../../../styles/streaming/StudioList.module.css';
// import Badgess from '../Badges';
import badgesStyles from '../../../styles/discover/Badges.module.css';
import badges1Styles from '../../../styles/badges/Badges.module.css';

import userApi from '../../../api/user';
import { useAuth } from '../../../contexts/AuthContext';
import Badge from '../Badge';

import loadingStyles from '../../../styles/Loading.module.css';
import spinnerGroupStyles from '../../../styles/spinner/Group.module.css';

function Badges() {
  const { authState } = useAuth();
  const { user } = authState || {};
  const [badges, setBadges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadBadges();
  }, []);

  const loadBadges = async () => {
    setIsLoading(true);
    userApi
      .getBadges(user.id)
      .then((res) => {
        setBadges(res.results);
      })
      .catch((error) => {
        console.log('error: ', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className={pageStyles.container}
      style={{ height: 'auto', position: 'relative', minHeight: '216px' }}
    >
      <div className={`${pageStyles.inner} ${streamingStudiosStyles.inner}`}>
        <Link className={pageStyles.backLink} to={DISCOVER}>
          <svg
            style={{ verticalAlign: 'inherit' }}
            aria-hidden="true"
            focusable="false"
            className={pageStyles.chevron}
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M16.4244649,19.2868077 C16.7653329,19.6293551 16.7639715,20.1833728 16.4214241,20.5242408 C16.0788767,20.8651089 15.524859,20.8637475 15.183991,20.5212001 L7.25476309,12.5529074 C6.9164664,12.2129441 6.91488398,11.6640093 7.25121504,11.3221012 L15.1804429,3.26139014 C15.5193304,2.9168832 16.073331,2.91232773 16.417838,3.2512152 C16.7623449,3.59010267 16.7669004,4.1441033 16.4280129,4.48861023 L9.10587647,11.932163 L16.4244649,19.2868077 Z"></path>
          </svg>
          <span>Back to Discover</span>
        </Link>
        <div className={pageStyles.header}>
          <h2>
            <span>Your Badges</span>
          </h2>
          {/* <p className={pageStyles.subheader}>
            <span>Studios offering online classes.</span>
          </p> */}
        </div>

        <div className={badgesStyles.container}>
          <div className={badgesStyles.inner}>
            {isLoading ? (
              <div
                className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`}
              ></div>
            ) : (
              <div
                className={badges1Styles.container}
                style={{ marginBottom: '35px' }}
              >
                {badges.map((badge, i) => (
                  <Badge
                    key={i}
                    badge={badge}
                    style={{ marginBottom: '35px' }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { Badges };
