import { get } from 'lodash';
import apiInstance from './api';

export const getFitOnSignature = async (content) => {
  const api = await apiInstance.getApi();
  return api
    .get('/fit-on/generate-signature', { params: { content } })
    .then((res) => {
      return get(res, 'data.signature');
    });
};

export const getFitOnFeatureFlag = async (featureName) => {
  const api = await apiInstance.getApi();
  return api.get(`/feature-flag/${featureName}`).then((res) => {
    return get(res, 'data.enabled') === 1;
  });
};
