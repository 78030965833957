import React from 'react';

import { NavLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { SETTINGS } from '../../navigation/CONSTANTS';
import navStyles from '../../styles/user/Nav.module.css';
import utilities from '../../styles/utilities.module.css';

function UserNavigation() {
  const { authState, login } = useAuth();

  return (
    <div className={navStyles.container}>
      <div className={`${navStyles.content} ${utilities['page-container']}`}>
        <ul className={navStyles.tabs}>
          <li className={navStyles.navItem}>
            <NavLink
              to={`/user/${authState.user?.id}/upcoming`}
              className={navStyles.link}
              activeClassName={`${navStyles.activeLink} ${navStyles.link}`}
            >
              Upcoming
            </NavLink>
          </li>
          <li className={navStyles.navItem}>
            <NavLink
              to={`/user/${authState.user?.id}/memberships`}
              className={navStyles.link}
              activeClassName={`${navStyles.activeLink} ${navStyles.link}`}
            >
              Memberships
            </NavLink>
          </li>
          <li className={navStyles.navItem}>
            <NavLink
              to={`/user/${authState.user?.id}/saved`}
              className={navStyles.link}
              activeClassName={`${navStyles.activeLink} ${navStyles.link}`}
            >
              Favorites
            </NavLink>
          </li>
          <li className={navStyles.navItem}>
            <NavLink
              to={`/user/${authState.user?.id}/attended`}
              className={navStyles.link}
              activeClassName={`${navStyles.activeLink} ${navStyles.link}`}
            >
              Past Classes
            </NavLink>
          </li>
          <li className={navStyles.navItem}>
            <NavLink
              to={SETTINGS}
              className={navStyles.link}
              activeClassName={`${navStyles.activeLink} ${navStyles.link}`}
            >
              Settings
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default UserNavigation;
