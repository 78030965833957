import React, { useContext, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import logoBurst from './Assets/burst-color.svg';
import logo from './Assets/peerfit-core.png';
import moveBurst from './Assets/move-burst.svg';
import moveLogo from './Assets/peerfit-move.png';
import { AuthContext } from './contexts/AuthContext';
import { GlobalContext } from './contexts/GlobalContext';
import { SiteContext } from './contexts/SiteContext';
import HeaderAction from './HeaderAction';
import {
  ADMIN,
  CORPORATE_WELLNESS,
  DISCOVER,
  EXPLORE_CLASSES,
  EXPLORE_STUDIOS,
  FITNESS_PARTNERS,
  HOME,
} from './navigation/CONSTANTS';
import Search from './Search';
import useWindowSize, { _MD, _SM } from './shared/hooks/useWindowSize';
import btnDefaultStyles from './styles/button/Default.module.css';
import headerStyles from './styles/Header.module.css';
import AdminHeader from './Admin/pages/Admin/AdminHeader';

function Header() {
  const authContext = useContext(AuthContext);
  const globalContext = useContext(GlobalContext);
  const siteContext = useContext(SiteContext);

  const size = useWindowSize();
  const matchHome = useRouteMatch({ path: HOME, exact: true });
  const matchExplore = useRouteMatch([EXPLORE_STUDIOS, EXPLORE_CLASSES]);
  const matchAdmin = useRouteMatch(ADMIN);

  const onScroll = (e) => {
    window.requestAnimationFrame(() => {
      setScrollTop(e.target.documentElement.scrollTop);
    });
  };

  const [scrollTop, setScrollTop] = useState();
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if (matchAdmin) {
    return <AdminHeader />;
  }

  return (
    <header
      id="header"
      className={headerStyles.container}
      data-hero-route="false"
      data-static-banner={matchHome ? 'true' : 'false'}
      data-search-route={size[3] <= _MD ? 'true' : 'false'}
      data-sticky={scrollTop > 5} //"true"
    >
      <div className={headerStyles.inner} style={{ alignItems: 'center' }}>
        <Link to={DISCOVER} className={headerStyles.index}>
          {siteContext.isMoveSite ? (
            <img className={`${headerStyles.logo} ${headerStyles.move}`} src={size[3] <= _SM ? moveBurst : moveLogo} alt="" />
          ) : (
            <img className={headerStyles.logo} src={size[3] <= _SM ? logoBurst : logo} alt="" />
          )}
        </Link>

        {!authContext.authState.isAuthenticated ? (
          <>
            {size[3] <= _SM ? (
              <>
                <HeaderAction />
                {matchExplore ? (
                  <Search />
                ) : (
                  <>
                    <Link className={`${headerStyles.linkButton} ${btnDefaultStyles.button}`} to={`${EXPLORE_STUDIOS}?${globalContext.exploreParams}`}>
                      <span>Explore</span>
                    </Link>
                    {(
                      <>
                        <Link className={`${headerStyles.linkButton} ${btnDefaultStyles.button}`} to={CORPORATE_WELLNESS}>
                          <span>Corporate Wellness</span>
                        </Link>
                        <Link className={`${headerStyles.linkButton} ${btnDefaultStyles.button}`} to={FITNESS_PARTNERS}>
                          <span>Fitness Partners</span>
                        </Link>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {matchExplore ? (
                  <Search />
                ) : (
                  <>
                    <Link className={`${headerStyles.linkButton} ${btnDefaultStyles.button}`} to={`${EXPLORE_STUDIOS}?${globalContext.exploreParams}`}>
                      <span>Explore</span>
                    </Link>
                    {size[3] > _MD && (
                      <>
                        <Link className={`${headerStyles.linkButton} ${btnDefaultStyles.button}`} to={CORPORATE_WELLNESS}>
                          <span>Corporate Wellness</span>
                        </Link>
                        <Link className={`${headerStyles.linkButton} ${btnDefaultStyles.button}`} to={FITNESS_PARTNERS}>
                          <span>Fitness Partners</span>
                        </Link>
                      </>
                    )}
                  </>
                )}
                <HeaderAction />
              </>
            )}
          </>
        ) : (
          <>
            {size[3] <= _SM ? (
              <Link className={`${headerStyles.exploreLinkButton} ${headerStyles.linkButton} ${btnDefaultStyles.button} focus_outline`} to={EXPLORE_STUDIOS}>
                <span>Explore</span>
              </Link>
            ) : (
              ''
            )}

            {size[3] <= _SM ? (
              <>
                <HeaderAction />
                <Search />
              </>
            ) : (
              <>
                <Search />
                <HeaderAction />
              </>
            )}
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
