export const ROOT = '/';
export const HOME = '/';
export const ADMIN = '/admin';
export const WELLNESS_PROGRAMS = '/for-wellness-programs';
export const FITNESS_FACILITIES = '/for-fitness-facilities';

export const CHECKOUT = '/checkout';

export const DISCOVER = '/discover';
export const BADGES = '/discover/badges';

export const EXPLORE = '/explore';
export const EXPLORE_STUDIOS = '/explore/studios';
export const EXPLORE_CLASSES = '/explore/classes';

export const STREAMING = '/streaming';
export const STREAMING_STUDIOS = '/streaming/studios';
export const STUDIO = '/studio';
export const USER = '/user';
export const ACCOUNT = '/account';
export const SETTINGS = '/account/settings';
export const PROFILE = '/account/profile';
export const PREFERENCES = '/account/preferences';
export const PAYMENT = '/account/payment';
export const CREDITS = '/account/credits';

export const LOGIN = '/login';
export const REGISTER = '/register';
export const REGISTER_NEW = '/register-new';
export const GUEST = '/guest';
export const GUEST_USER = '/guest/user';
export const REGISTER_USER = '/register/user';
export const REGISTER_CHECK_YOUR_EMAIL = '/register/check-your-email';
export const FORGOT_PASSWORD = '/forgot-password';
export const CHANGE_PASSWORD = '/change-password';
export const REGISTER_SPONSORED = '/register/sponsored';
export const REGISTER_CONSUMER = '/register/consumer';
export const REGISTER_MOVE = '/register/move'; // https://app.peerfitmove.cm/register
export const REGISTER_MOVE_ALT = '/register/move-alt'; // https://app.peerfitmove.cm/register-alt

export const NOMINATE_EMPLOYER = '/nominate-employer'; // "https://wellness.peerfit.cm/nominate-employer"

export const ABOUT_US = '/about-us';
export const HOW_IT_WORKS = '/how-it-works';
export const INDIVIDUAL_SUBSCRIPTION = '/individual-subscription';
export const CORPORATE_WELLNESS = '/corporate-wellness';
export const FITNESS_PARTNERS = '/fitness-partners';
export const CONTACT_US = '/contact-us';
export const PRIVACY_POLICY = '/privacy-policy';
export const COOKIE_POLICY = '/cookie-policy';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const ACCESSIBILITY = '/accessibility';

export const AETNA = '/aetna';
export const ADMIN_FILE_UPLOAD = `${ADMIN}/file-upload`;
export const ADMIN_FILE_UPLOAD_PREVIEW = `${ADMIN_FILE_UPLOAD}/preview`;

export const DIGITAL = '/digital';

// https://medium.com/@saurabhshah23/react-js-architecture-features-folder-structure-design-pattern-70b7b9103f22
