import React from 'react';
import digitalFitnessBannerStyles from '../styles/DigitalFitnessBanner.module.css';

import { STREAMING } from '../navigation/CONSTANTS';
import { Link } from 'react-router-dom';

export default function DigitalFitnessBanner() {
  return (
    <Link className={digitalFitnessBannerStyles.container} to={STREAMING}>
      <svg
        aria-hidden="true"
        focusable="false"
        className={digitalFitnessBannerStyles.icon}
        width="92"
        height="92"
        viewBox="0 0 92 92"
        fill="none"
      >
        <svg
          width="92"
          height="92"
          viewBox="0 0 92 92"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="46" cy="46" r="46" fill="#EDF4FD"></circle>
          <path
            d="M74.9985 29.993C74.9976 29.993 74.9969 29.9923 74.9969 29.9915C74.9963 27.2677 73.2489 25 71.1546 25H20.8423C18.7445 25 17 27.2686 17 29.993V58C17 61.3137 19.6863 64 23 64H69C72.3137 64 75 61.3137 75 58V29.9945C75 29.9937 74.9993 29.993 74.9985 29.993Z"
            fill="white"
            stroke="#212B56"
            strokeWidth="1.5056"
            strokeMiterlimit="10"
          ></path>
          <path
            d="M20.3535 56.8413C18.6109 56.8413 17.1982 58.254 17.1982 59.9966C17.1982 62.3194 18.9396 64.2536 21.0268 64.2536H36.1183C38.9825 64.2536 41.4214 68.0195 38.559 68.1227C38.4899 68.1252 38.4003 68.1234 38.3311 68.123C37.7033 68.1198 34.4286 68.2288 34.6058 70.861C34.6455 71.4792 35.0924 72.0209 35.6493 72.0209H56.5365C57.0935 72.0209 57.5893 71.4792 57.5801 70.861C57.5427 68.247 54.6506 68.112 53.9185 68.1197C53.8069 68.1209 53.6638 68.1258 53.5524 68.1192C50.6804 67.9511 53.1451 64.2536 56.022 64.2536H71.1591C73.2493 64.2536 74.9876 62.3194 74.9876 59.9966C74.9876 58.254 73.575 56.8413 71.8324 56.8413H20.3535Z"
            fill="white"
            stroke="#212B56"
            strokeWidth="1.5056"
            strokeMiterlimit="10"
          ></path>
          <path
            opacity="0.2"
            d="M20.3535 56.8413C18.6109 56.8413 17.1982 58.254 17.1982 59.9966C17.1982 62.3194 18.9396 64.2536 21.0268 64.2536H36.1183C38.9825 64.2536 41.4214 68.0195 38.559 68.1227C38.4899 68.1252 38.4003 68.1234 38.3311 68.123C37.7033 68.1198 34.4286 68.2288 34.6058 70.861C34.6455 71.4792 35.0924 72.0209 35.6493 72.0209H56.5365C57.0935 72.0209 57.5893 71.4792 57.5801 70.861C57.5427 68.247 54.6506 68.112 53.9185 68.1197C53.8069 68.1209 53.6638 68.1258 53.5524 68.1192C50.6804 67.9511 53.1451 64.2536 56.022 64.2536H71.1591C73.2493 64.2536 74.9876 62.3194 74.9876 59.9966C74.9876 58.254 73.575 56.8413 71.8324 56.8413H20.3535Z"
            fill="#D91E61"
          ></path>
          <path
            d="M45.9837 51.7975C51.7592 51.7975 56.4411 47.1156 56.4411 41.3402C56.4411 35.5647 51.7592 30.8828 45.9837 30.8828C40.2083 30.8828 35.5264 35.5647 35.5264 41.3402C35.5264 47.1156 40.2083 51.7975 45.9837 51.7975Z"
            fill="white"
            stroke="#212B56"
            strokeWidth="1.5056"
            strokeMiterlimit="10"
          ></path>
          <path
            d="M49.6907 42.206C50.3572 41.8211 50.3572 40.8591 49.6907 40.4741L43.714 37.0222C43.0473 36.6371 42.2139 37.1183 42.2139 37.8881V44.792C42.2139 45.5619 43.0473 46.043 43.714 45.6579L49.6907 42.206Z"
            fill="white"
            stroke="#212B56"
            strokeWidth="1.5056"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            opacity="0.2"
            d="M50.4069 42.3762C51.0977 41.9963 51.0977 41.0037 50.4069 40.6238L43.4819 36.8151C42.8155 36.4485 42 36.9307 42 37.6913V45.3087C42 46.0693 42.8155 46.5515 43.4819 46.1849L50.4069 42.3762Z"
            fill="#D91E61"
          ></path>
          <path
            d="M17.2471 43.1061L28.4328 31.9204"
            stroke="#212B56"
            strokeWidth="1.5056"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M30.2746 24.7622L24.9189 30.121"
            stroke="#212B56"
            strokeWidth="1.5056"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </svg>
      <div className={digitalFitnessBannerStyles.copyContainer}>
        <h2 className={digitalFitnessBannerStyles.title}>
          <span>Peerfit at Home</span>
        </h2>
        <span className={digitalFitnessBannerStyles.description}>
          Stream thousands of workouts from the comfort of home. See live and
          on-demand options!
        </span>
      </div>
    </Link>
  );
}
