export const ErrorMessageMap = {
  AllClassesCancelled: 'All classes have been cancelled, please select another fitness partner.',
  NoClasses: 'This Fitness partner is offline, please find another partner or contact us at support@peerfit.com for assistance in reserving your class.',
  NoPricingOptions: 'Class schedule is offline, please select another fitness partner.',
  Unauthorized:
    'This Fitness partner is offline, please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  Forbidden: 'Class schedule is offline, , please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  InternalServerError:
    'This Fitness partner is offline, please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  SiteSyncError:
    'This Fitness partner is offline, please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  LocationSyncError:
    'Class schedule is offline, please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  ProgramServiceSyncError:
    'Class schedule is offline, please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  ClassDescriptionSyncError:
    'Class schedule is offline, please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  ClassesSyncError:
    'Class schedule is offline, , please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  UnknownError:
    'This Fitness partner is offline, please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
  SyncDisabled: 'Class schedule is offline, please select another fitness partner or contact us at support@peerfit.com for assistance in reserving your class.',
};
