import { GoogleMap } from '@react-google-maps/api';
import React, { useCallback, useState, useEffect } from 'react';
import { useSearch } from '../contexts/SearchContext';

import MyMarker from './MyMarker';
import RegionMarker from './RegionMarker';
import { SearchResult } from '../components/SearchResult';
import { useSite } from '../contexts/SiteContext';

import customMapStyles from '../shared/Map/mapStyles';
import btnDefaultStyles from '../styles/button/Default.module.css';
import loadingStyles from '../styles/Loading.module.css';
import mapStyles from '../styles/Map.module.css';

const options = {
  disableDefaultUI: true,
  fullscreenControl: true,
  zoomControl: true,
  clickableIcons: false,
  scrollwheel: false,
  minZoom: 8,
  styles: [
    ...customMapStyles,
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

export default function Map() {
  const [activeStudioId, setActiveStudioId] = useState(null);
  const [mapChanged, setMapChanged] = useState(false);
  const [zoom, setZoom] = useState(11);
  const [center, setCenter] = useState(11);
  const { setMapBounds, searchState, studioList, studiosLoading, mapRegions, setForceSearch } = useSearch();
  const [mapRef, setMapRef] = useState(null);
  const { isMobileApp } = useSite();

  const onBoundsChanged = () => {
    if (searchState.bounds.length === 0) {
      console.log('bounds loaded.');
      handleUpdateSearch();
    }
  };

  const handleUpdateSearch = () => {
    if (mapRef) {
      const b = mapRef.getBounds();
      const sw = b?.getSouthWest();
      const ne = b?.getNorthEast();
      const c = mapRef.getCenter();
      setMapChanged(false);
      if (sw && ne && c) setMapBounds([sw?.lng(), sw?.lat(), ne?.lng(), ne?.lat()], [c?.lat(), c?.lng()]);
    }
  };

  const onZoomChanged = () => {
    if (mapRef) {
      // console.log("zoom: ", mapRef.zoom);
      setZoom(mapRef.zoom);
      if (!mapChanged) setMapChanged(true);
    }
  };

  const onDragStart = () => {
    if (mapRef) {
      // console.log("dragStart.");
      if (!mapChanged) setMapChanged(true);
    }
  };

  const onLoad = useCallback(function onLoad(mapInstance) {
    console.log('SETTING MAP INSTANCE.');
    setMapRef(mapInstance);
    setMapChanged(false);
    if (searchState?.center[0] && searchState?.center[1]) {
      setCenter({
        lat: parseFloat(searchState?.center[0]),
        lng: parseFloat(searchState?.center[1]),
      });
    }
  });

  useEffect(() => {
    if (searchState.shouldForceSearch) {
      handleUpdateSearch();
      setForceSearch(false);
    }
  }, [searchState.bounds, searchState.shouldForceSearch]);

  // not sure why use key to control bounds refresh, but fixed with old logic here
  const mapKey = searchState.search + searchState.center?.join(',');

  return (
    searchState.center.length > 0 && (
      <>
        <div className={studiosLoading ? loadingStyles.spinner : loadingStyles.hidden}></div>
        <div data-cy="map-center" className={mapStyles.map}>
          {mapChanged && (
            <div className={mapStyles.mapUpdateBtnContainer}>
              <button onClick={handleUpdateSearch} className={`${mapStyles.mapUpdateBtn} ${btnDefaultStyles.button} focus_outline`}>
                <span>Redo search in this area</span>
              </button>
            </div>
          )}
          {!isMobileApp && (
            <div className={mapStyles.mapOptions}>
              {(zoom === 8 || zoom > 9) && (
                <div className={mapStyles.notice}>
                  {zoom === 8 && <span>Zoom in to see studios or enter a specific location in the search bar above.</span>}
                  {zoom > 9 && <span>{studioList.length > 99 ? '100+' : studioList.length} Studio Results</span>}
                </div>
              )}
            </div>
          )}

          {isMobileApp && <SearchResult />}

          {/* MAP */}
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%', margin: '0px' }}
            center={center}
            zoom={zoom}
            options={options}
            onLoad={onLoad}
            onBoundsChanged={onBoundsChanged}
            onZoomChanged={onZoomChanged}
            onDragStart={onDragStart}
            onClick={() => {
              setActiveStudioId(null);
            }}
            key={mapKey}
          >
            {zoom > 9 ? (
              studioList?.map((x, i) => <MyMarker key={'_' + i} studio={x} activeStudioId={activeStudioId} setActiveStudioId={setActiveStudioId} />)
            ) : (
              <>
                {mapRegions?.map((x, a) => (
                  <RegionMarker key={'__' + a} region={x} mapRef={mapRef} handleUpdateSearch={handleUpdateSearch} />
                ))}
              </>
            )}
          </GoogleMap>
        </div>
      </>
    )
  );
}
