import React from 'react';
import useModal from '../../shared/Modal/useModal';
import { ToTimezone } from '../../utilities/date';
//components
import { ReservationModal } from '../../shared/Modal/ReservationModal';
import { useSite } from '../../contexts/SiteContext';
// styles
import css from './SessionCard.module.css';

export const SessionCard = ({ session, isSearchResultMode = false, ...props }) => {
  const { isShowing, toggle, ref } = useModal();
  const { studio } = session || {};
  const { isMobileApp } = useSite();

  return (
    <div className={`${css['session-card']} ${isMobileApp ? css['mobile-app-session-card'] : ''}`} data-testid="session-card">
      <div data-testid="session-details" className={`${css['session-details']} ${isMobileApp ? css['mobile-app-session-details'] : ''}`}>
        <p data-testid="session-name" className={css['session-name']}>
          <strong>{session.name}</strong>{' '}
        </p>
        {session.instructor && (
          <p
            data-testid="session-instructor"
            className={css['session-instructor']}
          >
            with&nbsp;
            {session.instructor.name?.match(/^[A-Z][^ ]+/)?.[0] ||
              session.instructor.name}
          </p>
        )}

        {
          !isMobileApp &&
          <p className={css['session-time']}>
            <strong>
              {ToTimezone(session.starts_at, studio.timezone, 'hh:mm A z')}
            </strong>
            <span> - </span>
            <span
              data-testid="session-duration"
              className={css['session-duration']}
            >
              {session.duration_minutes} Min
            </span>
          </p>
        }
      </div>

      <div data-testid="session-details" className={`${css['session-details']} ${isMobileApp ? css['mobile-app-session-details'] : ''}`}>
        {
          isMobileApp &&
          <p className={css['session-time']}>
            <strong>
              {ToTimezone(session.starts_at, studio.timezone, 'hh:mm A z')}
            </strong>
            <span> - </span>
            <span
              data-testid="session-duration"
              className={css['session-duration']}
            >
              {session.duration_minutes} Min
              {
                isSearchResultMode && <>
                  {' '}<span>-</span>{' '}{session?.studio?.distance ?? 0} miles
                </>
              }
            </span>
          </p>
        }
        {session.total_booked >= session.capacity ? (
          <p className="session-full">Full</p>
        ) : (
          <div className={`${css['attendance-details-container']} ${isMobileApp ? css['mobile-app-attendance-details-container'] : ''}`}>
            {isSearchResultMode ?
              <p className={css['spots-left']}>
                {session.capacity - session.total_booked} Spots Left
              </p>
            : <>
              <p className={css['spots-left']}>
                {session.capacity - session.total_booked} Spots Left -{' '}
              </p>
              <p
                data-tesid="session-total-booked"
                className={css['total-booked']}
              >
                {session.total_booked} Going
              </p>
              {
                isMobileApp && !isSearchResultMode ?
                  <p data-testid="session-credits" className={`${css['strip']} ${isMobileApp ? css['mobile-app-strip'] : ''}`}>
                    {session.credits} Credits
                  </p> : null
              }
            </>
            }
          </div>
        )}

        {
          !isMobileApp &&
          <p data-testid="session-credits" className={`${css['strip']} `}>
            {session.credits} Credits
          </p>
        }
      </div>

      <div className={css['session-reservation']}>
        {session.total_booked >= session.capacity ? (
          <p className="session-full">Class at capacity</p>
        ) : (
          <button
            className={`reserve-session ${css['button']} ${isMobileApp ? css['mobile-app-reserve-button'] : ''}`}
            onClick={toggle}
          >
            {
              isSearchResultMode
                ? <>
                  <span>Reserve - {session?.credits} cr</span>
                </>
                : <>
                  Reserve
                </>
            }
          </button>
        )}
        {/* testing prop passed from unit test hides this component */}
        {props.testing ? null : (
          <ReservationModal
            ref={ref}
            isShowing={isShowing}
            hide={toggle}
            session={session}
          />
        )}
      </div>
    </div>
  );
};

export default SessionCard;
