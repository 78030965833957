import React, { useState, useEffect } from 'react';
import workoutPreferencesStyles from '../../styles/WorkoutPreferences.module.css';
import checkboxStyles from '../../styles/Checkbox.module.css';
import checkboxFieldStyles from '../../styles/checkbox/Field.module.css';
import tagStyles from '../../styles/Tag.module.css';
import submitStyles from '../../styles/Submit.module.css';
import defaultBtnStyles from '../../styles/button/Default.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import utilitiesStyles from '../../styles/utilities.module.css';
import { useAuth } from '../../contexts/AuthContext';
import userApi from '../../api/user';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { useGlobal } from '../../contexts/GlobalContext';
import { useId } from 'react-id-generator';
import { logUserProfileUpdateEvent } from '../../utilities/utilities';

function WorkoutPreferences() {
  const { authState, verify } = useAuth();
  const { user } = authState || {};
  const { userprofile } = user || {};
  const { workout_preferences } = userprofile || {};
  const [formChanged, setFormChanged] = useState(false);

  const handleSubmit = async (values) => {
    const userTags = workout_preferences || [];
    const valTags = values.user_tags || [];
    const toRemove = userTags.filter((x) => !valTags.includes(x));
    const toAdd = valTags.filter((x) => !userTags.includes(x));

    let promises = [];
    // api call to remove tags
    toRemove.map((x) => promises.push(userApi.removePreference(user.id, x)));

    // api call to add tags
    toAdd.map((x) => promises.push(userApi.addPreference(user.id, x)));

    if (promises.length > 0) {
      await Promise.all(promises).then((x) => {
        verify();
        logUserProfileUpdateEvent('Workout Preference', valTags.join(','));
      });
    }
  };

  const UserTagPills = ({ ...props }) => {
    const [field, meta] = useField(props);
    const { tags } = useGlobal();
    const [htmlId] = useId();
    const id1 = htmlId;

    return (
      <>
        <fieldset className={workoutPreferencesStyles.pills}>
          {tags?.map((tag, i) => {
            const selected = (field?.value?.indexOf(tag.name) ?? -1) > -1;
            return (
              <div key={i} className={checkboxFieldStyles.pill}>
                <div
                  className={`${tagStyles.toggle} ${
                    selected ? tagStyles.toggleOn : ''
                  }`}
                >
                  <input
                    {...field}
                    value={tag.name}
                    type="checkbox"
                    className={`${tagStyles.input}`}
                    id={`${id1}-${i}`}
                    aria-checked={selected}
                  />
                  <label htmlFor={`${id1}-${i}`} className={tagStyles.label}>
                    <span>{tag.name}</span>
                  </label>
                </div>
              </div>
            );
          })}
        </fieldset>
      </>
    );
  };

  const SubmitButton = ({ label }) => {
    const { isSubmitting } = useFormikContext();

    return (
      <button
        type="submit"
        className={`${submitStyles.button} ${defaultBtnStyles.button} focus_outline`}
      >
        <div
          className={`${
            isSubmitting
              ? `${submitStyles.spinner} ${loadingStyles.spinner}`
              : loadingStyles.hidden
          }`}
        ></div>
        <span
          className={`${
            isSubmitting ? submitStyles.invisible : submitStyles.visible
          }`}
        >
          <span>{label}</span>
        </span>
      </button>
    );
  };

  const FormObserver = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      const userTags = workout_preferences || [];
      const valTags = values.user_tags || [];
      const toRemove = userTags.filter((x) => !valTags.includes(x));
      const toAdd = valTags.filter((x) => !userTags.includes(x));
      if (toAdd.length || toRemove.length) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    }, [values]);
    return null;
  };

  if (workout_preferences && workout_preferences.length) {
    return null;
  }

  return (
    <div className={workoutPreferencesStyles.container}>
      <div
        className={`${workoutPreferencesStyles.content} ${utilitiesStyles['page-container']}`}
      >
        <h2 className={workoutPreferencesStyles.title}>
          <span>Tell us your workout preferences</span>
        </h2>
        <h3 className={workoutPreferencesStyles.subtitle}>
          <span>We'll use them to customize your studio recommendations.</span>
        </h3>

        <Formik
          initialValues={{
            user_tags: workout_preferences, // user?.tags.map(x => x.name),
          }}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
        >
          {() => (
            <Form>
              <FormObserver />
              <fieldset>
                <UserTagPills name="user_tags" />
                {formChanged && <SubmitButton label="Save Preferences" />}
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default WorkoutPreferences;
