import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import StudioList from '../StudioList';
import pageStyles from '../../../styles/studios/Page.module.css';
import streamingStudiosStyles from '../../../styles/StreamingStudios.module.css';
import StudioFilterForm from '../StudioFilterForm';
import StudioContextProvider from '../../../contexts/StudioContext';
import { STREAMING } from '../../../navigation/CONSTANTS';
import {
  getSourceFromParams,
  logAmplitudeEvent,
  logBrazeEvent,
} from '../../../api/integration';

function Studios() {
  useEffect(() => {
    logBrazeEvent('Screen View: Streaming - Studios');
    logAmplitudeEvent('Screen View: Streaming - Studios', {
      Source: getSourceFromParams(),
    });
  }, []);
  return (
    <div className={pageStyles.container}>
      <StudioContextProvider>
        <div className={`${pageStyles.inner} ${streamingStudiosStyles.inner}`}>
          <Link className={pageStyles.backLink} to={STREAMING}>
            <svg
              style={{ verticalAlign: 'inherit' }}
              aria-hidden="true"
              focusable="false"
              className={pageStyles.chevron}
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M16.4244649,19.2868077 C16.7653329,19.6293551 16.7639715,20.1833728 16.4214241,20.5242408 C16.0788767,20.8651089 15.524859,20.8637475 15.183991,20.5212001 L7.25476309,12.5529074 C6.9164664,12.2129441 6.91488398,11.6640093 7.25121504,11.3221012 L15.1804429,3.26139014 C15.5193304,2.9168832 16.073331,2.91232773 16.417838,3.2512152 C16.7623449,3.59010267 16.7669004,4.1441033 16.4280129,4.48861023 L9.10587647,11.932163 L16.4244649,19.2868077 Z"></path>
            </svg>
            <span>Back to Streaming</span>
          </Link>
          <div className={pageStyles.header}>
            <h2>
              <span>Stream Classes</span>
            </h2>
            <p className={pageStyles.subheader}>
              <span>Studios offering online classes.</span>
            </p>
          </div>
          <StudioFilterForm />
          <StudioList />
        </div>
      </StudioContextProvider>
    </div>
  );
}

export { Studios };
