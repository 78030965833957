import UAParser from 'ua-parser-js';
const UA = new UAParser().getResult();

export const getIsCoreSite = () => window.location.hostname.includes(process.env.REACT_APP_CORE_HOSTNAME);

export const getIsMoveSite = () => window.location.hostname.includes(process.env.REACT_APP_MOVE_HOSTNAME);

export const getIsMobileSite = () => ['mobile', 'tablet', 'wearable'].includes(UA.device.type) || window?.ReactNativeWebView;

export const getIsReactNative = () => !!window?.ReactNativeWebView;
