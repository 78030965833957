import React, { useEffect, useState } from 'react';
import heroStyles from '../styles/fitness-partners/Hero.module.css';
import videoStyles from '../styles/fitness-partners/Video.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import pageStyles from '../styles/fitness-partners/Page.module.css';
import featureQuoteStyles from '../styles/fitness-partners/FeatureQuote.module.css';
import pageclampStyles from '../styles/fitness-partners/Pageclamp.module.css';
import stackingBulletsStyles from '../styles/fitness-partners/StackingBullets.module.css';
import mindBodyStyles from '../styles/fitness-partners/MindBody.module.css';
import partnersStyles from '../styles/fitness-partners/Partners.module.css';
import studioFormStyles from '../styles/fitness-partners/StudioForm.module.css';
import useWindowSize, { _MD } from '../shared/hooks/useWindowSize';
import useModal from '../shared/Modal/useModal';
import { VideoModal } from '../shared/Modal/VideoModal';

export default function FitnessPartners() {
  const { isShowing, toggle, ref } = useModal();
  const [videoId, setVideoId] = useState();
  const size = useWindowSize();
  useEffect(() => {
    // console.log("SIZE:", size);
  }, [size[2]]);

  const openVideo = (id) => {
    setVideoId(id);
    toggle();
  };

  useEffect(() => {
    if (!isShowing) setVideoId(null);
  }, [isShowing]);

  return (
    <div style={{ height: 'auto', position: 'relative', minHeight: '216px' }}>
      <div>
        <div
          className={heroStyles.container}
          style={{
            backgroundImage: `url(${
              size[3] > _MD
                ? '/d3337c162dce93c0606ec24ed64941ba.jpg'
                : size[3] == _MD
                ? '/047f83605829921a152c036b98cb50a4.jpg'
                : ''
            })`,
          }}
        >
          <div className={heroStyles.content}>
            <h2 className={heroStyles.title}>
              <span>Your Bridge to Health Insurance Dollars</span>
            </h2>
            <p className={heroStyles.copy}>
              <span>
                Join the Peerfit Network and become a fitness benefit to
                employers and insurance carriers seeking progressive solutions.
                Free to join, easy to manage.
              </span>
            </p>
            <a
              rel="noopener noreferrer"
              className={`${heroStyles.btn} ${btnDefaultStyles.button} focus_outline`}
              href="https://pulse.peerfit.com/studio/"
              target="_blank"
            >
              <span>Get Connected</span>
            </a>
            <div className={heroStyles.titleAndVideo}>
              <h3 className={heroStyles.howItWorks}>
                <span>How It Works</span>
              </h3>
              <button
                onClick={() => openVideo('291504109')}
                className={`${heroStyles.videoContainer} ${videoStyles.container}`}
              >
                <img
                  className={videoStyles.thumb}
                  src="/fd075490b1af5e449965c786624755ea.jpg"
                  alt="Corporate Wellness Within Reach Video Thumbnail"
                />
                <div className={videoStyles.iconContainer}>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    className={videoStyles.icon}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M3 22v-20l18 10-18 10z"></path>
                  </svg>
                  <span className={videoStyles.screenReadersOnly}>
                    Opens a new dialog
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <h2 className={pageStyles.quotesHeadline}>
          <span>What studios and gyms are saying...</span>
        </h2>
        <div
          className={`${pageStyles.quoteContainer} ${featureQuoteStyles.container}`}
        >
          <div
            className={`${featureQuoteStyles.inner} ${pageclampStyles.clamp}`}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              className={featureQuoteStyles.icon}
              viewBox="0 0 48 36"
            >
              <path d="M26,23.45 C26,13.168 33.784,2.412 46,0 L47.968,4.252 C43.538,5.922 39.642,11.736 39.208,15.744 C44.19,16.528 48,20.838 48,26.042 C48,32.406 42.832,36 37.602,36 C31.572,36 26,31.39 26,23.45 Z M0,23.45 C0,13.168 7.784,2.412 20,0 L21.968,4.252 C17.538,5.922 13.642,11.736 13.208,15.744 C18.19,16.528 22,20.838 22,26.042 C22,32.406 16.832,36 11.602,36 C5.572,36 0,31.39 0,23.45 Z"></path>
              <path d="M26,23.45 C26,13.168 33.784,2.412 46,0 L47.968,4.252 C43.538,5.922 39.642,11.736 39.208,15.744 C44.19,16.528 48,20.838 48,26.042 C48,32.406 42.832,36 37.602,36 C31.572,36 26,31.39 26,23.45 Z M0,23.45 C0,13.168 7.784,2.412 20,0 L21.968,4.252 C17.538,5.922 13.642,11.736 13.208,15.744 C18.19,16.528 22,20.838 22,26.042 C22,32.406 16.832,36 11.602,36 C5.572,36 0,31.39 0,23.45 Z"></path>
            </svg>
            <div className={featureQuoteStyles.quote}>
              “We know we have a strong brand here but it was nearly impossible
              for us to partner with employers...Peerfit is a{' '}
              <em className={pageStyles.quoteEmphasis}>powerful</em>{' '}
              partnership.”
            </div>
            <h3 className={featureQuoteStyles.author}>
              Mary Lyn Jenkins, Owner
            </h3>
            <h3 className={featureQuoteStyles.author}>Big Fish Power Yoga</h3>
          </div>
        </div>
        <div className={stackingBulletsStyles.container}>
          <ul className={stackingBulletsStyles.list}>
            <li className={stackingBulletsStyles.blurb}>
              <span>Competitive Reimbursements</span>
            </li>
            <li className={stackingBulletsStyles.blurb}>
              <span>Easy to Integrate</span>
            </li>
            <li className={stackingBulletsStyles.blurb}>
              <span>Clear Insights</span>
            </li>
          </ul>
        </div>
        <div className={mindBodyStyles.container}>
          <div className={mindBodyStyles.content}>
            <div className={mindBodyStyles.mindbody}>
              <div>
                <h2 className={mindBodyStyles.MbTitle}>
                  <span>Integration with</span>
                </h2>
                <img
                  className={mindBodyStyles.logo}
                  src="/8309182e67b7ed76667c91420fd81883.png"
                  alt="MINDBODY Logo"
                />
              </div>
              <p className={mindBodyStyles.copy}>
                <span>
                  If you use MINDBODY, your schedule is displayed right on your
                  Peerfit page. Users reserve classes with a single click.
                </span>
              </p>
            </div>
            <div className={mindBodyStyles.noMindbody}>
              <h2 className={mindBodyStyles.noMbTitle}>
                <span>Not using MINDBODY?</span>
              </h2>
              <p className={mindBodyStyles.copy}>
                <span>
                  We still support reservations if you have a schedule online.
                </span>
              </p>
            </div>
          </div>
          <div className={mindBodyStyles.imageContainer}>
            <img
              className={mindBodyStyles.image}
              src="/d422e47b3bb9f4c14b0d10f434f7b1cb.png"
              alt="MINDBODY Integration Screenshot"
            />
          </div>
        </div>
        <div className={partnersStyles.container}>
          <h2 className={partnersStyles.title}>
            <span>You're in good company</span>
          </h2>
          <div>
            <img
              className={partnersStyles.studioLogo}
              src="/c20ee260bec448a2f2838dcb04f83c8e.png"
              alt="Curves Logo"
            />
            <img
              className={partnersStyles.studioLogo}
              src="/c4a7a5135724b9f22b9d67f6843462d8.png"
              alt="yogaworks Logo"
            />
            <img
              className={partnersStyles.studioLogo}
              src="/00d8d0cb0829d813f49d3b49b9a735a0.png"
              alt="YouFit Logo"
            />
            <img
              className={partnersStyles.studioLogo}
              src="/886d18e646f6e53e80622319bbf719af.png"
              alt="Title Boxing Club Logo"
            />
          </div>
        </div>
        <div className={studioFormStyles.container}>
          <div className={studioFormStyles.content}>
            <h2 className={studioFormStyles.title}>
              <span>Connect to Corporate Wellness</span>
            </h2>
            <button
              onClick={() => openVideo('343289735')}
              className={`${studioFormStyles.video} ${heroStyles.videoContainer} ${videoStyles.container}`}
            >
              <img
                className={videoStyles.thumb}
                src="/a4077233409e898e3f36277d2551ff1f.jpg"
                alt="Connect Your Studio Video Thumbnail"
              />
              <div className={videoStyles.iconContainer}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className={videoStyles.icon}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 22v-20l18 10-18 10z"></path>
                </svg>
                <span className={videoStyles.screenReadersOnly}>
                  Opens a new dialog
                </span>
              </div>
            </button>
            <h3 className={studioFormStyles.instruction}>
              <span>Take the first step to join the Peerfit Network</span>
            </h3>
            <a
              rel="noopener noreferrer"
              className={`${studioFormStyles.btn} ${btnDefaultStyles.button} focus_outline`}
              href="https://pulse.peerfit.com/studio/"
              target="_blank"
            >
              <span>Get Connected</span>
            </a>
            <h3
              className={`${studioFormStyles.call} ${studioFormStyles.instruction}`}
            >
              <span>or give our Wellness team a call at (813) 392-3333</span>
            </h3>
          </div>
        </div>
      </div>

      <VideoModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        videoId={videoId}
      />
    </div>
  );
}
