import React from 'react';
import pageStyles from '../styles/privacy-policy/Page.module.css';
import { useSite } from '../contexts/SiteContext/SiteContext';

export default function PrivacyPolicy() {
  const { isMoveSite, isCoreSite } = useSite();

  return (
    <>
      {isCoreSite && (
        <div
          className={pageStyles.container}
          style={{ height: 'auto', position: 'relative', minHeight: '216px' }}
        >
          <div>
            <p>Last modified June 14, 2021. Effective June 14, 2021.</p>
            <h2 data-cy="privacy-policy-header">Peerfit Privacy Policy</h2>
            <p>
              Peerfit, Inc. (“Peerfit”) is committed to respecting the privacy
              and being transparent in how we collect, use, and disclose the
              information of all persons using the Peerfit.com website (the
              “Site”), the Peerfit mobile applications, which includes but is
              not limited to an iOS and Android version (Collectively the
              “App”), other interactions (e.g., customer service inquiries,
              industry and user conferences, etc.) and the associated Peerfit
              service (collectively, the Site, the App and the services shall be
              referred to herein as the “Service”). This Privacy Policy
              describes the information gathering and dissemination practices
              for the Service and the choices you have with respect to this
              information.
            </p>
            <p>
              In order to use the Site, you may be required to provide some
              personally identifiable information (such information may include
              your first and last name, gender, date of birth, city, state, zip
              code, phone number and a valid email address). This Privacy Policy
              will apply to Peerfit’s use of such information. If you register
              as a member on the Service, Peerfit may also request that you
              provide certain limited personal information and you may provide
              certain personal information through and in connection with your
              use of the Service. When utilizing the App you will have the
              option to generate health and fitness information, considered to
              be Protected Health Information for the purposes of tracking your
              fitness and sharing progress at your discretion. The terms of this
              Privacy Policy will apply to Peerfit’s handling of all such
              information.
            </p>
            <h3>1. Collection and Receipt of Personal Information</h3>
            <p>
              Peerfit may collect and receive Customer Data and other
              information and data (“Other Information”) in a variety of ways:
            </p>
            <ul>
              <li>
                a. <u>Account Information.</u> When you register as a Member
                with the Service, we will ask you to provide us with your
                personal information, including as applicable your name, email
                address, physical address, gender and birth date. You can opt
                whether or not to provide additional personal information when
                establishing your account, such as personal preferences and
                likes and dislikes relating to fitness and exercise activities.
                We may also receive personal information from other websites
                with which we partner or link, such as Facebook or other social
                networks, if you register through those services.
              </li>
              <li>
                b. <u>Usage data.</u> We may collect and analyze information
                about your use of the Site and Service for the legitimate
                business purposes. These legitimate interests of the business
                may include: (i) determining your compliance with the Peerfit{' '}
                <a href="/terms-of-service">Terms of Use</a>; or (ii) we may
                also collect and analyze such information for statistical
                purposes. This information may be analyzed and/or aggregated to
                determine appropriate modifications and enhancements to the Site
                or Service or to customize your personal experience, including
                providing search results and information we believe appropriate
                based on your information. We will use the data collected to
                better design the Service and better understand the needs and
                interests of our visitors. All the above allows Peerfit to
                provide more relevant and useful content.
              </li>
              <li>
                c. <u>Use of IP Addresses.</u> Peerfit may use your IP address
                to help diagnose problems with our servers and to administer our
                Service. Your IP address may be used with the IP addresses of
                other members to gather broad demographic information to
                calculate usage levels of our Services. IP Address is only
                leveraged in relation to the Site and Service, and not shared
                with any third-party except where required by law.
              </li>
              <li>
                d. <u>Use of Cookies.</u> We or our business partners may use
                “cookies” and similar technologies in connection with the Site
                and Service. The Site and Service may also include cookies and
                similar tracking technologies of third parties, which may
                collect Other Information about you via the Site and Service and
                across other websites and online services. For more details
                about how we use these technologies, and your opt-out
                opportunities and other options, please see our Cookie Policy.
                Please note, we are not responsible for the use of cookies by
                other websites that are accessed through us, including
                advertisers, Facilities or Instructors.
              </li>
              <li>
                e. <u>Contests and Surveys.</u> From time to time, we may run
                contests and promotions and conduct online surveys in which we
                may invite you to participate. Participation is voluntary.
                Should you choose to participate, we may request additional
                information, including but not limited to name, email address
                and demographic information. We may also use contact data from
                our contests and surveys to send you information about our
                services and promotional material from some of our business
                partners. You may opt-out of receiving future emails relating to
                contests and surveys; see the choice/opt-out section below.
              </li>
              <li>
                f. <u>Location Information.</u> When you use the Services, we
                may collect certain location information from you. This
                information may consist of the location information you
                affirmatively provide in connection with becoming a Member or
                when registering for classes or other wellness activities. Your
                location information may also be collected automatically from
                your device. This location information may include your
                longitudinal and latitudinal coordinates. If you do not agree to
                this collection, you can change your privacy preferences on your
                device, but some functionality of the Services may be disabled.
              </li>
              <li>
                g. <u>Information We Get From Others.</u> We may get information
                about you from other sources. We may add this to information we
                get from the Services. If you register using a third-party
                authenticator login (such as Facebook), you authorize us to
                access certain account information from the third-party
                authenticator, such as your public profile (consistent with the
                third-party’s privacy policy and your privacy settings related
                thereto), email address, interests, “likes,” gender, birthday,
                education history, work history, interests, current city,
                photos, videos, personal description, friend list, and other
                information from your use of the third-party service. If you are
                using the Service as part of any offering from your employer, we
                may also receive certain information about you from your
                employer. Information we get from others (including third-party
                data companies) may be combined with the information you give
                us.
              </li>
              <li>
                h. <u>Payment Information.</u> You may also make payments using
                the third-party payment services provider, such as Stripe, made
                available through the Service. In connection with making
                payments, You may be required to provide additional information
                to facilitate the transaction, such a bank account or credit
                card information. Peerfit does not store or use this payment
                information in any way other than to relay the information to
                the third-party payment services provider to facilitate the
                transfer of funds. After payment, Peerfit does not have access
                to bank account or credit card information. Peerfit disclaims
                any liability for any loss or compromise of payment information.
                The third-party payment services provider’s use of such
                information shall be governed by its applicable terms of
                services and/or privacy policy.
              </li>
              <li>
                I. <u>Health and Fitness Information.</u> The App has the
                optional ability to integrate with the platform’s native health
                tracking, the Service and App will use this integration, when
                opted into by the end user to read and/or write certain data to
                the native database on your device (the “Health Data”). Such
                information includes the activities listed available within the
                native platform’s terms, for more details please refer to their
                website:{' '}
                <a
                  target="_blank"
                  href="https://www.google.com/fit/"
                  rel="noreferrer"
                >
                  Google Health
                </a>
                ,{' '}
                <a
                  target="_blank"
                  href="https://www.samsung.com/us/samsung-health/"
                  rel="noreferrer"
                >
                  Samsung Health
                </a>
                , and{' '}
                <a
                  target="_blank"
                  href="https://www.apple.com/ios/health/"
                  rel="noreferrer"
                >
                  Healthkit
                </a>
                .
                <p>
                  We will use your Health Data to show you details of your
                  activities in the App. We will not use your Health Data for
                  marketing or advertising purposes. Your Health Data will be
                  accessed locally through the native database and will not be
                  collected or stored by native service and will never leave
                  your device, be stored by the App or Service. Your Health Data
                  will remain on your device. The App cannot read from the
                  native database without your consent. For clarity, we do not
                  collect any statistics about the behavior of users' health
                  activity and we do not store any Health Data on our servers.
                </p>
              </li>
            </ul>
            <h3>2. Use of Personal Information</h3>
            <ul>
              <li>
                a. <u>Provision of Goods and Services.</u> Peerfit will use your
                personal information for the legitimate interests of our
                business as follows:
              </li>
              <ul>
                <li>
                  i. i. operate, maintain, and improve our App, Site and
                  Services, including research and development activities,
                  including beta testing
                </li>
                <li>ii. process and deliver contest entries and rewards.</li>
                <li>
                  iii. respond to comments and questions and provide customer
                  service.
                </li>
                <li>
                  iv. send information including confirmations, invoices,
                  technical notices, updates, security alerts, and support and
                  administrative messages.
                </li>
                <li>
                  v. communicate about promotions, upcoming events, and other
                  news about products and services offered by us and our
                  selected partners.
                </li>
                <li>
                  vi. link or combine user information with other personal
                  information.
                </li>
                <li>
                  vii. protect, investigate, and deter against fraudulent,
                  unauthorized, or illegal activity.
                </li>
                <li>
                  viii. customize and tailor content and advertising to specific
                  audiences or individuals.
                </li>
                <li>
                  ix. provide and deliver products and services customers
                  request.
                </li>
                <li>x. facilitate purchase transactions.</li>
                <li>
                  xi. If you are a member via your employer, a broker, health
                  plan or insurance carrier (“Group”), we may send you
                  announcements and news feeds related to your Group and posted
                  by the Group administrator.
                </li>
                <li>
                  Xii. To investigate and help prevent security issues and
                  abuse.
                </li>
              </ul>
              <li>
                b. <u>Your Employer.</u> If you are using the Service as part of
                any offering from your employer, we may share information
                regarding your use of the Service with your employer. Such
                information may include, but is not limited to attendance or
                participation in specific service offerings and utilization
                rates.
              </li>
              <li>
                c. <u>Targeted Advertising.</u> We may customize advertisements
                on the Service based upon: (i) the information that you
                knowingly provide us (including age, gender, stated fitness and
                lifestyle interests and other information in your profile); (ii)
                geographic location which we may determine through your use of
                the Service; (iii) data we receive from third-parties and/or
                (iv) your behavior while using the Service. When not prohibited
                by applicable laws, we may engage a third-party vendor who,
                through the use of cookies and your use of the Service,
                determines your interests and targets advertisements and
                promotions based on those interests. This vendor may target
                advertisements not only on the Service but on other websites
                with which the vendor has a relationship.
              </li>
            </ul>
            <h3>3. Sharing and Disclosure of Personal Information</h3>
            <p>
              This section describes how Peerfit may share and disclose
              Information. Customers determine their own policies and practices
              for the sharing and disclosure of Information, and Peerfit does
              not control how they or any other third parties choose to share or
              disclose Information.
            </p>
            <ul>
              <li>a.</li>
              <ul>
                <li>
                  i. Disclose your personal information to companies who work on
                  behalf of or with Peerfit to provide the Service. Unless we
                  tell you differently, these companies do not have any right to
                  use the personal information we provide to them beyond what is
                  necessary to assist us.
                </li>
                <li>
                  ii. Enhance or merge the personal information collected by us
                  with data received from third-parties.
                </li>
                <li>
                  iii. Release personal information and/or other information if
                  required to do so by law, or in the good-faith belief that
                  such action is necessary to comply with state, federal or
                  international laws or respond to a court order, subpoena, or
                  search warrant.
                </li>
                <li>
                  iv. Disclose personal information and/or other information
                  about you that we believe, in good faith, is appropriate or
                  necessary to enforce our agreements, take precautions against
                  liability, to investigate and defend ourself against any
                  third-party claims or allegations, to assist government
                  enforcement agencies, to protect the security or integrity of
                  the Site or Service, and protect the rights, property, or
                  personal safety of Peerfit, our users or others.
                </li>
                <li>
                  v. Aggregated or De-identified Data. We may disclose or use
                  aggregated or de-identified Other Information for any purpose.
                  For example, we may share aggregated or de-identified Other
                  Information with prospects or partners for business or
                  research purposes, such as telling prospective Peerfit clients
                  relevant metrics from the Service.
                  <p>
                    If Information is aggregated or de-identified so that it is
                    no longer reasonably associated with an identified or
                    identifiable natural person, Peerfit may use it for any
                    business purpose. To the extent Information is associated
                    with an identified or identifiable natural person and is
                    protected as personal data under applicable data protection
                    law, it is referred to in this Privacy Policy as “Personal
                    Data.”
                  </p>
                </li>
              </ul>
            </ul>
            <h3>4. Data Retention</h3>
            <p>
              Peerfit will retain Personal Data in accordance with contractual
              agreements, including any applicable terms in the Terms of Service
              and as required by applicable law. The deletion of Personal Data
              and other use of the Service by users may result in the deletion
              and/or de-identification of certain associated Other Information.
              Peerfit may retain Other Information pertaining to you for as long
              as necessary for the purposes described in this Privacy Policy.
              This may include keeping your Other Information after you have
              deactivated your account for the period of time needed for Peerfit
              to pursue legitimate business interests, conduct audits, comply
              with (and demonstrate compliance with) legal obligations, resolve
              disputes, and enforce our agreements.
            </p>
            <h3>5. Other Terms</h3>
            <ul>
              <li>
                a. <u>Profiles/Public Spaces.</u> Information you provide for
                your profile page may be accessed and viewed publicly unless you
                choose otherwise through the Service. Only your legal name is
                required to be shown publicly on your profile page. It is your
                option to show or not show the other profile information
                publicly on your profile page. Please remember that any
                information disclosed in publicly accessible areas of the
                Service will be disclosed to third-parties and become public
                information. Peerfit cannot control and shall have no liability
                for third-party uses of information you make available in
                publicly accessible areas of the Service. As with any social
                networking service, you should exercise caution when deciding to
                disclose your personal or proprietary information.
              </li>
              <li>
                b. <u>Usage of Peerfit by Children.</u> Peerfit does not
                knowingly allow children under the age of eighteen (18) to sign
                up for an account on the Service or to otherwise disclose any of
                their personal information to us. If you are under 18 years old,
                please do not use or access our Service. We do not intend to
                collect information about children under the age of 18. If we
                later obtain actual knowledge that a user is under 18 years of
                age, we will take steps to remove that user’s information from
                our systems. If you are the parent or guardian of a child under
                18 whom you believe has disclosed personal information to us,
                please contact us at{' '}
                <a href="mailto:privacy@peerfit.com">privacy@peerfit.com</a> so
                that we may take the appropriate steps to delete and remove the
                child’s information from our systems.
              </li>
              <li>
                c. <u>Choice/Opt-Out.</u> We may provide you the opportunity to
                opt-out of receiving certain communications from us and our
                business partners during registration or otherwise from time to
                time as you use the Service. Even if you opt out of such
                communications, however, we reserve the right to send you email
                and other communications to disclose maintenance and other
                service-related issues applicable to the Site or the Service.
              </li>
              <li>
                d. <u>User Information Correction/Update.</u> You may access,
                update and/or correct your account information at any time by
                clicking the “edit my profile” button on your profile page. You
                may need to supply certain information so that we can try to
                verify that you have authority to modify this information.
              </li>
              <li>
                e. <u>Third-Party Sites.</u> The Site or Service may contain
                links to other Internet sites. We have no responsibility for the
                privacy practices of other websites, including the websites of
                Facilities or Instructors, even if linked through to our
                Service. Our privacy policies do not apply to your use of such
                websites or information you may provide on such websites. We
                encourage you to review the privacy policies of any websites you
                may access through our Site or Services.
              </li>
              <li>
                f. <u>Legal Requirements.</u> We are committed to protecting
                your privacy as described in this policy while also fulfilling
                our responsibility to uphold all applicable laws and
                regulations. Due to potential legal requirements and other
                possible circumstances, we cannot ensure that all of your
                private communications and other personally identifiable
                information will never be disclosed or accessed in ways not
                otherwise described in this policy. We may be compelled under
                law to disclose information to government or third-parties under
                certain circumstances or third-parties may unlawfully intercept
                or access transmissions or private communications. Further, we
                can, and you hereby authorize us to, disclose any information to
                law enforcement or other government officials that we, in our
                sole discretion, believe necessary or appropriate in compliance
                with the law.
              </li>
              <li>
                g. <u>Sale of Company or Assets.</u> In the event that all or
                part of Peerfit is acquired by or merged with a third-party
                entity, you agree that we may transfer or assign the information
                we have collected from you as part of such merger, acquisition,
                or other change of control. In the unlikely event of our
                bankruptcy, insolvency, reorganization, receivership, or
                assignment for the benefit of creditors, or the application of
                laws or equitable principles affecting creditors' rights
                generally, we may not be able to control how your personal
                information is treated, transferred, or used.
              </li>
              <li>
                h. <u>International Users.</u> While we are committed to
                implementing best privacy practices, we have done so in a manner
                designed to meet United States privacy laws and regulations.
                Therefore, if you are covered by any laws governing privacy
                outside of the United States you may not use our Site or
                Services. Peerfit is based within the United States and does not
                offer goods or services to individuals in the European Union or
                monitor behavior of individuals within the European Union.
              </li>
              <li>
                i. <u>Security.</u> We will use reasonable security measures on
                the Site and Service to help protect against the loss, misuse
                and alteration of the personal information under our control.
                Additionally, Peerfit takes reasonable steps to ensure that our
                third-party business partners, including our hosting partners,
                provide sufficient protection for personal information. However,
                due to the realities of data security, no security system or
                storage system can be guaranteed to be 100% effective or secure.
                Thus, we cannot guarantee that any information provided to us or
                collected by us will not be accessed, hacked, disclosed,
                altered, or destroyed by unauthorized parties.
              </li>
              <ul>
                <li>
                  i. In the event, that Peerfit becomes aware of a security
                  breach which we believe has resulted, or may result, in the
                  unauthorized access, use, or disclosure of non-public personal
                  information of users, we will promptly investigate the matter
                  and notify the applicable parties of such breach. Such
                  investigation will be without delay, consistent with (i) the
                  legitimate needs of law enforcement; (ii) measures necessary
                  to determine the scope of the breach; (iii) efforts to
                  identify the individuals affected; and (iv) steps to restore
                  the reasonable integrity of our Platform.
                </li>
                <li>
                  ii. If you have reason to believe that your interaction with
                  us is no longer secure (for example, if you feel that the
                  security of any account you might have with us might have been
                  compromised), or if you suspect someone else is using your
                  account, please let us know immediately by contacting us as
                  indicated in the “Contacting Peerfit” section below.
                </li>
              </ul>
              <li>
                j. <u>Changes and updates to this Privacy Policy.</u> This
                Privacy Policy may be revised periodically by Peerfit, as
                reflected by the "last modified" date above. Please revisit this
                page to stay aware of any changes. Material amendments to this
                Privacy Policy shall be communicated to you at the time you log
                into the Site or Service. Peerfit will provide additional
                notice, such as via email or through the Service. If you
                disagree with the changes to this Privacy Policy, you should
                deactivate your Services account. Contact the Customer If you do
                not agree with such changes, you should cease accessing or using
                the Site or Service. if you wish to request the removal of
                Personal Data under their control please contact us as described
                below.
              </li>
              <li>
                k. <u>Terms of Use.</u> This Privacy Policy is part of and
                incorporated into Peerfit Terms of Use, which includes
                provisions such as limitations on damages, disclaimers of
                warranty, and application of Florida USA state law.
              </li>
              <li>
                l. <u>California Consumer Rights.</u> This section provides
                additional details about the personal information we collect
                about California consumers and the rights afforded to them under
                the California Consumer Privacy Act or “CCPA.”
              </li>
              <p>
                For more details about the personal information we have
                collected over the last 12 months, including the categories of
                sources, please see the “Collection and Use of Personal
                Information” section above. We collect this information for
                business and commercial purposes and share this information as
                described in the “Use and Disclosure of Information '' section
                above. Peerfit does not sell (as such term is defined in the
                CCPA) the personal information we collect (and will not sell it
                without providing a right to opt out). Please note that we do
                use third-party cookies for our advertising purposes as further
                described in our Cookie Policy.
              </p>
              <p>
                Subject to certain limitations, the CCPA provides California
                consumers the right to request to know more details about the
                categories or specific pieces of personal information we collect
                (including how we use and disclose this information), to delete
                their personal information, and to opt out of any “sales” that
                may be occurring. We will not discriminate against you if you
                choose to exercise your rights under the CCPA.
              </p>
              <p>
                California consumers may make a request pursuant to their rights
                under the CCPA, but no more than twice in a 12 month period, by
                contacting us at privacy@peerfit.com. We will verify your
                request using the information associated with your account,
                including email address. Government identification may be
                required. Consumers can also designate an authorized agent to
                exercise these rights on their behalf.Please note that if an
                authorized agent submits a request on your behalf, we may need
                to contact you to verify your identity and protect the security
                of your personal information. We will not fulfill your CCPA
                request unless you have provided sufficient information for us
                to reasonably verify you are the consumer about whom we
                collected personal information.
              </p>
              <p>
                California residents are entitled to know how we respond to “Do
                Not Track” browser settings. However, we do not currently take
                actions to respond to Do Not Track signals because a uniform
                technological standard has not yet been developed.
              </p>
              <li>
                m. <u>Contacting Peerfit.</u> We hope to create a Site and
                Service that are beneficial to you and that you trust. If you
                have any questions about this privacy policy, the information
                handling practices of Peerfit, or if you are seeking to exercise
                any of your statutory rights. Peerfit will respond within a
                reasonable timeframe. Please contact us at{' '}
                <a href="mailto:privacy@peerfit.com">privacy@peerfit.com</a>; or
                via mail at Peerfit, Inc., 1060 Woodcock Rd Ste 128 #96128,
                Orlando, FL 32803-3607
              </li>
            </ul>
          </div>
        </div>
      )}
      {isMoveSite && (
        <div
          className={pageStyles.container}
          style={{ height: 'auto', position: 'relative', minHeight: '216px' }}
        >
          <div>
            <p>​​Last modified March 31, 2020. Effective March 31, 2020.</p>
            <h2 data-cy="privacy-policy-header">Peerfit Move Privacy Policy</h2>
            <p>
              Peerfit, Inc. (“Peerfit”) is committed to respecting the privacy
              and being transparent in how we collect, use, and disclose the
              information of all persons using the Peerfitmove.com website (the
              “Site”), the Peerfit Move mobile applications, which includes but
              is not limited to an iOS and Android version (Collectively the
              “App”), other interactions (e.g., customer service inquiries,
              industry and user conferences, etc.) and the associated Peerfit
              Move service (collectively, the Site, the App and the services
              shall be referred to herein as the “Service”). This Privacy Policy
              describes the information gathering and dissemination practices
              for the Service and the choices you have with respect to this
              information.
            </p>
            <p>
              In order to use the Site, you may be required to provide some
              personally identifiable information (such information may include
              your first and last name, gender, date of birth, city, state, zip
              code, phone number and a valid email address). This Privacy Policy
              will apply to Peerfit’s use of such information. If you register
              as a member on the Service, Peerfit may also request that you
              provide certain limited personal information and you may provide
              certain personal information through and in connection with your
              use of the Service. When utilizing the App you will have the
              option to generate health and fitness information, considered to
              be Protected Health Information for the purposes of tracking your
              fitness and sharing progress at your discretion. The terms of this
              Privacy Policy will apply to Peerfit’s handling of all such
              information.
            </p>
            <h3>1. Collection and Receipt of Personal Information</h3>
            <p>
              Peerfit may collect and receive Customer Data and other
              information and data (“Other Information”) in a variety of ways:
            </p>
            <ul>
              <li>
                a. <u>Account Information.</u> When you register as a Member
                with the Service, we will ask you to provide us with your
                personal information, including as applicable your name, email
                address, physical address, gender and birth date. You can opt
                whether or not to provide additional personal information when
                establishing your account, such as personal preferences and
                likes and dislikes relating to fitness and exercise activities.
                We may also receive personal information from other websites
                with which we partner or link, such as Facebook or other social
                networks, if you register through those services.
              </li>
              <li>
                b. <u>Usage data.</u> We may collect and analyze information
                about your use of the Site and Service for the legitimate
                business purposes. These legitimate interests of the business
                may include: (i) determining your compliance with the Peerfit
                Move Terms of Use; or (ii) we may also collect and analyze such
                information for statistical purposes. This information may be
                analyzed and/or aggregated to determine appropriate
                modifications and enhancements to the Site or Service or to
                customize your personal experience, including providing search
                results and information we believe appropriate based on your
                information. We will use the data collected to better design the
                Service and better understand the needs and interests of our
                visitors. All the above allows Peerfit to provide more relevant
                and useful content.
              </li>
              <li>
                c. <u>Use of IP Addresses.</u> Service. Your IP address may be
                used with the IP addresses of other members to gather broad
                demographic information to calculate usage levels of our
                Services. IP Address is only leveraged in relation to the Site
                and Service, and not shared with any third-party except where
                required by law.
              </li>
              <li>
                d. <u>Use of Cookies.</u> We or our business partners may use
                “cookies” and similar technologies in connection with the Site
                and Service. The Site and Service may also include cookies and
                similar tracking technologies of third parties, which may
                collect Other Information about you via the Site and Service and
                across other websites and online services. For more details
                about how we use these technologies, and your opt-out
                opportunities and other options, please see our Cookie Policy.
                Please note, we are not responsible for the use of cookies by
                other websites that are accessed through us, including
                advertisers, Facilities or Instructors.
              </li>
              <li>
                e. <u>Contests and Surveys.</u> From time to time, we may run
                contests and promotions and conduct online surveys in which we
                may invite you to participate. Participation is voluntary.
                Should you choose to participate, we may request additional
                information, including but not limited toname, email address and
                demographic information. We may also use contact data from our
                contests and surveys to send you information about our services
                and promotional material from some of our business partners. You
                may opt-out of receiving future emails relating to contests and
                surveys; see the choice/opt-out section below.
              </li>
              <li>
                f. <u>Location Information.</u> When you use the Services, we
                may collect certain location information from you. This
                information may consist of the location information you
                affirmatively provide in connection with becoming a Member or
                when registering for classes or other wellness activities. Your
                location information may also be collected automatically from
                your device. This location information may include your
                longitudinal and latitudinal coordinates. If you do not agree to
                this collection, you can change your privacy preferences on your
                device, but some functionality of the Services may be disabled.
              </li>
              <li>
                g. <u>Information We Get From Others.</u> We may get information
                about you from other sources. We may add this to information we
                get from the Services. If you register using a third-party
                authenticator login (such as Facebook), you authorize us to
                access certain account information from the third-party
                authenticator, such as your public profile (consistent with the
                third-party’s privacy policy and your privacy settings related
                thereto), email address, interests, “likes,” gender, birthday,
                education history, work history, interests, current city,
                photos, videos, personal description, friend list, and other
                information from your use of the third-party service. If you are
                using the Service as part of any offering from your employer, we
                may also receive certain information about you from your
                employer. Information we get from others (including third-party
                data companies) may be combined with the information you give
                us.
              </li>
              <li>
                h. <u>Payment Information.</u> You may also make payments using
                the third-party payment services provider, such as Stripe, made
                available through the Service. In connection with making
                payments, You may be required to provide additional information
                to facilitate the transaction, such a bank account or credit
                card information. Peerfit does not store or use this payment
                information in any way other than to relay the information to
                the third-party payment services provider to facilitate the
                transfer of funds. After payment, Peerfit does not have access
                to bank account or credit card information. Peerfit disclaims
                any liability for any loss or compromise of payment information.
                The third-party payment services provider’s use of such
                information shall be governed by its applicable terms of
                services and/or privacy policy.
              </li>
              <li>
                I. <u>Health and Fitness Information.</u> The App has the
                optional ability to integrate with the platform’s native health
                tracking, the Service and App will use this integration, when
                opted into by the end user to read and/or write certain data to
                the native database on your device (the “Health Data”). Such
                information includes the activities listed available within the
                native platform’s terms, for more details please refer to their
                website:{' '}
                <a
                  target="_blank"
                  href="https://www.google.com/fit/"
                  rel="noreferrer"
                >
                  Google Health
                </a>
                ,{' '}
                <a
                  target="_blank"
                  href="https://www.samsung.com/us/samsung-health/"
                  rel="noreferrer"
                >
                  Samsung Health
                </a>
                , and{' '}
                <a
                  target="_blank"
                  href="https://www.apple.com/ios/health/"
                  rel="noreferrer"
                >
                  Healthkit
                </a>
                .
                <p>
                  We will use your Health Data to show you details of your
                  activities in the App. We will not use your Health Data for
                  marketing or advertising purposes. Your Health Data will be
                  accessed locally through the native database and will not be
                  collected or stored by native service and will never leave
                  your device, be stored by the App or Service. Your Health Data
                  will remain on your device. The App cannot read from the
                  native database without your consent. For clarity, we do not
                  collect any statistics about the behavior of users' health
                  activity and we do not store any Health Data on our servers.
                </p>
              </li>
            </ul>
            <h3>2. Use of Personal Information</h3>
            <ul>
              <li>
                a. <u>Provision of Goods and Services.</u> Peerfit will use your
                personal information for the legitimate interests of our
                business as follows:
              </li>
              <ul>
                <li>
                  i. operate, maintain, and improve our App, Site and Services,
                  including research and development activities, including beta
                  testing
                </li>
                <li>ii. process and deliver contest entries and rewards.</li>
                <li>
                  iii. respond to comments and questions and provide customer
                  service.
                </li>
                <li>
                  iv. send information including confirmations, invoices,
                  technical notices, updates, security alerts, and support and
                  administrative messages.
                </li>
                <li>
                  v. communicate about promotions, upcoming events, and other
                  news about products and services offered by us and our
                  selected partners.
                </li>
                <li>
                  vi. link or combine user information with other personal
                  information.
                </li>
                <li>
                  vii. protect, investigate, and deter against fraudulent,
                  unauthorized, or illegal activity.
                </li>
                <li>
                  viii. customize and tailor content and advertising to specific
                  audiences or individuals.
                </li>
                <li>
                  ix. provide and deliver products and services customers
                  request.
                </li>
                <li>x. facilitate purchase transactions.</li>
                <li>
                  xi. If you are a member via your health plan provider
                  (“Group”), we may send you announcements and news feeds
                  related to your Group and posted by the Group administrator.
                </li>
                <li>
                  Xii. To investigate and help prevent security issues and
                  abuse.
                </li>
              </ul>
              <li>
                b. <u>Your Employer.</u> If you are using the Service as part of
                any offering from your employer, we may share information
                regarding your use of the Service with your employer. Such
                information may include, but is not limited to attendance or
                participation in specific service offerings and utilization
                rates.
              </li>
              <li>
                c. <u>Targeted Advertising.</u> We may customize advertisements
                on the Service based upon: (i) the information that you
                knowingly provide us (including age, gender, stated fitness and
                lifestyle interests and other information in your profile); (ii)
                geographic location which we may determine through your use of
                the Service; (iii) data we receive from third-parties and/or
                (iv) your behavior while using the Service. When not prohibited
                by applicable laws, we may engage a third-party vendor who,
                through the use of cookies and your use of the Service,
                determines your interests and targets advertisements and
                promotions based on those interests. This vendor may target
                advertisements not only on the Service but on other websites
                with which the vendor has a relationship.
              </li>
            </ul>
            <h3>3. Sharing and Disclosure of Personal Information</h3>
            <p>
              This section describes how Peerfit may share and disclose
              Information. Customers determine their own policies and practices
              for the sharing and disclosure of Information, and Peerfit does
              not control how they or any other third parties choose to share or
              disclose Information.
            </p>
            <ul>
              <li>a.</li>
              <ul>
                <li>
                  i. Disclose your personal information to companies who work on
                  behalf of or with Peerfit to provide the Service. Unless we
                  tell you differently, these companies do not have any right to
                  use the personal information we provide to them beyond what is
                  necessary to assist us.
                </li>
                <li>
                  ii. Enhance or merge the personal information collected by us
                  with data received from third-parties.
                </li>
                <li>
                  iii. Release personal information and/or other information if
                  required to do so by law, or in the good-faith belief that
                  such action is necessary to comply with state, federal or
                  international laws or respond to a court order, subpoena, or
                  search warrant.
                </li>
                <li>
                  iv. Disclose personal information and/or other information
                  about you that we believe, in good faith, is appropriate or
                  necessary to enforce our agreements, take precautions against
                  liability, to investigate and defend ourself against any
                  third-party claims or allegations, to assist government
                  enforcement agencies, to protect the security or integrity of
                  the Site or Service, and protect the rights, property, or
                  personal safety of Peerfit, our users or others.
                </li>
                <li>
                  v. Aggregated or De-identified Data. We may disclose or use
                  aggregated or de-identified Other Information for any purpose.
                  For example, we may share aggregated or de-identified Other
                  Information with prospects or partners for business or
                  research purposes, such as telling prospective Peerfit clients
                  relevant metrics from the Service.
                  <p>
                    If Information is aggregated or de-identified so that it is
                    no longer reasonably associated with an identified or
                    identifiable natural person, Peerfit may use it for any
                    business purpose. To the extent Information is associated
                    with an identified or identifiable natural person and is
                    protected as personal data under applicable data protection
                    law, it is referred to in this Privacy Policy as “Personal
                    Data.”
                  </p>
                </li>
              </ul>
            </ul>
            <h3>4. Data Retention</h3>
            <p>
              Peerfit will retain Personal Data in accordance with contractual
              agreements, including any applicable terms in the Terms of Service
              and as required by applicable law. The deletion of Personal Data
              and other use of the Service by users may result in the deletion
              and/or de-identification of certain associated Other Information.
              Peerfit may retain Other Information pertaining to you for as long
              as necessary for the purposes described in this Privacy Policy.
              This may include keeping your Other Information after you have
              deactivated your account for the period of time needed for Peerfit
              to pursue legitimate business interests, conduct audits, comply
              with (and demonstrate compliance with) legal obligations, resolve
              disputes, and enforce our agreements.
            </p>
            <h3>5. Other Terms</h3>
            <ul>
              <li>
                a. <u>Profiles/Public Spaces.</u> Information you provide for
                your profile page may be accessed and viewed publicly unless you
                choose otherwise through the Service. Only your legal name is
                required to be shown publicly on your profile page. It is your
                option to show or not show the other profile information
                publicly on your profile page. Please remember that any
                information disclosed in publicly accessible areas of the
                Service will be disclosed to third-parties and become public
                information. Peerfit cannot control and shall have no liability
                for third-party uses of information you make available in
                publicly accessible areas of the Service. As with any social
                networking service, you should exercise caution when deciding to
                disclose your personal or proprietary information.
              </li>
              <li>
                b. <u>Usage of Peerfit Move by Children.</u> Peerfit does not
                knowingly allow children under the age of eighteen (18) to sign
                up for an account on the Service or to otherwise disclose any of
                their personal information to us. If you are under 18 years old,
                please do not use or access our Service. We do not intend to
                collect information about children under the age of 18. If we
                later obtain actual knowledge that a user is under 18 years of
                age, we will take steps to remove that user’s information from
                our systems. If you are the parent or guardian of a child under
                18 whom you believe has disclosed personal information to us,
                please contact us at{' '}
                <a href="mailto:privacy@peerfitmove.com">
                  privacy@peerfitmove.com
                </a>{' '}
                so that we may take the appropriate steps to delete and remove
                the child’s information from our systems.
              </li>
              <li>
                c. <u>Choice/Opt-Out.</u> We may provide you the opportunity to
                opt-out of receiving certain communications from us and our
                business partners during registration or otherwise from time to
                time as you use the Service. Even if you opt out of such
                communications, however, we reserve the right to send you email
                and other communications to disclose maintenance and other
                service-related issues applicable to the Site or the Service.
              </li>
              <li>
                d. <u>User Information Correction/Update.</u> You may access,
                update and/or correct your account information at any time by
                clicking the “edit my profile” button on your profile page. You
                may need to supply certain information so that we can try to
                verify that you have authority to modify this information.
              </li>
              <li>
                e. <u>Third-Party Sites.</u> The Site or Service may contain
                links to other Internet sites. We have no responsibility for the
                privacy practices of other websites, including the websites of
                Facilities or Instructors, even if linked through to our
                Service. Our privacy policies do not apply to your use of such
                websites or information you may provide on such websites. We
                encourage you to review the privacy policies of any websites you
                may access through our Site or Services.
              </li>
              <li>
                f. <u>Legal Requirements.</u> We are committed to protecting
                your privacy as described in this policy while also fulfilling
                our responsibility to uphold all applicable laws and
                regulations. Due to potential legal requirements and other
                possible circumstances, we cannot ensure that all of your
                private communications and other personally identifiable
                information will never be disclosed or accessed in ways not
                otherwise described in this policy. We may be compelled under
                law to disclose information to government or third-parties under
                certain circumstances or third-parties may unlawfully intercept
                or access transmissions or private communications. Further, we
                can, and you hereby authorize us to, disclose any information to
                law enforcement or other government officials that we, in our
                sole discretion, believe necessary or appropriate in compliance
                with the law.
              </li>
              <li>
                g. <u>Sale of Company or Assets.</u> In the event that all or
                part of Peerfit is acquired by or merged with a third-party
                entity, you agree that we may transfer or assign the information
                we have collected from you as part of such merger, acquisition,
                or other change of control. In the unlikely event of our
                bankruptcy, insolvency, reorganization, receivership, or
                assignment for the benefit of creditors, or the application of
                laws or equitable principles affecting creditors' rights
                generally, we may not be able to control how your personal
                information is treated, transferred, or used.
              </li>
              <li>
                h. <u>International Users.</u> While we are committed to
                implementing best privacy practices, we have done so in a manner
                designed to meet United States privacy laws and regulations.
                Therefore, if you are covered by any laws governing privacy
                outside of the United States you may not use our Site or
                Services. Peerfit is based within the United States and does not
                offer goods or services to individuals in the European Union or
                monitor behavior of individuals within the European Union.
              </li>
              <li>
                i. <u>Security.</u> We will use reasonable security measures on
                the Site and Service to help protect against the loss, misuse
                and alteration of the personal information under our control.
                Additionally, Peerfit takes reasonable steps to ensure that our
                third-party business partners, including our hosting partners,
                provide sufficient protection for personal information. However,
                due to the realities of data security, no security system or
                storage system can be guaranteed to be 100% effective or secure.
                Thus, we cannot guarantee that any information provided to us or
                collected by us will not be accessed, hacked, disclosed,
                altered, or destroyed by unauthorized parties.
              </li>
              <ul>
                <li>
                  i. In the event, that Peerfit becomes aware of a security
                  breach which we believe has resulted, or may result, in the
                  unauthorized access, use, or disclosure of non-public personal
                  information of users, we will promptly investigate the matter
                  and notify the applicable parties of such breach. Such
                  investigation will be without delay, consistent with (i) the
                  legitimate needs of law enforcement; (ii) measures necessary
                  to determine the scope of the breach; (iii) efforts to
                  identify the individuals affected; and (iv) steps to restore
                  the reasonable integrity of our Platform.
                </li>
                <li>
                  ii. If you have reason to believe that your interaction with
                  us is no longer secure (for example, if you feel that the
                  security of any account you might have with us might have been
                  compromised), or if you suspect someone else is using your
                  account, please let us know immediately by contacting us as
                  indicated in the “Contacting Peerfit” section below.
                </li>
              </ul>
              <li>
                j. <u>Changes and updates to this Privacy Policy.</u> This
                Privacy Policy may be revised periodically by Peerfit, as
                reflected by the "last modified" date above. Please revisit this
                page to stay aware of any changes. Material amendments to this
                Privacy Policy shall be communicated to you at the time you log
                into the Site or Service. Peerfit will provide additional
                notice, such as via email or through the Service. If you
                disagree with the changes to this Privacy Policy, you should
                deactivate your Services account. Contact the Customer If you do
                not agree with such changes, you should cease accessing or using
                the Site or Service. if you wish to request the removal of
                Personal Data under their control please contact us as described
                below.
              </li>
              <li>
                k. <u>Terms of Use.</u> This Privacy Policy is part of and
                incorporated into Peerfit Move Terms of Use, which includes
                provisions such as limitations on damages, disclaimers of
                warranty, and application of Florida USA state law.
              </li>
              <li>
                l. <u>California Consumer Rights.</u> This section provides
                additional details about the personal information we collect
                about California consumers and the rights afforded to them under
                the California Consumer Privacy Act or “CCPA.” For more details
                about the personal information we have collected over the last
                12 months, including the categories of sources, please see the
                “Collection and Use of Personal Information” section above. We
                collect this information for business and commercial purposes
                and share this information as described in the “Use and
                Disclosure of Information '' section above. Peerfit does not
                sell (as such term is defined in the CCPA) the personal
                information we collect (and will not sell it without providing a
                right to opt out). Please note that we do use third-party
                cookies for our advertising purposes as further described in our
                Cookie Policy. Subject to certain limitations, the CCPA provides
                California consumers the right to request to know more details
                about the categories or specific pieces of personal information
                we collect (including how we use and disclose this information),
                to delete their personal information, and to opt out of any
                “sales” that may be occurring. We will not discriminate against
                you if you choose to exercise your rights under the CCPA.
                California consumers may make a request pursuant to their rights
                under the CCPA, but no more than twice in a 12 month period, by
                contacting us at{' '}
                <a href="mailto:privacy@peerfitmove.com">
                  privacy@peerfitmove.com
                </a>
                . We will verify your request using the information associated
                with your account, including email address. Government
                identification may be required. Consumers can also designate an
                authorized agent to exercise these rights on their behalf.Please
                note that if an authorized agent submits a request on your
                behalf, we may need to contact you to verify your identity and
                protect the security of your personal information. We will not
                fulfill your CCPA request unless you have provided sufficient
                information for us to reasonably verify you are the consumer
                about whom we collected personal information. California
                residents are entitled to know how we respond to “Do Not Track”
                browser settings. However, we do not currently take actions to
                respond to Do Not Track signals because a uniform technological
                standard has not yet been developed.
              </li>
              <li>
                m. <u>Contacting Peerfit.</u> We hope to create a Site and
                Service that are beneficial to you and that you trust. If you
                have any questions about this privacy policy, the information
                handling practices of Peerfit, or if you are seeking to exercise
                any of your statutory rights. Peerfit will respond within a
                reasonable timeframe. Please contact us at{' '}
                <a href="mailto:privacy@peerfitmove.com">
                  privacy@peerfitmove.com
                </a>
                ; or via mail at Peerfit, Inc., 1060 Woodcock Rd Ste 128 #96128,
                Orlando, FL 32803-3607
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
