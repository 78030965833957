import React from 'react';
import flexStyles from '../styles/Flex.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';

import teamStyles from '../styles/about-us/Team.module.css';
import { TeamMemberModal } from '../shared/Modal/TeamMemberModal';
import useModal from '../shared/Modal/useModal';

export const TeamMemberCard = ({ member }) => {
  const { isShowing, toggle, ref } = useModal();

  return (
    <div
      className={`${flexStyles['col-md-6']} ${flexStyles['col-lg-4']} ${teamStyles.tile}`}
    >
      <div className={teamStyles.bio}>
        <div className={teamStyles.bubble}>
          <img
            src={member.imgPath}
            width="100%"
            alt={`Photo of ${member.name} ${member.subtitle}`}
          />
        </div>
        <h4 className={teamStyles.leaderName}>{member.name}</h4>
        <h5 className={teamStyles.subtitle}>{member.subtitle}</h5>
        <p>
          {member.description.substring(0, 200)}...
          <button
            onClick={toggle}
            className={`${teamStyles.moreInfo} ${btnDefaultStyles.button_link} focus_outline`}
            tabIndex="0"
            aria-label={`Learn more about ${member.name} our ${member.subtitle}`}
          >
            <span>Read more</span>
          </button>
        </p>
      </div>
      <TeamMemberModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        member={member}
      />
    </div>
  );
};
