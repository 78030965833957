import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import AboutUs from '../AboutUs/AboutUs';
import { Account } from '../Account';
import { Checkout } from '../Checkout';
import { Badges } from '../components/Discover/pages';
import {
  AboutUsHelmet,
  AccessibilityHelmet,
  AetnaHelmet,
  ContactUsHelmet,
  CookiePolicyHelmet,
  CorporateWellnessHelmet,
  ExploreStudiosHelmet,
  FitnessPartnersHelmet,
  ForFitnessFacilitiesHelmet,
  ForWellnessProgramsHelmet,
  HomeHelmet,
  HowItWorksHelmet,
  IndividualSubscriptionHelmet,
  PrivacyPolicyHelmet,
  RegisterHelmet,
  StreamingHelmet,
  StreamingStudiosHelmet,
  TermsOfServiceHelmet,
} from '../components/Helmets';
import { Studios } from '../components/streaming/pages';
import { useAuth } from '../contexts/AuthContext';
import { useSite } from '../contexts/SiteContext';
import { ExplorePage as Explore } from '../Explore';
import { Home } from '../Home/Home';
import { Discover, Login, Streaming, User } from '../pages';
import Aetna from '../pages/Aetna';
import CorporateWellness from '../pages/CorporateWellness';
import FitnessPartners from '../pages/FitnessPartners';
import HowItWorks from '../pages/HowItWorks';
import IndividualSubscription from '../pages/IndividualSubscription';
import { Register } from '../Register';
import { Studio } from '../Studio';
import Accessibility from '../Support/Accessibility';
import ContactUs from '../Support/ContactUs';
import CookiePolicy from '../Support/CookiePolicy';
import PrivacyPolicy from '../Support/PrivacyPolicy';
import TermsOfService from '../Support/TermsOfService';
import { SignIn } from '../components/SignIn';
import {
  ABOUT_US,
  ACCESSIBILITY,
  ACCOUNT,
  AETNA,
  CHECKOUT,
  CONTACT_US,
  COOKIE_POLICY,
  CORPORATE_WELLNESS,
  CREDITS,
  DISCOVER,
  EXPLORE,
  EXPLORE_CLASSES,
  EXPLORE_STUDIOS,
  FITNESS_FACILITIES,
  FITNESS_PARTNERS,
  HOW_IT_WORKS,
  INDIVIDUAL_SUBSCRIPTION,
  LOGIN,
  PAYMENT,
  PREFERENCES,
  PRIVACY_POLICY,
  PROFILE,
  REGISTER,
  REGISTER_CONSUMER,
  REGISTER_MOVE,
  REGISTER_MOVE_ALT,
  REGISTER_SPONSORED,
  ROOT,
  SETTINGS,
  STREAMING,
  TERMS_OF_SERVICE,
  USER,
  WELLNESS_PROGRAMS,
  REGISTER_NEW,
  REGISTER_USER,
  REGISTER_CHECK_YOUR_EMAIL,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  ADMIN,
  DIGITAL,
  GUEST,
  GUEST_USER,
} from './CONSTANTS';
import NotFound from './NotFound';
import ProtectedRoute from './ProtectedRoute';
import { RegisterEmailPassword } from '../RegisterNew/Register';
import { ChangePassword } from '../RegisterNew/ChangePassword';
import { RegisterUserInfo } from '../RegisterNew/UserInfo';
import { CheckYourEmail } from '../RegisterNew/CheckYourEmail';
import { ForgotPassword } from '../RegisterNew/ForgotPassword';
import { GuestEmailAndPassword } from '../RegisterNew/GuestEmailAndPassword';
import { GuestInfo } from '../RegisterNew/GuestInfo';
import Admin from '../Admin';
import { MobileDigital } from '../components/MobileDigital';

export default function RouterConfig() {
  const { authState } = useAuth();
  const { user } = authState || {};
  const location = useLocation();
  const { isMoveSite, isCoreSite, isMobileApp } = useSite();

  if (authState.isLoading) {
    // console.log("AUTHSTATE IS LOADING. (RouterConfig)");
    return <></>;
  }

  // console.log("CONTINUING TO PROTECTED ROUTE.")
  return (
    <Switch>
      <ProtectedRoute exact path={CHECKOUT} component={Checkout} />
      <Route exact path={ROOT}>
        {isMobileApp && window?.ReactNativeWebView ? (
          <SignIn />
        ) : (
          <>
            <HomeHelmet />
            <Home />
          </>
        )}
      </Route>
      <Route exact path={WELLNESS_PROGRAMS}>
        <ForWellnessProgramsHelmet />
        <Home />
      </Route>
      <Route exact path={FITNESS_FACILITIES}>
        <ForFitnessFacilitiesHelmet />
        <Home />
      </Route>

      <Redirect exact path="/forte" to={STREAMING} />
      <Redirect exact path="/studios" to={FITNESS_PARTNERS} />
      <ProtectedRoute exact path={DISCOVER} component={Discover} />
      <Route path="/discover/badges" component={Badges} />

      <Route path={[EXPLORE_STUDIOS, EXPLORE_CLASSES]}>
        <ExploreStudiosHelmet />
        <Explore />
      </Route>
      <Route exact path={EXPLORE}>
        <Redirect to={{ pathname: EXPLORE_STUDIOS, search: location.search }} />
      </Route>
      <Route exact path={STREAMING}>
        <StreamingHelmet />
        <Streaming />
      </Route>
      <Route path="/streaming/studios">
        <StreamingStudiosHelmet />
        <Studios />
      </Route>
      <Route path="/studio/:id" component={Studio} />
      <Route path={USER} component={User} />

      <Route exact path={ACCOUNT}>
        <Redirect to={SETTINGS} />
      </Route>
      <ProtectedRoute exact path={SETTINGS} component={Account} />
      <ProtectedRoute exact path={PROFILE} component={Account} />
      <ProtectedRoute exact path={PREFERENCES} component={Account} />
      <ProtectedRoute exact path={PAYMENT} component={Account} />
      <ProtectedRoute exact path={CREDITS} component={Account} />

      <ProtectedRoute path={ADMIN} component={Admin} />
      <Route exact path={LOGIN} component={Login} />
      <Route exact path={REGISTER}>
        <RegisterHelmet />
        <RegisterEmailPassword />
      </Route>
      <Route exact path={REGISTER_NEW}>
        <RegisterHelmet />
        <RegisterEmailPassword />
      </Route>
      <Route exact path={REGISTER_USER}>
        <RegisterHelmet />
        <RegisterUserInfo />
      </Route>
      <Route exact path={GUEST}>
        <RegisterHelmet />
        <GuestEmailAndPassword />
      </Route>
      <Route exact path={GUEST_USER}>
        <RegisterHelmet />
        <GuestInfo />
      </Route>
      <Route exact path={REGISTER_CHECK_YOUR_EMAIL}>
        <RegisterHelmet />
        <CheckYourEmail />
      </Route>
      <Route exact path={FORGOT_PASSWORD}>
        <RegisterHelmet />
        <ForgotPassword />
      </Route>
      <Route exact path={CHANGE_PASSWORD}>
        <RegisterHelmet />
        <ChangePassword />
      </Route>
      <Route exact path={REGISTER_SPONSORED} component={Register} />
      <Route exact path={REGISTER_CONSUMER} component={Register} />
      <Route exact path={REGISTER_MOVE} component={Register} />
      <Route exact path={REGISTER_MOVE_ALT} component={Register} />

      <Route exact path={ABOUT_US}>
        <AboutUsHelmet />
        <AboutUs />
      </Route>
      <Route exact path={HOW_IT_WORKS}>
        <HowItWorksHelmet />
        <HowItWorks />
      </Route>
      <Route exact path={INDIVIDUAL_SUBSCRIPTION}>
        <IndividualSubscriptionHelmet />
        <IndividualSubscription />
      </Route>
      <Route exact path={CORPORATE_WELLNESS}>
        <CorporateWellnessHelmet />
        <CorporateWellness />
      </Route>
      <Route exact path={FITNESS_PARTNERS}>
        <FitnessPartnersHelmet />
        <FitnessPartners />
      </Route>
      <Route exact path={CONTACT_US}>
        <ContactUsHelmet />
        <ContactUs />
      </Route>
      <Route exact path={PRIVACY_POLICY}>
        <PrivacyPolicyHelmet />
        <PrivacyPolicy />
      </Route>
      <Route exact path={COOKIE_POLICY}>
        <CookiePolicyHelmet />
        <CookiePolicy />
      </Route>
      <Route exact path={TERMS_OF_SERVICE}>
        <TermsOfServiceHelmet />
        <TermsOfService />
      </Route>
      <Route exact path={ACCESSIBILITY}>
        <AccessibilityHelmet />
        <Accessibility />
      </Route>
      <Route exact path={AETNA}>
        <AetnaHelmet />
        <Aetna />
      </Route>
      <Route exact path={DIGITAL}>
        <MobileDigital />
      </Route>

      {/* 404 page not found */}
      <Route component={NotFound} />
    </Switch>
  );
}
