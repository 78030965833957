import React from 'react';
import GlobalLoading from '../shared/GlobalLoading';

const LazyLoadAdmin = React.lazy(() => import('./pages/Admin'));

type Props = {};

const Admin = (props: Props) => {
  return (
    <React.Suspense fallback={<GlobalLoading />}>
      <LazyLoadAdmin />
    </React.Suspense>
  );
};

export default Admin;
