import React from 'react';
import boardStyles from '../styles/about-us/Board.module.css';
import flexStyles from '../styles/Flex.module.css';

export const BoardMemberCard = ({ member }) => {
  return (
    <div
      className={`${flexStyles['col-md-6']} ${flexStyles['col-lg-4']} ${boardStyles.tile}`}
    >
      <div>
        <div className={boardStyles.bubble}>
          <img
            src={member.imgPath}
            width="100%"
            alt={`Photo of ${member.name}`}
          />
        </div>
        <h4 className={boardStyles.leaderName}>{member.name}</h4>
      </div>
    </div>
  );
};
