import { Combobox, ComboboxInput, ComboboxList } from '@reach/combobox';
import '@reach/combobox/styles.css';
import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useHistory, useRouteMatch } from 'react-router-dom';
import usePlacesAutoComplete from 'use-places-autocomplete';
import { SearchContext } from './contexts/SearchContext';
import { EXPLORE_CLASSES, EXPLORE_STUDIOS } from './navigation/CONSTANTS';
import useWindowSize, { _XS } from './shared/hooks/useWindowSize';
import defaultBtnStyles from './styles/button/Default.module.css';
import comboboxStyles from './styles/Combobox.module.css';
import inputFieldStyles from './styles/input/Field.module.css';
import loadingStyles from './styles/Loading.module.css';
import locationStyles from './styles/Location.module.css';
import searchStyles from './styles/Search.module.css';
import mobileSearchStyles from './styles/MobileSearch.module.css';
import styles from './styles/styles.module.css';
import submitStyles from './styles/Submit.module.css';
import svgIconStyles from './styles/SvgIcon.module.css';
import { useSearch } from './contexts/SearchContext';
import { getCityByPlaceId, getPlaceIdByLocation } from './utilities/geocode';
import { useLocalStorage } from 'react-use';
import { useSite } from './contexts/SiteContext';

export const useRecentSearches = () => {
  const [recentSearches, setRecentSearches] = useLocalStorage('recent_searches', []);
  return { recentSearches, setRecentSearches };
};

const Search = React.forwardRef((_, forwardRef) => {
  const searchContext = useContext(SearchContext);
  const size = useWindowSize();
  const [focused, setFocused] = useState(); // search | location
  const history = useHistory();
  const { searchState, recentSearches, updateRecentSearches, selectedPlace, setSelectedPlace, searchKeyword, setSearchKeyword, resetLocation, setGeoLocation } = useSearch();
  const [city, setCity] = useState('');
  const { isMobileApp } = useSite();

  useEffect(() => {
    (async () => {
      if (searchState.place_id) {
        try {
          const data = await getCityByPlaceId(searchState.place_id);
          setCity(data.formatted_address);
          if (!value) {
            setValue(data.formatted_address);
          }
        } catch (error) {}
      } else if (searchState.center?.length) {
        try {
          const location = {
            lat: searchState.center[0],
            lng: searchState.center[1],
          };
          const data = await getPlaceIdByLocation(location);
          if (data && data[0]) {
            setCity(data[0].formatted_address);
          }
        } catch (error) {}
      }
    })();
  }, [searchState]);

  const {
    ready,
    value = city,
    suggestions: { loading, status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutoComplete({
    requestOptions: {
      location: { lat: () => 33.486513, lng: () => -111.917507 }, // bias to user location. else
      radius: 60 * 1000, // 60km
      types: ['(regions)'],
      componentRestrictions: { country: ['us', 'ca'] },
      language: 'en',
    },
    debounce: 300,
    callbackName: 'initMap',
  });

  const ref = useOnclickOutside(() => {
    if (focused == 'location') {
      clearSuggestions();
      setValue(selectedPlace?.description ?? '', false);
      setFocused();
    }
  });

  const handleLocationFocus = () => {
    setValue('', false);
    setFocused('location');
  };

  const handleLocationBlur = () => {
    if (!value) {
      setValue(city, false);
    }
  };

  const handleInput = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
  };

  const matchExplore = useRouteMatch([EXPLORE_STUDIOS, EXPLORE_CLASSES]);
  // suggestion selected. set placeId AND if Explore, search immediately
  const handleSelect = ({ place_id, description }) => {
    // if Explore page, immediately set the location.
    console.log('selected:', { place_id, description });

    setSelectedPlace({ place_id, description });
    setValue(description, false);
    clearSuggestions();
    setFocused();

    if (matchExplore) {
      searchContext.setSearch(searchKeyword, place_id);
    }
  };

  // if Explore, search. else, redirect to explore with (search AND placeId)
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    console.log('searchState:', searchContext.searchState);
    if (value || searchKeyword) {
      let currentSearches;
      const key = `${searchKeyword} • ${value}`;
      const searchedIndex = recentSearches.findIndex((record) => record.key === key);
      if (searchedIndex > -1) {
        const [searched] = recentSearches.splice(searchedIndex, 1);
        currentSearches = [...recentSearches, searched];
      } else {
        currentSearches = [
          ...recentSearches,
          {
            id: +new Date(),
            key,
            title: searchKeyword,
            address: value,
            placeId: selectedPlace?.place_id,
          },
        ];
      }
      currentSearches = currentSearches.slice(-5);
      updateRecentSearches(currentSearches);
    }
    if (matchExplore && selectedPlace) {
      console.log('search only');
      // on EXPLORE PAGE
      searchContext.setSearch(searchKeyword, selectedPlace?.place_id);
    } else {
      console.log('search redirect');
      // else redirect

      const q = new URLSearchParams();
      if (searchKeyword) q.set('search', searchKeyword);
      if (selectedPlace && selectedPlace.place_id) q.set('place_id', selectedPlace.place_id);
      searchContext.setSearch(searchKeyword, selectedPlace?.place_id);
      console.log('q:', q.toString());

      history.replace({
        pathname: EXPLORE_STUDIOS,
        search: `?${q.toString()}`,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (data.length) {
        handleSelect(data[0]);
        event.target.blur();
      }
    }
  };

  searchContext.useGetLocation((homeCityString) => setValue(homeCityString, false));

  useImperativeHandle(forwardRef, () => {
    const submitSearch = () => {
      handleSubmit();
    };

    const resetSearch = () => {
      setValue('');
      setSearchKeyword('');
      setSelectedPlace('');
      updateRecentSearches([]);
      resetLocation();
    };

    const setSearchValues = (item) => {
      setGeoLocation(undefined);
      setSearchKeyword(item.title);
      setValue(item.address);
      setSelectedPlace({
        place_id: item.placeId,
        description: item.address,
      });
      searchContext.setSearch(item.title, item.place_id);
    };

    return {
      submitSearch,
      resetSearch,
      setSearchValues,
    };
  });

  const renderSuggestions = (sugg) =>
    sugg.map(({ place_id, description }) => (
      <li
        key={place_id}
        onClick={() => handleSelect({ place_id, description })}
        role="option"
        tabIndex="-1"
        data-type="place"
        data-cy="explore-location-match"
        aria-selected="false"
        id={`suggestion-${place_id}`}
      >
        <svg aria-hidden="true" focusable="false" className={locationStyles.icon} width="24" height="24" viewBox="0 0 24 24">
          <path d="M12.4824663,21.5022166 C12.1827655,21.8346255 11.6605113,21.8322434 11.3638552,21.4971145 C11.3128307,21.4394726 11.2212742,21.3331 11.0949504,21.1816943 C10.8867969,20.9322117 10.6554839,20.6451685 10.4067705,20.3242363 C9.69732337,19.4087874 8.98819413,18.4025938 8.32542158,17.3343826 C6.9774242,15.161773 5.99473154,13.0163779 5.5343039,10.9822002 C5.34738072,10.1563701 5.25,9.35897873 5.25,8.59346828 C5.25,4.8998898 8.03765621,2.25 11.9254398,2.25 C15.8383497,2.25 18.75,4.92229102 18.75,8.59346828 C18.75,9.40683382 18.6359801,10.2574688 18.41786,11.1411741 C17.9292076,13.1209313 16.9388902,15.2083659 15.5959667,17.324485 C14.9166866,18.3948645 14.1899383,19.4043961 13.4628961,20.3238688 C13.2080439,20.6461742 12.9710351,20.9345706 12.757773,21.1853233 C12.6283892,21.3374522 12.5346505,21.4443372 12.4824663,21.5022166 Z M12.2862835,19.3935017 C12.9829664,18.5124238 13.6799432,17.544248 14.3294731,16.5207477 C15.5928203,14.5300213 16.5174463,12.5810537 16.9615647,10.7817248 C17.1519684,10.0103112 17.25,9.27895545 17.25,8.59346828 C17.25,5.7808129 15.0372856,3.75 11.9254398,3.75 C8.84955723,3.75 6.75,5.74579678 6.75,8.59346828 C6.75,9.24199064 6.83394678,9.9293797 6.99729567,10.6510581 C7.4154854,12.498628 8.33254068,14.5007248 9.60001822,16.543558 C10.2334356,17.5644567 10.9130813,18.5288157 11.5924092,19.4053997 C11.7097701,19.5568386 11.8228969,19.7001543 11.9310868,19.8348846 C12.0444249,19.6966081 12.1630814,19.5493125 12.2862835,19.3935017 Z"></path>
          <path d="M12,11.75 C10.4812169,11.75 9.25,10.5187831 9.25,9 C9.25,7.48121694 10.4812169,6.25 12,6.25 C13.5187831,6.25 14.75,7.48121694 14.75,9 C14.75,10.5187831 13.5187831,11.75 12,11.75 Z M12,10.25 C12.6903559,10.25 13.25,9.69035594 13.25,9 C13.25,8.30964406 12.6903559,7.75 12,7.75 C11.3096441,7.75 10.75,8.30964406 10.75,9 C10.75,9.69035594 11.3096441,10.25 12,10.25 Z"></path>
        </svg>
        {description}
      </li>
    ));

  return (
    <form
      onReset={() => {
        setValue('');
        setSearchKeyword('');
      }}
      data-show-phone={size[3] == _XS ? 'true' : 'false'}
      data-show-mobile={size[3] == _XS ? 'true' : 'false'}
      className={isMobileApp ? mobileSearchStyles.container : searchStyles.container}
      autoComplete="on"
      noValidate=""
      {...(!!focused ? { ['data-focused']: focused } : '')}
    >
      <fieldset>
        <div className={searchStyles.inner}>
          <div className={isMobileApp ? mobileSearchStyles.fields : searchStyles.fields}>
            <div
              onFocus={() => setFocused('search')}
              onBlur={() => setFocused()}
              className={`${isMobileApp ? mobileSearchStyles.search : searchStyles.search} ${inputFieldStyles.field}`}
            >
              <div className={inputFieldStyles.inlineLabel}>
                <span>Find:</span>
              </div>
              <label className={`${inputFieldStyles.label} ${styles.container}`} htmlFor="id-144-2">
                Studios &amp; Gyms
              </label>

              <input
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                type="text"
                name="search"
                autoComplete="off"
                aria-label="Find Studios &amp; Gyms"
                placeholder="Studios &amp; Gyms"
                tabIndex="0"
                className={inputFieldStyles.input}
                aria-invalid="false"
                aria-describedby=" id-144-1"
                style={{ paddingLeft: '57px', fontSize: 14 }}
              />
              <div id="id-144-1" aria-live="polite" aria-atomic="true" role="status"></div>
            </div>

            <Combobox
              ref={ref}
              onFocus={handleLocationFocus}
              onBlur={handleLocationBlur}
              aria-labelledby="demo"
              className={`
                ${isMobileApp ? '' : searchStyles.location}
                ${locationStyles.container}
                ${isMobileApp ? mobileSearchStyles.comboBoxContainer : comboboxStyles.container}
                ${inputFieldStyles.field}
              `}
              data-show-suggestions={focused === 'location'}
              data-loading={loading}
            >
              <div className={comboboxStyles.inlineLabel}>
                <span>Near:</span>
              </div>
              <ComboboxInput
                value={value ?? city}
                onChange={handleInput}
                onKeyDown={handleKeyDown}
                disabled={!ready}
                autoComplete="off"
                placeholder="Your Location"
                aria-label="Your Location"
                style={{ paddingLeft: '61px' }}
                aria-expanded={focused === 'location'}
              />
              <div role="status" aria-atomic="true" aria-live="polite" className={styles.container} id="combobox-suggestions-message">
                <span>{data.length} location results are available. Use Down Arrow key to navigate and press Enter to select.</span>
              </div>
              <div id="combobox-suggestions-id-145-1">
                <ComboboxList>{renderSuggestions(status === 'OK' && data.length > 0 ? data : [])}</ComboboxList>
              </div>
              <div className={`${comboboxStyles.loading} ${loadingStyles.spinner}`}></div>
            </Combobox>
          </div>
          {!isMobileApp && (
            <div className={searchStyles.submitContainer}>
              <button
                className={`${searchStyles.submit} ${submitStyles.button} ${defaultBtnStyles.button} focus_outline`}
                aria-label="Search Studios"
                onClick={(e) => {
                  handleSubmit(e);
                  e.preventDefault();
                }}
              >
                <div className={loadingStyles.hidden}></div>
                <span className={submitStyles.visible}>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    className={svgIconStyles.icon}
                    style={{ verticalAlign: 'top' }}
                    viewBox="0 0 24 24"
                    aria-label="hidden"
                  >
                    <path d="M15.2820029,14.2031457 L20.531247,19.4705884 C20.8236329,19.763988 20.8228112,20.2388611 20.5294116,20.531247 C20.236012,20.8236329 19.7611389,20.8228112 19.468753,20.5294116 L14.2233598,15.2658331 C13.0670539,16.1944188 11.5984084,16.75 10,16.75 C6.27207794,16.75 3.25,13.7279221 3.25,10 C3.25,6.27207794 6.27207794,3.25 10,3.25 C13.7279221,3.25 16.75,6.27207794 16.75,10 C16.75,11.5891087 16.2008648,13.0499601 15.2820029,14.2031457 Z M10,15.25 C12.8994949,15.25 15.25,12.8994949 15.25,10 C15.25,7.10050506 12.8994949,4.75 10,4.75 C7.10050506,4.75 4.75,7.10050506 4.75,10 C4.75,12.8994949 7.10050506,15.25 10,15.25 Z"></path>
                  </svg>
                </span>
              </button>
            </div>
          )}
        </div>
      </fieldset>
    </form>
  );
});

export default Search;
