import React, { useRef, useState } from 'react';
import { useField } from 'formik';

import imageUploaderStyles from '../../styles/ImageUploader.module.css';
import inputFieldStyles from '../../styles/input/Field.module.css';
import fieldStyles from '../../styles/input/Field.module.css';

import styles from '../../styles/styles.module.css';
import defaultBtnStyles from '../../styles/button/Default.module.css';
import fineprintStyles from '../../styles/Fineprint.module.css';
import utilities from '../../styles/utilities.module.css';
import { useId } from 'react-id-generator';
import { logAmplitudeEvent } from '../../api/integration';

export const PhotoUpload = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue, setTouched } = helpers;
  const [htmlId] = useId();
  const fileInputRef = useRef(null);
  const handleClick = () => {
    if (fileInputRef?.current?.click) {
      fileInputRef.current.click();
    }
    logAmplitudeEvent('Profile: Change Photo');
  };
  const handleChange = (e) => {
    setValue(e.currentTarget.files[0]);
  };

  return (
    <div
      className={`${imageUploaderStyles.container} ${inputFieldStyles.field}`}
    >
      <PhotoPreview file={field.value} emptyClick={handleClick} />
      <div
        className={`${imageUploaderStyles.inputContainer} ${utilities['hide-offscreen']}`}
      >
        <input
          id="id-47-1111"
          tabIndex="-1"
          aria-labelledby="id-47-222"
          type="file"
          onChange={handleChange}
          ref={fileInputRef}
        />
      </div>
      <button
        type="button"
        className={`${imageUploaderStyles.button} ${defaultBtnStyles['secondary-button']} ${defaultBtnStyles.button} focus_outline`}
        onClick={handleClick}
        htmlFor="id-47-1111"
      >
        <span>Change Photo</span>
      </button>
      <label
        id="id-47-222"
        className={`${imageUploaderStyles.label} ${inputFieldStyles.label} ${styles.container}`}
        htmlFor="id-47-222"
      >
        Image Uploader
      </label>
      <p className={fineprintStyles.fineprint}>
        <span>Compatible file types: JPG, PNG</span>
        <br />
        <span>1 MB limit</span>
      </p>

      <div id={htmlId} aria-live="polite" aria-atomic="true" role="status">
        {meta.error ? (
          <span className={fieldStyles.errorLabel}>
            <span>{meta.error}</span>
          </span>
        ) : null}
      </div>
      {meta.error && <div className={fieldStyles.errorIcon}>!</div>}
    </div>
  );
};

export const PhotoPreview = ({ file, emptyClick }) => {
  const [preview, setPreview] = useState(null);

  if (file && file instanceof Blob) {
    let reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }

  return (
    <div
      className={`${!file ? imageUploaderStyles.thumbEmpty : ''} ${
        imageUploaderStyles.thumb
      }`}
      onClick={file ? undefined : emptyClick}
    >
      {file ? (
        <div
          className={imageUploaderStyles.nail}
          style={{ backgroundImage: `url('${preview ?? file}')` }}
        ></div>
      ) : (
        <svg
          aria-hidden="true"
          focusable="false"
          className={imageUploaderStyles.camera}
          viewBox="0 0 24 24"
        >
          <path d="M5 4h-3v-1h3v1zm8 6c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-5v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm13 4c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z"></path>
        </svg>
      )}
    </div>
  );
};
