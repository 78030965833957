import React, { useEffect } from 'react';

import styles from '../styles/styles.module.css';
import errorFormStyles from '../styles/error/Form.module.css';
import pageStyles from '../styles/register/SponsoredPage.module.css';

import formStyles from '../styles/register/Form.module.css';
import loginStyles from '../styles/Login.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import loadingStyles from '../styles/Loading.module.css';
import submitStyles from '../styles/Submit.module.css';
import checkboxStyles from '../styles/Checkbox.module.css';
import contentStyles from '../styles/Content.module.css';
import fieldStyles from '../styles/input/Field.module.css';

import titledFormStyles from '../styles/TitledForm.module.css';
import registerBg from '../Assets/register-bg.png';
import pfMoveLogo from '../Assets/pf-move-logo.png';
import {
  LOGIN,
  REGISTER_SPONSORED,
  REGISTER_CONSUMER,
  REGISTER_MOVE,
  NOMINATE_EMPLOYER,
  REGISTER,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  REGISTER_MOVE_ALT,
} from '../navigation/CONSTANTS';
import { Link, useRouteMatch } from 'react-router-dom';
import { Field, Form, Formik, useField } from 'formik';
import { FormikDebug } from '../shared/Formik/FormikDebug';
import nextId, { setPrefix, useId } from 'react-id-generator';
// import TextField from '../shared/Formik/TextField';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import * as Yup from 'yup';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import authApi from '../api/auth';

export const Move = () => {
  const validationSchema = Yup.object().shape({
    pmid: Yup.string().required().label('Peerfit Move ID (PF XXXX XXXX)'),
    birth_date: Yup.date()
      .required()
      .typeError(
        'Date of Birth must be a valid date, with the format of MM/DD/YYYY.'
      )
      .min(
        new Date(1000, 1, 1),
        'Date of Birth must be a valid date, with the format of MM/DD/YYYY.'
      )
      .max(
        moment().endOf('day').subtract(13, 'years'),
        'Must be at least 13 years of age.'
      )
      .label('Date of Birth'),
  });
  const handleSubmit = async (values, { setErrors }) => {
    // api call [POST] register/
    console.log('VERIFY: ', values);
    authApi
      .verifyEnrollment(values)
      .then((res) => {
        console.log('verification success.', res);
        // if verification success, do what?
        //      - authcontext add user.
        //      - authcontext add token
        // if failed, setErrors({ summary: [Message here] })
      })
      .catch((err) => {
        setErrors({ summary: err.response.data.errors[0].message });
      });
  };

  return (
    <div className={contentStyles.container}>
      <div className={pageStyles.formContainer}>
        <h2 className={pageStyles.title}>
          <span>Sign Up with your Peerfit Move ID number</span>
        </h2>
        <p>
          Sign up for your Peerfit account below. You may have received your
          Peerfit Move ID number via email or mail. If you don't know your ID
          number, you can sign up by clicking "I don't know my Peerfit Move ID
          number" below.
        </p>

        <Formik
          initialValues={{
            pmid: '',
            birth_date: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await handleSubmit(values, actions);
          }}
        >
          {(formikProps) => (
            <Form className={pageStyles.form} autoComplete="on" noValidate="">
              <fieldset>
                {formikProps.errors.summary && (
                  <div className={errorFormStyles.error} tabIndex="-1">
                    <span aria-label={formikProps.errors.summary}>
                      {formikProps.errors.summary}
                    </span>
                  </div>
                )}

                <PmidField
                  name="pmid"
                  label="Peerfit Move ID (PF XXXX XXXX)"
                  placeholder="PF XXXX XXXX"
                  autoComplete="off"
                  data-cy="register-pfMemberId"
                />

                <Link
                  rel="noopener noreferrer"
                  style={{
                    display: 'block',
                    fontWeight: '600',
                    marginBottom: '1.6rem',
                  }}
                  // to={REGISTER_SPONSORED} target="_blank"
                  to={REGISTER_MOVE_ALT}
                  target="_blank"
                  aria-label="I don't know my Peerfit Move ID number"
                >
                  <span>
                    I don't know my Peerfit Move ID number
                    <svg
                      viewBox="0 0 19 13"
                      style={{
                        marginLeft: '0.8rem',
                        height: '1.3rem',
                        width: '1.9rem',
                      }}
                    >
                      <g
                        fill="currentColor"
                        strokeWidth="2.16"
                        fillRule="evenodd"
                      >
                        <path
                          d="M9.07943925,4.08000004 C8.4829717,4.08000004 7.99943921,3.59646755 7.99943921,3 C7.99943921,2.40353245 8.4829717,1.91999996 9.07943925,1.91999996 L17.1594393,1.91999996 L17.1594393,10 C17.1594393,10.5964676 16.6759068,11.08 16.0794393,11.08 C15.4829717,11.08 14.9994392,10.5964676 14.9994392,10 L14.9994392,4.08000004 L9.07943925,4.08000004 Z"
                          transform="translate(12.579439, 6.500000) rotate(45.000000) translate(-12.579439, -6.500000) "
                        ></path>
                        <path d="M16.6267218,5.43851848 C17.2231893,5.43851848 17.7067218,5.92205097 17.7067218,6.51851852 C17.7067218,7.11498607 17.2231893,7.59851856 16.6267218,7.59851856 L1.62969178,7.59851856 C1.03322423,7.59851856 0.549691741,7.11498607 0.549691741,6.51851852 C0.549691741,5.92205097 1.03322423,5.43851848 1.62969178,5.43851848 L16.6267218,5.43851848 Z"></path>
                      </g>
                    </svg>
                  </span>
                </Link>

                <DateField
                  name="birth_date"
                  label="Date of Birth"
                  placeholder="MM/DD/YYYY"
                  autoComplete="bday"
                  data-cy="register-birthdate"
                />

                <button
                  data-cy="register-submit"
                  type="submit"
                  className={`${formStyles.submit} ${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                >
                  <div className={loadingStyles.hidden}></div>
                  <span className={submitStyles.visible}>
                    <span>Sign Up</span>
                  </span>
                </button>
                {/* <FormikDebug /> */}
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export const DateField = ({ label, placeholder, ...props }) => {
  const [field, meta] = useField(props);

  const [htmlId] = useId();
  const id1 = htmlId + '-1';
  const id2 = htmlId + '-2';

  const handleBlur = (e) => {
    e.target.placeholder = label;
    field.onBlur(e);
  };

  return (
    <div className={fieldStyles.field}>
      <label
        className={`${fieldStyles.label} ${styles.container}`}
        htmlFor={id2}
      >
        {label}
      </label>
      <MaskedInput
        {...field}
        type="text"
        id={id2}
        tabIndex="0"
        className={`${meta.touched && meta.error && fieldStyles.inputError} ${
          fieldStyles.input
        }`}
        aria-describedby={id1}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        placeholder={label}
        guide={true}
        onFocus={(e) => (e.target.placeholder = placeholder)}
        onBlur={handleBlur}
        {...props}
      />
      <div id={id1} aria-live="polite" aria-atomic="true" role="status">
        {meta.touched && meta.error ? (
          <span className={fieldStyles.errorLabel}>
            <span>Error: {meta.error}</span>
          </span>
        ) : null}
      </div>
      {meta.touched && meta.error && (
        <div className={fieldStyles.errorIcon}>!</div>
      )}
    </div>
  );
};

export const PmidField = ({ label, placeholder, ...props }) => {
  const [field, meta] = useField(props);

  const [htmlId] = useId();
  const id1 = htmlId + '-1';
  const id2 = htmlId + '-2';

  const handleBlur = (e) => {
    e.target.placeholder = label;
    field.onBlur(e);
  };

  return (
    <div className={fieldStyles.field}>
      <label
        className={`${fieldStyles.label} ${styles.container}`}
        htmlFor={id2}
      >
        {label}
      </label>
      <MaskedInput
        {...field}
        type="text"
        id={id2}
        tabIndex="0"
        className={`${meta.touched && meta.error && fieldStyles.inputError} ${
          fieldStyles.input
        }`}
        aria-describedby={id1}
        mask={[
          'P',
          'F',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        placeholder={label}
        guide={true}
        onFocus={(e) => (e.target.placeholder = placeholder)}
        onBlur={handleBlur}
        {...props}
      />
      <div id={id1} aria-live="polite" aria-atomic="true" role="status">
        {meta.touched && meta.error ? (
          <span className={fieldStyles.errorLabel}>
            <span>Error: {meta.error}</span>
          </span>
        ) : null}
      </div>
      {meta.touched && meta.error && (
        <div className={fieldStyles.errorIcon}>!</div>
      )}
    </div>
  );
};
