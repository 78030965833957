import React from 'react';
import { Link } from 'react-router-dom';

import { EXPLORE } from '../navigation/CONSTANTS';
import { WorkoutStyles } from '../shared/WorkoutStyles';

import peerfitterStyles from '../styles/home/Peerfitters.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';

export default function Peerfitters() {
  return (
    <>
      <div data-testid="peerfitters" className={peerfitterStyles.container}>
        <h2>
          <span>Experience Workouts Near You</span>
        </h2>
        <h3>
          <span>
            Find a studio and reserve class or gym time that fits your schedule.
          </span>
        </h3>
        <WorkoutStyles />
        <Link
          className={`${peerfitterStyles.button} ${btnDefaultStyles.button} focus_outline`}
          aria-label="View all studios near you"
          to={EXPLORE}
        >
          <span>View All Nearby</span>
        </Link>
      </div>
    </>
  );
}
