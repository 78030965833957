import React, { useEffect, useState } from 'react'
import classRowStyles from '../styles/ClassRow.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import useModal from '../shared/Modal/useModal';
import Modal from '../shared/Modal/Modal';
import { ReservationModal } from '../shared/Modal/ReservationModal';
import { GetDate, ToTimezone } from '../utilities/date';
import { useAuth } from '../contexts/AuthContext';
import { useSite } from '../contexts/SiteContext';
import { SessionCard as MobileSessionCard } from '../Studio/SessionCard/SessionCard';

export default function SessionCard({ session }) {
    const { isShowing, toggle, ref } = useModal();

    const { studio } = session || {};

    const [hasMembership, setHasMembership] = useState(false);
    const { isMobileApp } = useSite();
    const { memberships } = useAuth();

    useEffect(() => {
        // console.log("memberships:", memberships)
        // var d = ToTimezone(session.starts_at, studio.timezone, 'hh:mm A')
        // console.log("formatted:", d)
        // console.log("-------------------------------------------");
    }, [memberships])

    return isMobileApp ? <MobileSessionCard session={session} isSearchResultMode={true}/> : (
        <tr className={classRowStyles.container}>
            <td className={classRowStyles.time}>
                {/* <strong>{format(GetDate(session.starts_at), 'h:mm a')}</strong> */}
                <strong>{ToTimezone(session.starts_at, studio.timezone, 'hh:mm A z')}</strong>
                <span>{session.duration_minutes} Min</span>
            </td>
            <td className={classRowStyles.info}>
                <strong className={classRowStyles.sessionName}>
                    <Link className={`${classRowStyles.classTitle} ${classRowStyles.sessionName}`} to={`/studio/${session?.studio?.id}?from_source=Session`}>
                        {session.name} {session?.instructor?.name && `with ${session?.instructor?.name}`}
                    </Link>
                </strong>
                <Link className={`${classRowStyles.studioName} ${classRowStyles.sessionName}`} to={`/studio/${session?.studio?.id}?from_source=Session`}>{session?.studio?.name}</Link>
                <div>
                    <span className={classRowStyles.spotsLeft}>
                        {session?.total_booked >= session?.capacity
                            ?
                            <span>Full</span>
                            :
                            <span>{session?.capacity - session?.total_booked} Spots Left</span>
                        }
                    </span>
                    <span>{session?.studio?.distance} miles</span>
                </div>
            </td>
            <td className={classRowStyles.reserve}>
                {/* Peerfit allows reservation if negative is available. is this a bug on Peerfit? */}
                {(session?.total_booked >= session?.capacity) ?
                    <span>Class at capacity</span> :
                    <button className={`${classRowStyles.reserveBtn} ${btnDefaultStyles.button} focus_outline`} data-cy="classrow-reserve" onClick={toggle}>
                        <span>Reserve - {session?.credits} cr</span>
                    </button>
                }
                <ReservationModal
                    ref={ref}
                    isShowing={isShowing}
                    hide={toggle}
                    session={session}
                />
            </td>
        </tr>
    )
}
