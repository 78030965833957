import React, { useEffect, useState } from 'react';
import studioApi from '../../api/studio';
import featuredVirtualClasses from '../../styles/components/VirtualClasses.module.css';
import VirtualClassesCard from './VirtualClassesCard';

function FeaturedVirtualClasses () {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);

  const shuffleCards = res => {
    var shuffled = res?.sort(() => 0.5 - Math.random()) ?? res; // shuffle and take 4.
    const sortedVenues = sortByDate(shuffled);
    setClasses(sortedVenues?.slice ? sortedVenues.slice(0, 4) : sortedVenues);
  };

  const getSessions = () => {
    const date = new Date(Date.now());
    const year = date.getFullYear();
    const month = Number(date.getMonth()) + 1;
    const afterDate = year + '-' + month + '-' + date.getDate();
    var beforeDate = year + '-' + (month + 1);

    if (month + 1 === 13) {
      beforeDate = year + 1 + '-' + '1';
    }

    studioApi
      .getSessionsByQuery({ afterDate, beforeDate, keyword: 'Made for Move' })
      .then(res => {
        shuffleCards(res);
      })
      .finally(x => setLoading(false));
  };

  const sortByDate = venues => {
    if (!venues || !venues.sort) {
      return venues;
    }
    venues.sort(
      (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime)
    );
    return venues;
  };

  useEffect(() => {
    setLoading(true);
    getSessions();
  }, []);

  return (
    <div className={featuredVirtualClasses.container}>
      <div className={featuredVirtualClasses.inner}>
        <h2 className={featuredVirtualClasses.title}>
          Featured Virtual Classes
        </h2>
        <div className={featuredVirtualClasses.subtitleRow}>
          <span className={featuredVirtualClasses.description}>
            Get moving from home with these Peerfit Move-hosted online classes
          </span>
        </div>
        <div className={featuredVirtualClasses.studiolist}>
          {loading ? (
            <>
              <div className={featuredVirtualClasses.studiolistCard}>
                <VirtualClassesCard empty={true} />
              </div>
              <div className={featuredVirtualClasses.studiolistCard}>
                <VirtualClassesCard empty={true} />
              </div>
              <div className={featuredVirtualClasses.studiolistCard}>
                <VirtualClassesCard empty={true} />
              </div>
              <div className={featuredVirtualClasses.studiolistCard}>
                <VirtualClassesCard empty={true} />
              </div>
            </>
          ) : (
            classes?.map((item, index) => (
              <div
                key={index}
                className={featuredVirtualClasses.studiolistCard}
              >
                <VirtualClassesCard studio={item} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default FeaturedVirtualClasses;
