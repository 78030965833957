import { setPFToken } from '../utilities/TokenHelper';

export const useAutoLogin = () => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get('admin_link_from_fiton');
  const redirect = url.searchParams.get('redirect');
  let needRedirectFlag = false;
  if (token || redirect) {
    if (token) {
      setPFToken(token);
      url.searchParams.delete('admin_link_from_fiton');
    }
    if (redirect) {
      url.pathname = redirect;
      url.searchParams.delete('redirect');
    }
    url.searchParams.set('self_close', '1');
    needRedirectFlag = true;
    window.location.href = url.toString();
  }
  return needRedirectFlag;
};
