import React, { useState } from 'react';
import customClassStyles from '../styles/CustomClass.module.css';
import utilitiesStyles from '../styles/utilities.module.css';
import submitStyles from '../styles/Submit.module.css';
import loadingStyles from '../styles/Loading.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';

import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Select from '../shared/Formik/components/Select';
import CustomDatePicker from '../shared/Formik/components/CustomDatePicker';
import { Formik, Form } from 'formik';
import { FormikDebug } from '../shared/Formik/FormikDebug';
import * as Yup from 'yup';
import useModal from '../shared/Modal/useModal';
import { ClassModal } from '../shared/Modal/ClassModal';
import { SelectReservation, SelectInput } from '../components/SelectReservation';
import { CustomDatePicker as MobileDatePicker } from '../components/CustomDatePicker';
import { useSite } from '../contexts/SiteContext';

import { MinimizeOutlined } from '@material-ui/icons';
import moment from 'moment';

class LocalizedUtils extends DateFnsUtils {
  // https://material-ui-pickers.dev/guides/formats
  getWeekdays () {
    //     console.log("weekday: ", [...Array(7).keys()].map(i => locale.localize.day(i, { width: 'narrow' })));
    return [...Array(7).keys()].map(i => locale.localize.day(i, { width: 'narrow' }));
  }
}

export default function Reservation ({ studio, classes }) {
  const { isShowing, toggle, ref } = useModal();
  const [cls, setCls] = useState();
  const [startsAt, setStartsAt] = useState();
  const { isMobileApp } = useSite();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const validationSchema = Yup.object().shape({
    class: Yup.string().required().label('Class'),
    date: Yup.date()
      .nullable()
      .required()
      .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Date must be in the future.')
      .label('Date'),
    time: Yup.date().test('time-test', 'Time must be in the future.', function (value) {
      const { date, time } = this.parent;
      var timeValue = new Date(date.setHours(time.getHours(), time.getMinutes(), 0, 0));
      return new Date() < timeValue;
    })
  });

  const handleSubmit = async (values, actions) => {
    var d = new Date(values.date);
    var t = new Date(values.time);

    var year = d.getFullYear();
    var month = d.getMonth();
    var date = d.getDate();
    var hour = t.getHours();
    var min = t.getMinutes();

    var o = {
      year: year,
      month: month,
      day: date,
      hour: hour,
      minute: min
    };

    var t = moment.tz(o, studio.timezone);
    // console.log("moment:", t.format('ddd, MMM Do hh:mm A z'));
    // console.log("t:", t.toISOString())
    setStartsAt(t.toISOString());
    var ccc = classes.find(x => x.id == values.class);
    setCls(ccc);

    toggle();
  };

  return (
    <div className={`${customClassStyles.form} ${utilitiesStyles['clear-fix']}`}>
      <Formik
        initialValues={{ class: classes.length > 1 ? '' : classes[0].id, date: null, time: null }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          if (!isMobileApp) {
            await handleSubmit(values, actions);
          }
        }}
      >
        {formikProps => {
          const { values } = formikProps;
          console.log('values is', values);
          return (
            <Form>
              <fieldset>
                {/* {classes.length >1 ? ():()} */}
                <h4 className={customClassStyles.title}>
                  {classes?.length > 1 ? (
                    <span>Make a Reservation at {studio?.name}</span>
                  ) : (
                    <span>
                      {classes[0]?.name} at {studio?.name}
                    </span>
                  )}
                </h4>
                <h4 className={customClassStyles.classCredits}>
                  {classes?.length > 1
                    ? classes.find(x => x.id == formikProps.values.class)
                      ? `Cost: ${classes.find(x => x.id == formikProps.values.class)?.credits ?? -1} Credits`
                      : ''
                    : `Cost: ${classes[0]?.credits ?? -1} Credits`}
                </h4>
                <div className={customClassStyles.classOptions}>
                  {classes.length > 1 ? (
                    isMobileApp ? (
                      <>
                        <SelectReservation
                          value={formikProps.values.class}
                          options={classes}
                          onChange={option => {
                            formikProps.setFieldValue('class', option.id.toString());
                          }}
                        />
                        <br />
                      </>
                    ) : (
                      <Select
                        name="class"
                        label="Class"
                        options={[
                          { label: 'Select Reservation' },
                          ...classes.map(x => {
                            return { value: x.id, label: x.name };
                          })
                        ]}
                      />
                    )
                  ) : (
                    ''
                  )}
                  {isMobileApp ? (
                    <>
                      <SelectInput
                        value={date && moment(new Date(date)).format('llll').split(',').slice(0, 2).join(',')}
                        placeholder="Date"
                        open={openDatePicker}
                        onClick={() => setOpenDatePicker(status => !status)}
                      />
                      <MobileDatePicker
                        open={openDatePicker}
                        setOpen={setOpenDatePicker}
                        onTimeChange={date => {
                          console.log('date', date);
                          setDate(date);
                        }}
                      />
                      <br />
                      <SelectInput
                        value={time && moment(new Date(time)).format('hh:mm A')}
                        placeholder="Time"
                        open={openTimePicker}
                        onClick={() => setOpenTimePicker(status => !status)}
                      />
                      <MobileDatePicker
                        mode="time"
                        open={openTimePicker}
                        setOpen={setOpenTimePicker}
                        onTimeChange={time => {
                          console.log('time', time);
                          setTime(time);
                        }}
                      />
                      <br />
                    </>
                  ) : (
                    <MuiPickersUtilsProvider utils={LocalizedUtils}>
                      <CustomDatePicker label="Date" name="date" />
                      <CustomDatePicker label="Time" name="time" type="time" />
                    </MuiPickersUtilsProvider>
                  )}
                  {isMobileApp ? (
                    values.class &&
                    date &&
                    time && (
                      <button
                        type="submit"
                        disabled={!values.class || !date || !time}
                        className={`${customClassStyles.submit} ${submitStyles.button} ${btnDefaultStyles.button} focus_outline`}
                        onClick={() => {
                          if (values.class && date && time) {
                            handleSubmit({
                              ...formikProps.values,
                              date,
                              time
                            });
                          }
                        }}
                      >
                        <div className={loadingStyles.hidden}></div>
                        <span className={submitStyles.visible}>
                          <span>Reserve</span>
                        </span>
                      </button>
                    )
                  ) : (
                    <button type="submit" className={`${customClassStyles.submit} ${submitStyles.button} ${btnDefaultStyles.button} focus_outline`}>
                      <div className={loadingStyles.hidden}></div>
                      <span className={submitStyles.visible}>
                        <span>Reserve</span>
                      </span>
                    </button>
                  )}
                </div>
              </fieldset>
              {/* <FormikDebug /> */}
            </Form>
          );
        }}
      </Formik>
      <ClassModal ref={ref} isShowing={isShowing} hide={toggle} cls={cls} starts_at={startsAt} studio={studio} />
    </div>
  );
}
