import React, { createContext, useContext, useState } from 'react';

export const CacheContext = createContext();
export const useCache = () => useContext(CacheContext);

export const CacheContextProvider = (props) => {
  const [studioResponseCache, setStudioResponseCache] = useState({});
  const [sessionResponseCache, setSessionResponseCache] = useState({});
  const [regions, setRegions] = useState([]);
  const [mapRegions, setMapRegions] = useState([]);

  return (
    <CacheContext.Provider
      value={{
        studioResponseCache,
        sessionResponseCache,
        regions,
        mapRegions,

        setStudioResponseCache,
        setSessionResponseCache,
        setRegions,
        setMapRegions,
      }}
    >
      {props.children}
    </CacheContext.Provider>
  );
};
