import React from 'react';
import loginFormStyles from '../../styles/login/Form.module.css';
import loginStyles from '../../styles/Login.module.css';

interface Props {
  title: string;
  subTitle?: string;
}

export const TitleContent: React.FC<Props> = ({
  title,
  subTitle,
}) => {
  return (
    <>
      <h2 className={loginFormStyles.title} style={{ textAlign: 'center', fontSize: '3.2rem', }}>
        <span>{title}</span>
      </h2>
      {
        subTitle &&
        <div className={loginStyles.copy} style={{ textAlign: 'center', lineHeight: 1.5, fontSize: '1.6rem', color: '#222B56', margin: '0 auto 24px', }}>
          <span>{subTitle}</span>
        </div>
      }
    </>
  );
};
