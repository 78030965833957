import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import nextId from 'react-id-generator';
import _ from 'lodash';
import * as Yup from 'yup';
import { TitleContent } from '../TitleContent';
import { CustomTextInput } from '../CustomTextInput';
import { SubmitButton } from '../SubmitButton';
import { RegisterPageContainer } from '../RegisterPageContainer';
import loginFormStyles from '../../styles/login/Form.module.css';
import loginStyles from '../../styles/Login.module.css';
import { forgotPassword } from '../../api/authentication';
import { ErrorBox } from '../ErrorBox';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { REGISTER_CHECK_YOUR_EMAIL } from '../../navigation/CONSTANTS';

export const ForgotPasswordForm: React.FC = () => {
  const [apiError, setApiError] = useState('');
  const { setRegisterUser, password_reset } = useAuth();
  const history = useHistory();

  return (
    <RegisterPageContainer>
      <TitleContent
        title="Forgot password"
        subTitle="Enter your email to receive a magic link to instantly login"
      />
      <Formik
        validateOnChange
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required()
            .label('Email'),
        })}
        onSubmit={async (values) => {
          try {
            const res = await forgotPassword({ email: values.email });
            if (res.status === 204) {
              setRegisterUser({
                email: values.email,
              });
              history.push({
                pathname: REGISTER_CHECK_YOUR_EMAIL,
                search: '?from=forgot-password',
              });
            } else {
              password_reset();
            }
          } catch (error) {
            setApiError(
              error?.response?.data.Message ?? error?.message ?? 'Server error'
            );
          }
        }}
      >
        {(props) => {
          const disabledButton =
            !props.values.email || !_.isEmpty(props.errors);
          return (
            <Form className={`${loginFormStyles.form} ${loginStyles.form}`}>
              <fieldset>
                <CustomTextInput
                  type="text"
                  name="email"
                  label="email"
                  id={nextId('id-')}
                  placeholder="Email Address"
                  onFocus={(e) => (e.target.placeholder = 'example@email.com')}
                  onBlur={(e) => {
                    e.target.placeholder = 'Email';
                    props.setFieldTouched('email');
                  }}
                />
                <ErrorBox show={!!apiError} title={apiError} />
                <SubmitButton
                  disabled={disabledButton}
                  isRegister={true}
                  isSubmitting={props.isSubmitting}
                >
                  Send email
                </SubmitButton>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </RegisterPageContainer>
  );
};
