import React, { useRef, useState, useEffect } from 'react';
import { createCarouselSlider } from './main';
import { useAuth } from '../../contexts/AuthContext';
import { getSignUpFullUrl } from '../../api/integration/fitOnSignUp';
import './main.css';
import FitOnPng from './fiton.png';
import Images1Png from './images-1.png';
import Images2Png from './images-2.png';
import Images3Png from './images-3.png';
import ImagePrev from './images-prev.svg';
import ImageNext from './images-next.svg';
import { useEffectOnce } from 'react-use';
import { postMessageToRN } from '../../utilities/rn';

export const GetStarted = () => {
  const { authState } = useAuth();
  const ref = useRef();
  const [fitOnSingUpUrl, setFitOnSignUpUrl] = useState('');

  const handleClickGetStarted = () => {
    if (fitOnSingUpUrl) {
      postMessageToRN({ key: 'SHOW_GO_BACK' });
    }
  };

  useEffectOnce(() => {
    createCarouselSlider(ref.current);
  });

  useEffect(() => {
    if (authState.user) {
      getSignUpFullUrl(authState.user, 'streaming').then((url) => {
        setFitOnSignUpUrl(url);
      });
    }
  }, [authState]);

  return (
    <div className="peerfit-fiton-integration-container">
      <div className="content-box">
        <div className="left-part">
          <img className="fiton-icon" src={FitOnPng} alt="" />
          <p className="fiton-concept-text">
            Unlimited Workouts,
            <br /> any time, anywhere
          </p>
          <p className="peerfit-concept-text">NEW! You can now enjoy FitOn as part of your Peerfit membership</p>
          <a style={{ textDecoration: 'none' }} className="get-started-cta start-button" href={fitOnSingUpUrl} target="_blank" onClick={handleClickGetStarted}>
            GET STARTED
          </a>
        </div>
        <div className="right-part">
          <div className="carousel-slider" ref={ref}>
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="carousel-slider-animate-opacity">
                    <img src={Images1Png} alt="" />
                    <div className="slide-content">
                      <h2>Unlimited workouts with Celebrities & top trainers</h2>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="carousel-slider-animate-opacity">
                    <img src={Images2Png} alt="" />
                    <div className="slide-content">
                      <h2>Turn your home into a Gym</h2>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="carousel-slider-animate-opacity">
                    <img src={Images3Png} alt="Meal Plans" />
                    <div className="slide-content">
                      <h2>
                        Meal plans <br />
                        Stop counting calories
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-button-prev">
              <img src={ImagePrev} alt="Image back arrow" />
            </div>
            <div className="swiper-button-next">
              <img src={ImageNext} alt="Image next arrow" />
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
        <a
          style={{ textDecoration: 'none' }}
          className="get-started-cta start-button-mobile"
          href={fitOnSingUpUrl}
          target="_blank"
          onClick={handleClickGetStarted}
        >
          GET STARTED
        </a>
      </div>
      <div className="gradient-background"></div>
    </div>
  );
};
