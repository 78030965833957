import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import controllerStyles from '../../styles/Controller.module.css';
import overlayDefaultStyles from '../../styles/overlay/Default.module.css';
import svgStyles from '../../styles/SvgIcon.module.css';
import reservationHeaderStyles from '../../styles/ReservationHeader.module.css';

import studioBannerImg from '../../Assets/studioBanner.jpg';

import btnDefaultStyles from '../../styles/button/Default.module.css';
import loginStyles from '../../styles/Login.module.css';
import fieldStyles from '../../styles/input/Field.module.css';
import styles from '../../styles/styles.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import submitStyles from '../../styles/Submit.module.css';
import errorFormStyles from '../../styles/error/Form.module.css';

import studioApi from '../../api/studio';
import { Link, useHistory } from 'react-router-dom';
import { PAYMENT, REGISTER } from '../../navigation/CONSTANTS';
import { useAuth } from '../../contexts/AuthContext';
import { Form, Formik } from 'formik';
import { ToTimezone } from '../../utilities/date';

// RESERVE SESSION
export const Modal = React.forwardRef(({ isShowing, hide, session }, ref) => {
  const { authState, login, verify, card } = useAuth();
  const [showNotes, setShowNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { studio } = session || {};

  const history = useHistory();
  const handleSubmit = async (values, actions) => {
    console.log('handleSubmit: ', values);

    await studioApi
      .reserve(authState.user?.id, session.id, values.note)
      .then((res) => {
        console.log('form submit values:', values);
        console.log('booking success. userId: ' + authState.user?.id);
        verify(); // pull user object to update credit count.
        history.push(`/user/${authState.user?.id}/upcoming`);
      })
      .catch((err) => {
        // add error message here.
        console.log('ERROR ON BOOKING. DISPLAY MESSAGE TO USER.', err);
        // if(err.Code == "InternalServerError"){
        //     actions.setErrors({ summary: "Something went wrong." })
        // }
        // else if(err.Code == "Payment Declined") {
        //     NEED TO CATCH AN ERROR AROUND PAYMENT WAS DECLINED.
        //     actions.setErrors({ summary: err.Message })
        // }
        // else {
        //     actions.setErrors({ summary: err.Message })
        // }

        actions.setErrors({ summary: 'Something went wrong.' });
      });
  };

  // useEffect(() => {
  //     var d = ToTimezone(session.starts_at, studio.timezone, 'ddd, MMM Do hh:mm A')
  //     console.log("MODAL:", d)
  //     console.log("-------------------------------------------");
  // }, [])

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className={controllerStyles.overlay}>
            <div
              ref={ref}
              role="dialog"
              tabIndex="0"
              data-cy="Login"
              className={overlayDefaultStyles.dialog}
              aria-labelledby="reservation-header-title"
            >
              <div
                data-focus-guard="true"
                tabIndex="0"
                style={{
                  width: '1px',
                  height: '0px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'fixed',
                  top: '1px',
                  left: '1px',
                }}
              ></div>
              <div
                data-focus-guard="true"
                tabIndex="1"
                style={{
                  width: '1px',
                  height: '0px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'fixed',
                  top: '1px',
                  left: '1px',
                }}
              ></div>
              <div data-focus-lock-disabled="false">
                <button
                  className={`${overlayDefaultStyles.close} focus_outline`}
                  aria-label="Close"
                  onClick={hide}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    className={`${overlayDefaultStyles.closeIcon} ${svgStyles.icon}`}
                    viewBox="0 0 17 17"
                  >
                    <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z"></path>
                  </svg>
                </button>
                <div
                  className={reservationHeaderStyles.header}
                  style={{ backgroundImage: `url(${studioBannerImg})` }}
                >
                  <div
                    data-cy="reservation-modal"
                    className={reservationHeaderStyles.content}
                  >
                    <div className={reservationHeaderStyles.textWrapper}>
                      <h3 id="reservation-header-title">
                        {authState.isAuthenticated ? (
                          <span>Reservation</span>
                        ) : (
                          <span>Sign In to Reserve</span>
                        )}
                      </h3>
                      <h4>
                        {session.studio_class.name} at {session.studio.name}
                      </h4>
                      {/* <h4>{format(GetDate(session.starts_at), 'E, LLL do h:mm a')}</h4> */}
                      <h4>
                        {ToTimezone(
                          session.starts_at,
                          studio.timezone,
                          'ddd, MMM Do hh:mm A'
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className={overlayDefaultStyles.content}>
                  <Formik
                    initialValues={{
                      note: '',
                    }}
                    onSubmit={handleSubmit}
                  >
                    {(formikProps) => (
                      <Form className={loginStyles.form}>
                        <fieldset>
                          {authState.isAuthenticated ? (
                            <>
                              {formikProps.errors.summary && (
                                <div
                                  className={errorFormStyles.error}
                                  tabIndex="-1"
                                >
                                  <span aria-label={formikProps.errors.summary}>
                                    {formikProps.errors.summary}
                                  </span>
                                </div>
                              )}

                              {/* if non integrated, show notes (!studio.is_mindbody) */}
                              {session.is_mindbody ? (
                                ''
                              ) : (
                                <div className={fieldStyles.field}>
                                  <label
                                    className={`${fieldStyles.label} ${styles.container}`}
                                    htmlFor="id-3-2"
                                  >
                                    Note
                                  </label>
                                  <textarea
                                    onChange={formikProps.handleChange}
                                    value={formikProps.values.note}
                                    type="textarea"
                                    rows="4"
                                    name="note"
                                    autoComplete="off"
                                    aria-required="true"
                                    id="id-3-9"
                                    placeholder="Add Note to Instructor..."
                                    tabIndex="0"
                                    className={fieldStyles.input}
                                  />
                                  <div
                                    id="id-3-1"
                                    aria-live="polite"
                                    aria-atomic="true"
                                    role="status"
                                  ></div>
                                </div>
                              )}

                              <div className={fieldStyles.field}>
                                <div>
                                  <span style={{ float: 'right' }}>
                                    {authState.user.credits}
                                  </span>
                                  <span>Available credits</span>
                                </div>
                                <div>
                                  <span style={{ float: 'right' }}>
                                    {session.credits}
                                  </span>
                                  <span>Credit cost</span>
                                </div>
                                {session.credits - authState.user.credits >
                                0 ? (
                                  <div>
                                    <strong style={{ float: 'right' }}>
                                      $
                                      {(
                                        (session.credits -
                                          authState.user.credits) *
                                        3.5
                                      ).toFixed(2)}
                                    </strong>
                                    <strong>
                                      {session.credits - authState.user.credits}{' '}
                                      credits needed (1cr x $3.50)
                                    </strong>
                                  </div>
                                ) : (
                                  <div>
                                    <strong style={{ float: 'right' }}>
                                      {authState.user.credits - session.credits}
                                    </strong>
                                    <strong>Credits Remaining</strong>
                                  </div>
                                )}
                              </div>
                              {/*not enough credits and no card on file.*/}

                              {authState.user.credits < session.credits &&
                              !card ? (
                                <>
                                  <div className={loginStyles.copy}>
                                    <span>Not enough credits.</span>
                                    <Link
                                      className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`}
                                      to={PAYMENT}
                                    >
                                      <span>Add a Card</span>
                                    </Link>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  {/*has card on file*/}
                                  {authState.user.credits < session.credits &&
                                  card ? (
                                    <div className={fieldStyles.field}>
                                      <span>
                                        *The remainder will be charged to your
                                        card on file.
                                      </span>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <button
                                    type="submit"
                                    className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                    disabled={formikProps.isSubmitting}
                                  >
                                    <div
                                      className={
                                        formikProps.isSubmitting
                                          ? `${submitStyles.spinner} ${loadingStyles.newSpinner}`
                                          : loadingStyles.hidden
                                      }
                                    ></div>
                                    <span
                                      className={
                                        formikProps.isSubmitting
                                          ? submitStyles.invisible
                                          : submitStyles.visible
                                      }
                                    >
                                      <span>Reserve</span>
                                    </span>
                                  </button>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className={loginStyles.copy}>
                                <span>Don't have a Peerfit account? </span>
                                <Link
                                  className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`}
                                  to={REGISTER}
                                >
                                  <span>Sign Up</span>
                                </Link>
                              </div>
                              <div className={loginStyles.copy}>
                                <span>Have an account? </span>
                                <button
                                  onClick={login}
                                  className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                >
                                  <span>Sign In</span>
                                </button>
                              </div>
                            </>
                          )}
                          {/* <FormikDebug /> */}
                        </fieldset>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div
                data-focus-guard="true"
                tabIndex="0"
                style={{
                  width: '1px',
                  height: '0px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'fixed',
                  top: '1px',
                  left: '1px',
                }}
              ></div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
});
