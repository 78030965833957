import React, { useEffect, useState } from 'react'
import desktopStyles from '../styles/Desktop.module.css';
import membershipStyles from '../styles/Membership.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';


import customClassStyles from '../styles/CustomClass.module.css';
import selectStyles from '../styles/Select.module.css';
import fieldStyles from '../styles/input/Field.module.css';
import styles from '../styles/styles.module.css';
import { set } from 'date-fns';
import useModal from '../shared/Modal/useModal';
import Modal from '../shared/Modal/Modal';
import { MembershipModal } from '../shared/Modal/MembershipModal';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';

export default function Membership({ memberships, studio }) {
    /* 
       purchase membership:
       if not logged in => popup login modal.
       if not a member => popup montlhy memberhsip modal.
    */
    const { authState, memberships: userMemberships } = useAuth();
    const { user } = authState || {};
    const { isShowing, toggle, ref } = useModal();
    const [membership, setMembership] = useState(memberships[0]);
    useEffect(() => {
        setMembership(memberships[0]);
    }, [memberships])

    useEffect(() => {

        // console.log("studio_membership: ", membership)
        // console.log("userMemberships: ", userMemberships)
        // console.log("mplan:", userMemberships.find(x => x.membershipplan.id == membership.id))
    }, [memberships])

    return (
        memberships.length > 0 &&

        <div>
            <div className={membershipStyles.container}>
                <div className={`${membershipStyles.left} ${membershipStyles.desc}`}>

                    {(memberships?.length ?? 0) > 1 ?
                        <>
                            <div className={membershipStyles.subtitle}>
                                <span>Purchase a membership plan</span>
                            </div>
                            <span>{membership?.description ?? ""}</span>  {/*no description*/}

                            <div className={`${customClassStyles.select} ${selectStyles.field} ${fieldStyles.field} ${customClassStyles.sharedFieldStyles}`}>
                                <label className={`${selectStyles.label} ${fieldStyles.label} ${styles.container}`} htmlFor="id-7-1">Select Membership</label>
                                <select
                                    onChange={(e) => setMembership(memberships.find(x => x.id == e.target.value))}
                                    value={membership?.id}
                                    aria-label="Select Membership" id="id-7-1"
                                    className={selectStyles.select} tabIndex="0">
                                    {memberships.map((opt, index) => <option key={index} value={opt.id}>{opt.description_title}</option>)}
                                </select>
                                <svg aria-hidden="true" focusable="false" className={selectStyles.caretDelegateClick} viewBox="0 0 24 24">
                                    <path d="M19.2868077,7.25476326 C19.6293551,6.91389521 20.1833728,6.91525659 20.5242408,7.25780398 C20.8651089,7.60035138 20.8637475,8.15436907 20.5212001,8.49523712 L12.5529074,16.424465 C12.2129441,16.7627617 11.6640093,16.7643441 11.3221012,16.4280131 L3.26139014,8.49878517 C2.9168832,8.1598977 2.91232773,7.60589707 3.2512152,7.26139014 C3.59010267,6.9168832 4.1441033,6.91232773 4.48861023,7.2512152 L11.932163,14.5733516 L19.2868077,7.25476326 Z">
                                    </path>
                                </svg>
                            </div>
                        </> : <>
                            <div className={membershipStyles.subtitle}>
                                <span>{(membership?.description_title ?? "") == "" ? "no title" : membership.description_title}</span>
                            </div>
                            <span>{(membership?.description ?? "") == "" ? "" : membership.description}</span>    {/*no description*/}
                            {(studio?.booking_service == "60" || studio?.booking_service == "61") ?
                                <span style={{ color: "#e61761" }}>* Visit the location to purchase membership.</span>
                                : ''}
                        </>}
                </div>
                <div className={`${membershipStyles.right} ${membershipStyles.desc}`}>
                    <div>
                        <span className={membershipStyles.subtitle}>{membership?.credits} Credits</span> <span>/ Month</span>
                    </div>
                    {(studio?.booking_service == "60" || studio?.booking_service == "61") ? '' :
                        <>
                            {userMemberships?.find(x => x.membershipplan?.id == membership?.id)
                                ?
                                <Link className={`${membershipStyles.button} ${btnDefaultStyles["transparent-button"]} ${btnDefaultStyles.button_link} ${btnDefaultStyles.button} focus_outline`} to={`/user/${user.id}/memberships`} >
                                    <span>View Membership</span>
                                </Link>
                                :
                                <button onClick={toggle} className={`${membershipStyles.button} ${btnDefaultStyles.button} focus_outline`}>
                                    <span>Purchase Membership</span>
                                </button>}
                        </>}
                </div>
            </div>

            {/* <div className={membershipStyles.container}>
                <div className={`${membershipStyles.left} ${membershipStyles.desc}`}>
                    <div className={membershipStyles.subtitle}>
                        <span>Monthly Membership</span>
                    </div>
                    <span>Get unlimited access to this facility every month using your credits.</span>
                </div>
                <div className={`${membershipStyles.right} ${membershipStyles.desc}`}>
                    <div>
                        <span className={membershipStyles.subtitle}>{memberships[0]?.credits} Credits</span> <span>/ Month</span>
                    </div>
                    <button className={`${membershipStyles.button} ${btnDefaultStyles.button} focus_outline`}>
                        <span>Purchase Membership</span>
                    </button>
                </div>
            </div> */}
            <MembershipModal
                ref={ref}
                isShowing={isShowing}
                hide={toggle}
                membership={membership}
                studio={studio}
            />
        </div>
    )
}
