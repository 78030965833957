import React from 'react';
import barreImg from '../Assets/barre.jpg';
import bootcampImg from '../Assets/bootcamp.jpg';
import boxingImg from '../Assets/boxing.jpg';
import crossfitImg from '../Assets/crossfit.jpg';
import cyclingImg from '../Assets/cycling.jpg';
import dancingImg from '../Assets/dancing.jpg';
import hiitImg from '../Assets/hiit.jpg';
import martial_artsImg from '../Assets/martial_arts.jpg';
import open_gymImg from '../Assets/open_gym.jpg';
import pilatesImg from '../Assets/pilates.jpg';
import strength_trainingImg from '../Assets/strength_training.jpg';
import trxImg from '../Assets/trx.jpg';
import yogaImg from '../Assets/yoga.jpg';
import { useSearch } from '../contexts/SearchContext/SearchContext';
import { useSite } from '../contexts/SiteContext/SiteContext';
import flexStyles from '../styles/Flex.module.css';
import studiosStyles from '../styles/Studios.module.css';
import utilities from '../styles/utilities.module.css';
import workoutStyles from '../styles/WorkoutStyles.module.css';
import { WorkoutStylesCard } from './WorkoutStylesCard';
import { WorkoutStylesCategoryCard } from './WorkoutStylesCategoryCard';

export const WorkoutStyles = ({ title }) => {
  const tags = [
    { name: 'Barre', image: barreImg },
    { name: 'Bootcamp', image: bootcampImg },
    { name: 'Boxing', image: boxingImg },
    { name: 'CrossFit', image: crossfitImg },
    { name: 'Cycling', image: cyclingImg },
    { name: 'Dance', image: dancingImg },
    { name: 'HIIT', image: hiitImg },
    { name: 'Martial Arts', image: martial_artsImg },
    { name: 'Open Gym', image: open_gymImg },
    { name: 'Pilates', image: pilatesImg },
    { name: 'Strength Training', image: strength_trainingImg },
    { name: 'TRX', image: trxImg },
    { name: 'Yoga', image: yogaImg },
  ];

  const imgs = [martial_artsImg, yogaImg, barreImg, open_gymImg];

  const { isMoveSite } = useSite();
  const categories = [
    {
      id: 4,
      tags: [
        {
          id: 1,
          name: 'Cycling',
        },
        {
          id: 2,
          name: 'Yoga',
        },
        {
          id: 4,
          name: 'Dance',
        },
        {
          id: 5,
          name: 'Bootcamp',
        },
        {
          id: 9,
          name: 'Boxing',
        },
        {
          id: 13,
          name: 'CrossFit',
        },
        {
          id: 18,
          name: 'Martial Arts',
        },
        {
          id: 26,
          name: 'TRX',
        },
        {
          id: 28,
          name: 'HIIT',
        },
        {
          id: 21,
          name: 'Open Gym',
        },
      ],
      name: 'Martial Arts',
      client_type: 2,
    },
    {
      id: 3,
      tags: [
        {
          id: 2,
          name: 'Yoga',
        },
        {
          id: 5,
          name: 'Bootcamp',
        },
        {
          id: 6,
          name: 'Barre',
        },
        {
          id: 7,
          name: 'Strength Training',
        },
        {
          id: 9,
          name: 'Boxing',
        },
        {
          id: 13,
          name: 'CrossFit',
        },
        {
          id: 16,
          name: 'Pilates',
        },
        {
          id: 18,
          name: 'Martial Arts',
        },
        {
          id: 26,
          name: 'TRX',
        },
        {
          id: 28,
          name: 'HIIT',
        },
        {
          id: 21,
          name: 'Open Gym',
        },
      ],
      name: 'Yoga',
      client_type: 2,
    },
    {
      id: 2,
      tags: [
        {
          id: 2,
          name: 'Yoga',
        },
        {
          id: 4,
          name: 'Dance',
        },
        {
          id: 6,
          name: 'Barre',
        },
        {
          id: 16,
          name: 'Pilates',
        },
        {
          id: 18,
          name: 'Martial Arts',
        },
        {
          id: 21,
          name: 'Open Gym',
        },
      ],
      name: 'Flexibility',
      client_type: 2,
    },
    {
      id: 1,
      tags: [
        {
          id: 1,
          name: 'Cycling',
        },
        {
          id: 2,
          name: 'Yoga',
        },
        {
          id: 4,
          name: 'Dance',
        },
        {
          id: 6,
          name: 'Barre',
        },
        {
          id: 7,
          name: 'Strength Training',
        },
        {
          id: 16,
          name: 'Pilates',
        },
        {
          id: 18,
          name: 'Martial Arts',
        },
        {
          id: 26,
          name: 'TRX',
        },
        {
          id: 21,
          name: 'Open Gym',
        },
      ],
      name: 'Low-Impact',
      client_type: 2,
    },
  ];
  const homeCityString = useSearch();

  return (
    <div className={workoutStyles.container}>
      <div
        className={`${workoutStyles.content} ${utilities['page-container']}`}
      >
        {title && (
          <div>
            <h2 className={`${workoutStyles.title} ${studiosStyles.title}`}>
              <span>
                Workout Styles{' '}
                {homeCityString &&
                  typeof homeCityString === 'string' &&
                  `in ${homeCityString}`}
              </span>
            </h2>
          </div>
        )}

        <div className={`${workoutStyles.cards} ${flexStyles.row}`}>
          {isMoveSite
            ? categories.map((category, index) => (
                <WorkoutStylesCategoryCard
                  key={index}
                  category={category}
                  img={imgs[index % imgs.length]}
                />
              ))
            : tags.map((tag, index) => (
                <WorkoutStylesCard key={index} tag={tag} />
              ))}
        </div>
      </div>
    </div>
  );
};
