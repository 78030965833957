import React from 'react';
import pageStyles from '../styles/accessibility/Page.module.css';
import utilities from '../styles/utilities.module.css';

export default function Accessibility() {
  return (
    <div
      className={`${pageStyles.page} ${utilities.page}`}
      style={{ height: 'auto', position: 'relative', minHeight: '216px' }}
    >
      <div className={pageStyles.container}>
        <h2>
          <span>Web Accessibility Statement</span>
        </h2>
        <h3>
          <span>We’re commited to easy access for everyone.</span>
        </h3>
        <p>
          <span>
            Peerfit is commited to providing equal access to all individuals
            seeking information on our site and using our platform. We are
            continually striving to improve the experience for everyone. We
            follow{' '}
            <a
              target="_blank"
              href="https://www.w3.org/TR/WCAG20/"
              rel="noreferrer"
            >
              Web Content Accessibility Guidelines (WCAG) 2.0 Level AA standards
            </a>
            . This includes accessibility for users with disabilities by
            supporting:
          </span>
        </p>
        <ul className={pageStyles.bulletList}>
          <li>
            <span>Ability to adjust the font size</span>
          </li>
          <li>
            <span>Maintaining text color and contrast ratios</span>
          </li>
          <li>
            <span>Use of screen readers</span>
          </li>
          <li>
            <span>Keyboard accessible navigation</span>
          </li>
          <li>
            <span>Skip to content links</span>
          </li>
        </ul>
        <p>
          <span>
            Should you have any difficulty accessing the Peerfit website or have
            any comments about the accessibility of our site, please contact us
            at{' '}
            <a href="mailto:support@peerfit.com&amp;subject=Accessibility">
              support@peerfit.com
            </a>
            .
          </span>
        </p>
      </div>
    </div>
  );
}
