import React, { useContext, useEffect, useState } from 'react';
import { logAmplitudeEvent } from '../../api/integration';
import ChangePassword from './ChangePassword';
import MailingListSubscription from './MailingListSubscription';
import roundedButtonStyle from '../../styles/button/Rounded.module.css';
import settingsStyle from './settings.module.css';
import { callRNFunction } from '../../utilities/rn';
import { AuthContext } from '../../contexts/AuthContext';
import GlobalLoading from '../../shared/GlobalLoading';
import { useSite } from '../../contexts/SiteContext';

export const Settings = () => {
  const authContext = useContext(AuthContext);
  const { isMobileApp } = useSite();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    logAmplitudeEvent('Screen View: Settings - Account');
  }, []);
  if (loading) {
    return <GlobalLoading />;
  }
  return (
    <div className={settingsStyle.container}>
      <div>
        <ChangePassword />
      </div>
      {isMobileApp && (
        <div style={{ padding: 10 }}>
          <button
            type="button"
            onClick={async () => {
              const res = await callRNFunction('confirm', 'Are you sure you want to sign out?');
              if (res) {
                setLoading(true);
                await authContext.logout();
              }
            }}
            className={`${roundedButtonStyle['secondary-button']} ${roundedButtonStyle.button} ${settingsStyle.signOutButton} focus_outline`}
          >
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
};
