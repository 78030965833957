import React, { useState, useMemo } from 'react';
import { Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import loginFormStyles from '../../styles/login/Form.module.css';
import loginStyles from '../../styles/Login.module.css';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import nextId from 'react-id-generator';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { REGISTER_USER, SETTINGS } from '../../navigation/CONSTANTS';
import CheckActiveIcon from '../../Assets/check_active.png';
import CheckInActiveIcon from '../../Assets/check_inactive.png';
import _ from 'lodash';
import { CustomTextInput } from '../CustomTextInput';
import { SubmitButton } from '../SubmitButton';
import { TitleContent } from '../TitleContent';
import { logAmplitudeEvent } from '../../api/integration';
import { changePassword } from '../../api/authentication';
import { useEffect } from 'react';
import { ErrorBox } from '../ErrorBox';
import { RegisterPageContainer } from '../RegisterPageContainer';
import useQuery from '../../shared/hooks/useQuery';

const shadowSize = '5px';
const shadowColor = '#ccc';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1,
  },
  box: {
    position: 'relative',
    minWidth: 256,
    padding: 16,
    boxShadow: `0 0 ${shadowSize} #ccc`,
    backgroundColor: '#fff',
    borderRadius: 4,
    marginTop: 10,
    marginBottom: 30,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '17%',
      right: 0,
      width: 10,
      height: 10,
      backgroundColor: '#fff',
      boxShadow: `0 0 ${shadowSize} ${shadowColor}`,
      transform: 'translate(-1260%, -300%) rotate(-45deg)',
      clipPath: `polygon(-${shadowSize} -${shadowSize}, calc(100% + ${shadowSize}) -${shadowSize}, calc(100% + ${shadowSize}) calc(100% + ${shadowSize}))`,
    }
  },
  title: {
    padding: 0,
    margin: 0,
    color: '#222B56',
    fontSize: 14,
    fontWeight: 500,
  },
  text: {
    padding: 0,
    margin: 0,
    color: '#757575',
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center'
  },
  disabled: {
    pointerEvents: 'none',
    backgroundColor: '#D0D0D0',
  },
  link: {
    color: '#538FE9',
  }
}));

const CheckIcon = ({ isActive = false }) => {
  return <img style={{ width: 15, height: 15, marginRight: 8 }} src={isActive ? CheckActiveIcon : CheckInActiveIcon} />;
};

export const ChangePasswordForm: React.FC<any> = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopper, setShowPopper] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [apiError, setApiError] = useState('');
  const [currentNewPassword, setCurrentNewPassword] = useState();
  const query = useQuery();
  const type = useMemo(() => query.get('type'), [query]);
  const { authState } = useAuth();

  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    logAmplitudeEvent('Screen View: Change Password');
  }, []);

  const handleSubmit = async (values) => {
    try {
      setApiError('');
      const res = await changePassword({
        newPassword: values.password,
        pin: authState.changePasswordPinCode,
      });
      if (res.status === 200 || res.status === 204) {
        if (type === 'unregistered') {
          history.push(REGISTER_USER);
        } else {
          history.push(SETTINGS);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        setApiError('Password is incorrect. Please try again.');
      } else if (error.response.status === 400) {
        setApiError((error?.response?.data?.Errors?.[0]?.message || error?.response?.data?.Message || error.message) || 'Bad request');
      } else {
        setApiError(error?.response?.data?.Errors?.[0]?.message || error?.response?.data?.Message || error.message || 'Unhandled error encountered.');
      }
      logAmplitudeEvent('View: Error Message', {
        Screen: 'Change Password',
        Message: error?.response?.data?.Errors?.[0]?.message || error?.response?.data?.Message || error.message || 'unknown error',
      });
    }
  };

  return (
    <RegisterPageContainer>
      <TitleContent title='Change password' subTitle='Please enter your new password below' />
      <Formik
        validateOnChange
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .required()
            .test("isValidPass", "Please use a stronger password", (value) => {
              const validateMinLength = value?.length >= 8;
              const validateHasUpperCase = /[A-Z]/.test(value);
              const validateHasLowerCase = value && /[a-z]/.test(value);
              const validateHasSymbol = /[!@#$^%&*]/.test(value);
              let validConditions = 0;
              const numberOfMustBeValidConditions = 4;
              const conditions = [validateMinLength, validateHasLowerCase, validateHasUpperCase, validateHasSymbol];
              conditions.forEach((condition) =>
                condition ? validConditions++ : null
              );
              setIsMinLength(validateMinLength);
              setHasUpperCase(validateHasUpperCase);
              setHasLowerCase(validateHasLowerCase);
              setHasSymbol(validateHasSymbol);
              if (validConditions >= numberOfMustBeValidConditions) {
                return true;
              }
              return false;
            })
            .label('New password'),
            confirmPassword: Yup.string()
            .required()
            .test("isValidPass", "Please use a stronger password.", (value) => {
              const validateMinLength = value?.length >= 8;
              const validateHasUpperCase = /[A-Z]/.test(value);
              const validateHasLowerCase = value && /[a-z]/.test(value);
              const validateHasSymbol = /[!@#$^%&*]/.test(value);
              let validConditions = 0;
              const numberOfMustBeValidConditions = 4;
              const conditions = [validateMinLength, validateHasLowerCase, validateHasUpperCase, validateHasSymbol];
              conditions.forEach((condition) =>
                condition ? validConditions++ : null
              );
              setIsMinLength(validateMinLength);
              setHasUpperCase(validateHasUpperCase);
              setHasLowerCase(validateHasLowerCase);
              setHasSymbol(validateHasSymbol);
              if (validConditions >= numberOfMustBeValidConditions) {
                return true;
              }
              return false;
            })
            .test("isSamePassword", "Password do not match.", (value) => {
              return currentNewPassword !== undefined && value !== currentNewPassword ? false : true;
            })
            .label('Confirm new password'),
        })}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
      >
        {(props) => {
          const disabledButton = !props.values.password || !props.values.confirmPassword || !_.isEmpty(props.errors);
          return (
            <Form className={`${loginFormStyles.form} ${loginStyles.form}`}  >
              <fieldset>
                <CustomTextInput
                  label="password"
                  name="password"
                  type="password"
                  id={nextId('id-')}
                  placeholder="New password"
                  showIcon={true}
                  onFocus={e => {
                    setAnchorEl(e.currentTarget);
                    setShowPopper(true);
                    props.setFieldTouched('password', false);
                  }}
                  onBlur={(e) => {
                    setCurrentNewPassword(e.currentTarget.value);
                    setAnchorEl(null);
                    setShowPopper(false);
                    props.setFieldTouched('password');
                  }}
                />
                <ErrorBox show={!!apiError} title={apiError} />
                <Popper
                  open={showPopper && !!props.errors.password}
                  anchorEl={anchorEl}
                  className={classes.popper}
                  placement="bottom"
                  modifiers={{
                    flip: {
                      enabled: true,
                    },
                  }}
                >
                  <div className={classes.box}>
                    <p className={classes.title}>
                      Your password must have:
                    </p>
                    <p className={classes.text}>
                      <CheckIcon isActive={isMinLength} />
                      at least 8 characters
                    </p>
                    <p className={classes.text}>
                      <CheckIcon isActive={hasUpperCase} />
                      at least 1 capital letter
                    </p>
                    <p className={classes.text}>
                      <CheckIcon isActive={hasLowerCase} />
                      at least 1 lowercase letter
                    </p>
                    <p className={classes.text}>
                      <CheckIcon isActive={hasSymbol} />
                      at least 1 special character
                    </p>
                  </div>
                </Popper>
                <CustomTextInput
                  label="Confirm new password"
                  name="confirmPassword"
                  type="password"
                  id={nextId('id-')}
                  placeholder="Confirm new password"
                  showIcon={true}
                  onFocus={e => {
                    props.setFieldTouched('confirmPassword', false);
                  }}
                  onBlur={() => {
                    props.setFieldTouched('confirmPassword');
                  }}
                />
                <SubmitButton disabled={disabledButton} isSubmitting={props.isSubmitting}>
                  Submit
                </SubmitButton>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </RegisterPageContainer>
  );
};
