import React from 'react';
import DigitalFitnessBanner from './DigitalFitnessBanner';
import studioListStyles from '../styles/explore/StudioList.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import Loading from '../shared/Loading';
import pagination from '../styles/Pagination.module.css';
import paginationStyles from '../styles/pagination/styles.module.css';
import styles from '../styles/styles.module.css';
import FitOnFitnessBanner from './FitOnFitnessBanner';
import { useGlobal } from '../contexts/GlobalContext';
import { useAuth } from '../contexts/AuthContext';
import { logAmplitudeEvent } from '../api/integration';
import { useSearch } from '../contexts/SearchContext';
import StudioCard from './StudioCard';

export default function Studios() {
  const { authState } = useAuth();
  const { studioList, studioListStore, page, setPage, totalPages, studiosLoading } = useSearch();
  const { isDigitalFitOnSurfacing } = useGlobal();
  const isSubscribeToFitOn = authState?.user?.subscriptions?.some((item) => item.provider_name === 'FITON');
  const showFitOn = isDigitalFitOnSurfacing && !isSubscribeToFitOn;

  const handlePage = (pageNum) => {
    if (pageNum == page) return;

    console.log('going to page: ', pageNum);
    setPage(pageNum);
  };

  const displayStudioList = page === 1 ? studioList : studioListStore[page];

  return (
    <div className={studioListStyles.studios}>
      <div role="status" aria-live="polite" aria-atomic="true" className={styles.container}>
        <span>{displayStudioList?.length ?? -1} Studio Result</span>
      </div>
      {studiosLoading ? (
        <Loading />
      ) : (
        <>
          {showFitOn ? <FitOnFitnessBanner /> : <DigitalFitnessBanner />}
          <div className={studioListStyles.studios} data-cy="location-studio-results">
            {displayStudioList?.map((x, i) => (
              <StudioCard key={i} studio={x} source="Explore" />
            ))}
          </div>
          <div className={studioListStyles.studioCTA}>
            <div className={studioListStyles.studioCTAInner}>
              <h3>
                <span>Your favorite studio should be here</span>
              </h3>
              <a
                rel="noopener noreferrer"
                onClick={() => {
                  logAmplitudeEvent('Nominate: Studio', { Source: 'Explore' });
                }}
                className={`${studioListStyles.studioCTAButton} ${btnDefaultStyles['secondary-button']} ${btnDefaultStyles.button} focus_outline`}
                target="_blank"
                href="https://pulse.peerfit.com/nominate-a-studio/"
              >
                <span>Nominate Studio</span>
              </a>
            </div>
          </div>

          {totalPages > 1 && (
            <div style={{ paddingBottom: 20 }} className={`${pagination.container} ${paginationStyles.container}`}>
              {page > 1 ? (
                <div
                  onClick={() => handlePage(page - 1)}
                  className={`${pagination.previous} ${paginationStyles.action} ${paginationStyles.number} ${paginationStyles.ellipsis}`}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    className={`${paginationStyles.icon_left} ${paginationStyles.icon}`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.4244649,19.2868077 C16.7653329,19.6293551 16.7639715,20.1833728 16.4214241,20.5242408 C16.0788767,20.8651089 15.524859,20.8637475 15.183991,20.5212001 L7.25476309,12.5529074 C6.9164664,12.2129441 6.91488398,11.6640093 7.25121504,11.3221012 L15.1804429,3.26139014 C15.5193304,2.9168832 16.073331,2.91232773 16.417838,3.2512152 C16.7623449,3.59010267 16.7669004,4.1441033 16.4280129,4.48861023 L9.10587647,11.932163 L16.4244649,19.2868077 Z"></path>
                  </svg>
                  <span>Prev</span>
                </div>
              ) : (
                <div className={`${paginationStyles.action} ${paginationStyles.number} ${paginationStyles.ellipsis}`}></div>
              )}

              {[...Array(totalPages)].map((e, i) => {
                const loop = i + 1;
                // console.log(`LOOP: ${loop}, page: ${page}`)

                // first and last number.
                if (loop == 1 || loop == totalPages)
                  return (
                    <div
                      key={i}
                      onClick={() => handlePage(loop)}
                      className={`${page == loop && pagination.current} ${pagination.number} ${paginationStyles.number} ${paginationStyles.ellipsis}`}
                    >
                      {loop}
                    </div>
                  );

                // first 3 & last 3 numbers.
                if (page <= 3 && loop <= 3)
                  return (
                    <div
                      key={i}
                      onClick={() => handlePage(loop)}
                      className={`${page == loop && pagination.current} ${pagination.number} ${paginationStyles.number} ${paginationStyles.ellipsis}`}
                    >
                      {loop}
                    </div>
                  );
                if (page >= 8 && loop >= 7)
                  return (
                    <div
                      key={i}
                      onClick={() => handlePage(loop)}
                      className={`${page == loop && pagination.current} ${pagination.number} ${paginationStyles.number} ${paginationStyles.ellipsis}`}
                    >
                      {loop}
                    </div>
                  );

                // middle numbers (surrounded by ellipsis)
                if (loop <= page + 1 && loop >= page - 1) {
                  // console.log("loop page:", i + 1, page)
                  return (
                    <div
                      key={i}
                      onClick={() => handlePage(loop)}
                      className={`${page == loop && pagination.current} ${pagination.number} ${paginationStyles.number} ${paginationStyles.ellipsis}`}
                    >
                      {loop}
                    </div>
                  );
                }

                if (loop == 2 && page >= 4) {
                  // console.log("left ellipsis")
                  return (
                    <div key={i} className={paginationStyles.ellipsis}>
                      ...
                    </div>
                  );
                }
                if (loop == totalPages - 1 && page <= totalPages - 3) {
                  // console.log("right ellipsis")
                  return (
                    <div key={i} className={paginationStyles.ellipsis}>
                      ...
                    </div>
                  );
                }
              })}

              {page < totalPages ? (
                <div
                  onClick={() => handlePage(page + 1)}
                  className={`${pagination.next} ${paginationStyles.action} ${paginationStyles.number} ${paginationStyles.ellipsis}`}
                >
                  <span>Next</span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    className={`${paginationStyles.icon_right} ${paginationStyles.icon}`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7.25476326,19.2868077 L14.5733516,11.932163 L7.2512152,4.48861023 C6.91232773,4.1441033 6.9168832,3.59010267 7.26139014,3.2512152 C7.60589707,2.91232773 8.1598977,2.9168832 8.49878517,3.26139014 L16.4280131,11.3221012 C16.7643441,11.6640093 16.7627617,12.2129441 16.424465,12.5529074 L8.49523712,20.5212001 C8.15436907,20.8637475 7.60035138,20.8651089 7.25780398,20.5242408 C6.91525659,20.1833728 6.91389521,19.6293551 7.25476326,19.2868077 Z"></path>
                  </svg>
                </div>
              ) : (
                <div className={`${paginationStyles.action} ${paginationStyles.number} ${paginationStyles.ellipsis}`}></div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
