import axios from 'axios';
import { ROOT, CHANGE_PASSWORD } from '../navigation/CONSTANTS';
import { clearPFToken } from '../utilities/TokenHelper';
import authApi from './auth';

const getApi = async (isPublic) => {
  const siteSource = window.location.hostname.includes(
    process.env.REACT_APP_MOVE_HOSTNAME
  )
    ? 'move'
    : 'core';

  const accessToken = isPublic ? undefined : await authApi.getAccessToken();
  // console.log("ACCESS_TOKEN:", accessToken);
  const instance = axios.create({
    // baseURL: 'https://gateway-api.peerfit.com/api',
    baseURL: process.env.REACT_APP_PF_API_URL + '/api',
    // baseURL: 'https://peerfitapi20201220170918.azurewebsites.net/api/pf',
    // baseURL: 'https://localhost:44306/api',
    headers: {
      'Access-Control-Allow-Origin': '*',
      sitesource: siteSource,
      clientType: 'web',
    },
  });

  if (accessToken) {
    instance.defaults.headers.common['authorization'] = `Bearer ${accessToken}`;
  }

  if (!isPublic) {
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401 && window.location.href.indexOf(CHANGE_PASSWORD) === -1) {
          clearPFToken();
          window.location.href = ROOT;
        }
        return Promise.reject(error);
      }
    );
  }
  return instance;
};

const getApiAccessToken = async () => await authApi.getAccessToken();

export default {
  getApi,
  getApiAccessToken,
};
