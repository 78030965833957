import { OpenInNew } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getStudioLogInfo, logAmplitudeEvent, logBrazeEvent } from '../api/integration';
import studioApi from '../api/studio';
import mbLogo from '../Assets/mbLogo.png';
import useQuery from '../shared/hooks/useQuery';
import useModal from '../shared/Modal/useModal';
import btnDefaultStyles from '../styles/button/Default.module.css';
import dateSelectorStyles from '../styles/DateSelector.module.css';
import loadingStyles from '../styles/Loading.module.css';
import mbScheduleStyles from '../styles/MindbodySchedule.module.css';
import scheduleStyles from '../styles/Schedule.module.css';
import { GetDate } from '../utilities/date';
import Reservation from './Reservation';
import SessionCard from './SessionCard/SessionCard';
import { useSite } from '../contexts/SiteContext';
import { postMessageToRN } from '../utilities/rn';

const BookingServiceValue = {
  10: '10,Non-Integrated',
  20: '20,Mindbody Online',
  30: '30,Mindbody Affiliate',
  40: '40,Zendesk Manual Booking',
  50: '50,In-Shape',
  60: '60,LA Fitness',
  61: '61,Anytime Fitness',
  90: '90,Pen test',
};

export default function Schedule({ studio, onGetSessionError = () => {}, isRefetching }) {
  const { isShowing, toggle } = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [sessionsByDate, setSessionsByDate] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [classes, setClasses] = useState(null);
  const [noData, setNoData] = useState(true);

  const [currentDate, setCurrentDate] = useState('');
  const query = useQuery();
  const history = useHistory();
  const { isMobileApp } = useSite();

  const handleDateSelect = (date) => {
    if (date) {
      if (sessionsByDate.findIndex((x) => x.date == date) >= 0) {
        history.replace('?current_date=' + date);
        setCurrentDate(date);
      }
    }
  };

  useEffect(() => {
    if (currentDate) {
      const index = sessionsByDate.findIndex((x) => x.date == currentDate);
      let startIndex = 0;
      if (sessionsByDate.length > 5) startIndex = index < 2 ? 0 : index > sessionsByDate.length - 3 ? sessionsByDate.length - 5 : index - 2;
      setStartIndex(startIndex);
      setSelectedIndex(index);
    }
  }, [currentDate]);

  useEffect(() => {
    if (studio) {
      studioApi.getClasses(studio.id).then((data) => {
        setClasses(data.results);
      });
    }

    // ONLY GET SESSIONS IF STUDIO.IS_MINDBODY && STUDIO.HAS_SESSIONS.
    studioApi.getSessionsByStudioId(studio.id).then((data) => {
      if (data.has_error === true) {
        onGetSessionError(data.error_code);
      } else if (data.has_error === false) {
        onGetSessionError(null);
      } else {
        onGetSessionError('');
        const grouped = data.reduce((result, currentValue) => {
          const dateKey = format(GetDate(currentValue.start_datetime), 'yyyy-MM-dd');
          result[dateKey] = [...(result[dateKey] || []), currentValue];
          return result;
        }, {});

        const groupArray = Object.keys(grouped).map((date) => {
          return {
            date,
            dateString: format(GetDate(grouped[date][0].start_datetime), 'E, LLL dd'),
            sessions: grouped[date].sort((a, b) => (a.start_datetime > b.start_datetime ? 1 : -1)),
          };
        });

        const sorted = groupArray.sort((a, b) => (a.date > b.date ? 1 : -1));
        setSessionsByDate(sorted);
        setNoData(false);
      }
      setIsLoading(false);
    });
  }, [studio]);

  useEffect(() => {
    if (sessionsByDate && sessionsByDate.length > 0) {
      const currDate = query.get('current_date') || '';
      const index = sessionsByDate.findIndex((x) => x.date == currDate);

      console.log('setting currentDate to ', sessionsByDate[index > 0 ? index : 0].date);
      setCurrentDate(sessionsByDate[index > 0 ? index : 0].date);
    }
  }, [sessionsByDate]);

  const currentSessions = sessionsByDate.slice(startIndex, startIndex + 5);
  const sessionsForDisplay = isMobileApp
    ? selectedIndex === 0
      ? currentSessions.slice(0, 3)
      : selectedIndex === currentSessions.length - 1
      ? currentSessions.slice(currentSessions.length - 3)
      : currentSessions.slice(selectedIndex - 1, selectedIndex + 2)
    : currentSessions;

  const selectedDate = sessionsByDate[selectedIndex];

  return (
    <>
      <div>
        <div>
          {studio.is_mindbody ? (
            <>
              {/* MINDBODY, HAS SESSIONS. */}
              <h3>
                <span className={scheduleStyles.title}>Schedule</span>
              </h3>
              <div className={mbScheduleStyles.container}>
                {isLoading || isRefetching ? (
                  <div className={loadingStyles.spinner}></div>
                ) : sessionsByDate.length > 0 ? (
                  <div data-cy="mbo-schedule">
                    <div className={`${dateSelectorStyles.dateSelector} ${dateSelectorStyles.strip}`}>
                      <button
                        onClick={() => {
                          handleDateSelect(sessionsByDate[selectedIndex - 1]?.date);
                        }}
                        className={`${dateSelectorStyles.arrowContainerLeft} ${dateSelectorStyles.arrowContainer}`}
                        data-cy="dateselector-goback"
                        aria-label={`Show Schedule for ${sessionsByDate[selectedIndex - 1]?.dateString ?? ''}`}
                        style={{ cursor: 'initial' }}
                      >
                        <svg aria-hidden="true" focusable="false" className={dateSelectorStyles.arrow} width="24" height="24" viewBox="0 0 24 24">
                          <path d="M16.4244649,19.2868077 C16.7653329,19.6293551 16.7639715,20.1833728 16.4214241,20.5242408 C16.0788767,20.8651089 15.524859,20.8637475 15.183991,20.5212001 L7.25476309,12.5529074 C6.9164664,12.2129441 6.91488398,11.6640093 7.25121504,11.3221012 L15.1804429,3.26139014 C15.5193304,2.9168832 16.073331,2.91232773 16.417838,3.2512152 C16.7623449,3.59010267 16.7669004,4.1441033 16.4280129,4.48861023 L9.10587647,11.932163 L16.4244649,19.2868077 Z"></path>
                        </svg>
                      </button>
                      <div className={dateSelectorStyles.datesContainer} data-cy="dateselector-container">
                        {sessionsForDisplay.map((d, idx) => (
                          <button
                            key={idx + startIndex}
                            onClick={() => {
                              handleDateSelect(d.date);
                            }}
                            className={`${selectedDate.dateString === d.dateString ? dateSelectorStyles.currentDateOption : ''} ${
                              dateSelectorStyles.dateOption
                            }`}
                            data-cy="dateselector-day"
                            aria-label={`Show Schedule for ${d.dateString}`}
                          >
                            <span>{d.dateString}</span>
                          </button>
                        ))}
                      </div>
                      <button
                        onClick={() => {
                          handleDateSelect(sessionsByDate[selectedIndex + 1]?.date);
                        }}
                        className={`${dateSelectorStyles.arrowContainerRight} ${dateSelectorStyles.arrowContainer}`}
                        data-cy="dateselector-goforward"
                        aria-label={`Show Schedule for ${sessionsByDate[selectedIndex + 1]?.dateString ?? ''}`}
                        style={{ cursor: 'initial' }}
                      >
                        <svg aria-hidden="true" focusable="false" className={dateSelectorStyles.arrow} width="24" height="24" viewBox="0 0 24 24">
                          <path d="M7.25476326,19.2868077 L14.5733516,11.932163 L7.2512152,4.48861023 C6.91232773,4.1441033 6.9168832,3.59010267 7.26139014,3.2512152 C7.60589707,2.91232773 8.1598977,2.9168832 8.49878517,3.26139014 L16.4280131,11.3221012 C16.7643441,11.6640093 16.7627617,12.2129441 16.424465,12.5529074 L8.49523712,20.5212001 C8.15436907,20.8637475 7.60035138,20.8651089 7.25780398,20.5242408 C6.91525659,20.1833728 6.91389521,19.6293551 7.25476326,19.2868077 Z"></path>
                        </svg>
                      </button>
                    </div>
                    <div className="table-wrapper">
                      <table cellSpacing="0" cellPadding="0" className={mbScheduleStyles.desktopSchedule} style={{ tableLayout: 'fixed', width: '100%' }}>
                        <thead>
                          <tr>
                            <th>
                              <span>Time</span>
                            </th>
                            <th>
                              <span>Class Name</span>
                            </th>
                            <th>
                              <span>Capacity</span>
                            </th>
                            <th>
                              <span>Total Attending</span>
                            </th>
                            <th>
                              <span>Credits</span>
                            </th>
                            <th>
                              <span>Reserve</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sessionsByDate[selectedIndex].sessions.map((session) => (
                            <SessionCard key={session.id} session={session} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  !noData && (
                    <div className={`${mbScheduleStyles.noSessions} ${mbScheduleStyles.container}`}>
                      <h5>
                        <span>
                          This Fitness partner is offline, please find another partner or contact us at{' '}
                          <a href="mailto:support@peerfit.com">support@peerfit.com</a> for assistance in reserving your class.
                        </span>
                      </h5>
                    </div>
                  )
                )}
                {!noData && <img src={mbLogo} className={mbScheduleStyles.mblogo} alt="Powered By MINDBODY" />}
              </div>
            </>
          ) : (
            <>
              {/* NOT MINDBODY, HAS CLASSES. */}
              {classes?.length > 0 ? (
                <>
                  {/* VIEW SCHEDULE BUTTON */}
                  <h3>
                    <span className={scheduleStyles.title}>Schedule</span>
                  </h3>
                  {studio?.schedule && (
                    <div className={scheduleStyles.section}>
                      <button
                        // This should open a banner at the top with link to new tab.
                        // You'll be taken offsite to view this studio's schedule. Return to Peerfit to reserve a class below. Got It.
                        onClick={() => {
                          if (isMobileApp) {
                            console.log('studio.sched', studio.schedule);
                            postMessageToRN({ key: 'SHOW_GO_BACK' });
                            window.open(/^http|^https/.test(studio.schedule) ? studio.schedule : `https://${studio.schedule}`, '_self');
                          } else {
                            window.open(studio.schedule, '_blank');
                          }
                          const studioInfo = {
                            ...getStudioLogInfo(studio),
                            URL: studio.schedule,
                            'Studio.BookingService': BookingServiceValue[studio.booking_service],
                          };
                          logBrazeEvent('Studio: View Schedule', studioInfo);
                          logAmplitudeEvent('Studio: View Schedule', studioInfo);
                        }}
                        className={`${scheduleStyles.button} ${btnDefaultStyles.button} focus_outline`}
                      >
                        <span>
                          View Schedule <OpenInNew />
                        </span>
                      </button>
                    </div>
                  )}

                  {studio.booking_service == '60' || studio.booking_service == '61' ? '' : <Reservation studio={studio} classes={classes} />}
                </>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
