import React from 'react';
import { ChangePasswordForm } from '../components/Authentication/ChangePasswordForm';
import { useAuth } from '../contexts/AuthContext';
import { Redirect } from 'react-router-dom';
import { SETTINGS } from '../navigation/CONSTANTS';

export const ChangePassword = () => {
  const { authState } = useAuth();

  if (!authState.changePasswordPinCode) {
    return <Redirect to={SETTINGS} />;
  }

  return <ChangePasswordForm />;
};
