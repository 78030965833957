import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useSearch } from '../contexts/SearchContext';
import useQuery from '../shared/hooks/useQuery';
import useWindowSize, { _LG, _MD } from '../shared/hooks/useWindowSize';

import Map from './Map';
import Sessions from './Sessions';
import Studios from './Studios';
import { SearchMenu } from './SearchMenu';
import SimpleBar from 'simplebar-react';

import exploreStyles from '../styles/Explore.module.css';
import indexStyles from '../styles/explore/index.module.css';
import exploreMapStyles from '../styles/explore/Map.module.css';
import pageStyles from '../styles/explore/Page.module.css';
import studioListStyles from '../styles/explore/StudioList.module.css';
import navStyles from '../styles/Nav.module.css';
import '../styles/scroll.css';
import '../../node_modules/simplebar-react/dist/simplebar.min.css';
import { logAmplitudeEvent, logBrazeEvent } from '../api/integration';
import { useSite } from '../contexts/SiteContext';

export const Explore = () => {
  const {
    searchState,
    initSearch,
    setCurrentTab,
    LoadRegions,
    showMap,
    selectedPlace,
  } = useSearch();
  const query = useQuery();
  const { authState } = useAuth();

  // PAGE LOAD
  const studioMatch = useRouteMatch('/explore/studios');
  const { isMobileApp } = useSite();

  useEffect(() => {
    LoadRegions();

    const tabIndex = studioMatch && studioMatch.isExact ? 0 : 1; // on load set the correct tab.

    let centerParam = []; // x2

    const homeCity = authState?.user?.home_city;
    const boundsParam = searchState.bounds?.length
      ? searchState.bounds
      : query.getAll('bounds');
    const dateParam = searchState.date || query.get('date');
    const hasDigitalParam = searchState.has_digital || query.get('has_digital');
    const hasMembershipsParam =
      searchState.has_memberships || query.get('has_memberships');
    const placeIdParam =
      searchState.place_id || query.get('place_id') || selectedPlace.place_id;
    const searchParam = searchState.search || query.get('search');
    const sortParam = searchState.sort || query.get('sort');
    const tagsParam = searchState.tags?.length
      ? searchState.tags
      : query.getAll('tags');
    const categoriesParam = searchState.categories?.length
      ? searchState.categories
      : query.getAll('categories');
    const timeParam = searchState.time || query.get('time');
    // initial load. location search when bounds are provided from map.

    initSearch(
      tabIndex,
      centerParam,
      dateParam,
      hasDigitalParam,
      hasMembershipsParam,
      placeIdParam,
      searchParam,
      sortParam,
      tagsParam,
      categoriesParam,
      timeParam,
      homeCity,
      boundsParam
    );
  }, [authState?.user?.home_city, authState?.user?.id]);

  const handleTabChange = (tab) => setCurrentTab(tab);

  useEffect(() => {
    logBrazeEvent('Screen View: Explore');
    logAmplitudeEvent('Screen View: Explore');

    return function unmount() {
      document.body.style.overflow = '';
    };
  }, []);

  const [showFilter, setShowFilter] = useState(false);
  const size = useWindowSize();
  useEffect(() => {
    if (size[3] <= _MD && (showMap || showFilter)) {
      document.body.style.overflow = ''; // scrollable
    } else {
      window.scrollTo(0, 0);
    }
  }, [size, showMap, showFilter]);

  return (
    <div
      className={pageStyles.container}
      style={{
        height: 'auto',
        position: 'relative',
        minHeight: size[1] - (86 + 56 + 85),
      }}
    >
      <div id="explore-tab" className={exploreStyles.container}>
        <div
          className={size[3] < _LG ? '' : exploreStyles.desktopSidebar}
          style={{ height: size[3] < _LG ? 'auto' : size[1] - 85 }}
        >
          <SearchMenu setShowFilter={setShowFilter} />

          {/* TABS */}
          <div
            tabIndex="-1"
            role="tablist"
            data-cy="explore-nav-tabs"
            className={`${navStyles.container} ${indexStyles.container}`}
            tabs="[object Object],[object Object]"
          >
            <button
              onClick={() => handleTabChange(0)}
              className={`${
                searchState.current_tab === 0 ? navStyles.activeLink : ''
              } ${navStyles.link} ${indexStyles.tab} focus_outline`}
              id="nav-tab-0"
              role="tab"
              aria-posinset="1"
              aria-setsize="2"
              aria-selected="true"
              aria-expanded="true"
              aria-controls="explore-tab"
            >
              <span>Studios</span>
            </button>
            <button
              onClick={() => handleTabChange(1)}
              className={`${
                searchState.current_tab === 1 ? navStyles.activeLink : ''
              } ${navStyles.link} ${indexStyles.tab} focus_outline`}
              id="nav-tab-1"
              role="tab"
              aria-posinset="2"
              aria-setsize="2"
              aria-selected="false"
              aria-expanded="false"
              aria-controls="explore-tab"
            >
              <span>Classes</span>
            </button>
          </div>

          {size[3] >= _LG ? (
            <div
              className={`scrollarea ${exploreStyles.scrollingContainer}`}
              style={{ height: size[1] - 85 - 55 - 48 - 30 }}
            >
              <SimpleBar style={{ height: '100%' }}>
                {searchState.current_tab === 0 && <Studios />}
                {searchState.current_tab === 1 && <Sessions />}
              </SimpleBar>
            </div>
          ) : showMap ? (
            ''
          ) : (
            !isMobileApp && (
              <>
                <div
                  className={`${exploreStyles.listContainer} ${studioListStyles.grid}`}
                  style={{ height: size[1] - 85 }}
                >
                  <SimpleBar style={{ height: '100%' }}>
                    {searchState.current_tab === 0 && <Studios />}
                    {searchState.current_tab === 1 && <Sessions />}
                  </SimpleBar>
                </div>
              </>
            )
          )}
        </div>

        {/* MAP */}
        <div
          className={`${
            size[3] <= _MD && !showMap && !isMobileApp
              ? exploreStyles.hidden_map
              : ''
          } ${exploreStyles.map} ${exploreMapStyles.container}`}
          style={{ height: size[1] - 85 }}
        >
          <Map />
        </div>
      </div>
    </div>
  );
};
