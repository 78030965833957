import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

export const useGoBackAccount = () => {
  const authContext = useContext(AuthContext);
  const user = authContext?.authState?.user;
  const history = useHistory();
  const backToAccountPage = () => {
    history.replace(`/user/${user?.id}/`);
  };
  return backToAccountPage
};
