import React, { useState } from 'react';
import classnames from 'classnames';

import searchIcon from './search.svg';
import styles from './index.module.css';

interface SearchItem {
  id: number;
  key: string;
  title?: string;
  address?: string;
  placeId?: string;
}

interface Props {
  savedSearches: SearchItem[];
  onSelect: (item: SearchItem) => void;
}

// export const DEFAULT_LIST: SearchItem[] = [
//   { id: 1, title: 'Studios', address: 'New york, NY 10036' },
//   { id: 2, title: 'Gym', address: 'New york, NY' },
//   { id: 3, title: 'Meditation', address: 'Nearby' },
//   { id: 4, title: 'Premium', address: 'San Diego, CA' },
//   { id: 5, title: 'Personal coaches', address: 'Denver, CO' },
// ];

export const RecentSearches: React.FC<Props> = ({ savedSearches = [], onSelect }) => {
  if (savedSearches.length === 0) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.label}>Recent searches</div>
      <div className={styles.list}>
        {[...savedSearches].reverse().map((item) => (
          <div key={item.key} className={styles.item} onClick={() => onSelect(item)}>
            <img src={searchIcon} alt="" className={styles.icon} />
            <div className={styles.title}>{item.title}</div>
            {item.title && <div className={styles.divider}></div>}
            <div className={styles.address}>{item.address || 'Nearby'}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
