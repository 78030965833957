import * as msal from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: `${
      window.location.hostname.includes(process.env.REACT_APP_CORE_HOSTNAME)
        ? process.env.REACT_APP_CORE_B2C_CLIENT_ID
        : process.env.REACT_APP_MOVE_B2C_CLIENT_ID
    }`,
    redirectUri: `${
      window.location.hostname.includes(process.env.REACT_APP_CORE_HOSTNAME)
        ? process.env.REACT_APP_CORE_URL
        : process.env.REACT_APP_MOVE_URL
    }/discover`,
    postLogoutRedirectUri: `${
      window.location.hostname.includes(process.env.REACT_APP_CORE_HOSTNAME)
        ? process.env.REACT_APP_CORE_URL
        : process.env.REACT_APP_MOVE_URL
    }`,
    knownAuthorities: [`${process.env.REACT_APP_B2C}.b2clogin.com`],
    authority: `https://${process.env.REACT_APP_B2C}.b2clogin.com/tfp/${process.env.REACT_APP_B2C}.onmicrosoft.com/B2C_1_SignIn_Pf`,
    scopes: [
      `https://${process.env.REACT_APP_B2C}.onmicrosoft.com/${process.env.REACT_APP_B2C_PF_GATEWAY_CLIENT_ID}/user_impersonation`,
    ],
    navigateToLoginRequestUrl: false,
  },
  policies: {
    signInAuthority: `https://${process.env.REACT_APP_B2C}.b2clogin.com/tfp/${process.env.REACT_APP_B2C}.onmicrosoft.com/B2C_1_SignIn_Pf`,
    signUpSponsoredAuthority: `https://${process.env.REACT_APP_B2C}.b2clogin.com/tfp/${process.env.REACT_APP_B2C}.onmicrosoft.com/B2C_1_SignUp_Sponsored`,
    signUpPmidAuthority: `https://${process.env.REACT_APP_B2C}.b2clogin.com/tfp/${process.env.REACT_APP_B2C}.onmicrosoft.com/B2C_1_SignUp_Sponsored_Pmid`,
    signUpConsumerAuthority: `https://${process.env.REACT_APP_B2C}.b2clogin.com/tfp/${process.env.REACT_APP_B2C}.onmicrosoft.com/B2C_1_SignUp_Consumer`,
    passwordResetAuthority: `https://${process.env.REACT_APP_B2C}.b2clogin.com/tfp/${process.env.REACT_APP_B2C}.onmicrosoft.com/B2C_1_Password_Reset`,
    signUpAetnaAuthority: `https://${process.env.REACT_APP_B2C}.b2clogin.com/tfp/${process.env.REACT_APP_B2C}.onmicrosoft.com/B2C_1_Aetna_Registration`,
  },
};

export const getPublicClient = () => {
  return new msal.PublicClientApplication({ auth: { ...msalConfig.auth } });
};

export const loginRequest = {
  authority: msalConfig.policies.signInAuthority,
  scopes: msalConfig.auth.scopes,
  redirectUri: `${
    window.location.hostname.includes(process.env.REACT_APP_CORE_HOSTNAME)
      ? process.env.REACT_APP_CORE_URL
      : process.env.REACT_APP_MOVE_URL
  }/discover`,
};

export const registerConsumerRequest = {
  authority: msalConfig.policies.signUpConsumerAuthority,
  scopes: msalConfig.auth.scopes,
  redirectUri: `${
    window.location.hostname.includes(process.env.REACT_APP_CORE_HOSTNAME)
      ? process.env.REACT_APP_CORE_URL
      : process.env.REACT_APP_MOVE_URL
  }/discover`,
};

export const registerSponsoredRequest = {
  authority: msalConfig.policies.signUpSponsoredAuthority,
  scopes: msalConfig.auth.scopes,
};

export const registerPmidRequest = {
  authority: msalConfig.policies.signUpPmidAuthority,
  scopes: msalConfig.auth.scopes,
};

export const passwordResetRequest = {
  authority: msalConfig.policies.passwordResetAuthority,
  scopes: msalConfig.auth.scopes,
};

export const registerAetnaRequest = {
  authority: msalConfig.policies.signUpAetnaAuthority,
  scopes: msalConfig.auth.scopes,
};
