import { initialAuthState } from './initialAuthState';

export const SET_LOADING = 'SET_LOADING';
export const SET_LOGGING_OUT = 'SET_LOGGING_OUT';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SET_REGISTER_USER = 'SET_REGISTER_USER';
export const SET_REGISTERING = 'SET_REGISTERING';
export const SET_CHANGE_PASSWORD_PIN_CODE = 'SET_CHANGE_PASSWORD_PIN_CODE';

export const authReducer = (
  state,
  {
    type,
    isLoading,
    isLoggingOut,
    user,
    isAuthenticated,
    isAdmin,
    token,
    error,
    registerUser,
    changePasswordPinCode,
    registering,
  }
) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading,
      };
    case SET_LOGGING_OUT:
      return {
        ...state,
        isLoggingOut: true,
      };
    case VERIFY_SUCCESS:
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      if (!registering) {
        sessionStorage.setItem('token', token); // set sessionStorage.token here???
      }
      return {
        ...state,
        isLoading: false,
        isAdmin,
        isAuthenticated: true,
        token,
        user,
        registering,
      };
    case LOGOUT_SUCCESS:
      sessionStorage.removeItem('token'); // clear sessionStorage.token here???
      return { ...initialAuthState, isLoading: false };
    case LOGIN_FAIL:
      sessionStorage.removeItem('token');
      return { ...initialAuthState, isLoading: false };
    case SET_REGISTER_USER:
      return {
        ...state,
        registerUser: {
          ...state.registerUser,
          ...registerUser,
        },
      };
    case SET_CHANGE_PASSWORD_PIN_CODE:
      return {
        ...state,
        changePasswordPinCode,
      };
    case SET_REGISTERING:
      return {
        ...state,
        registering,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
