import { initialSearchState } from "./initialSearchState";

export const INITIALIZE = 'INITIALIZE';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';


export const SET_SEARCH = 'SET_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
// export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_PLACE_ID = 'SET_PLACE_ID'  // google maps

export const SET_SORT = 'SET_SORT';
export const SET_TAGS = 'SET_TAGS'

export const TOGGLE_HAS_MEMBERSHIPS = 'TOGGLE_HAS_MEMBERSHIPS';
export const CLEAR_HAS_MEMBERSHIPS = 'CLEAR_HAS_MEMBERSHIPS';
export const CLEAR_HAS_DIGITAL = 'CLEAR_HAS_DIGITAL';
export const SET_STUDIO_FILTERS = 'SET_STUDIO_FILTERS'; // tags, digital

export const SET_SESSION_FILTERS = 'SET_SESSION_FILTERS';   // tags, timeOfDay
export const SET_DATE = 'SET_DATE';

export const SET_FILTERS = 'SET_FILTERS';

export const CLEAR_TAGS = 'CLEAR_TAGS';
export const CLEAR_CATEGORIES = 'CLEAR_CATEGORIES';
export const CLEAR_TIME_OF_DAY = 'CLEAR_TIME_OF_DAY';

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_FORCE_SEARCH = 'SET_FORCE_SEARCH';

export const searchReducer = (state, {
    type,
    bounds,
    center,
    date,
    has_digital,
    has_memberships,
    place_id,
    search,
    sort,
    tags,
    categories,
    time,
    current_tab,
    shouldForceSearch,
}) => {
    // console.log("CURRENT_STATE:", state);
    // console.log(`dispatch ${type}:`, {
    //     bounds,
    //     center,
    //     date,
    //     has_digital,
    //     has_memberships,
    //     place_id,
    //     search,
    //     sort,
    //     tags,
    //     categories,
    //     time,
    //     current_tab,
    // });

    switch (type) {
        case INITIALIZE:





            return {
                ...state, current_tab,
                bounds: bounds.map(x => parseFloat(Number(x).toFixed(4))),
                center: center.map(x => parseFloat(Number(x).toFixed(4))),
                date, has_digital, has_memberships, place_id, search, sort, tags, time
            }
        case SET_MAP_BOUNDS:
            // const test = {
            //     ...state,
            //     bounds: bounds.map(x => parseFloat(Number(x).toFixed(4))),
            //     center: center.map(x => parseFloat(Number(x).toFixed(4)))
            // }
            // console.log("test:", test);
            if (bounds == null || bounds.length == 0) {

                return {
                    ...state,
                    center: center.map(x => parseFloat(Number(x).toFixed(4)))
                }
            }
            return {
                ...state,
                bounds: bounds.map(x => parseFloat(Number(x).toFixed(4))),
                center: center.map(x => parseFloat(Number(x).toFixed(4)))
            }
        case SET_CURRENT_TAB:
            return { ...state, current_tab }
        case SET_DATE:
            return { ...state, date }
        case TOGGLE_HAS_MEMBERSHIPS:
            return { ...state, has_memberships: !(state.has_memberships ?? false) }
        case CLEAR_HAS_MEMBERSHIPS:
            return { ...state, has_memberships: false }
        case CLEAR_HAS_DIGITAL:
            return { ...state, has_digital: false }
        case SET_SORT:
            return { ...state, sort };
        case SET_TAGS:
            return { ...state, tags };
        case SET_FILTERS:
            return { ...state, tags, categories, time, has_digital };
        case CLEAR_TAGS:
            return { ...state, tags: [] };
        case CLEAR_CATEGORIES:
            return { ...state, categories: [] };
        case CLEAR_TIME_OF_DAY:
            return { ...state, time: null };
        case SET_SEARCH:
            return {
                ...state,
                bounds: bounds?.length ? bounds : [],
                center: center.map(x => parseFloat(Number(x).toFixed(4))),
                search,
                place_id,
                current_tab: 0,
            };
        case CLEAR_SEARCH:
            return {
                ...state,
                search: null,
                place_id: null,
            }
        case SET_FORCE_SEARCH:
            return {
                ...state,
                shouldForceSearch,
            }
        default:
            throw new Error(`Unhandled action type: ${type}`)
    }
}
