import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import MapSection from '../components/User/MapSection';
import Info from '../components/User/Info';
import UserNavigation from '../components/User/UserNavigation';
import {
  Memberships,
  Upcoming,
  Attended,
  Favorites,
} from '../components/User/pages';
import dashboardStyles from '../styles/Dashboard.module.css';
import { useAuth } from '../contexts/AuthContext';
import { useSite } from '../contexts/SiteContext';

function User() {
  const { isMobileApp } = useSite();
  const { authState } = useAuth();
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!authState.isAuthenticated) {
    return <Redirect push to={'/'} />;
  }

  return (
    <div style={{ height: 'auto', position: 'relative', minHeight: '216px' }}>
      <div className={dashboardStyles.container}>
        <MapSection className={dashboardStyles.map} />
        <Info />
        <UserNavigation />

        <Switch>
          <Route exact path={`/user/${authState.user?.id}`}>
            {isMobileApp ? null : (
              <Redirect to={`/user/${authState.user?.id}/memberships`} />
            )}
          </Route>
          <Route
            exact
            path={`/user/${authState.user?.id}/memberships`}
            component={Memberships}
          />
          <Route
            exact
            path={`/user/${authState.user?.id}/upcoming`}
            component={Upcoming}
          />
          <Route
            exact
            path={`/user/${authState.user?.id}/upcoming/this-week`}
            component={Upcoming}
          />
          <Route
            exact
            path={`/user/${authState.user?.id}/upcoming/next-week`}
            component={Upcoming}
          />
          <Route
            exact
            path={`/user/${authState.user?.id}/attended`}
            component={Attended}
          />
          <Route
            exact
            path={`/user/${authState.user?.id}/saved`}
            component={Favorites}
          />
          {/* ELSE, NOT FOUND PAGE */}
        </Switch>
      </div>
    </div>
  );
}

export { User };
