import React from 'react';
import ReactDOM from 'react-dom';
import LinkedInTag from 'react-linkedin-insight';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import {
  AuthContextProvider,
  CacheContextProvider,
  GlobalContextProvider,
  SearchContextProvider,
  SiteContextProvider,
  TestContextProvider,
} from './contexts';
import TagManager from 'react-gtm-module';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { useSite } from './contexts/SiteContext';

const tagManagerArgs = {
  gtmId: 'GTM-NZ8VCD9',
};

TagManager.initialize(tagManagerArgs);

serviceWorkerRegistration.register();

// https://prawira.medium.com/react-conditional-import-conditional-css-import-110cc58e0da6
const MoveTheme = React.lazy(() => import('./MoveTheme'));
const ThemeSelector = ({ children }) => {
  const { isMoveSite } = useSite();
  return (
    <>
      <React.Suspense fallback={<></>}>
        {isMoveSite && <MoveTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

LinkedInTag.init(189892, 'dc', false);

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <AuthContextProvider>
          <GlobalContextProvider>
            <SiteContextProvider>
              <CacheContextProvider>
                <TestContextProvider>
                  <SearchContextProvider>
                    <ThemeSelector>
                      <App />
                    </ThemeSelector>
                  </SearchContextProvider>
                </TestContextProvider>
              </CacheContextProvider>
            </SiteContextProvider>
          </GlobalContextProvider>
        </AuthContextProvider>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
