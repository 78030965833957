import React, { useEffect, useState } from 'react';
import pageStyles from '../../../styles/user/Page.module.css';
import utilities from '../../../styles/utilities.module.css';
import emptyCardStyles from '../../../styles/user/EmptyCard.module.css';
import flexStyles from '../../../styles/Flex.module.css';
import noClassesStyles from '../../../styles/user/NoClasses.module.css';
import reservationsStyles from '../../../styles/user/Reservations.module.css';
import favoritesStyles from '../../../styles/user/Favorites.module.css';
import favoriteStyles from '../../../styles/Favorite.module.css';

import bannerStyles from '../../../styles/Banner.module.css';
import studioStyles from '../../../styles/Studio.module.css';
import linkStyles from '../../../styles/Link.module.css';
import studioTagsStyles from '../../../styles/StudioTags.module.css';
import defaultStudioImg from '../../../Assets/defaultStudio.jpg';
import userApi from '../../../api/user';
import { useAuth } from '../../../contexts/AuthContext';
import studioApi from '../../../api/studio';
import { Link } from 'react-router-dom';
import { getStudioLogInfo, logAmplitudeEvent, logBrazeEvent } from '../../../api/integration';

export const FavoriteCard = ({ studio, reload }) => {

    const { authState } = useAuth();
    const { user } = authState;

    const removeFavorite = (studioId) => {
        userApi.removeFavorite(user.id, studioId)
            .then(res => {
                reload();
            })
            
        logBrazeEvent('Studio: Remove Favorite', getStudioLogInfo(studio));
        logAmplitudeEvent('Studio: Remove Favorite', getStudioLogInfo(studio));
    }

    const [imageExists, setImageExists] = useState(true);
    const handleImageError = () => {
        console.log("IMAGE_ERROR");
        setImageExists(false);
    }

    return (
        <div className={`${flexStyles["col-sm-6"]} ${flexStyles["col-md-4"]} ${flexStyles["col-lg-3"]}`}>
            <div className={favoritesStyles.studio}>
                <Link className={`${studioStyles.link} ${linkStyles.link} focus_outline`} to={`/studio/${studio.id}?from_source=Favorites`}>
                    <div className={bannerStyles.container}
                        style={{ backgroundImage: `url(${imageExists ? studio.banner_image : defaultStudioImg})` }}>
                        <div className={bannerStyles.copy}>
                            <h3 className={bannerStyles.title}>{studio.name}</h3>
                            <span className={bannerStyles.subtitle}>
                                {studio.tags?.map((tag, i) => <span key={i} className={studioTagsStyles.tag}>{tag.name}</span>)}
                            </span>
                        </div>
                        <img hidden src={studio.banner_image ?? "undefined"} onError={handleImageError} />
                    </div>
                </Link>
                <div className={studioStyles.label}>
                    <span className={studioStyles.labelContent}>

                        <svg aria-hidden="true" focusable="false" className={studioStyles.marker} width="24" height="24" viewBox="0 0 24 24">
                            <path d="M12.4824663,21.5022166 C12.1827655,21.8346255 11.6605113,21.8322434 11.3638552,21.4971145 C11.3128307,21.4394726 11.2212742,21.3331 11.0949504,21.1816943 C10.8867969,20.9322117 10.6554839,20.6451685 10.4067705,20.3242363 C9.69732337,19.4087874 8.98819413,18.4025938 8.32542158,17.3343826 C6.9774242,15.161773 5.99473154,13.0163779 5.5343039,10.9822002 C5.34738072,10.1563701 5.25,9.35897873 5.25,8.59346828 C5.25,4.8998898 8.03765621,2.25 11.9254398,2.25 C15.8383497,2.25 18.75,4.92229102 18.75,8.59346828 C18.75,9.40683382 18.6359801,10.2574688 18.41786,11.1411741 C17.9292076,13.1209313 16.9388902,15.2083659 15.5959667,17.324485 C14.9166866,18.3948645 14.1899383,19.4043961 13.4628961,20.3238688 C13.2080439,20.6461742 12.9710351,20.9345706 12.757773,21.1853233 C12.6283892,21.3374522 12.5346505,21.4443372 12.4824663,21.5022166 Z M12.2862835,19.3935017 C12.9829664,18.5124238 13.6799432,17.544248 14.3294731,16.5207477 C15.5928203,14.5300213 16.5174463,12.5810537 16.9615647,10.7817248 C17.1519684,10.0103112 17.25,9.27895545 17.25,8.59346828 C17.25,5.7808129 15.0372856,3.75 11.9254398,3.75 C8.84955723,3.75 6.75,5.74579678 6.75,8.59346828 C6.75,9.24199064 6.83394678,9.9293797 6.99729567,10.6510581 C7.4154854,12.498628 8.33254068,14.5007248 9.60001822,16.543558 C10.2334356,17.5644567 10.9130813,18.5288157 11.5924092,19.4053997 C11.7097701,19.5568386 11.8228969,19.7001543 11.9310868,19.8348846 C12.0444249,19.6966081 12.1630814,19.5493125 12.2862835,19.3935017 Z">
                            </path>
                            <path d="M12,11.75 C10.4812169,11.75 9.25,10.5187831 9.25,9 C9.25,7.48121694 10.4812169,6.25 12,6.25 C13.5187831,6.25 14.75,7.48121694 14.75,9 C14.75,10.5187831 13.5187831,11.75 12,11.75 Z M12,10.25 C12.6903559,10.25 13.25,9.69035594 13.25,9 C13.25,8.30964406 12.6903559,7.75 12,7.75 C11.3096441,7.75 10.75,8.30964406 10.75,9 C10.75,9.69035594 11.3096441,10.25 12,10.25 Z">
                            </path>
                        </svg>
                        <span>{studio.city}, {studio.state}</span>
                    </span>
                    <button onClick={() => removeFavorite(studio.id)} className={`${studioStyles.favorite} focus_outline`} aria-pressed="true" aria-label={`Remove ${studio.name} in ${studio.city}, ${studio.state} to your saved studios`}>
                        <svg aria-hidden="true" focusable="false" className={favoriteStyles.favorited} viewBox="0 0 24 24">
                            <path d="M7.71075,4.00001328 C5.32351048,3.99460728 3,5.64026328 3,8.64401328 C3,12.1397633 10.2404741,19.986625 12,19.986625 C13.7595259,19.986625 21,12.1397633 21,8.64401328 C21,5.63576328 18.6772423,4.01294709 16.2945,4.00751328 C13.0057278,4.00001328 12.4403428,6.27840859 12,6.27840859 C11.5596572,6.27840859 11.02268,4.00751328 7.71075,4.00001328 Z">
                            </path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}
