import React, { useEffect, useState } from 'react';
import fieldInputStyles from '../../styles/input/Field.module.css';
import cardStyles from '../../styles/Card.module.css';
import pageStyles from '../../styles/account/preferences/Page.module.css';
import defaultBtnStyles from '../../styles/button/Default.module.css';
import submitStyles from '../../styles/Submit.module.css';
import loadingStyles from '../../styles/Loading.module.css';

import styles from '../../styles/styles.module.css';
import { Form, Formik, useField } from 'formik';
import { UserTags } from './UserTags';
import { TextField } from '../../shared/Formik/TextField1';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import SubmitButton from '../../shared/Formik/SubmitButton';
import userApi from '../../api/user';
import { UserCategories } from './UserCategories';
import { useSite } from '../../contexts/SiteContext/SiteContext';
import { logAmplitudeEvent } from '../../api/integration';
import { logUserProfileUpdateEvent } from '../../utilities/utilities';
import get from 'lodash-es/get';
import { Dialog } from '@material-ui/core';

/*
    Might not need separate components but if you do.
        1. WorkoutPreferences components. (or just keep it in the page.)
*/
export const Preferences = () => {
  const { verify, authState } = useAuth();
  const { user } = authState || {};
  const { userprofile } = user || {};
  const { isMoveSite } = useSite();
  const [showSaved, setShowSaved] = useState(false);

  useEffect(() => {
    logAmplitudeEvent('Screen View: Settings - Preferences', {
      Category: get(user, 'tags', []).map((item) => item.name),
    });
  }, []);

  const handleSubmit = async (values) => {
    // categories
    const userCategories = user?.categories.map((x) => x.name);
    const valCategories = values.user_categories;
    const toRemoveCategories = userCategories.filter((x) => !valCategories.includes(x));
    const toAddCategories = valCategories.filter((x) => !userCategories.includes(x));

    // tags
    const userTags = user?.tags.map((x) => x.name);
    const valTags = values.user_tags;
    const toRemove = userTags.filter((x) => !valTags.includes(x));
    const toAdd = valTags.filter((x) => !userTags.includes(x));

    let promises = [];
    if (isMoveSite) {
      console.log('toRemoveCategory:', toRemoveCategories);
      console.log('toAddCategory:', toAddCategories);
      toRemoveCategories.map((x) =>
        promises.push(
          userApi.removeCategoryPreference(user.id, x) // remove tags
        )
      );
      toAddCategories.map((x) =>
        promises.push(
          userApi.addCategoryPreference(user.id, x) // add tags
        )
      );
    } else {
      console.log('toRemove:', toRemove);
      console.log('toAdd:', toAdd);
      toRemove.map((x) =>
        promises.push(
          userApi.removePreference(user.id, x) // remove tags
        )
      );
      toAdd.map((x) =>
        promises.push(
          userApi.addPreference(user.id, x) // add tags
        )
      );
    }

    promises.push(
      // api call to patch workout song
      userApi.updateProfile({
        userId: user.id,
        workout_song: values.workout_song,
      })
    );

    await Promise.all(promises).then((x) => {
      setShowSaved(true);
      setTimeout(() => {
        setShowSaved(false);
      }, 1000);
      verify();
      logUserProfileUpdateEvent('Workout Preference', valTags.join(','));
    });
  };

  return (
    <div className={cardStyles.container}>
      <Dialog open={showSaved} maxWidth="lg" hideBackdrop={true} onClose={() => setShowSaved(false)}>
        <div
          style={{
            padding: '12px 16px',
            background: '#333',
            fontSize: 14,
            color: 'white',
          }}
        >
          Saved!
        </div>
      </Dialog>
      <h3 className={cardStyles.title}>
        <span>Workout Preferences</span>
      </h3>
      <div className={`${cardStyles.hasTitle} ${cardStyles.content}`} style={{ paddingTop: 16, paddingBottom: 16 }}>
        <Formik
          initialValues={{
            user_categories: user?.categories.map((x) => x.name),
            user_tags: user?.tags.map((x) => x.name),
            workout_song: userprofile?.workout_song === 'null' ? '' : userprofile?.workout_song ?? '',
          }}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
        >
          {(formikProps) => (
            <Form>
              <fieldset>
                {isMoveSite ? <UserCategories name="user_categories" /> : <UserTags name="user_tags" />}

                <TextField
                  name="workout_song"
                  label="Favorite Workout Song"
                  placeholder="Favorite Workout Song"
                  autoComplete="off"
                  classProps={pageStyles.song}
                />

                <SubmitButton label="Save Changes" />
              </fieldset>
              {/* <FormikDebug /> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
