import { OverlayView } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react'
import markerStyles from '../styles/Marker.module.css';
import markerImg from '../Assets/marker.png';
import markerActiveImg from '../Assets/marker-active.png';
import { useSite } from '../contexts/SiteContext';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function MyMarker({ studio, activeStudioId, setActiveStudioId }) {
    const [mouseOver, setMouseOver] = useState(false);
    const history = useHistory();
    const { isMobileApp } = useSite();
    const handleClick = (e) => setActiveStudioId(studio.id)
    const handleMouseEnter = (e) => setMouseOver(true);
    const handleMouseLeave = (e) => setMouseOver(false);

    useEffect(() => {
        if (isMobileApp && studio?.id === activeStudioId) {
            history.push(`/studio/${studio.id}?from_source=Explore-Marker`);
        }
    }, [activeStudioId])

    return (
        <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{
                lat: studio?.point?.coordinates[1],
                lng: studio?.point?.coordinates[0]
            }}
        >
            <button className={markerStyles.marker}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <img
                    src={mouseOver ? markerActiveImg : markerImg}
                    className={markerStyles.indicator} />
                {(activeStudioId === studio.id) &&
                    <Link className={markerStyles.tooltip} to={`/studio/${studio.id}?from_source=Explore-Marker`} style={{ zIndex: 99 }}>
                        <span className={markerStyles.title} to={`/studio/${studio.id}?from_source=Explore-Marker`}>{studio.name}</span>
                        <span>{studio.distance} Miles</span>
                    </Link>
                }
            </button>
        </OverlayView>
    )
}
