import { setPFToken } from '../utilities/TokenHelper';
import apiInstance from './api';

export const login = async (body) => {
  const api = await apiInstance.getApi(true);
  return api.post('/authentication/public/login', body).then((res) => {
    const token = res.data?.token;
    setPFToken(token);
    return token;
  });
};

export const tokenLogin = async (body) => {
  const api = await apiInstance.getApi(true);
  return api.post('/authentication/public/login-token', body).then((res) => {
    const token = res.data?.token;
    setPFToken(token);
    return token;
  });
};

export const preRegister = async (body) => {
  const api = await apiInstance.getApi(true);
  return api
    .post('/authentication/public/pre-registration', body)
    .then((res) => {
      return res;
    });
};

export const registerGuest = async (body) => {
  const api = await apiInstance.getApi();
  return api
    .post('/authentication/guest-registration', body)
    .then((res) => {
      return res;
    });
}

export const pinCode = async (body) => {
  const api = await apiInstance.getApi(true);
  return api.post('/authentication/public/login-pin', body).then((res) => {
    return res;
  });
};

export const forgotPassword = async (body) => {
  const api = await apiInstance.getApi(true);
  return api
    .post('/authentication/public/forgot-password', body)
    .then((res) => {
      return res;
    });
};

export const completeRegistry = async (body) => {
  const api = await apiInstance.getApi();
  return api.post('/authentication/complete-registration', body).then((res) => {
    return res;
  });
};

export const logout = async () => {
  const api = await apiInstance.getApi();
  return api.post('/authentication/logout').then((res) => {
    return res;
  });
};

export const getChangePasswordCode = async (body) => {
  const api = await apiInstance.getApi();
  return api.post('/authentication/change-password/verify-email', body).then(res => {
    return res;
  })
}

export const pinChangePasswordCode = async (body) => {
  const api = await apiInstance.getApi();
  return api.post('/authentication/change-password/verify-pin', body).then((res) => {
    return res;
  });
};

export const changePassword = async (body) => {
  const api = await apiInstance.getApi();
  return api.post('/authentication/change-password', body).then(res => {
    return res;
  });
};
