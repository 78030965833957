import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { ReactComponent as DigitalIcon } from '../Assets/mobile/nav-item-digital-icon.svg';
import { ReactComponent as FavIcon } from '../Assets/mobile/nav-item-account-icon.svg';
import { ReactComponent as HomeIcon } from '../Assets/mobile/nav-item-home-icon.svg';
import { ReactComponent as ExploreIcon } from '../Assets/mobile/nav-item-explore-icon.svg';
import { ReactComponent as ActiveDigitalIcon } from '../Assets/mobile/nav-item-digital-icon-active.svg';
import { ReactComponent as ActiveFavIcon } from '../Assets/mobile/nav-item-account-icon-active.svg';
import { ReactComponent as ActiveHomeIcon } from '../Assets/mobile/nav-item-home-icon-active.svg';
import { ReactComponent as ActiveExploreIcon } from '../Assets/mobile/nav-item-explore-icon-active.svg';
import { ROOT } from '../navigation/CONSTANTS';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useSite } from '../contexts/SiteContext';
import styles from './mobileNavigation.module.css';

export const MobileNavigation = () => {
  const history = useHistory();
  const { authState } = useAuth();
  const { isMobileApp } = useSite();

  const isDiscoverActive = useMemo(
    () => history.location.pathname === '/discover' || history.location.pathname === '/corporate-wellness' || history.location.pathname === '/fitness-partners' || history.location.pathname === '/',
    [history.location.pathname]
  );
  const isSearchActive = useMemo(
    () => history.location.pathname.includes('/explore'),
    [history.location.pathname]
  );
  const isDigitalActive = useMemo(
    () => history.location.pathname === '/digital',
    [history.location.pathname]
  );
  const isAccountActive = useMemo(
    () => /user\/\d+/.test(history.location.pathname),
    [history.location.pathname]
  );

  if (history.location.pathname === '/' && window?.ReactNativeWebView) {
    return null
  }

  if (!authState.isAuthenticated && isAccountActive) {
    return <Redirect push to='/login' />;
  }

  return (
    isMobileApp && (
      <nav className={styles['mobile-nav']}>
        <div
          className={`${styles['mobile-nav-item']} ${
            isDiscoverActive ? styles['mobile-nav-item-active'] : ''
          }`}
          onClick={() => history.push('/discover')}
        >
          {isDiscoverActive ? <ActiveHomeIcon /> : <HomeIcon />}
          <span style={{ color: isDiscoverActive && '#E61761' }}>Home</span>
        </div>
        <div
          className={`${styles['mobile-nav-item']} ${
            isSearchActive ? styles['mobile-nav-item-active'] : ''
          }`}
          onClick={() => history.push('/explore/studios')}
        >
          {isSearchActive ? <ActiveExploreIcon /> : <ExploreIcon />}
          <span style={{ color: isSearchActive && '#E61761' }}>Explore</span>
        </div>
        <div
          className={`${styles['mobile-nav-item']} ${
            isDigitalActive ? styles['mobile-nav-item-active'] : ''
          }`}
          onClick={() => history.push(`/digital`)}
        >
          {isDigitalActive ? <ActiveDigitalIcon /> : <DigitalIcon />}

          <span style={{ color: isDigitalActive && '#E61761' }}>Digital</span>
        </div>
        <div
          className={`${styles['mobile-nav-item']} ${
            isAccountActive ? styles['mobile-nav-item-active'] : ''
          }`}
          onClick={() => authState.isAuthenticated ? history.push(`/user/${authState?.user?.id}`) : history.push('/login')}
        >
          {isAccountActive ? <ActiveFavIcon /> : <FavIcon />}
          <span style={{ color: isAccountActive && '#E61761' }}>Account</span>
        </div>
      </nav>
    )
  );
};
