import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import loginStyles from '../../styles/Login.module.css';
import btnDefaultStyles from '../../styles/button/Default.module.css';
import submitStyles from '../../styles/Submit.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import { useSite } from '../../contexts/SiteContext';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  isSubmitting?: boolean;
  disabled?: boolean;
  isRegister?: boolean;
}

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 4,
  },
  disabled: {
    pointerEvents: 'none',
    backgroundColor: '#D0D0D0',
  },
}));

export const SubmitButton: React.FC<Props> = ({
  isSubmitting,
  isRegister = false,
  disabled = false,
  children,
  ...props
}) => {
  const classes = useStyles();
  const { isMoveSite } = useSite();

  return (
    <button
      data-cy="button-submit"
      type="submit"
      disabled={disabled}
      className={`${loginStyles.submit} ${isMoveSite && isRegister ? btnDefaultStyles.register : btnDefaultStyles.button} ${classes.button} ${disabled ? classes.disabled : null}`}
      {...props}
    >
      <div
        className={`${isSubmitting
          ? [submitStyles.spinner, loadingStyles.spinner].join(' ')
          : loadingStyles.hidden
          }`}
      ></div>
      <span
        className={
          isSubmitting
            ? submitStyles.invisible
            : submitStyles.visible
        }
      >
        <span>{children}</span>
      </span>
    </button>
  );
};
