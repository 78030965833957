import React, { useEffect } from 'react';
import badgeIcon from '../../Assets/badgeIcon.png';

import badgesStyles from '../../styles/discover/Badges.module.css';
import badgeStyles from '../../styles/discover/Badge.module.css';

function Badge({ badge }) {
  return (
    <div className={badgesStyles.badge}>
      <button
        className={`${badgeStyles.container} focus_outline ${badgeStyles.clickableContainer}`}
      >
        <div className={badgeStyles.imgContainer}>
          <div className={badgeStyles.imgShine}></div>
          {badge.completed ? (
            <img src={badge.image} />
          ) : (
            <img src={badgeIcon} data-cy="badge-img-hidden-chest" alt="" />
          )}
        </div>
        <div className={badgeStyles.copyContainer}>
          <h3 className={badgeStyles.title}>{badge.name}</h3>
          <span className={badgeStyles.description}>{badge.description}</span>
        </div>
      </button>
    </div>
  );
}

export default Badge;
