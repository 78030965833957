import React from 'react';
import pageStyles from '../styles/Page.module.css';
import notFoundStyles from '../styles/NotFound.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import utilities from '../styles/utilities.module.css';

export default function NotFound() {
  return (
    <div
      className={pageStyles.container}
      style={{ height: 'auto', position: 'relative', minHeight: '248px' }}
    >
      <div className={notFoundStyles.container}>
        <div
          className={`${notFoundStyles.content} ${utilities['page-container']}`}
        >
          <div className={notFoundStyles.floorContent}>
            <h2 className={notFoundStyles.title}>
              <span>Oops!</span>
            </h2>
            <h3 className={notFoundStyles.subtitle}>
              <span>Looks like you've run into a page that doesn't exist</span>
            </h3>
            <div className={notFoundStyles.actions}>
              <button
                className={`${notFoundStyles.homepage} ${btnDefaultStyles.button} focus_outline`}
              >
                <span>Go Back</span>
              </button>
              <a
                className={`${notFoundStyles.contact} ${btnDefaultStyles['secondary-button']} ${btnDefaultStyles.button} focus_outline`}
                href={`mailto:info@peerfit.com?Subject=Peerfit+404+Page+Inquiry&amp;body=I experienced an unexpected 404 at ${window.location.href}`}
              >
                <span>Contact Us</span>
              </a>
            </div>
            <div className={notFoundStyles.footloose}>
              <svg
                aria-hidden="true"
                focusable="false"
                className={notFoundStyles.footIcon}
                viewBox="0 0 24 24"
              >
                <path d="M15,24H0v-0.8h15V24z M21.6,21.3l-0.5-0.5l-1,1h-1.7c-0.9,0-2-1-2.4-1.4l-7.9-7.9L7.6,13l7.9,7.9 c0.1,0.1,1.5,1.6,2.9,1.6h2L21.6,21.3z M24,22.1c0-0.5-0.4-1-0.5-1c0,0-0.1-0.1-0.2-0.1l-2.9-0.7l-2.8-2.8v-5.1h-0.8v5.2 c0,0.1,0,0.2,0.1,0.3l3,3c0,0,0.1,0.1,0.2,0.1l2.9,0.7c0.1,0.1,0.2,0.3,0.2,0.4c0,0.6-0.5,1.1-1.1,1.1h-4.5c-0.9,0-2-1-2.4-1.4 l-7.8-7.8v-0.4l3.9-3.9h0.2v0.4c0,1.7,1.3,3,3,3h1.5v-0.7h-1.5c-1.2,0-2.2-1-2.2-2.2V9.4C12.4,9.2,12.2,9,12,9h-0.8 c-0.1,0-0.2,0-0.3,0.1l-4.1,4.1c-0.1,0.1-0.1,0.2-0.1,0.3v0.8c0,0.1,0,0.2,0.1,0.3l7.9,7.9c0.1,0.1,1.5,1.6,2.9,1.6h4.5 C23.2,24,24,23.2,24,22.1z M17.3,14.3H15V15h2.2V14.3z M17.3,15.8h-1.5v0.8h1.5V15.8z M11.3,21.4h-6v0.8h6V21.4z M8.3,19.1H1.5v0.7 h6.7V19.1z M7.5,16.9h-3v0.8h3V16.9z M9.8,19.1H9v0.7h0.8V19.1z M3.8,16.9H3v0.8h0.7V16.9z M13,8.9l2.2-2.2l3.8-6.1 c0.1-0.2,0.1-0.4-0.1-0.5c-0.2-0.1-0.4-0.1-0.5,0.1l-3.7,6l-2.2,2.2c-0.1,0.1-0.1,0.4,0,0.5C12.6,9,12.7,9,12.8,9 C12.8,9,12.9,9,13,8.9z M17.6,11.7l0.3-1l5.9-5.9c0.1-0.1,0.1-0.4,0-0.5c-0.1-0.1-0.4-0.1-0.5,0l-6.1,6.1l-0.4,1.2 c-0.1,0.2,0,0.4,0.2,0.5c0,0,0.1,0,0.1,0C17.4,12,17.6,11.9,17.6,11.7z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
