import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorTipIcon from '../../Assets/error_tip.png';

const useStyles = makeStyles(() => ({
  box: {
    background: '#FDF6F6',
    border: '1px solid #D6D6D6',
    borderRadius: 4,
    padding: 16,
    marginBottom: 16,
  },
  errorTitle: {
    color: '#D91E61',
    fontSize: '1.3rem',
    fontWeight: 600,
    marginBottom: 8,
  },
  errorTipRow: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '1.3rem',
    color: '#222B56',
    marginTop: 8,

    '& img': {
      marginRight: 8,
      marginTop: 3,
    }
  }
}));

interface Props {
  show: boolean;
  title: ReactNode;
  tips?: string[];
}

export const ErrorBox: React.FC<Props> = ({
  show,
  title,
  tips = []
}) => {
  const classes = useStyles();

  if (!show) {
    return null;
  }

  return (
    <div className={classes.box}>
      <span className={classes.errorTitle}>{title}</span>
      {
        tips.map((tip, index) => {
          return (
            <div className={classes.errorTipRow} key={index}>
              <img src={ErrorTipIcon} />
              <span>{tip}</span>
            </div>
          );
        })
      }
    </div>
  );
};
