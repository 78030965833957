import React, { useState, useImperativeHandle } from 'react';
import { useSearch } from '../contexts/SearchContext';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { useGlobal } from '../contexts/GlobalContext';
import { useSite } from '../contexts/SiteContext/SiteContext';
import useWindowSize, { _MD, _SM } from '../shared/hooks/useWindowSize';
import SearchMenu_Sort from './SearchMenu_Sort';
import studioSortStyles from '../styles/explore/StudioSort.module.css';
import pPageStyles from '../styles/account/preferences/Page.module.css';
import fieldInputStyles from '../styles/input/Field.module.css';
import radioStyles from '../styles/Radio.module.css';
import filtersStyles from '../styles/explore/Filters.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import checkboxStyles from '../styles/Checkbox.module.css';
import checkboxFieldStyles from '../styles/checkbox/Field.module.css';
import tagStyles from '../styles/Tag.module.css';
import inputFieldStyles from '../styles/input/Field.module.css';
import spinnerGroupStyles from '../styles/spinner/Group.module.css';
import loadingStyles from '../styles/Loading.module.css';
import submitStyles from '../styles/Submit.module.css';
import menuStyles from '../styles/explore/Menu.module.css';
import mobileStyles from '../styles/MobileSearch.module.css';

export const SearchMenu_Filters = React.forwardRef(({ setFiltersOpen = () => {} }, formRef) => {
  // Confirm this but i think timeOfDayOptions are static. does not retrieve
  const [timeOfDayOptions] = useState([
    { text: 'Early Morning', subtext: '5am - 8am', value: 'early_morning' },
    { text: 'Morning', subtext: '8am - 11am', value: 'morning' },
    { text: 'Midday', subtext: '11am - 2pm', value: 'midday' },
    { text: 'Afternoon', subtext: '2pm - 5pm', value: 'afternoon' },
    { text: 'Early Evening', subtext: '5pm - 7pm', value: 'early_evening' },
    { text: 'Evening', subtext: '7pm & Later', value: 'evening' }
  ]);
  const { isMobileApp } = useSite();
  const isMoveSite = true;
  const {
    searchState: { tags, categories, has_digital, time, current_tab, has_memberships },
    setFilters,
    clearTags,
    clearCategories,
    clearTime,
    toggleHasMemberships,
    clearHasMemberships,
    clearHasDigital
  } = useSearch();
  const size = useWindowSize();

  const handleSubmit = values => {
    setFilters(values);
    setFiltersOpen(false);
  };
  const handleClearTime = (resetForm, setFieldValue) => {
    clearTime();
    if (isMobileApp) {
      setFieldValue('time', null);
    } else {
      resetForm();
    }
  };
  const handleClearTags = (resetForm, setFieldValue) => {
    console.log('CLEAR TAGS');
    clearTags();
    if (isMobileApp) {
      setFieldValue('tags', []);
    } else {
      resetForm();
    }
  };
  const handleClearCategories = (resetForm, setFieldValue) => {
    console.log('CLEAR CATEGORIES');
    clearCategories();
    if (isMobileApp) {
      setFieldValue('categories', []);
    } else {
      resetForm();
    }
  };
  const handleCancel = () => {
    if (isMobileApp) {
      clearHasMemberships();
      clearTime();
      clearTags();
      clearCategories();
      clearHasDigital();
    } else {
      console.log('CANCELLING');
      setFiltersOpen(false);
    }
  };

  return (
    <div className={`${isMobileApp ? mobileStyles.filtersContainer : filtersStyles.container}`} id="explore-filters">
      {!isMobileApp && size[3] < _MD && (
        <div className={`${filtersStyles.studioSortPhone} ${studioSortStyles.container}`}>
          <SearchMenu_Sort />
        </div>
      )}

      <Formik
        initialValues={{
          tags: tags,
          categories: categories,
          has_digital: !!has_digital,
          has_memberships: !!has_memberships,
          time: time
        }}
        onSubmit={async values => {
          handleSubmit(values);
        }}
        onReset={handleCancel}
      >
        {props => {
          const { resetForm, setFieldValue, values } = props;
          useImperativeHandle(formRef, () => {
            const clearAllFilters = () => {
              handleClearTime(resetForm, setFieldValue);
              handleClearTags(resetForm, setFieldValue);
              handleClearCategories(resetForm, setFieldValue);
              clearHasMemberships();
              clearHasDigital();
              setFieldValue('has_digital', false);
            };

            const submitValues = () => {
              handleSubmit(values);
            };
            return {
              clearAllFilters,
              handleSubmit: submitValues
            };
          });
          return (
            <Form className={filtersStyles.searchGroup} autoComplete="on" noValidate="">
              <fieldset>
                {isMobileApp && (
                  <>
                    <div className={mobileStyles.filtersItem}>
                      <input
                        onChange={() => {
                          toggleHasMemberships();
                        }}
                        id="id-12-1"
                        role="checkbox"
                        type="checkbox"
                        name="has_memberships"
                        aria-checked="false"
                        tabIndex="0"
                        className={checkboxStyles.input}
                      />
                      <label htmlFor="id-12-1" className={`${menuStyles.checkboxLabelDesktop} ${menuStyles.checkboxLabel}`}>
                        <span className={checkboxStyles.checkbox}>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            className={has_memberships ? checkboxStyles.check : checkboxStyles.noCheck}
                            viewBox="0 0 24 24"
                          >
                            <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z"></path>
                          </svg>
                        </span>
                        <span className={checkboxStyles.label}>
                          <span>Membership Only</span>
                        </span>
                      </label>
                    </div>
                    <hr />
                  </>
                )}
                {isMoveSite ? (
                  <CategoryOptions label="Activities" name="categories" handleClear={handleClearCategories} />
                ) : (
                  <TagOptions label="Activities" name="categories" handleClear={handleClearTags} />
                )}
                {current_tab == 0 && <Has_DigitalCheckBox name="has_digital" label={`Live & On-Demand Classes Only`} />}
                {current_tab == 1 && <TimeOfDayPicker label="Time of Day" name="time" options={timeOfDayOptions} handleClear={handleClearTime} />}

                {current_tab == 0 && isMoveSite ? <TagOptions label="Types of Classes" name="tags" handleClear={handleClearTags} /> : ''}
                {!isMobileApp && (
                  <div className={filtersStyles.filterActions}>
                    <button
                      type="reset"
                      className={`${filtersStyles.closeButton} ${btnDefaultStyles['cancel-button-borderless']} ${btnDefaultStyles['cancel-button']} ${btnDefaultStyles.button} focus_outline`}
                    >
                      <span>Cancel</span>
                    </button>
                    <button data-cy="filter-button-search" type="submit" className={`${filtersStyles.searchButton} ${btnDefaultStyles.button} focus_outline`}>
                      <div className={loadingStyles.hidden}></div>
                      <span className={submitStyles.visible}>
                        <span>Search</span>
                      </span>
                    </button>
                  </div>
                )}
              </fieldset>
              {/* <FormikDebug /> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});

const TagOptions = ({ label, handleClear, ...props }) => {
  const [field] = useField(props);
  const { resetForm, setFieldValue } = useFormikContext();
  const { tags: options, tagsLoading } = useGlobal();

  return (
    <>
      <div>
        <button
          onClick={() => handleClear(resetForm, setFieldValue)}
          type="button"
          className={`${filtersStyles.clearButton} ${btnDefaultStyles['cancel-button-borderless']} ${btnDefaultStyles['cancel-button']} ${btnDefaultStyles.button} focus_outline`}
        >
          <span>Clear</span>
        </button>
        <h4 className={filtersStyles.filterHeader}>
          <span>{label}</span>
        </h4>
      </div>

      <fieldset className={filtersStyles.pillGroup}>
        {tagsLoading ? (
          <div className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`} />
        ) : (
          options.map((x, i) => {
            const selected = field.value.indexOf(x.name) > -1;
            return (
              <div key={i} className={checkboxFieldStyles.pill}>
                <div className={`${selected ? tagStyles.toggleOn : ''} ${tagStyles.toggle}`}>
                  <input
                    {...field}
                    name={field.name}
                    value={x.name}
                    type="checkbox"
                    className={tagStyles.input}
                    id={`id-${x.name}-1`}
                    aria-checked={selected}
                  />
                  <label htmlFor={`id-${x.name}-1`} className={tagStyles.label}>
                    <span>{x.name}</span>
                  </label>
                </div>
              </div>
            );
          })
        )}
      </fieldset>
      <hr />
    </>
  );
};

const CategoryOptions = ({ label, handleClear, ...props }) => {
  const [field, meta] = useField(props);
  const { resetForm, setFieldValue } = useFormikContext();
  const { categories: options, tagsLoading, categoriesLoading } = useGlobal();

  return (
    <>
      <div>
        <button
          onClick={() => handleClear(resetForm, setFieldValue)}
          type="button"
          className={`${filtersStyles.clearButton} ${btnDefaultStyles['cancel-button-borderless']} ${btnDefaultStyles['cancel-button']} ${btnDefaultStyles.button} focus_outline`}
        >
          <span>Clear</span>
        </button>
        <h4 className={filtersStyles.filterHeader}>
          <span>{label}</span>
        </h4>
      </div>

      <fieldset className={pPageStyles.workoutCheckboxes}>
        {categoriesLoading ? (
          <div className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`} />
        ) : (
          options
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            ?.map((category, index) => {
              const selected = field?.value.indexOf(category.name) > -1;
              return (
                <div key={index} className={`${checkboxFieldStyles.checkbox} ${checkboxStyles.field} ${fieldInputStyles.field}`} style={{ width: '100%' }}>
                  <input
                    {...field}
                    value={category.name}
                    id={`id-${index}-1`}
                    type="checkbox"
                    aria-checked={selected}
                    data-cy="workout-preferences-checkbox"
                    tabIndex="0"
                    className={checkboxStyles.input}
                  />
                  <label htmlFor={`id-${index}-1`} className={checkboxStyles.labelContainer}>
                    <span className={checkboxStyles.checkbox}>
                      <svg aria-hidden="true" focusable="false" className={selected ? checkboxStyles.check : checkboxStyles.noCheck} viewBox="0 0 24 24">
                        <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z"></path>
                      </svg>
                    </span>
                    <span className={checkboxStyles.label}>
                      <span>
                        <strong>{category.name}</strong>
                      </span>
                      <p style={{ margin: '0px' }}>
                        {category.tags
                          .map((tag, idx) => tag.name)
                          .sort()
                          .join(', ')}
                      </p>
                    </span>
                  </label>
                </div>
              );
            })
        )}
      </fieldset>
      <hr />
    </>
  );
};

const Has_DigitalCheckBox = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <h4 className={filtersStyles.digitalFilterHeader}>
        <span>Digital</span>
      </h4>
      <div className={`${filtersStyles.checkbox} ${checkboxStyles.field} ${inputFieldStyles.field}`}>
        <input
          {...field}
          id="id-188-1"
          role="checkbox"
          type="checkbox"
          name={field.name}
          aria-checked={!!field.value}
          checked={!!field.value}
          tabIndex="0"
          className={checkboxStyles.input}
        />
        <label htmlFor="id-188-1" className={checkboxStyles.labelContainer}>
          <span className={checkboxStyles.checkbox}>
            <svg aria-hidden="true" focusable="false" className={!!field.value ? checkboxStyles.check : checkboxStyles.noCheck} viewBox="0 0 24 24">
              <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z"></path>
            </svg>
          </span>
          <span className={checkboxStyles.label}>
            <span>{label}</span>
          </span>
        </label>
      </div>
      <hr />
    </>
  );
};

const TimeOfDayPicker = ({ label, options, handleClear, ...props }) => {
  const [field, meta] = useField(props);
  const { resetForm, setFieldValue } = useFormikContext();

  return (
    <>
      <div>
        <button
          onClick={() => handleClear(resetForm, setFieldValue)}
          type="button"
          className={`${filtersStyles.clearButton} ${btnDefaultStyles['cancel-button-borderless']} ${btnDefaultStyles['cancel-button']} ${btnDefaultStyles.button} focus_outline`}
        >
          <span>Clear</span>
        </button>
        <h4 className={filtersStyles.filterHeader}>
          <span>{label}</span>
          {/* <span>Time of Day</span> */}
        </h4>
      </div>
      <fieldset className={filtersStyles.timeFieldset}>
        <div className={filtersStyles.timeGroup}>
          {options?.map((x, i) => {
            return (
              <div key={i} className={filtersStyles.timeRadioContainer}>
                <div className={filtersStyles.timeRadio}>
                  <input
                    {...field}
                    id={`id-${i}-2`}
                    type="radio"
                    role="radio"
                    name={field.name}
                    value={x.value}
                    aria-checked={x.value === field.value}
                    tabIndex="0"
                    aria-labelledby={`id-${i}-2`}
                    className={radioStyles.input}
                  />
                  <label className={radioStyles.label} htmlFor={`id-${i}-2`}>
                    <span className={radioStyles.radio}>
                      <span className={`${x.value === field.value ? radioStyles.radioOn : ''} ${radioStyles.radioOff}`}></span>
                    </span>
                    {x.text}
                  </label>
                </div>
                <div className={filtersStyles.timeRadioCopy}>
                  <span>{x.subtext}</span>
                </div>
              </div>
            );
          })}
        </div>
      </fieldset>
      <hr />
    </>
  );
};
