import React, { useEffect } from 'react';
import { logAmplitudeEvent } from '../api/integration';
import HubspotForm from 'react-hubspot-form';
import { useAuth } from '../contexts/AuthContext';
import heroStyles from '../styles/contact-us/Hero.module.css';
import pageStyles from '../styles/contact-us/Page.module.css';
import utilities from '../styles/utilities.module.css';
import { LoadingPlaceholder } from '../shared/Loading';
import '../styles/HubSpotForm.css';

export default function ContactUs() {
  const { authState } = useAuth();

  useEffect(() => {
    if (authState?.user?.id) {
      logAmplitudeEvent('Screen View: Support - Contact Us');
    }
  }, [authState?.user?.id]);

  return (
    <div className={`${pageStyles.page} ${utilities.page}`} style={{ height: 'auto', position: 'relative', minHeight: '217px' }}>
      <div
        className={heroStyles.container}
        style={{
          backgroundImage: 'url("/f60b44c0013b4778e90b534cba6fe002.jpg")',
        }}
      >
        <div className={heroStyles.content}>
          <h2 className={heroStyles.title}>
            <span>Get in touch. Find out how we redefine wellness.</span>
          </h2>
        </div>
      </div>
      <div className={pageStyles.container} style={{ maxWidth: 800, marginBottom: 100 }}>
        <h2 className={pageStyles.title}>
          <span>Contact Us</span>
        </h2>
        <HubspotForm
          id="contact-us-hub-spot"
          region="na1"
          portalId="4945450"
          formId="4f38f145-525b-45ec-bc42-e6a750aac2c0"
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={
            <div style={{ height: 300 }}>
              <LoadingPlaceholder />
            </div>
          }
        />
      </div>
    </div>
  );
}
