import { Form, Formik } from 'formik';
import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import studioBannerImg from '../../Assets/studioBanner.jpg';
import { useAuth } from '../../contexts/AuthContext';
import { REGISTER } from '../../navigation/CONSTANTS';
import btnDefaultStyles from '../../styles/button/Default.module.css';
import controllerStyles from '../../styles/Controller.module.css';
import loginStyles from '../../styles/Login.module.css';
import overlayDefaultStyles from '../../styles/overlay/Default.module.css';
import reservationHeaderStyles from '../../styles/ReservationHeader.module.css';
import svgStyles from '../../styles/SvgIcon.module.css';

export const LoginModal = React.forwardRef(({ isShowing, hide, studio }, ref) => {

    const { authState, login } = useAuth();

    return (
        isShowing ? ReactDOM.createPortal(
            <React.Fragment>
                <div className={controllerStyles.overlay}>
                    <div ref={ref} role="dialog" tabIndex="0" data-cy="Login" className={overlayDefaultStyles.dialog} aria-labelledby="reservation-header-title">
                        <div data-focus-guard="true" tabIndex="0" style={{ width: "1px", height: "0px", padding: "0px", overflow: "hidden", position: "fixed", top: "1px", left: "1px" }}>
                        </div>
                        <div data-focus-guard="true" tabIndex="1" style={{ width: "1px", height: "0px", padding: "0px", overflow: "hidden", position: "fixed", top: "1px", left: "1px" }}>
                        </div>
                        <div data-focus-lock-disabled="false">
                            <button className={`${overlayDefaultStyles.close} focus_outline`} aria-label="Close" onClick={hide}>
                                <svg aria-hidden="true" focusable="false" className={`${overlayDefaultStyles.closeIcon} ${svgStyles.icon}`} viewBox="0 0 17 17">
                                    <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z">
                                    </path>
                                </svg>
                            </button>
                            <div className={reservationHeaderStyles.header} style={{ backgroundImage: `url(${studioBannerImg})` }}>
                                <div data-cy="reservation-modal" className={reservationHeaderStyles.content}>
                                    <div className={reservationHeaderStyles.textWrapper}>
                                        <h3 id="reservation-header-title">
                                            {authState.isAuthenticated ?
                                                <span>Favorite</span> : <span>Sign In to favorite</span>}
                                        </h3>
                                        <h4>{studio?.name}</h4>
                                        {/* <h4>{format(new Date(session.starts_at), 'E, LLL do h:mm a')}</h4> */}
                                    </div>
                                </div>
                            </div>
                            <div className={overlayDefaultStyles.content}>


                                <Formik
                                    initialValues={{
                                        note: ''
                                    }}
                                    onSubmit={async (values, actions) => {
                                        // await handleSubmit(values, actions);
                                    }}
                                >
                                    {formikProps => (
                                        <Form className={loginStyles.form}>
                                            <fieldset>
                                                {authState.isAuthenticated ?
                                                    <>
                                                     <h1>YOU ARE LOGGED IN {authState?.user?.name}</h1>
                                                    </>
                                                    :
                                                    <>
                                                        <div className={loginStyles.copy}>
                                                            <span>Don't have a Peerfit account? </span>
                                                            <Link className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`} to={REGISTER}>
                                                                <span>Sign Up</span>
                                                            </Link>
                                                        </div>
                                                        <div className={loginStyles.copy}>
                                                            <span>Have an account? </span>
                                                            <button onClick={login} className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}>
                                                                <span>Sign In</span>
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                                {/* <FormikDebug /> */}
                                            </fieldset>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        {/* <div data-focus-guard="true" tabIndex="0" style={{ width: "1px", height: "0px", padding: "0px", overflow: "hidden", position: "fixed", top: "1px", left: "1px" }}>
                        </div> */}
                    </div>
                </div>
            </React.Fragment>, document.body
        ) : null)
})