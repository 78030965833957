import React from 'react';

import fieldStyles from '../../styles/input/Field.module.css';
import { useField } from 'formik';
import { useId } from 'react-id-generator';
import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';

export const PhoneField = ({ label, className = '', ...props }) => {
  const phoneMask = '555-555-5555';
  const [field, meta] = useField(props);

  const [htmlId] = useId();
  const id1 = htmlId + '-1';
  const id2 = htmlId + '-2';

  const handleBlur = (e) => {
    e.target.placeholder = label; // set 'Phone'
    field.onBlur(e);
  };
  const handleFocus = (e) => {
    e.target.placeholder = phoneMask; // set '555-555-5555'
  };

  return (
    <>
      <div className={`${fieldStyles.field} ${className}`}>
        <Cleave
          {...field}
          placeholder={label}
          options={{
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`${meta.touched && meta.error && fieldStyles.inputError} ${
            fieldStyles.input
          }`}
          {...props}
        />

        <div id={id1} aria-live="polite" aria-atomic="true" role="status">
          {meta.touched && meta.error ? (
            <span className={`${fieldStyles.errorLabel} error-label`}>
              Error: {meta.error}
            </span>
          ) : null}
        </div>
        {meta.touched && meta.error && (
          <div className={fieldStyles.errorIcon}>!</div>
        )}
      </div>
    </>
  );
};
