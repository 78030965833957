import React, { useEffect, useState } from 'react';
import fitOnImg from '../../Assets/fitOn_image.png';
import videoStyles from '../../styles/streaming/Video.module.css';
import streamingServiceStyles from '../../styles/streaming/StreamingService.module.css';

import btnDefaultStyles from '../../styles/button/Default.module.css';
import useModal from '../../shared/Modal/useModal';
import { StreamingModal } from '../../shared/Modal/StreamingModal';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { getSignUpFullUrl } from '../../api/integration/fitOnSignUp';
import { get } from 'lodash';
import { logAmplitudeEvent, logBrazeEvent } from '../../api/integration';

function FitOnStreamingService({ digitalProvider }) {
  const { authState } = useAuth();
  const { user } = authState || {};
  const { subscriptions } = user || {};
  const userId = get(user, 'id');

  const { isShowing, toggle, ref } = useModal();
  const [canPurchase, setCanPurchase] = useState(false);
  const [fitOnSingUpUrl, setFitOnSignUpUrl] = useState('');

  const provider = {
    id: 0,
    descriptiontitle: 'FitOn',
    credits: 0,
    video_alt:
      'FitOn is a truly fantastic fitness app that offers tons of workout videos and resources for free.',
    description:
      'Get unlimited access to best workouts, personalized programs, guided meditations and more!',
    description_list: [],
  };
  useEffect(() => {
    if (subscriptions) {
      var isActive = subscriptions.filter(
        (x) => x.provider_name == 'FITON'
      ).length;
      setCanPurchase(!isActive);
    }
    if (authState.user) {
      getSignUpFullUrl(authState.user, 'streaming').then((url) => {
        setFitOnSignUpUrl(url);
      });
    }
  }, [authState]);

  return (
    <>
      <div className={streamingServiceStyles.container}>
        <button
          className={`${streamingServiceStyles.videoContainer} ${videoStyles.container}`}
        >
          <img
            className={videoStyles.thumb}
            src={fitOnImg}
            alt={provider.video_alt}
          />
        </button>
        <div className={streamingServiceStyles.description}>
          <h3>
            <span>{provider.descriptiontitle} Membership</span>
          </h3>
          <p>
            <span>{provider.description}</span>
          </p>
          <ul>
            {provider.description_list.map((item, index) => (
              <li key={index}>
                <span>{item}</span>
              </li>
            ))}
          </ul>
          {userId ? (
            canPurchase ? (
              <a
                className={`${streamingServiceStyles.membershipBtn} ${
                  btnDefaultStyles.button
                } focus_outline ${
                  !fitOnSingUpUrl ? streamingServiceStyles.btnDisabled : ''
                }`}
                href={fitOnSingUpUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  if (
                    provider &&
                    provider.descriptiontitle &&
                    provider.descriptiontitle.toLowerCase() === 'fiton'
                  ) {
                    const eventData = {
                      Type: 'Digital',
                      Credits: digitalProvider?.credits,
                      'Item Name': 'FitOn Subscription',
                      Source: 'Streaming',
                    };
                    logAmplitudeEvent('Click: Purchase', eventData);
                    logAmplitudeEvent('Screen View: Purchase', eventData);
                    logBrazeEvent('Click: Purchase', eventData);
                  }
                }}
              >
                <span>GET STARTED</span>
              </a>
            ) : (
              userId && (
                <Link
                  to={`user/${userId}/memberships`}
                  className={`${streamingServiceStyles.membershipBtn} ${btnDefaultStyles['transparent-button']} ${btnDefaultStyles.button} ${btnDefaultStyles.button_link} focus_outline`}
                >
                  View Subscription
                </Link>
              )
            )
          ) : (
            <button
              onClick={toggle}
              className={`${streamingServiceStyles.membershipBtn} ${btnDefaultStyles.button} focus_outline`}
            >
              <span>GET STARTED</span>
            </button>
          )}
        </div>
      </div>
      <StreamingModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        provider={digitalProvider}
      />
    </>
  );
}

export default FitOnStreamingService;
