import React from 'react';

import getDigitalSubscriptionStyles from '../../styles/GetDigitalSubscription.module.css';
import fitOnIcon from '../../Assets/fitOn_logo.png';
import fitOnImage from '../../Assets/fitOn_image.png';
import burnAlongIcon from '../../Assets/c86b9d659047213dd06a9f7183e7c3c8.png';
import burnalongImage from '../../Assets/c660f7bc64a5706dcac9d9804a67a443.png';

import DigitalStudioCard from './DigitalStudioCard';
import { useSite } from '../../contexts/SiteContext';
import { useGlobal } from '../../contexts/GlobalContext';

function GetDigitalSubscription({ style }) {
  const fitOn = {
    name: 'FitOn PRO Membership',
    provider_name: 'FITON',
    icon: fitOnIcon,
    image: fitOnImage,
    icon_alt: 'FitOn is a truly fantastic fitness app that offers tons of workout videos and resources for free.',
    credits: 0,
    description:
      'Join the #1 Fitness App with over 10+ Million members. FitOn offers personalized workouts from celebrity trainers, meditation & nutrition plans and is design for all ages & abilities.',
    cta: 'GET STARTED',
  };
  const burnalong = {
    name: 'BurnAlong',
    provider_name: 'BURN ALONG',
    icon: burnAlongIcon,
    image: burnalongImage,
    icon_alt: 'Burnalong ',
    credits: 0,
    description: 'Stream thousands of digital classes solo or with firneds! Connect with other users and experience group fitness without leaving the house.',
  };

  const { isMoveSite, isMobileApp } = useSite();
  const { isDigitalFitOnSurfacing } = useGlobal();

  return (
    <>
      <div className={getDigitalSubscriptionStyles.container} style={style}>
        <h2 className={getDigitalSubscriptionStyles.title}>
          <span>Digital Fitness Memberships</span>
        </h2>
        <span className={getDigitalSubscriptionStyles.subtitle}>Thousands of workout options you can do from anywhere.</span>
        <div className={getDigitalSubscriptionStyles.cardContainer}>
          {isDigitalFitOnSurfacing && <DigitalStudioCard provider={fitOn} />}
          {isMoveSite && <DigitalStudioCard provider={burnalong} />}
        </div>
      </div>
    </>
  );
}

export default GetDigitalSubscription;
