import React from 'react'
import flexStyles from '../../../styles/Flex.module.css';
import reservationsStyles from '../../../styles/user/Reservations.module.css';
import reservationStyles from '../../../styles/user/Reservation.module.css';

import bannerStyles from '../../../styles/Banner.module.css';
import useModal from '../../../shared/Modal/useModal';
import defaultStudioImg from '../../../Assets/defaultStudio.jpg';
import { format } from 'date-fns';
import { CancelReservationModal } from '../../../shared/Modal/CancelReservationModal';
import { Link, useRouteMatch } from 'react-router-dom';
import { GetDate } from '../../../utilities/date';

export const AttendedCard = ({ attended }) => {

    const { isShowing, toggle, ref } = useModal();
    const { studio } = attended;
    const { point } = studio;


    return (
        <>
            <div className={`${flexStyles["col-lg-3"]} ${flexStyles["col-md-4"]} ${flexStyles["col-sm-6"]} ${reservationsStyles.card}`}>
                <div className={reservationStyles.reservation}>
                    <div className={bannerStyles.container} style={{ backgroundImage: `url(${studio.banner_image || defaultStudioImg})` }}>
                        <Link className={bannerStyles.copy} to={`/studio/${attended.studio.id}?from_source=Attended`}>
                            <h3 className={bannerStyles.title}>{attended.studio.name} {attended?.session.instructor?.name && `with ${attended?.session.instructor?.name}`}</h3>
                        </Link>
                    </div>
                    <div className={reservationStyles.content}>
                        <div className={reservationStyles.info}>
                            <h3 className={`${reservationStyles.date}`} >{format(GetDate(attended.session.start_datetime), 'h:mm a E LLL dd')}</h3>
                            <h4 className={reservationStyles.tiny}>{attended.session.name}</h4>
                        </div>

                        <div className={reservationStyles.actions}>
                            <div className={reservationStyles.action}>
                                <a className={reservationStyles.button} href={`https://maps.google.com?q=${studio.point.coordinates[1]},${studio.point.coordinates[0]}`} target="_blank">Map Location</a>
                            </div>
                            {/* <div className={reservationStyles.action}>
                                <a onClick={() => alert('Inviation Modal here.')} className={reservationStyles.button}>Invite Friend</a>
                            </div> */}

                            {(attended.status == 4 || attended.status == 5 || attended.status == 6) && <h3 className={reservationStyles.noCancel}>Canceled</h3>}
                            {/* {(attended.status == 7 || attended.status == 8 || attended.status == 10) && <h3 className={reservationStyles.noCancel}>Attended</h3>} */}
                            {/* {(attended.status == 0 || attended.status == 1 || attended.status == 2 || attended.status == 3 || attended.status == 9) &&
                                <h3 className={`${reservationStyles.noCancel}`} style={{ color: "#e61761" }}>Not Attended</h3>} */}
                        </div>
                    </div>
                </div>
            </div>
            <CancelReservationModal
                ref={ref}
                isShowing={isShowing}
                hide={toggle}
                reservation={attended}
            // onSuccess={loadUpcomingReservations}
            />


        </>
    )
}
