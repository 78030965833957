import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import mobileStyles from '../styles/Mobile.module.css';
import favoriteStyles from '../styles/Favorite.module.css';
import studioStyles from '../styles/Studio.module.css';
import imageStyles from '../styles/Image.module.css';
import bannerStyles from '../styles/Banner.module.css';
import indexStyles from '../styles/studio/index.module.css';
import mbScheduleStyles from '../styles/MindbodySchedule.module.css';
import subnavStyles from '../styles/Subnav.module.css';
import dateSelectorStyles from '../styles/DateSelector.module.css';
import styles from '../styles/table/styles.module.css';
import pillStyles from '../styles/Pill.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import svgIconStyles from '../styles/SvgIcon.module.css';
import mbLogo from '../Assets/mbLogo.png';
import Schedule from './Schedule';
import MapBanner from './MapBanner';
import Membership from './Membership';
import About from './About';
import studioBanner from '../Assets/studioBanner.jpg';
import { useSearch } from '../contexts/SearchContext';
import { EXPLORE, EXPLORE_STUDIOS } from '../navigation/CONSTANTS';
import { useSite } from '../contexts/SiteContext/SiteContext';
import utilitiesStyle from '../styles/utilities.module.css';

export const StudioMobile = ({ studio, memberships }) => {

    const { id } = useParams();
    useEffect(() => {
        console.log("stuido id:", id);
    }, [])

    const [activeTab, setActiveTab] = useState(1);

    const [imageExists, setImageExists] = useState(true);
    const handleImageError = () => setImageExists(false);
    const { getSearchParams } = useSearch();
    const { isMoveSite } = useSite();

    return (
        <div className={mobileStyles.container}>
            <button className={mobileStyles.favorite} aria-pressed="false" aria-label={`Add ${studio?.name} in ${studio?.city} , ${studio?.state} to your saved studios`}>
                <svg aria-hidden="true" focusable="false" className={`${favoriteStyles.empty} ${favoriteStyles.favorited}`} viewBox="0 0 24 24">
                    <path d="M7.71075,4.00001328 C5.32351048,3.99460728 3,5.64026328 3,8.64401328 C3,12.1397633 10.2404741,19.986625 12,19.986625 C13.7595259,19.986625 21,12.1397633 21,8.64401328 C21,5.63576328 18.6772423,4.01294709 16.2945,4.00751328 C13.0057278,4.00001328 12.4403428,6.27840859 12,6.27840859 C11.5596572,6.27840859 11.02268,4.00751328 7.71075,4.00001328 Z">
                    </path>
                </svg>
            </button>
            <div className={`${mobileStyles.card} ${studioStyles.container} ${studioStyles.shadow}`}>
                <div className={`${imageStyles.image} ${bannerStyles.container}`} style={{ backgroundImage: `url(${imageExists ? studio.banner_image : studioBanner})` }}>
                    <div className={bannerStyles.copy}>
                        <h3 className={bannerStyles.title}>{studio?.name}</h3>
                        {isMoveSite ?
                            <>
                                {(studio?.categories.length > 0 || studio?.amenities.length > 0) && <h3 className={bannerStyles.subtitle}>
                                    {[...studio?.categories.map(c => c.name) ?? [], ...studio?.amenities ?? []].join(' • ')}
                                </h3>}
                            </>
                            :
                            (studio?.tags.length > 0) && <h3 className={bannerStyles.subtitle}>{studio?.tags.join(' • ')}</h3>}
                    </div>
                    <img hidden src={studio.banner_image ?? "undefined"} onError={handleImageError} />
                </div>
            </div>
            <div className={subnavStyles.container}>
                <div className={subnavStyles.content}>
                    <div tabIndex="-1" role="tablist" data-cy="explore-nav-tabs" className={`${mobileStyles.subnav} ${indexStyles.contanier}`} tabs="[object Object],[object Object],[object Object]">
                        <button onClick={() => setActiveTab(1)} className={`${mobileStyles.activeTab} ${activeTab == 1 ? indexStyles.active : ''} ${indexStyles.tab} focus_outline`} id="nav-tab-0" role="tab" aria-posinset="1" aria-setsize="3" aria-selected="true" aria-expanded="true" aria-controls="subpage-id-8-1">
                            <span>Classes</span>
                        </button>
                        {memberships.filter(x => x.is_active).length > 0 ?
                            <button onClick={() => setActiveTab(2)} className={`${mobileStyles.activeTab} ${activeTab == 2 ? indexStyles.active : ''} ${indexStyles.tab} focus_outline`} id="nav-tab-1" role="tab" aria-posinset="2" aria-setsize="2" aria-selected="true" aria-expanded="true" aria-controls="subpage-id-8-1">
                                <span>Membership</span>
                            </button>
                            : ''}
                        <button onClick={() => setActiveTab(3)} className={`${mobileStyles.tab} ${activeTab == 3 ? indexStyles.active : ''} ${indexStyles.tab} focus_outline`} id="nav-tab-2" role="tab" aria-posinset="3" aria-setsize="3" aria-selected="false" aria-expanded="false" aria-controls="subpage-id-8-1">
                            <span>Map</span>
                        </button>
                        <button onClick={() => setActiveTab(4)} className={`${mobileStyles.tab} ${activeTab == 4 ? indexStyles.active : ''} ${indexStyles.tab} focus_outline`} id="nav-tab-3" role="tab" aria-posinset="4" aria-setsize="4" aria-selected="false" aria-expanded="false" aria-controls="subpage-id-8-1">
                            <span>About</span>
                        </button>
                    </div>
                    <div id="subpage-id-8-1">
                        {activeTab == 1 && <Schedule studio={studio} />}
                        {activeTab == 2 && <Membership memberships={memberships.filter(x => x.is_active)} studio={studio} />}
                        {activeTab == 3 && <MapBanner coordinates={studio?.point?.coordinates} />}
                        {activeTab == 4 && <About studio={studio} />}
                    </div>
                </div>
            </div>
            <p className={`${mobileStyles.centered} ${utilitiesStyle.desktopOnly}`}>
                <Link className={mobileStyles.backtoStudios} to={`${EXPLORE_STUDIOS}?${getSearchParams()}`}>
                    <svg style={{ verticalAlign: "inherit" }} aria-hidden="true" focusable="false" className={svgIconStyles.icon} width="24" height="24" viewBox="0 0 24 24">
                        <path d="M16.4244649,19.2868077 C16.7653329,19.6293551 16.7639715,20.1833728 16.4214241,20.5242408 C16.0788767,20.8651089 15.524859,20.8637475 15.183991,20.5212001 L7.25476309,12.5529074 C6.9164664,12.2129441 6.91488398,11.6640093 7.25121504,11.3221012 L15.1804429,3.26139014 C15.5193304,2.9168832 16.073331,2.91232773 16.417838,3.2512152 C16.7623449,3.59010267 16.7669004,4.1441033 16.4280129,4.48861023 L9.10587647,11.932163 L16.4244649,19.2868077 Z">
                        </path>
                    </svg>
                    <span>View All Studios</span>
                </Link>
            </p>
        </div>
    )
}
