import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import controllerStyles from '../../styles/Controller.module.css';
import overlayDefaultStyles from '../../styles/overlay/Default.module.css';
import svgStyles from '../../styles/SvgIcon.module.css';
import studioBannerStyles from '../../styles/StudioBanner.module.css';
import reservationHeaderStyles from '../../styles/ReservationHeader.module.css';

import studioBannerImg from '../../Assets/studioBanner.jpg';

import btnDefaultStyles from '../../styles/button/Default.module.css';
import loginStyles from '../../styles/Login.module.css';
import fieldStyles from '../../styles/input/Field.module.css';
import styles from '../../styles/styles.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import submitStyles from '../../styles/Submit.module.css';
import checkboxStyles from '../../styles/Checkbox.module.css';
import errorFormStyles from '../../styles/error/Form.module.css';

import studioApi from '../../api/studio';
import { Link, useHistory } from 'react-router-dom';
import { DISCOVER, PAYMENT, REGISTER } from '../../navigation/CONSTANTS';
import { useAuth } from '../../contexts/AuthContext';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import userApi from '../../api/user';
import { FormikDebug } from '../Formik/FormikDebug';
import sleep from '../../utilities/sleep';
import { GetDate } from '../../utilities/date';
import { TextField } from '../../shared/Formik/TextField1';
import * as Yup from 'yup';
import { logAmplitudeEvent, logBrazeEvent } from '../../api/integration';

export const PromoModal = React.forwardRef(({ isShowing, hide }, ref) => {
  const { authState, verify } = useAuth();
  const { user } = authState || {};
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (values, actions) => {
    logAmplitudeEvent('Credits: Redeem Code');
    
    const { promoCode } = values;

    await userApi
      .redeemPromoCode(promoCode)
      .then((res) => {
        verify(); // reload user to update credit count.
        hide();

        logBrazeEvent('Credits: Redeem Code Success');
        logAmplitudeEvent('Credits: Redeem Code Success');
      })
      .catch((error) => {
        if (error.status == 400) {
          actions.setErrors({ summary: error.data.Message });
        } else {
          actions.setErrors({
            summary:
              'Unable to redeem promocode at the moment. Please try again later.',
          });
        }
        logAmplitudeEvent('Credits: Redeem Code Failure', {
          'Error Code': error.status,
          'Error Message': error.data?.Message,
        });
      });
  };

  const validationSchema = Yup.object().shape({
    promoCode: Yup.string().required().label('Gift Code'),
  });
  
  useEffect(() => {
    isShowing && logAmplitudeEvent('Screen View: Settings - Credits - Redeem Code');
  }, [isShowing]);

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className={controllerStyles.overlay}>
            <div
              ref={ref}
              role="dialog"
              tabIndex="0"
              data-cy="Login"
              className={overlayDefaultStyles.dialog}
              aria-labelledby="reservation-header-title"
            >
              <div
                data-focus-guard="true"
                tabIndex="0"
                style={{
                  width: '1px',
                  height: '0px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'fixed',
                  top: '1px',
                  left: '1px',
                }}
              ></div>
              <div
                data-focus-guard="true"
                tabIndex="1"
                style={{
                  width: '1px',
                  height: '0px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'fixed',
                  top: '1px',
                  left: '1px',
                }}
              ></div>
              <div data-focus-lock-disabled="false">
                <button
                  className={`${overlayDefaultStyles.close} focus_outline`}
                  aria-label="Close"
                  onClick={hide}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    className={`${overlayDefaultStyles.closeIcon} ${svgStyles.icon}`}
                    viewBox="0 0 17 17"
                  >
                    <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z"></path>
                  </svg>
                </button>
                <div
                  className={reservationHeaderStyles.header}
                  style={{ backgroundImage: `url(${studioBannerImg})` }}
                >
                  <div
                    data-cy="reservation-modal"
                    className={reservationHeaderStyles.content}
                  >
                    <div className={reservationHeaderStyles.textWrapper}>
                      <h3 id="reservation-header-title">
                        <span>Enter Gift Code</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className={overlayDefaultStyles.content}>
                  <Formik
                    initialValues={{
                      promoCode: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                      await handleSubmit(values, actions);
                    }}
                  >
                    {(formikProps) => (
                      <Form className={loginStyles.form}>
                        <fieldset>
                          {formikProps.errors.summary && (
                            <div
                              className={errorFormStyles.error}
                              tabIndex="-1"
                            >
                              <span aria-label={formikProps.errors.summary}>
                                {formikProps.errors.summary}
                              </span>
                            </div>
                          )}

                          <TextField
                            name="promoCode"
                            label="Gift Code"
                            placeholder="Gift Code"
                          />

                          <button
                            type="submit"
                            className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                            disabled={formikProps.isSubmitting}
                          >
                            <div
                              className={
                                formikProps.isSubmitting
                                  ? `${submitStyles.spinner} ${loadingStyles.spinner}`
                                  : loadingStyles.hidden
                              }
                            ></div>
                            <span
                              className={
                                formikProps.isSubmitting
                                  ? submitStyles.invisible
                                  : submitStyles.visible
                              }
                            >
                              <span>Submit</span>
                            </span>
                          </button>

                          {/* <FormikDebug /> */}
                        </fieldset>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div
                data-focus-guard="true"
                tabIndex="0"
                style={{
                  width: '1px',
                  height: '0px',
                  padding: '0px',
                  overflow: 'hidden',
                  position: 'fixed',
                  top: '1px',
                  left: '1px',
                }}
              ></div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
});
