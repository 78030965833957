import { useField } from 'formik';
import React from 'react'
import { useId } from 'react-id-generator';

import inputFieldStyles from '../../styles/input/Field.module.css';
import radioStyles from '../../styles/Radio.module.css';

export const RadioField = ({ options, ...props }) => {
    const [field, meta] = useField(props);


    const [htmlId] = useId();
    const id1 = htmlId + "-1";
    const id2 = htmlId + "-2";

    return (
        <fieldset>
            {options?.map((option, i) => {
                return (
                    <div key={i} className={`${radioStyles.field} ${inputFieldStyles.field}`}>
                        <input {...field} id={`id-${i}-1`} type="radio" role="radio" value={option.value} aria-checked={option.value === field.value} tabIndex="0" aria-labelledby={`id-${i}-2`} className={radioStyles.input} />
                        <label className={radioStyles.label} htmlFor={`id-${i}-1`}>
                            <span className={radioStyles.radio}>
                                {/* <span className={radioStyles.radioOff}></span> */}
                                <span className={`${option.value === field.value ? radioStyles.radioOn : ''} ${radioStyles.radioOff}`}></span>
                            </span>{option.name}</label>
                    </div>
                )
            })}
            {/* 
            // <div className={`${radioStyles.field} ${inputFieldStyles.field}`}>
            //     <input id="id-56-1" type="radio" role="radio" name="gender" aria-checked="" tabIndex="0" aria-labelledby="id-56-2" className={radioStyles.input} />
            //     <label className={radioStyles.label} htmlFor="id-56-1">
            //         <span className={radioStyles.radio}>
            //             <span className={radioStyles.radioOff}>
            //             </span>
            //         </span>Female</label>
            // </div>
            // <div className={`${radioStyles.field} ${inputFieldStyles.field}`}>
            //     <input id="id-57-1" type="radio" role="radio" name="gender" aria-checked="" tabIndex="0" aria-labelledby="id-57-2" className={radioStyles.input} />
            //     <label className={radioStyles.label} htmlFor="id-57-1">
            //         <span className={radioStyles.radio}>
            //             <span className={radioStyles.radioOff}>
            //             </span>
            //         </span>Male</label>
            // </div> 
            */}
        </fieldset>
    )
}
