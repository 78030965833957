import React, { useEffect, useRef, useState } from 'react';
import menuStyles from '../styles/explore/Menu.module.css';
import dropdownStyles from '../styles/dropdown/Default.module.css';
import studioSortStyles from '../styles/explore/StudioSort.module.css';
import mobileSearchStyles from '../styles/MobileSearch.module.css';

import { useSearch } from '../contexts/SearchContext';
import { ClickAwayListener } from '@material-ui/core';
import useWindowSize, { _SM } from '../shared/hooks/useWindowSize';
import { useSite } from '../contexts/SiteContext';

export default function SearchMenu_Sort({
  showResult = false,
}) {
  const {
    searchState: { sort, current_tab },
    setSort,
  } = useSearch();

  const sortRef = useRef(null);
  const [sortOpen, setSortOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const size = useWindowSize();
  const { isMobileApp } = useSite();

  const [sortOptions, setSortOptions] = useState([
    { name: 'Distance', value: 'distance', tab: [0] },
    { name: 'Time', value: 'start_datetime', tab: [1] },
    { name: 'Name', value: 'name', tab: [0,1] },
    { name: 'Newest', value: 'newest', tab: [0] },
    { name: 'Lowest Credits', value: 'credits', tab: [1] },
  ]);

  const handleSortClick = () => setSortOpen(!sortOpen);
  const handleSortClickAway = (e) => {
    if (sortRef.current && sortRef.current.contains(e.target)) {
      return;
    }
    setSortOpen(false);
  };

  const handleSortSelect = (opt) => {
    setSort(opt);
    setSortOpen(false);
  };

  // set selected option
  useEffect(() => {
    const selected = sortOptions.filter(
      (opt) => opt.tab.includes(current_tab) && opt.value === sort
    );
    if (selected.length > 0) {
      setSelectedOption(selected[0]);
    } else {
      // defaults: if studios, use 0. if sessions, use 1
      setSelectedOption(sortOptions[current_tab]);
    }
  }, [sort, current_tab]);

  return (
    <div
      className={`${menuStyles.studioSortContainer} ${studioSortStyles.container} ${isMobileApp ? mobileSearchStyles.sortContainer : ''}`}
    >
      <label htmlFor="sort" id="menu-label" className={studioSortStyles.label}>
        <span>Sort by</span>
      </label>
      <div
        className={`${size[3] <= _SM ? '' : menuStyles.studioSortDropdown} ${
          studioSortStyles.dropdown
        } ${dropdownStyles.label}`}
        data-open={sortOpen}
      >
        <button
          ref={sortRef}
          onClick={handleSortClick}
          className={`${studioSortStyles.dropdownButton} ${dropdownStyles.label}`}
          id="sort"
          name="sort"
          aria-haspopup="listbox"
          aria-labelledby="menu-label sort"
        >
          <span>{selectedOption.name}</span>
          <svg
            aria-hidden="true"
            focusable="false"
            className={`${sortOpen ? dropdownStyles.chevronUp : ''} ${
              dropdownStyles.chevron
            }`}
            viewBox="0 0 24 24"
          >
            <path d="M19.2868077,7.25476326 C19.6293551,6.91389521 20.1833728,6.91525659 20.5242408,7.25780398 C20.8651089,7.60035138 20.8637475,8.15436907 20.5212001,8.49523712 L12.5529074,16.424465 C12.2129441,16.7627617 11.6640093,16.7643441 11.3221012,16.4280131 L3.26139014,8.49878517 C2.9168832,8.1598977 2.91232773,7.60589707 3.2512152,7.26139014 C3.59010267,6.9168832 4.1441033,6.91232773 4.48861023,7.2512152 L11.932163,14.5733516 L19.2868077,7.25476326 Z"></path>
          </svg>
        </button>
        <ClickAwayListener onClickAway={handleSortClickAway}>
          <ul
            role="listbox"
            tabIndex="-1"
            id="studio-sort-menu"
            className={`${studioSortStyles.dropdownContent} ${dropdownStyles.dropdownContent} ${isMobileApp && !showResult ? mobileSearchStyles.sortOptions : ''}`}
            aria-labelledby="menu-label"
            aria-activedescendant="sort-distance"
          >
            <div
              className={`${dropdownStyles.rightIndicator} ${dropdownStyles.leftIndicator} ${isMobileApp && !showResult ? mobileSearchStyles.indicator : ''}`}
            >
              <div className={dropdownStyles.indicator}></div>
            </div>
            {sortOptions
              .filter((opt) => opt.tab.includes(current_tab))
              .map((x, i) => (
                <li
                  key={i}
                  onClick={() => handleSortSelect(x.value)}
                  id={`sort-${x.value}`}
                  role="option"
                  aria-selected={x.value === selectedOption.value}
                  className={`${
                    x.value === selectedOption.value
                      ? studioSortStyles.dropdownItemActive
                      : ''
                  } ${studioSortStyles.dropdownItem} ${
                    dropdownStyles.option
                  } focus_outline`}
                >
                  <span>{x.name}</span>
                </li>
              ))}
          </ul>
        </ClickAwayListener>
      </div>
    </div>
  );
}
