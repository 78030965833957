import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import svgIconStyles from '../styles/SvgIcon.module.css';
import defaultDropdownStyles from '../styles/dropdown/Default.module.css';
import navStyles from '../styles/account/Nav.module.css';
import {
  SETTINGS,
  PROFILE,
  PREFERENCES,
  CREDITS,
  PAYMENT,
} from '../navigation/CONSTANTS';
import { ClickAwayListener } from '@material-ui/core';
import useWindowSize, { _SM } from '../shared/hooks/useWindowSize';

function AccountNavigation() {
  const size = useWindowSize();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClick = (e) => {
    console.log('clicked');
    setOpen(!open);
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      // console.log("clicked inside");
      return;
    }
    setOpen(false);
  };

  const matchSettings = useRouteMatch(SETTINGS);
  const matchProfile = useRouteMatch(PROFILE);
  const matchPreferences = useRouteMatch(PREFERENCES);
  const matchCredits = useRouteMatch(CREDITS);
  const matchPayment = useRouteMatch(PAYMENT);

  const [navTitle, setNavTitle] = useState('Account');
  useEffect(() => {
    if (matchSettings) setNavTitle('Account');
    if (matchProfile) setNavTitle('Profile Details');
    if (matchPreferences) setNavTitle('Workout Preferences');
    if (matchPayment) setNavTitle('Payment Information');
    if (matchCredits) setNavTitle('Credits & Subscription');
  }, [open]);

  return (
    <>
      {size[3] <= _SM ? (
        <div
          className={defaultDropdownStyles.dropdown}
          style={{ zIndex: open ? '12001' : 'inherit' }}
        >
          <button
            ref={anchorRef}
            onClick={handleClick}
            className={`${navStyles.dropdownButton} ${defaultDropdownStyles.label}`}
            aria-haspopup="true"
            aria-expanded={open}
            aria-label="Dropdown Menu"
          >
            <span>{navTitle}</span>
            <svg
              aria-hidden="true"
              focusable="false"
              className={`${open ? defaultDropdownStyles.chevronUp : ''} ${
                defaultDropdownStyles.chevron
              }`}
              viewBox="0 0 24 24"
            >
              <path d="M19.2868077,7.25476326 C19.6293551,6.91389521 20.1833728,6.91525659 20.5242408,7.25780398 C20.8651089,7.60035138 20.8637475,8.15436907 20.5212001,8.49523712 L12.5529074,16.424465 C12.2129441,16.7627617 11.6640093,16.7643441 11.3221012,16.4280131 L3.26139014,8.49878517 C2.9168832,8.1598977 2.91232773,7.60589707 3.2512152,7.26139014 C3.59010267,6.9168832 4.1441033,6.91232773 4.48861023,7.2512152 L11.932163,14.5733516 L19.2868077,7.25476326 Z"></path>
            </svg>
          </button>
          <ClickAwayListener onClickAway={handleClose}>
            <div
              className={defaultDropdownStyles.dropdownContent}
              style={{
                display: open ? 'block' : 'none',
                left: 'auto',
                right: '0px',
              }}
            >
              <button className={defaultDropdownStyles.close}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className={svgIconStyles.icon}
                  viewBox="0 0 24 24"
                >
                  <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z"></path>
                </svg>
              </button>
              <NavLink
                onClick={handleClose}
                to={SETTINGS}
                className={`${navStyles.dropdownItem} ${defaultDropdownStyles.option} focus_outline`}
                activeClassName={`${navStyles.dropdownItemActive} ${defaultDropdownStyles.activeOption}`}
              >
                <span>Account</span>
              </NavLink>
              <NavLink
                onClick={handleClose}
                to={PROFILE}
                className={`${navStyles.dropdownItem} ${defaultDropdownStyles.option} focus_outline`}
                activeClassName={`${navStyles.dropdownItemActive} ${defaultDropdownStyles.activeOption}`}
              >
                <span>Profile Details</span>
              </NavLink>
              <NavLink
                onClick={handleClose}
                to={PREFERENCES}
                className={`${navStyles.dropdownItem} ${defaultDropdownStyles.option} focus_outline`}
                activeClassName={`${navStyles.dropdownItemActive} ${defaultDropdownStyles.activeOption}`}
              >
                <span>Workout Preferences</span>
              </NavLink>
              <NavLink
                onClick={handleClose}
                to={PAYMENT}
                className={`${navStyles.dropdownItem} ${defaultDropdownStyles.option} focus_outline`}
                activeClassName={`${navStyles.dropdownItemActive} ${defaultDropdownStyles.activeOption}`}
              >
                <span>Payment Information</span>
              </NavLink>
              <NavLink
                onClick={handleClose}
                to={CREDITS}
                className={`${navStyles.dropdownItem} ${defaultDropdownStyles.option} focus_outline`}
                activeClassName={`${navStyles.dropdownItemActive} ${defaultDropdownStyles.activeOption}`}
              >
                <span>Credits &amp; Subscription</span>
              </NavLink>
            </div>
          </ClickAwayListener>
        </div>
      ) : (
        <ul className={navStyles.list}>
          <li className={`${navStyles.navItem}`}>
            <NavLink
              to={SETTINGS}
              className={`${navStyles.link}`}
              activeClassName={navStyles.activeLink}
            >
              Account
            </NavLink>
          </li>
          <li className={navStyles.navItem}>
            <NavLink
              to={PROFILE}
              className={navStyles.link}
              activeClassName={navStyles.activeLink}
            >
              Profile Details
            </NavLink>
          </li>
          <li className={navStyles.navItem}>
            <NavLink
              to={PREFERENCES}
              className={navStyles.link}
              activeClassName={navStyles.activeLink}
            >
              Workout Preferences
            </NavLink>
          </li>
          <li className={navStyles.navItem}>
            <NavLink
              to={PAYMENT}
              className={navStyles.link}
              activeClassName={navStyles.activeLink}
            >
              Payment Information
            </NavLink>
          </li>
          <li className={navStyles.navItem}>
            <NavLink
              to={CREDITS}
              className={navStyles.link}
              activeClassName={navStyles.activeLink}
            >
              Credits &amp; Subscription
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );
}

export default AccountNavigation;
