import React from 'react'

import selectStyles from '../../../styles/Select.module.css';
import fieldStyles from '../../../styles/input/Field.module.css';
import defaults from '../../../styles/defaults.module.css';
import dateStyles from '../../../styles/Date.module.css';
import styles from '../../../styles/styles.module.css';
import customClassStyles from '../../../styles/CustomClass.module.css';

import { DatePicker, TimePicker } from '@material-ui/pickers'
import { useField } from 'formik'

const renderInput = ({ error, helperText, ...props }) => (
    <>
        {/* {console.log("renderDate: ", props)} */}
        <label className={`${dateStyles.label} ${fieldStyles.label} ${styles.container}`} htmlFor="id-8-1">{props.label}</label>
        <button
            onClick={props.onClick}
            onChange={props.onChange}
            type="button"
            className={`${error ? dateStyles.inputError : ''} ${dateStyles.input} ${defaults.input}`} tabIndex="0">
            {props.value ? props.value : props.label}
            <svg aria-hidden="true" focusable="false" className={`${dateStyles.caret} ${selectStyles.caret}`} viewBox="0 0 24 24">
                <path d="M19.2868077,7.25476326 C19.6293551,6.91389521 20.1833728,6.91525659 20.5242408,7.25780398 C20.8651089,7.60035138 20.8637475,8.15436907 20.5212001,8.49523712 L12.5529074,16.424465 C12.2129441,16.7627617 11.6640093,16.7643441 11.3221012,16.4280131 L3.26139014,8.49878517 C2.9168832,8.1598977 2.91232773,7.60589707 3.2512152,7.26139014 C3.59010267,6.9168832 4.1441033,6.91232773 4.48861023,7.2512152 L11.932163,14.5733516 L19.2868077,7.25476326 Z">
                </path></svg>
        </button>
        {error ? (<>
            <span className={fieldStyles.errorLabel}>{helperText}</span>
            <div className={fieldStyles.errorIcon}>!</div>
        </>) : ''}
        <div className={dateStyles.picker} style={{ display: "none" }}>
            <div style={{ fontSize: "16px", lineHeight: "24px", width: "256px", height: "48px", display: "inline-block", position: "relative", backgroundColor: "transparent", fontFamily: "Roboto, sans-serif", transition: "height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms", cursor: "auto" }}>
                <input type="text" name={props.name} id="id-8-1" style={{ appearance: "textfield", padding: "0px", position: "relative", width: "100%", border: "none", outline: "none", backgroundColor: "rgba(0, 0, 0, 0)", color: "rgba(0, 0, 0, 0.87)", cursor: "inherit", font: "inherit", opacity: "1", WebkitTapHighlightColor: "rgba(0, 0, 0, 0)", height: "100%" }} />
                <div>
                    <hr aria-hidden="true" style={{ borderTop: "none rgb(224, 224, 224)", borderLeft: "none rgb(224, 224, 224)", borderRight: "none rgb(224, 224, 224)", borderBottom: "1px solid rgb(224, 224, 224)", bottom: "8px", boxSizing: "content-box", margin: "0px", position: "absolute", width: "100%" }} />
                    <hr aria-hidden="true" style={{ borderTop: "none rgb(230, 23, 97)", borderLeft: "none rgb(230, 23, 97)", borderRight: "none rgb(230, 23, 97)", borderBottom: "2px solid rgb(230, 23, 97)", bottom: "8px", boxSizing: "content-box", margin: "0px", position: "absolute", width: "100%", transform: "scaleX(0)", transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms" }} />
                </div>
            </div>
            <div></div>
        </div>
    </>
);

export default function CustomDatePicker({ type, label, ...props }) {
    const [field, meta, helpers] = useField(props);
    return (
        <div className={`${type === "time" ? customClassStyles.time : customClassStyles.date} ${customClassStyles.datetimeSelect} ${dateStyles.field} ${fieldStyles.field} ${customClassStyles.sharedFieldStyles} ${meta.touched && meta.error ? dateStyles.fieldError : ''}`}>
            {type === "time" ?
                <TimePicker
                    label={label}
                    name={field.name}
                    value={field.value ?? null}
                    onChange={helpers.setValue}
                    TextFieldComponent={renderInput}

                    error={meta.touched && meta.error}
                    helperText={meta.error}
                /> :
                <DatePicker
                    label={label}
                    name={field.name}
                    value={field.value ?? null}
                    format="E, MMM d"
                    onChange={helpers.setValue}
                    TextFieldComponent={renderInput}

                    error={meta.touched && meta.error}
                    helperText={meta.error}
                />}
        </div>
    )
}
