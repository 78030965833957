import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';

import dividerStyles from '../../styles/Divider.module.css';
import cardStyles from '../../styles/Card.module.css';
import inputFieldStyles from '../../styles/input/Field.module.css';
import pageStyles from '../../styles/profile/Page.module.css';
import memberIdStyles from '../../styles/MemberId.module.css';
import emailFieldStyles from '../../styles/EmailField.module.css';
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { TextField } from '../../shared/Formik/TextField1';
import * as Yup from 'yup';
import moment from 'moment';
import { DateField } from '../../shared/Formik/DateField1';
import { PhoneField } from '../../shared/Formik/PhoneField';

import SubmitButton from '../../shared/Formik/SubmitButton';
import { RadioField } from '../../shared/Formik/RadioField';
import { PhotoUpload } from './PhotoUpload';
import userApi from '../../api/user';
import errorFormStyles from '../../styles/error/Form.module.css';
import HomeCity from './HomeCity';
import { logAmplitudeEvent, logBrazeEvent } from '../../api/integration';

export default function ProfileInfo() {
  const {
    authState: { user },
    verify,
  } = useAuth();
  const { userprofile } = user || {};
  const [verified, setVerified] = useState(false);
  useEffect(() => {
    setVerified(!!user?.email_addresses?.find((x) => x.primary && x.verified));
  }, [user]);
  const [showSaved, setShowSaved] = useState(false);

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  const MAX_FILE_SIZE = 1000000;
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required().label('First Name'),
    last_name: Yup.string().required().label('Last Name'),
    email: Yup.string()
      .required()
      .email('Invalid email address')
      .label('Email'),
    // https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
    phone: Yup.string()
      .matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        'Enter a valid phone number.'
      )
      .label('Phone'), // use basic regex
    birth_date: Yup.date()
      .required()
      .typeError(
        'Date of Birth must be a valid date, with the format of MM/DD/YYYY.'
      )
      .min(
        new Date(1000, 1, 1),
        'Date of Birth must be a valid date, with the format of MM/DD/YYYY.'
      )
      .max(
        moment().endOf('day').subtract(13, 'years'),
        'Must be at least 13 years of age.'
      )
      .label('Date of Birth'),
    zipcode: Yup.string().required().label('Home Zip Code'),
    profile_image: Yup.mixed()
      .test('fileSize', 'The file is too large', (value) => {
        console.log('fileSize: ', value?.size);
        return (
          value == userprofile?.profile_image ||
          value == null ||
          (value && value.size <= MAX_FILE_SIZE)
        );
      })
      .test('fileFormat', 'JPG, PNG only', (value) => {
        console.log('fileFormat: ', value?.type);
        return (
          value == userprofile?.profile_image ||
          value == null ||
          (value && SUPPORTED_FORMATS.includes(value.type))
        );
      }),
  });

  const handleSubmit = async (values, { setErrors }) => {
    const profileImageChanged =
      user?.userprofile?.profile_image !== values.profile_image;
    await userApi
      .updateProfile({ ...values, userId: user?.id })
      .then((res) => {
        // call verify() to reload userprofile.
        verify();

        // added phone event
        if (!user?.phone && values.phone) {
          logBrazeEvent('Profile: Add Phone Success');
          logAmplitudeEvent('Profile: Add Phone Success');
        }
        if (profileImageChanged) {
          logAmplitudeEvent('Profile: Change Photo Success');
        }
      })
      .catch((error) => {
        console.log('errorList:', error);
        if (profileImageChanged) {
          logAmplitudeEvent('Profile: Change Photo Failure');
        }
        if (error.status == 400) {
          setErrors({ summary: error.data.Errors[0].message });
        } else {
          setErrors({
            summary:
              "Can't update profile at this time. Please try again later.",
          });
        }
      });
  };

  const initialValue = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    home_city: user?.home_city,
    email: user?.email,
    phone: user?.phone,
    birth_date: [
      user?.birth_date?.split('-')[2],
      user?.birth_date?.split('-')[1],
      user?.birth_date?.split('-')[0],
    ].join('/'), // yyyy-dd-mm => mm/dd/yyyy
    zipcode: user?.zipcode ?? '',
    gender: user?.gender,
    profile_image: user?.userprofile?.profile_image,
  };

  useEffect(() => {
    logAmplitudeEvent('Screen View: Settings - About');
  }, []);

  return (
    <>
    <Dialog open={showSaved} maxWidth="lg" hideBackdrop={true} onClose={() => setShowSaved(false)}>
      <div style={{
        padding: '12px 16px',
        background: '#333',
        fontSize: 14,
        color: 'white',
      }}>
        Saved!
      </div>
    </Dialog>
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await handleSubmit(values, actions);
        setShowSaved(true)
        setTimeout(() => {
          setShowSaved(false)
        }, 1000)
      }}
    >
      {(formikProps) => (
        <Form>
          <Card className={cardStyles.container}>
            <CardHeader
              title={
                <h3 className={cardStyles.title}>
                  <span>Profile Photo</span>
                </h3>
              }
            />
            <CardContent
              classes={{ root: `${cardStyles.hasTitle} ${cardStyles.content}` }}
            >
              <PhotoUpload name="profile_image" />
            </CardContent>
          </Card>
          <Card className={cardStyles.container}>
            <CardHeader
              title={
                <h3 className={cardStyles.title}>
                  <span>Profile</span>
                </h3>
              }
            />
            <CardContent
              classes={{ root: `${cardStyles.hasTitle} ${cardStyles.content}` }}
            >
              <div className={cardStyles.section}>
                <h4 className={cardStyles.sectionTitle}>
                  <div>
                    <span>Info</span>
                    <p>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        className={pageStyles.lock}
                        viewBox="0 0 24 24"
                      >
                        <path d="M8.75,11 L7.25,11 L7.25,7 C7.25,4.37653644 9.37653644,2.25 12,2.25 C14.6234636,2.25 16.75,4.37653644 16.75,7 L16.75,11 L15.25,11 L15.25,7 C15.25,5.20496356 13.7950364,3.75 12,3.75 C10.2049636,3.75 8.75,5.20496356 8.75,7 L8.75,11 Z"></path>
                        <path d="M4.25,11.0450751 C4.25,10.6318042 4.58431655,10.2964095 4.99758528,10.2950789 L18.9975853,10.2500039 C19.41274,10.2486672 19.75,10.5848431 19.75,11 L19.75,19.9618159 C19.75,20.3760294 19.4142136,20.7118159 19,20.7118159 L5,20.7118159 C4.58578644,20.7118159 4.25,20.3760294 4.25,19.9618159 L4.25,11.0450751 Z M5.75,11.7926642 L5.75,19.2118159 L18.25,19.2118159 L18.25,11.7524186 L5.75,11.7926642 Z"></path>
                      </svg>
                      <span>We never share this info.</span>
                    </p>
                    <div
                      className={`${pageStyles.memberId} ${memberIdStyles.memberId}`}
                    >
                      <span>Peerfit Member ID:</span>
                      <span>{`${user?.pmid?.slice(0, 2)} ${user?.pmid?.slice(
                        2,
                        6
                      )} ${user?.pmid?.slice(6)}`}</span>
                    </div>
                  </div>
                </h4>
                <div className={cardStyles.sectionContent}>
                  {formikProps.errors.summary && (
                    <div className={errorFormStyles.error} tabIndex="-1">
                      <span aria-label={formikProps.errors.summary}>
                        {formikProps.errors.summary}
                      </span>
                    </div>
                  )}
                  <div className={pageStyles.nameContainer}>
                    <TextField
                      name="first_name"
                      label="First Name"
                      placeholder="First Name"
                      classProps={`${pageStyles.halfFields} ${pageStyles.left}`}
                    />
                    <TextField
                      name="last_name"
                      label="Last Name"
                      placeholder="Last Name"
                      classProps={`${pageStyles.halfFields} ${pageStyles.right}`}
                    />
                  </div>

                  <HomeCity name="home_city" label="City" />
                  <div
                    className={`${pageStyles.divider} ${dividerStyles.divider}`}
                  ></div>
                  <div className={emailFieldStyles.container}>
                    <span
                      className={`${emailFieldStyles.verified} ${
                        verified ? '' : emailFieldStyles.unverified
                      }`}
                    >
                      {verified ? 'Verified' : 'UnVerified'}
                    </span>
                    <TextField
                      name="email"
                      type="email"
                      label="Email"
                      placeholder="Email"
                      autoComplete="email"
                      readOnly
                      icon={
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          className={inputFieldStyles.icon}
                          viewBox="0 0 24 24"
                        >
                          <path d="M8.75,11 L7.25,11 L7.25,7 C7.25,4.37653644 9.37653644,2.25 12,2.25 C14.6234636,2.25 16.75,4.37653644 16.75,7 L16.75,11 L15.25,11 L15.25,7 C15.25,5.20496356 13.7950364,3.75 12,3.75 C10.2049636,3.75 8.75,5.20496356 8.75,7 L8.75,11 Z"></path>
                          <path d="M4.25,11.0450751 C4.25,10.6318042 4.58431655,10.2964095 4.99758528,10.2950789 L18.9975853,10.2500039 C19.41274,10.2486672 19.75,10.5848431 19.75,11 L19.75,19.9618159 C19.75,20.3760294 19.4142136,20.7118159 19,20.7118159 L5,20.7118159 C4.58578644,20.7118159 4.25,20.3760294 4.25,19.9618159 L4.25,11.0450751 Z M5.75,11.7926642 L5.75,19.2118159 L18.25,19.2118159 L18.25,11.7524186 L5.75,11.7926642 Z"></path>
                        </svg>
                      }
                    />
                  </div>
                  <PhoneField name="phone" label="Phone" autoComplete="tel" />
                  <DateField
                    name="birth_date"
                    label="Date of Birth"
                    placeholder="MM/DD/YYYY"
                    autoComplete="off"
                  />
                  <TextField
                    name="zipcode"
                    type="text"
                    label="Home Zip Code"
                    placeholder="Home Zip Code"
                  />
                  <RadioField
                    name="gender"
                    options={[
                      { name: 'Female', value: 'F' },
                      { name: 'Male', value: 'M' },
                    ]}
                  />
                  <div
                    className={`${pageStyles.divider} ${dividerStyles.divider}`}
                  ></div>
                  <UpdateFormWhenUserChange
                    user={user}
                    formikProps={formikProps}
                    initialValue={initialValue}
                  />
                  <SubmitButton label="Save Changes" />
                </div>
              </div>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
    </>
  );
}

const UpdateFormWhenUserChange = ({ user, formikProps, initialValue }) => {
  useEffect(() => {
    if (user) {
      formikProps.setValues(initialValue);
    }
  }, [user]);
  return null;
};
