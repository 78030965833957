import React, { useEffect } from 'react';
import cardStyles from '../../styles/Card.module.css';
import imageUploaderStyles from '../../styles/ImageUploader.module.css';
import inputFieldStyles from '../../styles/input/Field.module.css';
import styles from '../../styles/styles.module.css';
import locationStyles from '../../styles/Location.module.css';
import dividerStyles from '../../styles/Divider.module.css';
import radioStyles from '../../styles/Radio.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import phoneStyles from '../../styles/Phone.module.css';
import submitStyles from '../../styles/Submit.module.css';
import defaultBtnStyles from '../../styles/button/Default.module.css';
import comboboxStyles from '../../styles/Combobox.module.css';
import memberIdStyles from '../../styles/MemberId.module.css';
import fineprintStyles from '../../styles/Fineprint.module.css';
import utilities from '../../styles/utilities.module.css';
import emailFieldStyles from '../../styles/EmailField.module.css';
import { useAuth } from '../../contexts/AuthContext';
import ProfileInfo from './ProfileInfo';

export const Profile = () => {
  const {
    authState: { user },
  } = useAuth();
  // useEffect(() => {
  //    console.log("user: ", user);
  // }, [user])
  /*
        Profile page will load the following components:
            1. Profile (might not need these as separate components.)
            1. or try ProfilePhoto & ProfileInfo
   */
  return !user ? (
    ''
  ) : (
    <>
      <ProfileInfo />
    </>
  );
};
