import { getGeocode, getLatLng, getDetails } from 'use-places-autocomplete';

// given a place_id, return latlng.
export const geocode = async (place_id) => {
  // console.log("geocode:", place_id);
  return getGeocode({ placeId: place_id })
    .then((results) => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      // console.log("Coordinates:", { lat, lng });
      return { lat, lng };
    })
    .catch((error) => {
      console.log('geocode Error:', error);
      throw error;
    });
};

export const geocodeAddress = async (address) => {
  // console.log("geocodeAddress:", address);
  return getGeocode({ address: address })
    .then((results) => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      // console.log("Coordinates:", { lat, lng });
      return { lat, lng };
    })
    .catch((error) => {
      console.log('geocode Error:', error);
      throw error;
    });
};

export const getTzString = async (lat, lon) => {
  var now = Math.floor(Date.now() / 1000);
  return fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lon}&timestamp=${now}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`)
    .then((res) => res.json())
    .then((res) => {
      // console.log("GOOGLE MAP",res)
      return res.timeZoneId;
    })
    .catch((error) => {
      console.log(`Error getting timezoneId for [${lat}],[${lon}]`);
      return null;
    });
};

export const getCityByPlaceId = async (placeId) => {
  return getDetails({ placeId })
    .then((res) => {
      console.log('GOOGLE MAP city', res);
      return res;
    })
    .catch((error) => {
      console.log(`Error getting city for ${placeId}`);
      return null;
    });
};

export const getPlaceIdByLocation = async (location) => {
  return getGeocode({ location })
    .then((result) => {
      console.log('location', result);
      return result;
    })
    .catch((error) => {
      console.log(`Error getting location for [${location.lat}],[${location.lon}]`);
      return null;
    });
};

export const getDefaultBounds = (coords) => {
  const [lat, lng] = coords;
  return [lng - 0.5, lat - 0.4, lng + 0.5, lat + 0.4];
};
