import { getIsMoveSite } from '../../utilities/siteType';
import { getFitOnSignature } from '../fitOn';
import { get } from 'lodash';

export const genSignUpParams = async (user, source) => {
  const isMoveSite = getIsMoveSite();
  let partner = isMoveSite ? 'move' : 'core';
  if (!isMoveSite) {
    ['core-select', 'core-digital'].forEach((key) => {
      if (user?.network_names?.includes(key)) {
        partner = key;
      }
    });
  }
  const data = {
    partner,
    email: get(user, 'email'),
    source,
    pfuser_id: get(user, 'id')?.toString() || '',
    sponsor_name: get(user, 'sponsorship.sponsor.name') || '',
    sponsor_id: get(user, 'sponsorship.sponsor.id')?.toString() || '',
    username: get(user, 'username') || '',
    external_id: get(user, 'partner_external_id')?.toString() || '',
  };
  const signString = JSON.stringify({
    pfUserId: data.pfuser_id,
    source: data.source,
    sponsorId: data.sponsor_id,
    sponsorName: data.sponsor_name,
    externalId: data.external_id,
  });
  const signature = await getFitOnSignature(signString);
  const params = `data=${window.btoa(
    encodeURIComponent(JSON.stringify(data))
  )}&signature=${signature}&abButtonId=0`;
  return params;
};

export const getSignUpFullUrl = async (user, source) => {
  const params = await genSignUpParams(user, source);
  return `${process.env.REACT_APP_FIT_ON_URL}/peerfit-signup?${params}`;
};

export const defaultSignUpUrl = `${process.env.REACT_APP_FIT_ON_URL}/peerfit-signup`;
