export const setToken = (token) => sessionStorage.setItem('token', token);
export const getToken = () => sessionStorage.getItem('token');
export const clearToken = () => {
  sessionStorage.removeItem('token');
};

export const setPFToken = (token) =>
  localStorage.setItem('PF_ACCESS_TOKEN', token);
export const getPFToken = () => localStorage.getItem('PF_ACCESS_TOKEN');
export const clearPFToken = () => {
  localStorage.removeItem('PF_ACCESS_TOKEN');
};
