import React from 'react';
import { Link } from 'react-router-dom';

import infoStyles from '../../styles/user/Info.module.css';
import userStyles from '../../styles/user/User.module.css';
import memberIdStyles from '../../styles/user/MemberId.module.css';
import svgIconStyles from '../../styles/SvgIcon.module.css';
import { CREDITS, PROFILE } from '../../navigation/CONSTANTS';
import { useAuth } from '../../contexts/AuthContext';

function Info() {
  const { authState } = useAuth();
  const { user } = authState;

  return (
    <div className={infoStyles.container}>
      <div className={infoStyles.inner}>
        <div className={infoStyles.profile}>
          <div className={`${infoStyles.avatar} ${userStyles.avatar}`}>
            {user?.userprofile?.profile_image ? (
              <div
                className={userStyles.image}
                style={{
                  backgroundImage: `url(${user?.userprofile?.profile_image})`,
                }}
              ></div>
            ) : (
              <svg
                aria-hidden="true"
                focusable="false"
                className={`${userStyles.noImage} ${svgIconStyles.icon}`}
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M20,19.5c-2,2.1-4.8,3.5-8,3.5c-3.1,0-6-1.3-8-3.5c0.1-1.3,0.4-2.1,2.1-2.5c2.2-0.5,4.5-1,3.4-2.9C6.4,8.3,8.6,5,12,5 c3.3,0,5.6,3.2,2.5,9.1c-1,2,1.1,2.4,3.4,2.9C19.6,17.5,19.9,18.2,20,19.5z M24,12c0,6.6-5.4,12-12,12c0,0,0,0,0,0 C5.4,24,0,18.6,0,12C0,5.4,5.4,0,12,0C18.6,0,24,5.4,24,12z M23,12c0-6.1-4.9-11-11-11S1,5.9,1,12c0,2.9,1.2,5.6,3,7.5 c2,2.1,4.8,3.5,8,3.5c3.1,0,6-1.3,8-3.5C21.8,17.6,23,14.9,23,12z"></path>
              </svg>
            )}
          </div>
        </div>
        <div className={infoStyles.info}>
          <div className={infoStyles.titleRow}>
            <h2
              className={infoStyles.name}
            >{`${user?.first_name} ${user?.last_name}`}</h2>
            <div
              className={`${infoStyles.memberId} ${memberIdStyles.memberId}`}
            >
              <span>Peerfit Member ID:</span>
              <span>{user?.pmid}</span>
            </div>
          </div>
          <Link to={PROFILE} className={infoStyles.editBtnMobile}>
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0518 3.11462L13.3851 6.44796"
                stroke="#1F274C"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.83301 14L1.83301 14.6667L2.49967 10.6667L11.4423 1.724C11.6924 1.47404 12.0315 1.33362 12.385 1.33362C12.7386 1.33362 13.0776 1.47404 13.3277 1.724L14.7757 3.172C15.0256 3.42204 15.1661 3.76111 15.1661 4.11467C15.1661 4.46822 15.0256 4.8073 14.7757 5.05733L5.83301 14Z"
                stroke="#1F274C"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Edit Profile
          </Link>
          <Link className={infoStyles.credits} to={CREDITS}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1624_37379)">
                <path
                  d="M13.907 7.23329C13.9644 7.04969 13.9958 6.85895 14.0003 6.66663V3.99996C14.0003 2.09996 11.1337 0.666626 7.33366 0.666626C3.53366 0.666626 0.666992 2.09996 0.666992 3.99996V6.66663C0.703742 7.11076 0.854326 7.53794 1.10422 7.90694C1.35411 8.27594 1.69488 8.57432 2.09366 8.77329C2.03245 8.95368 2.00093 9.14281 2.00033 9.33329V12C2.00033 13.9 4.86699 15.3333 8.66699 15.3333C12.467 15.3333 15.3337 13.9 15.3337 12V9.33329C15.2977 8.88978 15.1474 8.46317 14.8973 8.09511C14.6473 7.72705 14.3061 7.43011 13.907 7.23329ZM7.33366 9.99996C9.90033 9.99996 12.0403 9.33329 13.1603 8.33329C13.6937 8.65329 14.0003 8.99996 14.0003 9.33329C14.0003 10.1466 11.9203 11.3333 8.66699 11.3333C5.41366 11.3333 3.39366 10.18 3.33366 9.36663C4.62069 9.80602 5.97384 10.0203 7.33366 9.99996ZM7.33366 1.99996C10.587 1.99996 12.667 3.18663 12.667 3.99996C12.667 4.81329 10.587 5.99996 7.33366 5.99996C4.08033 5.99996 2.00033 4.81329 2.00033 3.99996C2.00033 3.18663 4.08033 1.99996 7.33366 1.99996ZM2.00033 6.03996C3.62178 6.96234 5.46981 7.41048 7.33366 7.33329C9.19751 7.41048 11.0455 6.96234 12.667 6.03996V6.66663C12.667 7.47996 10.587 8.66663 7.33366 8.66663C4.08033 8.66663 2.00033 7.47996 2.00033 6.66663V6.03996ZM8.66699 14C5.41366 14 3.33366 12.8133 3.33366 12V11.3733C4.95511 12.2957 6.80314 12.7438 8.66699 12.6666C10.5308 12.7438 12.3789 12.2957 14.0003 11.3733V12C14.0003 12.8133 11.9203 14 8.66699 14Z"
                  fill="#2B4596"
                />
              </g>
              <defs>
                <clipPath id="clip0_1624_37379">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {user.credits} credits
            <svg
              className={infoStyles.creditsAction}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 14L11 8L5 2"
                stroke="#2B4596"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Info;
