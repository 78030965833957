// import appboy from '@braze/web-sdk';
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import authApi from '../../api/auth';
import { logAmplitudeEvent, logBrazeEvent, logCustomUserAttribute } from '../../api/integration';
import {
  getPublicClient,
  loginRequest,
  passwordResetRequest,
  registerAetnaRequest,
  registerConsumerRequest,
  registerPmidRequest,
  registerSponsoredRequest,
} from '../../api/configs/b2cConfig';
import paymentApi from '../../api/payment';
import userApi from '../../api/user';
import {
  authReducer,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  SET_LOGGING_OUT,
  SET_REGISTER_USER,
  SET_CHANGE_PASSWORD_PIN_CODE,
  SET_REGISTERING,
} from './AuthReducer';
import { initialAuthState } from './initialAuthState';
import { clearPFToken, getPFToken } from '../../utilities/TokenHelper';
import { parseJwt } from '../../utilities/jwt';
import { getFeatureFlag } from '../../api/config';
import * as pfAuthApi from '../../api/authentication';
import { DISCOVER, HOME, REGISTER_USER } from '../../navigation/CONSTANTS';
import { useHistory } from 'react-router-dom';
import { differenceInYears } from 'date-fns/esm';
import { callRNFunction, isReactNative, postMessageToRN } from '../../utilities/rn';
import { getIsMobileSite } from '../../utilities/siteType';

export const REDIRECT_STORAGE_KEY = 'PF_LOGIN_REDIRECT_PATH';

export const AuthContext = createContext<ReturnType<typeof useAuthContext>>(null);
export const useAuth = () => useContext(AuthContext);

const useAuthContext = () => {
  const [authState, dispatch] = useReducer(authReducer, initialAuthState) as any;
  const [card, setCard] = useState();
  const [memberships, setMemberships] = useState([]);
  const [isRegister, setIsRegister] = useState(false);
  const [isPFLogin, setIsPFLogin] = useState(undefined);
  const [hasMobileLocationPermission, setHasMobileLocationPermission] = useState(false);
  const history = useHistory();
  const isMobileApp = getIsMobileSite();

  const requestMobileLocationPermission = async () => {
    if (isMobileApp) {
      if (isReactNative) {
        try {
          await callRNFunction('requestLocationPermission');
          setHasMobileLocationPermission(true);
        } catch (error) {
          console.log('error', error);
        }
      } else {
        navigator?.geolocation?.getCurrentPosition(
          () => {
            setHasMobileLocationPermission(true);
          },
          () => {
            setHasMobileLocationPermission(false);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      }
    }
  };

  // resp is provided if it was a callback from b2c login or acquireToken. log in user and get data.
  // else, call verifyJwt to acquireToken.
  const handleResponse = async (resp) => {
    // console.log("handleResponse()", resp);
    if (resp != null) {
      // console.log("TOKEN RESPONSE RECEIVED:", resp);
      // var username = resp.account.username;
      // var b2cAccountId = resp.account.localAccountId;
      sessionStorage.setItem('token', resp.accessToken);
      var userId = resp.account.idTokenClaims['extension_contactId'];
      var pfUser = await userApi.getPfUser(userId);

      // appboy.changeUser(userId);
      // appboy.getUser().setEmail(pfUser.email);
      logBrazeEvent('Signin: Success');
      logAmplitudeEvent('Signin: Success');

      dispatch({
        type: LOGIN_SUCCESS,
        is_admin: false,
        token: resp.accessToken,
        user: pfUser,
      });
      requestMobileLocationPermission();
    } else {
      console.log('ACQUIRING TOKEN SILENTLY');
      verifyJwt(); // calls acquireTokenSilent then calls handleresponse(resp)
    }
  };

  const loginRedirect = () => {
    const redirectPath = localStorage.getItem(REDIRECT_STORAGE_KEY) || DISCOVER;
    localStorage.removeItem(REDIRECT_STORAGE_KEY);
    history.push(redirectPath);
  };

  const logEventsWhenRegister = async () => {
    const token = await authApi.getAccessToken();
    const userId = parseJwt(token)?.['nameid'];

    try {
      if (userId) {
        const pfUser = await userApi.getPfUser(userId);
        // appboy.changeUser(userId);
        // appboy.getUser().setEmail(pfUser.email);
        logCustomUserAttribute('user_alias_name', pfUser.username);
        logCustomUserAttribute('user_alias_label', pfUser.id);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const verifyPFToken = async (isSignUp = false, skipEvent = false, skipRedirect = false) => {
    const token = await authApi.getAccessToken();
    const decoded = parseJwt(token);
    const userId = decoded?.['nameid'];
    const registering = parseJwt(token)?.['registering'];
    const isUnregistered = registering === 'True';
    let isB2C = null;

    if (isUnregistered && decoded?.registration_type) {
      setRegisterUser({
        email: decoded.email,
        registrationType: Number(decoded.registration_type),
      });
      setRegistering(true);
      if (!skipRedirect) {
        // if the path is home, clear the registering token
        if (![HOME].includes(window.location.pathname)) {
          history.push({
            pathname: REGISTER_USER,
          });
        } else {
          clearPFToken();
        }
      }
    }

    if (userId) {
      const pfUser = await userApi.getPfUser(userId);
      isB2C = pfUser.is_b2c;
      postMessageToRN({ key: 'token', value: token });

      // appboy.changeUser(userId);
      // appboy.getUser().setEmail(pfUser.email);
      if (isSignUp) {
        try {
          const age = pfUser?.birth_date ? differenceInYears(new Date(), new Date(pfUser?.birth_date)) : undefined;
          logBrazeEvent('Signup: Success', {
            'Group ID': pfUser?.sponsorship?.sponsor?.id,
            'Group Name': pfUser?.sponsorship?.sponsor?.name,
            Source: 'Website',
            Age: age,
            'Login Type': 'New',
          });
          logAmplitudeEvent('Signup: Success', {
            'Group ID': pfUser?.sponsorship?.sponsor?.id,
            'Group Name': pfUser?.sponsorship?.sponsor?.name,
            Source: 'Website',
            Age: age,
            'Login Type': 'New',
          });
        } catch (error) {
          logBrazeEvent('Signup: Success', {
            'Login Type': 'New',
          });
          logAmplitudeEvent('Signup: Success', {
            'Login Type': 'New',
          });
        }
      } else {
        if (!skipEvent) {
          logBrazeEvent('Signin: Success');
          logAmplitudeEvent('Signin: Success', { 'Login Type': isB2C ? 'B2C' : 'New' });
        }
      }

      dispatch({
        type: LOGIN_SUCCESS,
        is_admin: false,
        token,
        user: pfUser,
        registering: registering === 'True',
      });
      requestMobileLocationPermission();
    }

    return { isB2C, isUnregistered };
  };

  const publicClient = getPublicClient();

  const login = () => {
    const pathname = window.location.pathname;
    if (pathname !== '/') {
      localStorage.setItem(REDIRECT_STORAGE_KEY, pathname);
    }
    logAmplitudeEvent('Screen View: Signin');
    if (isPFLogin === undefined) {
      return setTimeout(login, 500);
    } else if (isPFLogin) {
      return history.push('/login');
    } else {
      return publicClient.loginRedirect(loginRequest);
    }
  };

  const register_consumer = async () => {
    if (isRegister) return;
    await publicClient.handleRedirectPromise();
    setIsRegister(true);
    await publicClient.loginRedirect(registerConsumerRequest);
    setIsRegister(false);
  };

  const register_sponsored = async () => {
    if (isRegister) return;
    await publicClient.handleRedirectPromise();
    setIsRegister(true);
    await publicClient.loginRedirect(registerSponsoredRequest);
    setIsRegister(false);
  };

  const register_pmid = async () => {
    if (isRegister) return;
    await publicClient.handleRedirectPromise();
    setIsRegister(true);
    await publicClient.loginRedirect(registerPmidRequest);
    setIsRegister(false);
  };

  const register_aetna = async () => {
    if (isRegister) return;
    await publicClient.handleRedirectPromise();
    setIsRegister(true);
    await publicClient.loginRedirect(registerAetnaRequest);
    setIsRegister(false);
  };

  const password_reset = async () => {
    await publicClient.loginRedirect(passwordResetRequest);
  };

  const getB2cAccount = () => {
    const b2cAccount = publicClient.getAllAccounts()[0];
    if (!b2cAccount) return null;
    return b2cAccount;
  };

  // first handleResponse (if its a b2c redirect callback.)
  // then verify jwt.
  const verify = async () => {
    console.log('verifying.');
    let pfLoginFlag = isPFLogin;
    if (pfLoginFlag === undefined) {
      pfLoginFlag = await getFeatureFlag('replace_b2c');
      setIsPFLogin(pfLoginFlag);
      if (pfLoginFlag === false) {
        clearPFToken();
      }
    }
    if (authState.IsAuthenticated) {
      console.log('authenticated.');
      return;
    }
    const pfToken = getPFToken();
    if (pfLoginFlag && pfToken) {
      try {
        await verifyPFToken();
        return;
      } catch (error) {
        console.error(error);
        console.log('pfLogin failed try b2c.');
      }
    }
    return publicClient
      .handleRedirectPromise()
      .then(handleResponse)
      .catch((error) => {
        console.log('B2C Error:', error.response);
        if (error.message.includes('AADB2C90118')) {
          // Login=>ForgotPassword: The user has forgotten their password.
          password_reset();
        } else if (error.message.includes('AADB2C90091')) {
          // AADB2C90091: ChangePassword=>Cancel The user has canceled entering self-asserted information.
          // canceled from signup / forogtPassword: redirect to register/
          // canceled from changePassword: redirect to account/settings/
          verifyJwt();
        } else {
          // This catch might not be needed since verifyJwt will dispatch fail if SilentToken fails.
          console.log('LOGIN FAILED.', error);
          dispatch({ type: LOGIN_FAIL });
          throw error;
        }
      });
  };

  const verifyJwt = async () => {
    var acct = getB2cAccount();
    if (acct) {
      var accessTokenRequest = {
        scopes: [`https://${process.env.REACT_APP_B2C}.onmicrosoft.com/${process.env.REACT_APP_B2C_PF_GATEWAY_CLIENT_ID}/user_impersonation`],
        account: acct,
      };
      console.log('VERIFYING JWT.');
      await publicClient
        .acquireTokenSilent(accessTokenRequest)
        .then((tokenResponse) => {
          handleResponse(tokenResponse);
        })
        .catch(async (error) => {
          console.log('SILENT FAILED, ATTEMPTING REDIRECTING:', error);
          await publicClient.acquireTokenRedirect(accessTokenRequest); // NEED TO TEST THIS.
        });
    } else {
      console.log('FAILED NO B2CACCOUNT FOUND.');
      dispatch({ type: LOGIN_FAIL });
    }
  };

  const logout = async () => {
    dispatch({ type: SET_LOGGING_OUT });
    try {
      await Promise.all([
        pfAuthApi.logout(),
        publicClient.logout({
          account: publicClient.getAccountByUsername(authState.username),
        }),
      ]);
    } catch (error) {
      console.error(error);
    }
    clearPFToken();
    loginRedirect();
  };

  const verifyEnrollment = async ({ birth_date, pmid }) => {
    return authApi
      .verifyEnrollment({ birth_date, pmid })
      .then((data) => {
        console.log('verifyEnrollment success:', data);
      })
      .catch((err) => {
        console.log('verifyEnrollment error:', err.response);
        throw err;
      });
  };

  const register = async ({ birth_date, email, first_name, last_name, mailing_list, password1, terms_of_use, zipcode }) => {
    /*
        api register
            - success setSessionStorage token, set user, set isadmin, set authenticated
            - failed: page will handle.
    */
    return authApi
      .register({
        birth_date,
        email,
        first_name,
        last_name,
        mailing_list,
        password1,
        terms_of_use,
        zipcode,
      })
      .then((data) => {
        if (data) {
          console.log('register success:', data);
          dispatch({
            type: REGISTER_SUCCESS,
            is_admin: data.is_admin,
            token: data.token,
            user: data.user,
          });
        } else {
          console.log('register no data.');
        }
      })
      .catch((err) => {
        console.log('register error:', err.response);
        throw err;
      });
  };

  const [isCardLoading, setIsCardLoading] = useState(false);
  const loadCard = async () => {
    try {
      setIsCardLoading(true);
      const defaultSource = authState?.user?.customer?.default_source;
      if (defaultSource) {
        await paymentApi.GetStripeCards(authState.user?.id).then((res) => {
          const card = res.find((c) => c.stripeid == authState.user.customer.default_source);
          setCard(card);
        });
      }
    } finally {
      setIsCardLoading(false);
    }
  };

  const loadMemberships = async () => {
    try {
      await userApi.getUserMemberships(authState.user?.id).then((res) => {
        setMemberships(res.results);
      });
    } finally {
    }
  };

  const hasMembership = (studioId) => memberships.filter((x) => x.studioid == studioId).length > 0;

  const pfLogin = async (values) => {
    const res = await pfAuthApi.login({
      email: values.email,
      password: values.password,
    });

    const decoded = parseJwt(res);

    const { isB2C } = await verifyPFToken(false, true);
    if (res && decoded.registering === 'False') {
      loginRedirect();
    }

    return { ...decoded, isB2C };
  };

  const pfTokenLogin = async (magicToken, email) => {
    const res = await pfAuthApi.tokenLogin({ token: magicToken, email });
    if (res) {
      const { isUnregistered } = await verifyPFToken();
      logBrazeEvent('Signin: Success', { Source: 'Email - Magic Link' });
      logAmplitudeEvent('Signin: Success', { Source: 'Email - Magic Link' });
      if (!isUnregistered) {
        loginRedirect();
      }
    }
  };

  const pfPreRegister = async (values) => {
    const res = await pfAuthApi.preRegister({
      email: values.email,
      password: values.password,
      registrationType: values.registrationType || authState.registerUser?.registrationType,
    });
    return res;
  };

  const setRegisterUser = (registerUser) => {
    dispatch({
      type: SET_REGISTER_USER,
      registerUser,
    });
  };

  const setChangePasswordPinCode = (pinCode) => {
    dispatch({
      type: SET_CHANGE_PASSWORD_PIN_CODE,
      changePasswordPinCode: pinCode,
    });
  };

  const setRegistering = (registering) => {
    dispatch({
      type: SET_REGISTERING,
      registering,
    });
  };

  useEffect(() => {
    // console.log("updating card.", authState?.user?.customer);
    if (authState?.user) loadMemberships();
    if (authState?.user?.customer) {
      loadCard();
    }
  }, [authState]);

  return {
    login,
    register_sponsored,
    register_consumer,
    register_pmid,
    password_reset,
    verify,
    verifyJwt,
    isPFLogin,
    pfLogin,
    verifyPFToken,
    logEventsWhenRegister,
    pfTokenLogin,
    pfPreRegister,
    register,
    verifyEnrollment,
    logout,
    authState,
    publicClient,
    handleResponse,
    card,
    isCardLoading,
    memberships,
    hasMembership,
    register_aetna,
    setRegisterUser,
    setChangePasswordPinCode,
    setRegistering,
    hasMobileLocationPermission,
  };
};
export const AuthContextProvider = (props) => {
  const context = useAuthContext();
  return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
};
