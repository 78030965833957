import React from 'react';

import styles from '../../styles/styles.module.css';
import errorFormStyles from '../../styles/error/Form.module.css';
import pageStyles from '../../styles/register/SponsoredPage.module.css';

import formStyles from '../../styles/register/Form.module.css';
import loginStyles from '../../styles/Login.module.css';
import btnDefaultStyles from '../../styles/button/Default.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import submitStyles from '../../styles/Submit.module.css';
// import checkboxStyles from '../../styles/Checkbox.module.css';
import contentStyles from '../../styles/Content.module.css';

import fieldStyles from '../../styles/input/Field.module.css';
import { useId } from 'react-id-generator';
import { useField } from 'formik';


// import { Col, Form } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';  // BOOTSTRAP CSSS


// used in Account.Preferences,Register, 
export const TextField = ({ label, placeholder, ariaDescribedBy, classProps, icon, ...props }) => {
    const [field, meta] = useField(props);

    const [htmlId] = useId();
    const id1 = htmlId + "-1";
    const id2 = htmlId + "-2";

    return (
        <>
            <div className={`${fieldStyles.field} ${classProps ?? ''}`}>
                <label className={`${fieldStyles.label} ${styles.container}`} htmlFor={id2}>{label}</label>
                {icon ? icon : ''}
                <input
                    {...field}
                    type="text"
                    id={id2}
                    placeholder={placeholder}
                    tabIndex="0"
                    className={`${meta.touched && meta.error ? fieldStyles.inputError : ''} ${fieldStyles.input}`}
                    // aria-invalid="false"
                    aria-describedby={`${ariaDescribedBy} ${id1}`}
                    {...props}
                />
                <div id={id1} aria-live="polite" aria-atomic="true" role="status">
                    {meta.touched && meta.error ?
                        (
                            <span className={fieldStyles.errorLabel}>
                                <span>Error: {meta.error}</span>
                            </span>)
                        : null}
                </div>
                {meta.touched && meta.error && <div className={fieldStyles.errorIcon}>!</div>}
            </div >
        </>
    )
}