import React, { useState } from 'react';
import classnames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import DownArrow from './DownArrow.svg';
import CheckedIcon from './checked.svg';
import styles from './index.module.css';

type Option = {
  id: number;
  name: string;
  credits: number;
};

interface Props {
  value?: string | number;
  onChange: (value: Option) => void;
  options: Option[];
}

export const SelectInput = ({
  onClick = () => { },
  value = '',
  placeholder = '',
  open,
}: {
  onClick: (event) => void;
  value?: any;
  open: boolean,
  placeholder: string,
}) => {
  return (
    <div className={styles.selector} onClick={onClick}>
      {value || placeholder}
      <img
        src={DownArrow}
        alt=""
        className={classnames(styles.arrow, { open })}
      />
    </div>
  );
};

export const SelectReservation: React.FC<Props> = ({ value, onChange, options }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const list = () => (
    <div role="presentation">
      <div className={styles.drawerListHeader}>Select Reservation</div>
      <List>
        {options.map((option) => (
          <ListItem
            button
            key={option.id}
            className={styles.drawerListItem}
            onClick={(e) => {
              console.log('clicked')
              onChange(option)
              toggleDrawer(false)(e)
            }}
          >
            <ListItemText primary={option.name} secondary={`${option.credits} Credits`} />
            {value === option.id.toString() && (
              <img src={CheckedIcon} alt="" className={styles.checkedIcon} />
            )}
          </ListItem>
        ))}
      </List>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Button
          variant="contained"
          className={styles.doneButton}
          onClick={toggleDrawer(false)}
        >
          Done
        </Button>
      </div>
    </div>
  );

  const selectedItem = options.find(item => item.id.toString() === value.toString());
  const displayValue = selectedItem ? <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    color: '#757575',
  }}>
    <div style={{ fontSize: 14 }}>{selectedItem.name}</div>
    <div style={{ fontSize: 11 }}>{selectedItem.credits} credits</div>
  </div> : null;
  return (
    <>
      <SelectInput onClick={toggleDrawer(true)} value={displayValue} open={open} placeholder='Select Reservation' />
      <Drawer anchor={'bottom'} open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};
