// import { Card, CardHeader, Button, CardContent, Modal } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { logAmplitudeEvent } from '../../api/integration';
import { useAuth } from '../../contexts/AuthContext';
import cardStyles from '../../styles/Card.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import spinnerGroupStyles from '../../styles/spinner/Group.module.css';
import { AddCard } from './AddCard';
import { StripeCard } from './StripeCard';

function Payment() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { authState, verify, card, isCardLoading } = useAuth();
  const { user } = authState;
  const { customer } = user;

  useEffect(() => {
    logAmplitudeEvent('Screen View: Settings - Payment');
  }, []);

  return (
    <div className={cardStyles.container}>
      <h3 className={cardStyles.title}>
        <span>Your Payment Info</span>
        <LockOutlined fontSize="large" className={cardStyles.lockIcon} />
      </h3>
      <div className={`${cardStyles.hasTitle} ${cardStyles.content}`}>
        {isCardLoading ? (
          <div
            className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`}
          ></div>
        ) : card ? (
          <StripeCard stripeCard={card} />
        ) : (
          <AddCard />
        )}
      </div>
    </div>
  );
}

export { Payment };
