import React, { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSite } from '../contexts/SiteContext';
import {
  LOGIN,
  REGISTER,
} from './CONSTANTS';
// add this for redirect with showing any content
const RedirectHelmet: React.FC<{ children: ReactNode }> = (props) => {

  const { isMoveSite, isCoreSite, isMobileApp, isReactNative } = useSite();

  return (
    <Switch>
      <Route
        path="/cigna"
        component={() => {
          window.location.href = 'https://fitonhealth.com/members';
          return null;
        }}
      />
      <Route
        path="/hbg"
        component={() => {
          window.location.href = 'https://fitonhealth.com/hbg';
          return null;
        }}
      />
      <Route
        path="/meritain"
        component={() => {
          window.location.href = 'https://fitonhealth.com/meritain';
          return null;
        }}
      />
      <Route
        path="/studio-terms-of-service"
        component={() => {
          window.location.href = 'https://fitonhealth.com/network-terms-of-service';
          return null;
        }}
      />
      <Route
        path="/faq"
        component={() => {
          window.location.href = 'https://fitonhealth.com/help';
          return null;
        }}
      ></Route>
      <Route
        path="/gravie"
        component={() => {
          window.location.href = 'https://fitonhealth.com/gravie';
          return null;
        }}
      ></Route>
      <Route
        path="/afa"
        component={() => {
          window.location.href = 'https://fitonhealth.com/aetna';
          return null;
        }}
      ></Route>
      <Route
        path="/aetna"
        component={() => {
          window.location.href = 'https://fitonhealth.com/aetna';
          return null;
        }}
      ></Route>
      <Route
        path="/contact-us"
        component={() => {
          window.location.href = 'https://fitonhealth.com/contact-us';
          return null;
        }}
      ></Route>
      {!isMobileApp && <Route
        exact path={LOGIN}
        component={() => {
          const suffix = isCoreSite ? `health?utm_source=${process.env.REACT_APP_CORE_HOSTNAME}` : `move?utm_source=${process.env.REACT_APP_MOVE_HOSTNAME}`;
          window.location.href = `${process.env.REACT_APP_FIT_ON_URL}/login/${suffix}`;
          return null;  // matching the conventions above
        }}
      ></Route>}
      {!isMobileApp && <Route
          exact path={REGISTER}
          component={() => {
            const suffix = isCoreSite ? `health?utm_source=${process.env.REACT_APP_CORE_HOSTNAME}` : `move?utm_source=${process.env.REACT_APP_MOVE_HOSTNAME}`;
            window.location.href = `${process.env.REACT_APP_FIT_ON_URL}/signup/${suffix}`;
            return null;  // matching the conventions above
          }}
        ></Route>}
      <Route>{props.children}</Route>
    </Switch>
  );

};

export default RedirectHelmet;
