import React, { useState,useEffect } from 'react';
import Modal from 'react-modal';
import studioApi from '../../api/studio';
import closeButton from '../../Assets/icons8-xbox-x-100.png';
import { useAuth } from '../../contexts/AuthContext';
import linkStyles from '../../styles/Link.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import studioStyles from '../../styles/Studio.module.css';
import virtualClassCardStyles from '../../styles/VirtualClassCard.module.css';

function VirtualClassesCard({ studio, empty }) {
  const { authState } = useAuth();
  const [reserved, setReserved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');

  const formatDate = (date) => {
    var options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    let testDate = date+"Z"
    var newDate = new Date(testDate).toLocaleTimeString('en', options);
    return newDate;
  };

  const reserveClass = () => {
    console.log('RESERVING CLASS');
    setLoading(true);
    const user_id = authState.user?.id;
    const session_id = studio.id;
    const class_start_time = studio.start_datetime;

    studioApi
      .reserve(user_id, session_id, class_start_time)
      .then(() => {
        // Set Reserved Here to Grey Button
        setReserved(true);
        setLoading(false);
        setIsOpen(false);
      })
      .catch((error) => {
        setError(error.data.Message);
        setLoading(false);
      });

    console.log('DONE RESERVING');
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setError('');
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '500px',
      minHeight: '400px',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      zIndex: 2,
      borderRadius: '10px',
    },
  };

  useEffect(() => {
    let sample = "2022-04-01T17:30:00"
    console.log(formatDate(sample))
  }, [])

  return (
    <>
      {empty ? (
        <div
          className={`${virtualClassCardStyles.loadingCard} ${virtualClassCardStyles.container} ${studioStyles.shadow} ${linkStyles.link} focus_outline ${studioStyles.loading}`}
        >
          <div className={virtualClassCardStyles.infoContainer}></div>
          <div className={virtualClassCardStyles.imageContainer}></div>
        </div>
      ) : (
        <>
          <div
            key={studio.id}
            className={`${empty ? virtualClassCardStyles.loadingCard : ''} ${
              empty ? studioStyles.loading : ''
            } ${virtualClassCardStyles.container} ${studioStyles.shadow} ${
              linkStyles.link
            } focus_outline`}
          >
            <div className={virtualClassCardStyles.infoContainer}>
              <span className={virtualClassCardStyles.duration}>
                {studio.duration_minutes} Min
              </span>
              <div className={virtualClassCardStyles.scheduleInfo}>
                {formatDate(studio.start_datetime)}
              </div>
              <h2 className={virtualClassCardStyles.title}>
                {studio.studio.name}
              </h2>
              <h2 className={virtualClassCardStyles.title}>{studio.name}</h2>
              <span className={virtualClassCardStyles.subtitle}>
                {studio.description}
              </span>
              <div
                onClick={() => {
                  openModal();
                }}
                disabled={reserved}
                className={virtualClassCardStyles.buttonContainer}
              >
                <button
                  disabled={reserved}
                  className={virtualClassCardStyles.moveCreditButton}
                >
                  Reserve - {studio.credits} cr
                </button>
              </div>
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Confirmation Modal"
          >
            <div>
              {loading && <div className={loadingStyles.spinner}></div>}
              <div hidden={loading}>
                <button
                  onClick={closeModal}
                  style={{
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '10px',
                    right: '20px',
                    fontSize: '20px',
                  }}
                  hidden
                >
                  <img
                    style={{
                      height: '30px',
                      width: '30px',
                    }}
                    src={closeButton}
                  />
                </button>
                {error !== '' ? <p style={{ color: 'red' }}>{error}</p> : ''}
                <h3>Please confirm your reservation:</h3>
                <h2>{studio.studio.name}</h2>
                <h3>{studio.name}</h3>
                <p>{studio.description}</p>
                <h3>Cost: {studio.credits} cr</h3>
                <br />
                <br />
                <div className={virtualClassCardStyles.buttonContainer}>
                  <button onClick={reserveClass}>Confirm</button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default VirtualClassesCard;
