import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from '../../components/Authentication/LoginForm';
import LoginLogo from '../../Assets/peerfit-core.png';
import './index.scss';
import GlobalLoading from '../../shared/GlobalLoading';
import { useAuth } from '../../contexts/AuthContext';
import { useSite } from '../../contexts/SiteContext';
import { HOME } from '../../navigation/CONSTANTS';

function Login() {
  const [loading, setLoading] = useState();
  const { isMoveSite, isCoreSite, isMobileApp, isReactNative } = useSite();
  const history = useHistory();
  const { pfTokenLogin } = useAuth();
  const url = new URL(window.location.href.replace(/\+/g, '%2B'));
  const magicLink = url.searchParams.get('magic_link');
  const email = url.searchParams.get('email');

  useEffect(() => {
    if (magicLink) {
      url.searchParams.delete('magic_link');
      setLoading(true);
      pfTokenLogin(magicLink, email).catch(() => {
        setLoading(false);
      });
    }
  }, [magicLink, email]);

  if (loading) {
    return <GlobalLoading />;
  }

  return (
    <div className="login-page">
      <div className="login-page-header">
        {isMobileApp && (
          <div
            style={{
              padding: 12,
              fontSize: 16,
              fontWeight: 'bold',
              position: 'absolute',
              top: 0,
              left: 8,
            }}
          >
            <div
              onClick={() => {
                history.goBack();
              }}
            >
              <span>&#x3c;</span> Back
            </div>
          </div>
        )}
        <a href={HOME}>
          <img src={LoginLogo} className="login-logo"></img>
        </a>
      </div>
      <div className="login-page-main">
        <LoginForm />
      </div>
    </div>
  );
}

export { Login };
