export enum SignUpType {
  B2C = 'B2C',
  New = 'New',
}

const STORAGE_KEY = 'PF_SIGN_UP_TYPE';

export const getSignUpType = (isPFLogin: boolean) => {
  if (!isPFLogin) {
    return SignUpType.B2C;
  }
  return SignUpType.New;
};

export const clearSignUpType = () => {
  localStorage.removeItem(STORAGE_KEY);
};
