import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { logAmplitudeEvent, logBrazeEvent } from '../../api/integration';
import paymentApi from '../../api/payment';
import studioBannerImg from '../../Assets/studioBanner.jpg';
import { useAuth } from '../../contexts/AuthContext';
import addCreditCardStyles from '../../styles/AddCreditCard.module.css';
import controllerStyles from '../../styles/Controller.module.css';
import errorFormStyles from '../../styles/error/Form.module.css';
import overlayDefaultStyles from '../../styles/overlay/Default.module.css';
import reservationHeaderStyles from '../../styles/ReservationHeader.module.css';
import stripeStyles from '../../styles/StripeCard.module.css';
import svgStyles from '../../styles/SvgIcon.module.css';
import SubmitButton from '../Formik/SubmitButton';
import { TextField } from '../Formik/TextField1';

export const AddCardModal = React.forwardRef(
  ({ isShowing, hide, onSuccess }, ref) => {
    const { authState, verify } = useAuth();

    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (values, { setErrors }) => {
      logAmplitudeEvent('Payment: Add Card');
      
      console.log('stripe props: ', stripe);
      if (!authState.user) {
        console.log('NO USER FOUND.');
        // throw new Error("AuthState.User empty.");
        setErrors({ summary: 'User not found.' });
        return;
      }

      console.log('starting STRIPE:', stripe);
      console.log('stripe Info:', elements.getElement(CardNumberElement));
      console.log('ATTEMPTING STRIPE V2');
      const { error, token } = await stripe.createToken(
        elements.getElement(CardNumberElement),
        stripeResponseHandler
      );
      console.log('rr:', token);

      if (!error) {
        console.log('stripe success:', token);
        const { id } = token;

        const { user } = authState;
        await paymentApi
          .AddPaymentMethod(user.id, id)
          .then((res) => {
            console.log('successfully added stripe paymentMethod.');
            console.log('res:', res);

            // verify();
            // handleClose();  // CLOSE OUT MODAL.

            onSuccess();
            hide();

            logBrazeEvent('Payment: Add Card Success');
            logAmplitudeEvent('Payment: Add Card Success');
          })
          .catch((error) => {
            console.log('error:', error);
            setErrors({ summary: error.data.message });
            logAmplitudeEvent('Payment: Add Card Failure', {
              'Error Code': error.data?.code || error?.status,
              'Error Message': error.data?.message
            });
          });
      } else {
        console.log('stripe error:', error);
        setErrors({ summary: error.message });
      }
    };

    function stripeResponseHandler(status, response) {
      // Grab the form:
      // var $form = $('#payment-form');

      if (response.error) {
        // Problem!

        console.log('stripeResponseHandler ERROR: ', response.error.message);
        // Show the errors on the form
        //   $form.find('.payment-errors').text(response.error.message);
        //   $form.find('button').prop('disabled', false); // Re-enable submission
      } else {
        // Token was created!

        // Get the token ID:
        var token = response.id;
        console.log('stripeResponseHandler SUCCESS: ', response);
        // Insert the token into the form so it gets submitted to the server:
        //   $form.append($('<input type="hidden" name="stripeToken" />').val(token));

        // Submit the form:
        //   $form.get(0).submit();
      }
    }

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label('Your Name'),
    });

    useEffect(() => {
      isShowing && logAmplitudeEvent('Screen View: Settings - Payment - Add Card');
    }, [isShowing]);

    return isShowing
      ? ReactDOM.createPortal(
          <React.Fragment>
            <div className={controllerStyles.overlay}>
              <div
                ref={ref}
                role="dialog"
                tabIndex="0"
                data-cy="Login"
                className={overlayDefaultStyles.dialog}
                aria-labelledby="reservation-header-title"
              >
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
                <div
                  data-focus-guard="true"
                  tabIndex="1"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
                <div data-focus-lock-disabled="false">
                  <button
                    className={`${overlayDefaultStyles.close} focus_outline`}
                    aria-label="Close"
                    onClick={hide}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className={`${overlayDefaultStyles.closeIcon} ${svgStyles.icon}`}
                      viewBox="0 0 17 17"
                    >
                      <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z"></path>
                    </svg>
                  </button>
                  <div
                    className={reservationHeaderStyles.header}
                    style={{ backgroundImage: `url(${studioBannerImg})` }}
                  >
                    <div
                      data-cy="reservation-modal"
                      className={reservationHeaderStyles.content}
                    >
                      <div className={reservationHeaderStyles.textWrapper}>
                        <h3 id="reservation-header-title">Card Info</h3>
                      </div>
                    </div>
                  </div>
                  <div className={overlayDefaultStyles.content}>
                    <Formik
                      initialValues={{
                        name: '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={async (values, actions) => {
                        await handleSubmit(values, actions);
                      }}
                    >
                      {(formikProps) => (
                        <div
                          className={`${addCreditCardStyles.content} ${overlayDefaultStyles.content}`}
                        >
                          <Form autoComplete="on" noValidate="">
                            <fieldset>
                              {formikProps.errors.summary && (
                                <div
                                  className={errorFormStyles.error}
                                  tabIndex="-1"
                                >
                                  <span aria-label={formikProps.errors.summary}>
                                    {formikProps.errors.summary}
                                  </span>
                                </div>
                              )}

                              <TextField
                                name="name"
                                label="Your Name"
                                placeholder="Your Name"
                              />
                              <CardNumberElement />
                              <div
                                className={`${stripeStyles.cardDetailContainer}`}
                              >
                                <CardExpiryElement />
                                <CardCvcElement />
                              </div>
                              <SubmitButton label="Add Card" />
                            </fieldset>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
              </div>
            </div>
          </React.Fragment>,
          document.body
        )
      : null;
  }
);
