import React, { useEffect, useState } from 'react';
import studioApi from '../../api/studio';
import digitalStudioCardStyles from '../../styles/DigitalStudioCard.module.css';
import defaultBtnStyles from '../../styles/button/Default.module.css';
import btnDefaultStyles from '../../styles/button/Default.module.css';
import streamingServiceStyles from '../../styles/streaming/StreamingService.module.css';

import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import useModal from '../../shared/Modal/useModal';
import { StreamingModal } from '../../shared/Modal/StreamingModal';
import { getSignUpFullUrl } from '../../api/integration/fitOnSignUp';
import { logAmplitudeEvent, logBrazeEvent } from '../../api/integration';
import { postMessageToRN, callRNFunction } from '../../utilities/rn';
import { useSite } from '../../contexts/SiteContext';
import userApi from '../../api/user';

const FITON_PROVIDER_NUMBER = 8;

function DigitStudioCard({ provider }) {
  const { authState } = useAuth();
  const { user } = authState || {};
  const { isShowing, toggle, ref } = useModal();
  const { subscriptions } = user || {};
  const [digitalProviders, setDigitalProviders] = useState([]);
  const isFitOn = provider.provider_name === 'FITON';
  const [fitOnSingUpUrl, setFitOnSignUpUrl] = useState('');
  const [signedUpFitOn, setSignedUpFitOn] = useState(false);

  const [digitalProvider, setDigitalProvider] = useState({});
  const { isMobileApp, isReactNative } = useSite();

  const [isActive, setIsActive] = useState(false);
  const ctaText = provider.cta || 'Get Membership';

  useEffect(() => {
    studioApi.getDigitalProviders().then((res) => {
      setDigitalProviders(res.results);
      const fitOn = res.results?.find((x) => x.name == 'FITON');
      const forte = res.results?.find((x) => x.name == 'FORTE');
      const burnAlong = res.results?.find((x) => x.name == 'BURN ALONG');
      const lesmills = res.results?.find((x) => x.name == 'LESMILLS');

      console.log('PROVIDER: ', provider);

      if (provider.provider_name === 'FITON') {
        setDigitalProvider(fitOn);
      }
      if (provider.provider_name === 'FORTE') {
        setDigitalProvider(forte);
      } else if (provider.provider_name === 'LESMILLS') {
        setDigitalProvider(lesmills);
      } else if (provider.provider_name === 'BURN ALONG') {
        setDigitalProvider(burnAlong);
      }
    });
  }, []);

  useEffect(() => {
    if (authState.user.id) {
      userApi.getSubscriptions(authState.user.id).then(res => {
        const hasFitOn = res?.results?.find(item => item.provider_name === 'FITON')
        if (hasFitOn) {
          setSignedUpFitOn(true);
        }
      })
    }
  } ,[authState.user.id])

  useEffect(() => {
    if (subscriptions) {
      const isActive =
        subscriptions.filter((x) => x.provider_name == provider.provider_name)
          ?.length > 0;
      setIsActive(isActive);
    }
  }, []);

  useEffect(() => {
    if (authState.user && isFitOn) {
      getSignUpFullUrl(authState.user, 'discover').then((url) => {
        setFitOnSignUpUrl(url);
      });
    }
  }, [authState.user?.id]);

  return (
    <div className={digitalStudioCardStyles.container}>
      <div className={digitalStudioCardStyles.content}>
        <div className={digitalStudioCardStyles.logoContainer}>
          <img
            src={provider.icon}
            className={digitalStudioCardStyles.forteIcon}
            alt={provider.icon_alt}
          />
        </div>
        <div
          className={digitalStudioCardStyles.bannerImg}
          style={{ backgroundImage: `url(${provider.image})` }}
        ></div>
        <p className={digitalStudioCardStyles.description}>
          <span>{provider.description}</span>
        </p>
        {isActive && !isFitOn ? (
          <Link
            style={{ width: '100%', position: 'absolute', bottom: 0 }}
            to={`user/${user?.id}/memberships`}
            className={` ${defaultBtnStyles['transparent-button']} ${defaultBtnStyles.button} ${defaultBtnStyles.button_link} focus_outline`}
          >
            View Subscription
          </Link>
        ) : isFitOn ? (
          <a
            style={{ width: '100%', position: 'absolute', bottom: 0 }}
            className={`${streamingServiceStyles.membershipBtn} ${
              btnDefaultStyles.button
            } focus_outline ${
              !fitOnSingUpUrl ? streamingServiceStyles.btnDisabled : ''
            }`}
            href={fitOnSingUpUrl}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              const eventData = {
                Type: 'Digital',
                Credits: digitalProvider?.credits,
                'Item Name': 'FitOn Subscription',
                Source: 'Discover',
              };
              logAmplitudeEvent('Click: Purchase', eventData);
              logAmplitudeEvent('Screen View: Purchase', eventData);
              logBrazeEvent('Click: Purchase', eventData);
              if (isReactNative) {
                postMessageToRN({ key: 'SHOW_GO_BACK' });
              }
              if (isReactNative && signedUpFitOn) {
                callRNFunction('openLink', 'https://fiton.app');
                e.preventDefault();
              } else if (signedUpFitOn) {
                window.open('https://fitonapp.com/', '_blank')
                e.preventDefault();
              }
            }}
          >
            <span>{signedUpFitOn ? 'Launch FitOn' : ctaText}</span>
          </a>
        ) : (
          <button
            style={{ width: '100%', position: 'absolute', bottom: 0 }}
            disabled={!digitalProviders}
            onClick={toggle}
            className={`${streamingServiceStyles.membershipBtn} ${btnDefaultStyles.button} focus_outline`}
          >
            <span>{ctaText}</span>
          </button>
        )}
      </div>
      <StreamingModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        provider={digitalProvider}
      />
    </div>
  );
}

export default DigitStudioCard;
