import React, { ComponentType } from 'react';
import pageStyles from '../styles/account/Page.module.css';
import { Route, Switch } from 'react-router-dom';
import AccountNavigation from './AccountNavigation';
import { Settings } from './Settings';
import { Profile } from './Profile';
import { Preferences } from './Preferences';
import { Payment } from './Payment';
import { Credits } from './Credits';
import NotFound from '../navigation/NotFound';
import {
  CREDITS,
  PAYMENT,
  PREFERENCES,
  PROFILE,
  SETTINGS,
} from '../navigation/CONSTANTS';
import MobilePage from '../components/User/pages/MobilePage';
import { useGoBackAccount } from '../utilities/navigation';
import utilitiesStyle from '../styles/utilities.module.css';

export const Account = () => {
  const backToAccountPage = useGoBackAccount();
  return (
    <MobilePage title="Settings" onBack={backToAccountPage}>
      <div
        className={`${utilitiesStyle.fullHeight} ${pageStyles.container}`}
        style={{ position: 'relative', minHeight: '216px' }}
      >
        <div className={`${utilitiesStyle.fullHeight} ${pageStyles.page}`}>
          <div className={pageStyles.navContainer}>
            <h2 className={`${pageStyles.title} ${utilitiesStyle.desktopOnly}`}>
              <span>Settings</span>
            </h2>
            <div className={pageStyles.side} role="navigation">
              <AccountNavigation />
            </div>
          </div>

          <div
            className={`${pageStyles.contentContainer} ${utilitiesStyle.fullHeight}`}
          >
            <Switch>
              <Route exact path={SETTINGS} component={Settings} />
              <Route
                exact
                path={PROFILE}
                component={Profile as ComponentType<any>}
              />
              <Route exact path={PREFERENCES} component={Preferences} />
              <Route exact path={PAYMENT} component={Payment} />
              <Route exact path={CREDITS} component={Credits} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </div>
    </MobilePage>
  );
};
