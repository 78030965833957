import React, { useContext, useRef, useState } from 'react';
import styles from './index.module.css';
import headerStyles from '../../../styles/Header.module.css';
import userMenuStyles from '../../../styles/UserMenu.module.css';
import dropdownDefaultStyles from '../../../styles/dropdown/Default.module.css';
import { AuthContext } from '../../../contexts/AuthContext';
import userStyles from '../../../styles/User.module.css';
import svgIconStyles from '../../../styles/SvgIcon.module.css';
import { ClickAwayListener } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DISCOVER } from '../../../navigation/CONSTANTS';
import { SiteContext } from '../../../contexts/SiteContext';
import fitonHealthLogo from '../../../Assets/fiton-health-logo.png';
import faqIcon from '../../../Assets/admin/faq.svg';
import { logAdminAmplitudeEvent } from '../../../api/integration';


type Props = {};

const AdminHeader = (props: Props) => {
  const authContext = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const { userprofile } = authContext.authState.user || {};
  const siteContext = useContext(SiteContext);
  const anchorRef = useRef(null);
  const url = new URL(window.location.href);
  const self_close = url.searchParams.get('self_close');

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };
  const checkSelfClose = () => {
    if (self_close) {
      try {
        window.close();
      } catch (e) {}
    }
  };
  const handleClick = (e) => {
    setOpen((prevState) => !prevState);
  };
  return (
    <header id="header" className={headerStyles.container} data-hero-route="false">
      <div className={headerStyles.inner} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Link to={DISCOVER} className={headerStyles.index}>
          <img className={headerStyles.logo} src={fitonHealthLogo} alt="" />
        </Link>
        <div className={styles.headerRight}>
          <a
            className={styles.faq}
            href="https://help.fitonhealth.com/clients/managing-member-eligibility?ref=client-admin-header"
            onClick={() => logAdminAmplitudeEvent(`Click: Admin - FAQ`)}
          >
            <img src={faqIcon} alt=""></img>
            <div className={styles.faqText}>FAQ</div>
          </a>
          <div className={`${userMenuStyles.container} ${dropdownDefaultStyles.dropdown}`} data-cy="usermenu" style={{ zIndex: 'inherit' }}>
            <button
              className={`${userMenuStyles.dropdownLabel} ${dropdownDefaultStyles.label} focus_outline`}
              aria-haspopup="true"
              aria-expanded="false"
              aria-label="User Navigation Menu"
              onClick={handleClick}
              ref={anchorRef}
            >
              <div className={dropdownDefaultStyles.icon}>
                <div className={headerStyles.avatarImg}>
                  {userprofile?.profile_image ? (
                    <div
                      className={userStyles.image}
                      style={{
                        backgroundImage: `url(${userprofile.profile_image})`,
                      }}
                    ></div>
                  ) : (
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className={`${userStyles.noImage} ${svgIconStyles.icon}`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20,19.5c-2,2.1-4.8,3.5-8,3.5c-3.1,0-6-1.3-8-3.5c0.1-1.3,0.4-2.1,2.1-2.5c2.2-0.5,4.5-1,3.4-2.9C6.4,8.3,8.6,5,12,5 c3.3,0,5.6,3.2,2.5,9.1c-1,2,1.1,2.4,3.4,2.9C19.6,17.5,19.9,18.2,20,19.5z M24,12c0,6.6-5.4,12-12,12c0,0,0,0,0,0 C5.4,24,0,18.6,0,12C0,5.4,5.4,0,12,0C18.6,0,24,5.4,24,12z M23,12c0-6.1-4.9-11-11-11S1,5.9,1,12c0,2.9,1.2,5.6,3,7.5 c2,2.1,4.8,3.5,8,3.5c3.1,0,6-1.3,8-3.5C21.8,17.6,23,14.9,23,12z"></path>
                    </svg>
                  )}
                </div>
              </div>
            </button>
            <ClickAwayListener onClickAway={handleClose}>
              <div
                className={dropdownDefaultStyles.dropdownContent}
                style={{
                  display: open ? 'block' : 'none',
                  left: 'auto',
                  right: '-5px',
                }}
              >
                <div className={`${dropdownDefaultStyles.rightIndicator} ${dropdownDefaultStyles.leftIndicator}`}>
                  <div className={dropdownDefaultStyles.indicator}></div>
                </div>
                <Link
                  onClick={(e) => {
                    handleClose(e);
                    checkSelfClose();
                  }}
                  to={self_close ? '' : `/?from_source=header`}
                  className={`${dropdownDefaultStyles.option} focus_outline`}
                >
                  <span>Exit Admin</span>
                </Link>
              </div>
            </ClickAwayListener>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
