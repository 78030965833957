import { ClickAwayListener } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { getSourceFromPathName, logAmplitudeEvent } from './api/integration';
import userApi from './api/user';
import { NotificationItem } from './NotificationItem';
import dropdownDefaultStyles from './styles/dropdown/Default.module.css';
import notificationsStyles from './styles/Notifications.module.css';

export const Notification = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const anchorRefNotification = useRef(null);
  const handleClickNotification = () => {
    setOpenNotification((prevState) => !prevState);
  };
  const handleCloseNotification = (e) => {
    if (
      anchorRefNotification.current &&
      anchorRefNotification.current.contains(e.target)
    ) {
      return;
    }
    setOpenNotification(false);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const loadNotifications = () => {
    setIsLoading(true);
    userApi
      .getNotifications()
      .then((res) => {
        if (res.results) {
          setNotifications(res.results);
        }
      })
      .catch((error) => {
        console.log('error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    openNotification &&
      logAmplitudeEvent('Screen View: Notifications', {
        Unread: notifications.length,
        Source: getSourceFromPathName(),
      });
  }, [openNotification]);

  return (
    <div
      className={`${notificationsStyles.container} ${dropdownDefaultStyles.dropdown}`}
      style={{ zIndex: 'inherit' }}
    >
      <button
        ref={anchorRefNotification}
        className={`${notificationsStyles.dropdownLabel} ${dropdownDefaultStyles.label} focus_outline`}
        aria-haspopup="true"
        aria-expanded="false"
        aria-label="View Pending Notifications"
        onClick={handleClickNotification}
      >
        <div
          className={`${notificationsStyles.icon} ${
            notifications.length > 0 && notificationsStyles.hasUnread
          }`}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            className={`${notificationsStyles.alarmIcon} ${
              notifications.length > 0 && notificationsStyles.alarmIconUnread
            }`}
          >
            <path d="M9.32154417,18.7569823 L6.03705445,18.7569823 C5.46430509,18.7569823 5,18.3272883 5,17.797234 C5,17.6924928 5.01852653,17.5884579 5.05484471,17.4892559 L5.95178316,15.0392975 L5.95178316,10.5991217 C5.95284119,10.5672223 5.95351673,10.5468546 5.95462493,10.5297712 C5.95273151,10.473008 5.95178316,10.4161412 5.95178316,10.3591846 C5.95178316,7.53595242 8.27370555,5.21156262 11.2655253,4.88093761 L11.2655253,3.71981123 C11.2655253,3.32227047 11.6137541,3 12.0433161,3 C12.4728781,3 12.8211069,3.32227047 12.8211069,3.71981123 L12.8211069,4.89107007 C15.7710786,5.25649172 18.0483824,7.5632099 18.0483824,10.3591846 C18.0483824,10.4161412 18.0474341,10.473008 18.0455407,10.5297712 C18.0466489,10.5468546 18.0473244,10.5672223 18.0483824,10.5991217 L18.0483824,15.0499263 L18.9444826,17.4881911 C19.1289102,17.9900138 18.838843,18.5351847 18.2965993,18.7058644 C18.1890633,18.739713 18.0762492,18.7569823 17.9626635,18.7569823 L14.6784558,18.7569823 C14.4262914,20.1845719 13.5116622,21 12,21 C10.4883378,21 9.57370862,20.1845719 9.32154417,18.7569823 Z M10.8402495,18.7569823 C10.9988145,19.3648653 11.3715953,19.6044885 12,19.6044885 C12.6284047,19.6044885 13.0011855,19.3648653 13.1597505,18.7569823 L10.8402495,18.7569823 Z M6.75665106,17.3159868 L17.2347444,17.3159868 L16.499375,15.303514 C16.499375,12.1368478 16.499375,10.4903991 16.499375,10.3641677 C16.499375,8.45607556 14.8000645,6.30537874 11.9956977,6.30537874 C9.19133094,6.30537874 7.50757104,8.45607556 7.50757104,10.3641677 C7.50757104,10.4748122 7.50757104,12.1130213 7.50757104,15.2787948 L6.75665106,17.3159868 Z"></path>
          </svg>
        </div>
      </button>

      <ClickAwayListener onClickAway={handleCloseNotification}>
        <div
          className={dropdownDefaultStyles.dropdownContent}
          style={{
            display: openNotification ? 'block' : 'none',
            left: 'auto',
            right: '-5px',
          }}
        >
          {isLoading ? (
            <>loading</>
          ) : notifications.length > 0 ? (
            <div className={notificationsStyles.list}>
              {notifications.map((n, i) => (
                <NotificationItem
                  key={i}
                  closeMenu={handleCloseNotification}
                  notification={n}
                  reload={loadNotifications}
                />
              ))}
            </div>
          ) : (
            <div className={notificationsStyles.empty}>
              <svg
                aria-hidden="true"
                focusable="false"
                className={notificationsStyles.checkIcon}
                viewBox="0 0 24 24"
              >
                <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z"></path>
              </svg>
              <h4>
                <span>All Caught Up!</span>
              </h4>
              <p>
                <span>No New Notifications.</span>
              </p>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};
