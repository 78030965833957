import React from 'react';
import { useSite } from '.././contexts/SiteContext';
import MoveFooter from './MoveFooter';
import CoreFooter from './CoreFooter';

export default function Footer() {
  const { isMobileApp } = useSite();
  const { isMoveSite } = useSite();

  if (isMobileApp) return null;
  return (
    <div data-testid="footer-container">
      {isMoveSite ? <MoveFooter /> : <CoreFooter />}
    </div>
  );
}
