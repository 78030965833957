import React, { useEffect } from 'react';
import pageStyles from '../styles/register/Page.module.css';
import optionStyles from '../styles/register/RegisterOptions.module.css';
import titledFormStyles from '../styles/TitledForm.module.css';
import registerBg from '../Assets/register-bg.png';
import pfMoveLogo from '../Assets/pf-move-logo.png';
import {
  LOGIN,
  REGISTER_SPONSORED,
  REGISTER_CONSUMER,
  REGISTER_MOVE,
  NOMINATE_EMPLOYER,
  REGISTER,
  REGISTER_MOVE_ALT,
  DISCOVER,
} from '../navigation/CONSTANTS';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { RegisterOptions } from './RegisterOptions';
import { Sponsored } from './Sponsored';
import { Move } from './Move';
import { Consumer } from './Consumer';
import { useAuth } from '../contexts/AuthContext';

export const Register = () => {
  const history = useHistory();
  const { authState } = useAuth();

  const matchRegister = useRouteMatch(REGISTER);
  const matchSponsored = useRouteMatch(REGISTER_SPONSORED);
  const matchConsumer = useRouteMatch(REGISTER_CONSUMER);
  const matchMove = useRouteMatch(REGISTER_MOVE);
  const matchMoveAlt = useRouteMatch(REGISTER_MOVE_ALT);

  // if (authState.isAuthenticated) {
  //     console.log("PUSHING TO DISCOVER.");
  //     // history.push(DISCOVER);
  //     return <></>;
  // }

  return (
    <>
      <div
        className={pageStyles.page}
        style={{
          height: '853px',
          position: 'relative',
          minHeight: '853px',
          backgroundImage: `url(${registerBg})`,
        }}
      >
        {authState.isAuthenticated && !authState.registering? (
          <>
            {console.log('PUSHING TO DISCOVER.')}
            <Redirect push to={DISCOVER} />
          </>
        ) : (
          <>
            {matchRegister && matchRegister.isExact && <RegisterOptions />}
            {matchSponsored && <Sponsored />}
            {matchConsumer && <Consumer />}
            {matchMove && <Move />}
            {/* {matchMoveAlt && <MoveAlt />} */}
          </>
        )}
      </div>
    </>
  );
};
