import { useField } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useId } from 'react-id-generator';
// import 'bootstrap/dist/css/bootstrap.min.css';  // BOOTSTRAP CSSS

import formStyles from '../../styles/register/Form.module.css';
import checkboxStyles from '../../styles/Checkbox.module.css';

export const Checkbox = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const [htmlId] = useId();
  const id1 = htmlId + '-1';
  const id2 = htmlId + '-2';
  return (
    <Form.Row>
      <Form.Group>
        <Form.Check className={formStyles.agreeToTerms}>
          <Form.Check.Input
            {...field}
            id={id1}
            role="checkbox"
            type="checkbox"
            // ischecked={field.value ? "checked" : ''}
            className={checkboxStyles.input}
            isInvalid={meta.touched && meta.error}
          />
          <Form.Check.Label htmlFor={id1} style={{ marginBottom: '10px' }}>
            <span
              className={`${
                meta.touched && meta.error ? checkboxStyles.errorCheckbox : ''
              } ${checkboxStyles.checkbox} `}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                className={
                  field.value ? checkboxStyles.check : checkboxStyles.noCheck
                }
                viewBox="0 0 24 24"
              >
                <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z"></path>
              </svg>
            </span>
            <span className={checkboxStyles.label}>{label}</span>
          </Form.Check.Label>
          <Form.Control.Feedback type="invalid" style={{ marginLeft: '30px' }}>
            {meta.error}
          </Form.Control.Feedback>
        </Form.Check>
      </Form.Group>
    </Form.Row>
    // <div className={formStyles.agreeToTerms}>
    //     <input {...field}
    //         id={id1}
    //         role="checkbox"
    //         type="checkbox"
    //         ischecked={field.value ? "checked" : ''}
    //         aria-checked={field.value}
    //         tabIndex="0"
    //         className={checkboxStyles.input}
    //         {...props} />
    //     <label htmlFor={id1} className={checkboxStyles.labelContainer}>
    //         <span className={checkboxStyles.checkbox}>
    //             <svg aria-hidden="true" focusable="false" className={field.value ? checkboxStyles.check : checkboxStyles.noCheck} viewBox="0 0 24 24">
    //                 <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z">
    //                 </path>
    //             </svg>
    //         </span>
    //         <span className={checkboxStyles.label}>{label}</span>
    //     </label>
    //     {meta.error && meta.touched && <span className={`${checkboxStyles.errorLabel} ${fieldStyles.errorLabel}`}>{meta.error}</span>}
    // </div>
  );
};
