import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { JoinedFitOn } from '../JoinedFitOn';
import { GetStarted } from '../FitOnGetStarted';

export const MobileDigital = () => {
  const { authState } = useAuth();
  const { user } = authState || {};
  const { subscriptions } = user || {};
  const [hasSubscribedFitOn, setHasSubscribedFitOn] = useState(false);

  useEffect(() => {
    if (subscriptions) {
      var isActive = subscriptions?.filter((x) => x.provider_name === 'FITON')?.length > 0;
      setHasSubscribedFitOn(isActive);
    }
  }, [subscriptions]);

  return hasSubscribedFitOn ? <JoinedFitOn /> : <GetStarted />;
};
