import { useEffect, useState } from "react";
import { getFeatureFlag } from "../api/config";
import { getSignUpType, SignUpType } from "../utilities/loginType";

export const useRegisterFlowType = () => {
  const [shouldGoToNewAuthFlow, setShouldGoToNewAuthFlow] = useState<boolean>();
  useEffect(() => {
    (async () => {
      const enableReplaceB2C = await getFeatureFlag('replace_b2c');
      const signUpType = getSignUpType(enableReplaceB2C);
      setShouldGoToNewAuthFlow(signUpType === SignUpType.New);
    })();
  }, []);

  return { shouldGoToNewAuthFlow };
};
