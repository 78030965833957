import React from 'react';
import { Link } from 'react-router-dom';

import flexStyles from '../styles/Flex.module.css';
import workoutStylesStyles from '../styles/WorkoutStyles.module.css';

export const WorkoutStylesCard = ({ tag }) => {
  return (
    <div
      className={`${flexStyles['col-xs-6']} ${flexStyles['col-sm-6']} ${flexStyles['col-md-4']} ${flexStyles['col-lg-3']}`}
    >
      <Link
        className={`${workoutStylesStyles.card} focus_outline`}
        aria-label={`View ${tag.name} studios near you`}
        to={`/explore/studios/?tags=${tag.name}`}
        style={{ backgroundImage: `url(${tag.image})` }}
      >
        <span>{tag.name}</span>
      </Link>
    </div>
  );
};
