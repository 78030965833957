import React from 'react';
import flexStyles from '../styles/Flex.module.css';
import utilities from '../styles/utilities.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';

import pageStyles from '../styles/about-us/Page.module.css';
import heroStyles from '../styles/about-us/Hero.module.css';
import photoDividerStyles from '../styles/about-us/PhotoDivider.module.css';
import jobsCTAStyles from '../styles/about-us/JobsCTA.module.css';
import teamStyles from '../styles/about-us/Team.module.css';
import boardStyles from '../styles/about-us/Board.module.css';
import { TeamMemberCard } from './TeamMemberCard';
import { BoardMemberCard } from './BoardMemberCard';

export default function AboutUs() {
  const teamMembers = [
    {
      imgPath: '/0ff9c894e16919b5adb82bcae74aa6b8.jpg',
      name: 'Edward J. Buckley, III, PhD',
      subtitle: 'Chief Executive Officer',
      description:
        "Ed lives and leads our creed to “share fitness”, driving national partnerships, business development opportunities, and fundraising. With nearly 10 years in the fitness industry, and a background in digital health behavior research, Ed continues to push the envelope on innovation in the fitness-technology space. He is passionate about designing new ways to drive engagement and help deliver flexibility and personalization to the health and wellness marketplace. If you’ve met Ed, he’s probably invited you to an interval class. Ed holds a PhD in Digital Health Behavior, and a Master's of Public Health, from the University of Florida.",
    },
    {
      imgPath: '/2c474ab4caf78452e32bd1f14f7225b9.jpg',
      name: 'Tina Dumar',
      subtitle: 'Chief Financial Officer',
      description:
        'Tina brings over 25 years financial accounting experience to Peerfit in both public and private accounting. She provides leadership and mentoring to Peerfit team members in financial best practices to achieve strategic objectives. She began her career at Arthur Andersen gaining experience in a wide range of industries including healthcare, technology and the service industry. She later served in key executive roles for both a resort management company and a high-growth pharmacy benefit management (PBM) company which was recently sold to a Fortune 100 company. Tina is a CPA and has a Bachelor’s Degree in Accounting from the University of Florida. She is passionate about health and fitness and you can routinely find her at a local gym or running on Bayshore Boulevard.',
    },
    {
      imgPath: '/892a9e7e17cecc1782ae6d87e3aad710.jpg',
      name: 'Todd Slawter',
      subtitle: 'Chief Growth Officer',
      description:
        'Todd brings more than 20 years of experience in the health insurance industry to his role, most recently with senior executive positions with both Aetna and BenefitMall. Throughout his career, he has continuously looked for innovative solutions to help clients deliver meaningful and competitive benefits packages to their employees. He is responsible for creating new partnerships, advising Peerfit on how to scale and manage their internal product, design and marketing departments, and helping to manage the sales team. Todd received a BS in Communications from Appalachian State University.',
    },
    {
      imgPath: '/61869f3869824b5502651922277b6c8e.jpg',
      name: 'Christopher Patton',
      subtitle: 'Chief Revenue Officer',
      description:
        "With more than 10 years of experience in the health and wellness industry, Chris oversees the revenue focus across the organization by building an integrated approach across marketing, sales, network, product design, and customer service to create the best customer experience. Chris holds an MBA from Florida Atlantic University's Executive MBA program.",
    },
    {
      imgPath: '/c63d28daa7481726ce55a5293e645630.jpg',
      name: 'Jason Parry',
      subtitle: 'Chief Strategy Officer',
      description:
        'Jason Parry has more than twenty years of experience as an entrepreneur, analyst, consultant and executive. He has a long track record of helping companies to better understand market conditions and develop effective strategies for success. Jason brings this experience and perspective to Peerfit, working to ensure that our strategy and tactics align with the goals of the company. Jason earned his bachelor’s degree from Florida State University and his MBA from the University of North Florida. In his spare time, Jason likes to read, run, kayak, travel, and participate in anything that feeds his competitive streak.',
    },
    {
      imgPath: '/cb40fd55770eb1decdaabd4983e28525.jpg',
      name: 'Emma Maurer',
      subtitle: 'Vice President of Business Development and Partnerships',
      description:
        'Emma brings 13 years of experience in corporate health promotion to her role at Peerfit. She develops goals and strategies to grow Peerfit’s enterprise health division, which works with employers who are developing their worksite wellness programs. Her team assists employers with all stages of implementation, from identifying fitness studios that meet employees’ needs to engaging employees in using their employer’s worksite wellness dollars for better health.',
    },
    {
      imgPath: '/55a1f6d5c74b3a056bcbd38c26806afe.png',
      name: 'Bill Carstarphen',
      subtitle: 'Vice President of Sales',
      description:
        "Bill comes to Peerfit with nearly 20 years of robust experience working in the fitness and healthcare space, most recently as Vice President of Health Plan Fitness Sales at American Specialty Health. Prior to that, Bill was the EVP for Sales and Client Management at Linkwell Health, served 11 years as head of sales for Healthways' SilverSneakers Fitness Program, and has over 30 years in various leadership roles in the healthcare industry, including Blue Cross Blue Shield of Florida.",
    },
    {
      imgPath: '/ae31dfaa988d4dc6371b927225bd69ce.jpg',
      name: 'Maria Juan',
      subtitle: 'Vice President of Marketing and Communications',
      description:
        'With a background in digital marketing and branding, Maria, along with the Peerfit marketing team, develops key strategies and campaigns to reach incoming and current Peerfit community members, in addition to providing support to the growing sales and studio teams. A graduate of the University of South Florida’s Dance and Theatre Department, Maria brings a unique perspective and creative skillset to help develop brands and marketing personalities. With over 10 years experience working within the marketing and startup industry, Maria is excited to make the shift into the health and wellness industry with Peerfit. ',
    },
    {
      imgPath: '/9da1740f49f9ce8530b6135a1f763dc4.jpg',
      name: 'Jeni Dowst',
      subtitle: 'Director of Account Management',
      description:
        "Jeni has over 10 years of experience working in the health and wellbeing industry. With a background in member engagement and sales management, she brings a solutions-oriented approach to Peerfit's team of account managers. Jeni is passionate about health and fitness and is often found out in the community staying active with others. She brings that same passion to the experience Peerfit provides its partners: keeping their populations healthy through movement and community. Jeni is a University of Florida graduate and spends her free time under the Florida sunshine.",
    },
  ];

  const boardMembers = [
    {
      imgPath: '/0ff9c894e16919b5adb82bcae74aa6b8.jpg',
      name: 'Edward J. Buckley, III, PhD',
    },
    {
      imgPath: '/a6ab6c1aa2d1c1d8c82cf1ea09f5244a.jpg',
      name: 'Jim Phillip',
    },
    {
      imgPath: '/286815ba1040954790a5cdaa968e3083.jpg',
      name: 'Marc Blumenthal',
    },
  ];

  return (
    <div
      className={`${pageStyles.page} ${utilities.page}`}
      style={{ height: 'auto', position: 'relative', minHeight: '216px' }}
    >
      <div
        className={heroStyles.container}
        style={{
          backgroundImage: 'url("/8bb77b2e79218dfa061744c33765f84f.jpg")',
        }}
      >
        <div className={heroStyles.content}>
          <h2 className={heroStyles.title}>
            <span>
              We're Peerfit, a digital health tech company that believes
              communities can change lives.
            </span>
          </h2>
        </div>
      </div>
      <div className={`${pageStyles.container} ${utilities['page-container']}`}>
        <h3 className={pageStyles.title}>
          <span>About Us</span>
        </h3>
        <p className={pageStyles.intro}>
          <span>
            We’re on a mission to empower individuals to live healthy and active
            lifestyles. We believe wellness is personal and fitness is more fun
            with friends. Our day isn't complete without a little sweat and we
            stay motivated by our community. We believe today’s corporate
            wellness is stale and boring, and we’re changing that.
          </span>
        </p>
        <div className={photoDividerStyles.container}>
          <img
            className={photoDividerStyles.photo}
            src="/59854fd64235745d5a427137c9f1b959.jpg"
            alt="Photos of the Team"
          />
          <img
            className={photoDividerStyles.photo}
            src="/e76d47f31bbe57e61fe63b3eea05267b.jpg"
            alt="Photos of the Team"
          />
        </div>
      </div>
      <div
        className={jobsCTAStyles.container}
        style={{
          backgroundImage: 'url("/4604346818648d2aa55a2fff8b713092.jpg")',
        }}
      >
        <div className={jobsCTAStyles.content}>
          <h3 className={jobsCTAStyles.title}>
            <span>Want to join our team?</span>
          </h3>
          <a
            rel="noopener noreferrer"
            className={`${jobsCTAStyles.button} ${btnDefaultStyles.button} focus_outline`}
            href="https://pulse.peerfit.com/hiring/"
            target="_blank"
          >
            <span>See job openings</span>
          </a>
        </div>
      </div>
      <div className={`${pageStyles.container} ${utilities['page-container']}`}>
        <div className={teamStyles.container}>
          <h3 className={teamStyles.title}>
            <span>Leadership Team</span>
          </h3>
          <div className={`undefined ${flexStyles.row}`}>
            {teamMembers.map((x, i) => (
              <TeamMemberCard key={i} member={x} />
            ))}
          </div>
        </div>
      </div>
      <div className={`${pageStyles.container} ${utilities['page-container']}`}>
        <div className={boardStyles.container}>
          <h3 className={boardStyles.title}>
            <span>Board of Directors</span>
          </h3>
          <div className={`undefined ${flexStyles.row}`}>
            {boardMembers.map((x, i) => (
              <BoardMemberCard key={i} member={x} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
