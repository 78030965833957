import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useSite } from '../../../contexts/SiteContext';
import ContentLoading from '../../../shared/ContentLoading';
import mobilePageStyles from '../../../styles/user/MobilePage.module.css';

type Props = {
  children?: ReactNode;
  title: string;
  onBack?: () => void;
  loading?: boolean;
};

const MobilePage: React.FC<Props> = ({ children, title, onBack, loading = false }) => {
  const { isMobileApp } = useSite();
  const history = useHistory();
  const defaultOnBack = () => history.goBack();
  if (isMobileApp) {
    return (
      <div className={mobilePageStyles.pageContainer}>
        <div className={mobilePageStyles.pageHeader}>
          <svg
            className={mobilePageStyles.backBtn}
            onClick={onBack ?? defaultOnBack}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 14L5 8L11 2" stroke="#2B4596" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
          </svg>
          <div className={mobilePageStyles.pageHeaderTitle}>{title}</div>
        </div>
        <div className={mobilePageStyles.container}>{loading ? <ContentLoading /> : children}</div>
      </div>
    );
  }
  return <>{children}</>;
};

export default MobilePage;
