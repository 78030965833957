import React, { createContext, useContext, useEffect, useState } from 'react';
import studioApi from '../../api/studio';
import { getFitOnFeatureFlag } from '../../api/fitOn';

export const GlobalContext = createContext();
export const useGlobal = () => useContext(GlobalContext);

export const GlobalContextProvider = (props) => {
  const [exploreParams, setExploreParams] = useState('');
  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(true);

  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [isDigitalFitOnSurfacing, setIsDigitalFitOnSurfacing] = useState(false);

  const isMoveSite = window.location.hostname.includes(
    process.env.REACT_APP_MOVE_HOSTNAME
  );
  useEffect(() => {
    // setTagsLoading(true);
    if (isMoveSite) {
      studioApi.getAmenities().then((res) => {
        setTags(res);
        setTagsLoading(false);
      });
    } else {
      studioApi.getTags().then((res) => {
        setTags(res);
        setTagsLoading(false);
      });
    }
    studioApi.getCategories().then((res) => {
      setCategories(res.results);
      setCategoriesLoading(false);
    });
    getFitOnFeatureFlag('digital_fiton_surfacing').then((enabled) => {
      setIsDigitalFitOnSurfacing(enabled);
    });
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        tags,
        tagsLoading,
        categories,
        categoriesLoading,
        exploreParams,
        setExploreParams,
        isDigitalFitOnSurfacing,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
