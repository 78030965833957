import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import controllerStyles from '../../styles/Controller.module.css';
import overlayDefaultStyles from '../../styles/overlay/Default.module.css';
import svgStyles from '../../styles/SvgIcon.module.css';
import studioBannerStyles from '../../styles/StudioBanner.module.css';
import reservationHeaderStyles from '../../styles/ReservationHeader.module.css';

import studioBannerImg from '../../Assets/studioBanner.jpg';

import btnDefaultStyles from '../../styles/button/Default.module.css';
import loginStyles from '../../styles/Login.module.css';
import fieldStyles from '../../styles/input/Field.module.css';
import styles from '../../styles/styles.module.css';

import submitStyles from '../../styles/Submit.module.css';
import checkboxStyles from '../../styles/Checkbox.module.css';
import errorFormStyles from '../../styles/error/Form.module.css';

import studioApi from '../../api/studio';
import { Link, useHistory } from 'react-router-dom';
import { DISCOVER, PAYMENT, REGISTER } from '../../navigation/CONSTANTS';
import { useAuth } from '../../contexts/AuthContext';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import user from '../../api/user';
import { FormikDebug } from '../Formik/FormikDebug';
import { Checkbox } from '../Formik/Checkbox1';
import * as Yup from 'yup';
import { GetDate, ToTimezone } from '../../utilities/date';
import loadingStyles from '../../styles/Loading.module.css';
import spinnerGroupStyles from '../../styles/spinner/Group.module.css';
import sleep from '../../utilities/sleep';
import { Cancel } from '@material-ui/icons';
import { ToggleButton } from 'react-bootstrap';
import { useRadioGroup } from '@material-ui/core';
import userApi from '../../api/user';

export const AcceptedNotificationModal = React.forwardRef(
  ({ isShowing, hide, notification, reload }, ref) => {
    const { authState, login, verify, card, hasMembership } = useAuth();
    const [showNotes, setShowNotes] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [studio, setStudio] = useState({});
    const history = useHistory();
    const handleSubmit = async (values, actions) => {};

    const handleClose = () => {
      userApi.readNotification(notification.id).then((res) => {
        console.log('DONE READING NOTIFICATION!');
        reload();
        // hide();
      });
    };

    const readMail = async () => {
      // await sleep(3000);
      setIsLoading(false);
    };

    useEffect(() => {
      studioApi.getStudio(notification.data.studio_id).then((res) => {
        setStudio(res);
        readMail();
      });

      console.log('notifications:', notification);
    }, []);

    return isShowing
      ? ReactDOM.createPortal(
          <React.Fragment>
            <div className={controllerStyles.overlay}>
              <div
                ref={ref}
                role="dialog"
                tabIndex="0"
                data-cy="Login"
                className={overlayDefaultStyles.dialog}
                aria-labelledby="reservation-header-title"
              >
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
                <div
                  data-focus-guard="true"
                  tabIndex="1"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
                <div data-focus-lock-disabled="false">
                  <button
                    className={`${overlayDefaultStyles.close} focus_outline`}
                    aria-label="Close"
                    onClick={hide}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className={`${overlayDefaultStyles.closeIcon} ${svgStyles.icon}`}
                      viewBox="0 0 17 17"
                    >
                      <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z"></path>
                    </svg>
                  </button>

                  {isLoading ? (
                    <div className={overlayDefaultStyles.content}>
                      <div
                        className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`}
                      ></div>
                    </div>
                  ) : (
                    <>
                      <div
                        className={reservationHeaderStyles.header}
                        style={{ backgroundImage: `url(${studioBannerImg})` }}
                      >
                        <div
                          data-cy="reservation-modal"
                          className={reservationHeaderStyles.content}
                        >
                          <div className={reservationHeaderStyles.textWrapper}>
                            <h3 id="reservation-header-title">
                              {authState.isAuthenticated ? (
                                <span>
                                  {notification.data.accepter_name} Accepted
                                  Your Invitation!
                                </span>
                              ) : (
                                <span>Sign In to Reserve</span>
                              )}
                            </h3>
                            <h4>
                              {notification.data.studio_class_name} at{' '}
                              {notification.data.studio_name}
                            </h4>
                            {/* <h4>{format(GetDate(notification.expiration), 'E, LLL do h:mm a')}</h4> */}
                            <h4>
                              {ToTimezone(
                                notification.expiration,
                                studio.timezone,
                                'ddd, MMM Do hh:mm A'
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={overlayDefaultStyles.content}>
                        <Formik
                          initialValues={
                            {
                              // agreement: false,
                            }
                          }
                          // validationSchema={validationSchema}
                          onSubmit={async (values, actions) => {
                            await handleSubmit(values, actions);
                          }}
                        >
                          {(formikProps) => (
                            <Form className={loginStyles.form}>
                              <fieldset>
                                {authState.isAuthenticated ? (
                                  <>
                                    {formikProps.errors.summary && (
                                      <div
                                        className={errorFormStyles.error}
                                        tabIndex="-1"
                                      >
                                        <span
                                          aria-label={
                                            formikProps.errors.summary
                                          }
                                        >
                                          {formikProps.errors.summary}
                                        </span>
                                      </div>
                                    )}
                                    {/* {formikProps.errors.summary &&
                                                                    <div className={errorFormStyles.error} tabIndex="-1">
                                                                        <span aria-label={formikProps.errors.summary}>{formikProps.errors.summary}</span>
                                                                    </div>}

                                                                <div className={fieldStyles.field}>
                                                                    <div>
                                                                        <span style={{ float: "right" }}>{authState.user.credits}</span>
                                                                        <span>Available credits</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style={{ float: "right" }}>{creditCost}</span>
                                                                        <span>Credit cost</span>
                                                                    </div>
                                                                    {(authState.user.credits < creditCost) ?
                                                                        <div>
                                                                            <strong style={{ float: "right" }}>${((creditCost - authState.user.credits) * 3.50).toFixed(2)}</strong>
                                                                            <strong>{creditCost - authState.user.credits} credits needed (1cr x $3.50)</strong>
                                                                        </div> :
                                                                        <div>
                                                                            <strong style={{ float: "right" }}>{authState.user.credits - creditCost}</strong>
                                                                            <strong>Credits Remaining</strong>
                                                                        </div>}
                                                                </div>

                                                                {(authState.user.credits < creditCost) && !card ?
                                                                    <>
                                                                        <div className={loginStyles.copy}>
                                                                            <span>Not enough credits.</span>
                                                                            <Link className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`} to={PAYMENT}>
                                                                                <span>Add a Card</span>
                                                                            </Link>
                                                                        </div>
                                                                    </> :
                                                                    <>  
                                                                        {(authState.user.credits < creditCost) && card ?
                                                                            <div className={fieldStyles.field}>
                                                                                <span>*The remainder will be charged to your card on file.</span>
                                                                            </div> : ""}

                                                                        {studio.cancellation_allowed ? '' :
                                                                            <Checkbox label="I understand this class can't be canceled or refunded" name="agreement" />}

                                                                        <button type="submit" className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`} disabled={formikProps.isSubmitting}>
                                                                            <div className={formikProps.isSubmitting ? `${submitStyles.spinner} ${loadingStyles.spinner}` : loadingStyles.hidden}></div>
                                                                            <span className={formikProps.isSubmitting ? submitStyles.invisible : submitStyles.visible}>
                                                                                <span>Reserve Now</span>
                                                                            </span>
                                                                        </button>

                                                                        <button onClick={() => onCancel()} type="button" className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}>
                                                                            <span className={submitStyles.visible}>
                                                                                <span>No Thanks</span>
                                                                            </span>
                                                                        </button>
                                                                    </>} */}
                                    <button
                                      onClick={() => handleClose()}
                                      type="button"
                                      className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                    >
                                      <span className={submitStyles.visible}>
                                        <span>Ok</span>
                                      </span>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <div className={loginStyles.copy}>
                                      <span>
                                        Don't have a Peerfit account?{' '}
                                      </span>
                                      <Link
                                        className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`}
                                        to={REGISTER}
                                      >
                                        <span>Sign Up</span>
                                      </Link>
                                    </div>
                                    <div className={loginStyles.copy}>
                                      <span>Have an account? </span>
                                      <button
                                        onClick={login}
                                        className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                      >
                                        <span>Sign In</span>
                                      </button>
                                    </div>
                                  </>
                                )}
                              </fieldset>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </>
                  )}
                </div>
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
              </div>
            </div>
          </React.Fragment>,
          document.body
        )
      : null;
  }
);
