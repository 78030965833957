import apiInstance from './api';
import { getPublicClient, msalConfig } from '../api/configs/b2cConfig';
import { getPFToken } from '../utilities/TokenHelper';

const getAccessToken = async () => {
  const accessToken = getPFToken();
  if (accessToken) {
    return accessToken;
  }
  const publicClient = getPublicClient();
  var b2cAccount = publicClient.getAllAccounts()[0];
  if (b2cAccount) {
    var accessTokenRequest = {
      scopes: msalConfig.auth.scopes,
      account: b2cAccount,
    };
    return await publicClient
      .acquireTokenSilent(accessTokenRequest)
      .then((tokenResponse) => {
        sessionStorage.setItem('token', tokenResponse.accessToken);
        return tokenResponse.accessToken;
      })
      .catch(async (error) => {
        // await publicClient.acquireTokenRedirect(accessTokenRequest);
        throw error;
      });
  }
  return null;
};

const verifyJwt = async ({ token }) => {
  return {
    token:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3ODkxMywidXNlcm5hbWUiOiJzYWUyIiwiZXhwIjoxNjA4MjIyMzQ0LCJlbWFpbCI6InNhZXNvQHBlZXJmaXRkZW1vLmNvbSIsIm9yaWdfaWF0IjoxNjA4MTM1OTQ0fQ.lpGhvRYCJQ3TIRieyxkvdgv7dO9IMqV_kVa0cU1r5_M',
    user: {
      id: 78913,
      username: 'sae2',
      display_name: '',
      userprofile: {
        profile_image:
          'https://storage.googleapis123.com/pfapi/users/2020/11/edaff58a-dc9.jpg?GoogleAccessId=mba-service%40peerfit-149019.iam.gserviceaccount.com&Expires=1608223191&Signature=NxvHjnvvOldlAHglz0d6ej8uK51aqfg93PwThTz%2BTmO5NyeGepDRelRhoDYzOewX%2FXgmMtXeOvZ0hCWnhVpR%2B6wV%2FNuG7bpU60oO3lwXXc455qkHFYLMqWo8MMt7j7b9%2F1%2BZwfe7l1VyIoOWBZtaMNacBnKkc%2FoCGaWn04KcvMMK%2BvUEXzWSzAoCN%2BeSNsjacffR4HzguPGFRFR%2B9vYTW6%2BIhZ0je80DwPic%2FCxrYbtaWLmtq5ChgE1FQRUMGIz62qYVxPeSNJ3MDibMZAZ95%2FC%2B0r1m0M7rsWNfxgdZiSmB%2F9KGk5OQ%2FtIPg%2BWpv33Kjl%2Fq41iNsjQb3M5%2BD%2FJUaQ%3D%3D',
        bio: null,
        workout_song: 'wdsfasdf',
        workout_preferences: [],
      },
      email: 'saeso@peerfitdemo.com',
      first_name: 'Sae',
      last_name: 'So',
      address: null,
      city: null,
      state: null,
      zipcode: '90210',
      country: 'US',
      phone: '(480) 555-1234',
      birth_date: '1980-01-01',
      gender: null,
      region: null,
      registered_on: '2020-09-14T11:13:49.186855-04:00',
      referral_code: 'wklnwoB',
      referral_url: 'https://peerfit.cm/referral?referral_code=wklnwoB',
      sponsorship: null,
      subscriptions: [
        {
          id: 27761,
          subscriber: 78913,
          provider: 3,
          provider_name: 'BURN ALONG',
          description_title: 'BurnAlong',
          active: false,
          canceled: true,
          created: '2020-11-02T14:25:13.122935-05:00',
          modified: '2020-11-02T14:25:13.122948-05:00',
          start_date: '2020-11-02',
          end_date: '2020-12-01',
          next_bill_date: null,
        },
        {
          id: 27760,
          subscriber: 78913,
          provider: 1,
          provider_name: 'FORTE',
          description_title: 'FORTË',
          active: false,
          canceled: true,
          created: '2020-11-02T14:20:12.478434-05:00',
          modified: '2020-11-02T14:20:12.478447-05:00',
          start_date: '2020-11-02',
          end_date: '2020-12-01',
          next_bill_date: null,
        },
      ],
      contacts: [{ id: 2993, email: 'amarkwell8@gmail.com' }],
      contact_groups: [],
      groups: [],
      credits: 0,
      customer: {
        id: 77882,
        stripe_id: 'cus_I1M5MGhO1H2wwp',
        account_balance: '0.00',
        currency: '',
        delinquent: false,
        default_source: '',
        date_purged: null,
        subscription_set: [],
        card_set: [],
      },
      sociallink_set: [],
      email_addresses: [
        {
          id: 78617,
          email: 'saeso@peerfitdemo.com',
          verified: false,
          primary: true,
          md5hash: 'd32ddcfd6911c396c36590b1947250ad',
        },
      ],
      privacyrule_set: [],
      user_key: 'WoQxvkI6ib-QuHfwZFtgD4yfX9UU6in2bYNHqV8yc8E=',
      tags: [
        { id: 5, name: 'Bootcamp' },
        { id: 13, name: 'CrossFit' },
        { id: 4, name: 'Dance' },
        { id: 18, name: 'Martial Arts' },
        { id: 16, name: 'Pilates' },
      ],
      categories: [],
      user_studios: [
        { id: 1, studio_id: 47 },
        { id: 1, studio_id: 99 },
        { id: 1, studio_id: 5048 },
      ],
      legacy_user_studios: null,
      is_active: true,
      registration_intent: 2,
      permissions: [],
      profile_issues: [
        {
          code: 'unverified',
          title: 'Please Verify Your Email Address',
          message:
            'We need just one little favor! In order to continue, your email needs to be verified. We sent you an email when your account was created.',
        },
      ],
      home_city: 'Scottsdale, AZ, USA',
      latitude: '33.4941704000000016',
      longitude: '-111.9260519000000045',
      locked_out: false,
      consumer_subscription: {
        plan: null,
        renewal_date: null,
        status: 'Never subscribed',
        referrer: null,
        will_renew: false,
      },
      sponsor_type: null,
      pmid: 'PF03781BZ4',
      enrollment_effective_date: null,
      network_option: 'not_set',
    },
    is_admin: false,
  };
};

const login = async ({ email, password }) => {
  return {
    token:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3ODkxMywidXNlcm5hbWUiOiJzYWUyIiwiZXhwIjoxNjA4MjIyMzQ0LCJlbWFpbCI6InNhZXNvQHBlZXJmaXRkZW1vLmNvbSIsIm9yaWdfaWF0IjoxNjA4MTM1OTQ0fQ.lpGhvRYCJQ3TIRieyxkvdgv7dO9IMqV_kVa0cU1r5_M',
    user: {
      id: 78913,
      username: 'sae2',
      display_name: '',
      userprofile: {
        profile_image:
          'https://storage.googleapis123.com/pfapi/users/2020/11/edaff58a-dc9.jpg?GoogleAccessId=mba-service%40peerfit-149019.iam.gserviceaccount.com&Expires=1608222344&Signature=AZR%2FKG3N2gpVkVXC7UJRXShu0COd8K4%2F%2Bgqq0Zt%2BCgCyjC32M4RaL8CThBiqKB9H%2BZiyWtf7jAUUD8qgHcxKhTucNfxB8CcC24THqe8FCpIphUqEcmShnhZYxKIBihzZNIR00hXN1%2Fut1zb90YssEPGM%2BG%2FkrGsedDTkdC11lP0%2BthXmpG9SZ0hDvI2ybq7T1b0KZWok1ghNXd1VV5XCe6IcRCexSSn3a%2BdAMoqCwP2uV%2FkzbW%2BawBKsEpeS5XmwZfjo%2B1fhVOtflmgN4mAgpLAzKTDtOIDCmnLeCEAwuSy1M0q8tGPDF%2FC0nHBjx0aJQxufiV0meVBA%2FwAIZQW%2FBA%3D%3D',
        bio: null,
        workout_song: 'wdsfasdf',
        workout_preferences: [],
      },
      email: 'saeso@peerfitdemo.com',
      first_name: 'Sae',
      last_name: 'So',
      address: null,
      city: null,
      state: null,
      zipcode: '90210',
      country: 'US',
      phone: '(480) 555-1234',
      birth_date: '1980-01-01',
      gender: null,
      region: null,
      registered_on: '2020-09-14T11:13:49.186855-04:00',
      referral_code: 'wklnwoB',
      referral_url: 'https://peerfit.cm/referral?referral_code=wklnwoB',
      sponsorship: null,
      subscriptions: [
        {
          id: 27761,
          subscriber: 78913,
          provider: 3,
          provider_name: 'BURN ALONG',
          description_title: 'BurnAlong',
          active: false,
          canceled: true,
          created: '2020-11-02T14:25:13.122935-05:00',
          modified: '2020-11-02T14:25:13.122948-05:00',
          start_date: '2020-11-02',
          end_date: '2020-12-01',
          next_bill_date: null,
        },
        {
          id: 27760,
          subscriber: 78913,
          provider: 1,
          provider_name: 'FORTE',
          description_title: 'FORTË',
          active: false,
          canceled: true,
          created: '2020-11-02T14:20:12.478434-05:00',
          modified: '2020-11-02T14:20:12.478447-05:00',
          start_date: '2020-11-02',
          end_date: '2020-12-01',
          next_bill_date: null,
        },
      ],
      contacts: [{ id: 2993, email: 'amarkwell8@gmail.com' }],
      contact_groups: [],
      groups: [],
      credits: 0,
      customer: {
        id: 77882,
        stripe_id: 'cus_I1M5MGhO1H2wwp',
        account_balance: '0.00',
        currency: '',
        delinquent: false,
        default_source: '',
        date_purged: null,
        subscription_set: [],
        card_set: [],
      },
      sociallink_set: [],
      email_addresses: [
        {
          id: 78617,
          email: 'saeso@peerfitdemo.com',
          verified: false,
          primary: true,
          md5hash: 'd32ddcfd6911c396c36590b1947250ad',
        },
      ],
      privacyrule_set: [],
      user_key: 'WoQxvkI6ib-QuHfwZFtgD4yfX9UU6in2bYNHqV8yc8E=',
      tags: [
        { id: 5, name: 'Bootcamp' },
        { id: 13, name: 'CrossFit' },
        { id: 4, name: 'Dance' },
        { id: 18, name: 'Martial Arts' },
        { id: 16, name: 'Pilates' },
      ],
      categories: [],
      user_studios: [
        { id: 1, studio_id: 47 },
        { id: 1, studio_id: 99 },
        { id: 1, studio_id: 5048 },
      ],
      legacy_user_studios: null,
      is_active: true,
      registration_intent: 2,
      permissions: [],
      profile_issues: [
        {
          code: 'unverified',
          title: 'Please Verify Your Email Address',
          message:
            'We need just one little favor! In order to continue, your email needs to be verified. We sent you an email when your account was created.',
        },
      ],
      home_city: 'Scottsdale, AZ, USA',
      latitude: '33.4941704000000016',
      longitude: '-111.9260519000000045',
      locked_out: false,
      consumer_subscription: {
        plan: null,
        renewal_date: null,
        status: 'Never subscribed',
        referrer: null,
        will_renew: false,
      },
      sponsor_type: null,
      pmid: 'PF03781BZ4',
      enrollment_effective_date: null,
      network_option: 'not_set',
    },
    is_admin: false,
  };
};

const logout = async () => {
  const api = await apiInstance.getApi();
  return api.post('/logout/').catch((err) => {
    console.log('ERROR:', err.response.data.errors[0].message);
    throw err;
  });
};

const verifyEnrollment = async ({ birth_date, pmid }) => {
  const api = await apiInstance.getApi();
  const fullParams = {
    birth_date,
    pmid,
  };
  console.log('verifying enrollment');
  return api
    .post('/verify-enrollment/', fullParams)
    .then((res) => {
      console.log('verify:', res.data);
    })
    .catch((err) => {
      console.log('ERROR:', err.response.data.errors[0].message);
      throw err;
    });
};

export default {
  getAccessToken,
  verifyJwt,
  login,
  logout,
  verifyEnrollment,
};
