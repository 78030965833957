import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useField } from 'formik';
import IconButton from "@material-ui/core/IconButton";
import inputFieldStyles from '../../styles/input/Field.module.css';
import styles from '../../styles/styles.module.css';

const useStyles = makeStyles(() => ({
  input: {
    position: 'relative',
    marginBottom: 16,
    '&.error input': {
      outline: '1px solid #ff5252',
    },
    '& input': {
      background: '#ffffff !important',
      borderRadius: 4,
      border: 'none',
      boxShadow: '0px 0px 8px rgba(34, 43, 86, 0.2)',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '25px',
      padding: '14px 20px',
      margin: 0,
      width: '100%',

      '&:focus': {
        outline: '1px solid #538fe9',
        padding: '14px 20px',
        border: 'none',
      }
    }
  },
  errorInfo: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#ff5252',
    marginTop: 8,
    whiteSpace: 'nowrap',
  },
}));

export const CustomTextInput = ({ label, showIcon = false, type, ...props }) => {
  const classes = useStyles();
  // @ts-ignore
  const [field, meta] = useField(props);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const [showPassword, setShowPassword] = useState(false);
  const showError = meta.touched && meta.error;

  const customPadding = {
    paddingRight: 30,
  };


  return (
    <div className={`${classes.input} ${showError && 'error'}`}>
      <label
        htmlFor={`${props.id}-2`}
        className={`${inputFieldStyles.label} ${styles.container}`}
      >
        {label}
      </label>
      <input
        autoComplete='off'
        aria-describedby={`${props.id}-2`}
        id={`${props.id}-1`}
        type={showPassword ? 'text' : type}
        {...field}
        {...props}
        style={showIcon ? { ...customPadding } : null}
      />
      {
        showIcon ?
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            style={{ position: 'absolute', top: 8, right: 0, cursor: 'pointer' }}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton> : null
      }
      <div
        id={`${props.id}-1`}
        aria-live="polite"
        aria-atomic="true"
        role="status"
        className={classes.errorInfo}
      >
        {showError && (
          <>
            <span
              data-cy={`${label}-error`}
              aria-label={meta.error}
            >
              {meta.error}
            </span>
          </>
        )}
      </div>
    </div>
  );
};
