import React from 'react';
import { Link } from 'react-router-dom';
import {
  ABOUT_US,
  ACCESSIBILITY,
  CONTACT_US,
  COOKIE_POLICY,
  CORPORATE_WELLNESS,
  FITNESS_PARTNERS,
  HOW_IT_WORKS,
  INDIVIDUAL_SUBSCRIPTION,
  PRIVACY_POLICY,
  ROOT,
  TERMS_OF_SERVICE,
} from '../navigation/CONSTANTS';
//styles
import flexStyles from '.././styles/Flex.module.css';
import footerStyles from '.././styles/Footer.module.css';
import linkListStyles from '.././styles/LinkList.module.css';
import socialStyles from '.././styles/Social.module.css';
import { useAuth } from '../contexts/AuthContext';
import { getSourceFromPathName, logAmplitudeEvent } from '../api/integration';
export default function MoveFooter(props) {
  const { authState } = useAuth();
  return (
    <footer
      id="footer"
      className={`${footerStyles.container} ${footerStyles.coreFooter}`}
      data-testid="core-footer"
    >
      <>
        <div className={`${footerStyles.content} ${flexStyles.row}`}>
          <div className={`${footerStyles.inner} ${flexStyles.row}`}>
            <div
              className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-3']} ${footerStyles.col}`}
            >
              <Link
                className={`${footerStyles.homeLink} focus_outline`}
                aria-label="Peerfit home page"
                to={ROOT}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className={footerStyles.logo}
                  viewBox="0 0 238.87 60"
                >
                  <title>® Update</title>
                  <path
                    className="cls-1"
                    d="M24.91,32.6,18,44.66a1.9,1.9,0,0,1-1.65,1H5a1.86,1.86,0,0,1-1.8-1.34L.07,34.61a1.54,1.54,0,0,1,1.45-2Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M24.91,27.19H11a1.85,1.85,0,0,1-1.65-1L3.78,16.52A1.86,1.86,0,0,1,4,14.31l6.8-7.58A1.56,1.56,0,0,1,13.32,7Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M36.5,49.25,30.84,59a1.94,1.94,0,0,1-2.07.92l-9.94-2.11a1.53,1.53,0,0,1-1-2.27l11.75-20.3,7,12.06A1.82,1.82,0,0,1,36.5,49.25Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M41.19,4.36,29.55,24.51l-7-12.06a1.81,1.81,0,0,1,0-1.9L28.16,1A1.92,1.92,0,0,1,30.22,0l10,2.06A1.53,1.53,0,0,1,41.19,4.36Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M55.21,45.48l-6.75,7.63A1.52,1.52,0,0,1,46,52.85L34.29,32.6H48.2a1.85,1.85,0,0,1,1.65,1l5.62,9.74A1.87,1.87,0,0,1,55.21,45.48Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M57.53,27.19H34.24l6.95-12.06a2,2,0,0,1,1.65-1H54a1.9,1.9,0,0,1,1.81,1.29L59,25.13A1.52,1.52,0,0,1,57.53,27.19Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M75.2,55.69V18h6.24v3.66c1.34-2.32,4.43-4.28,8.6-4.28,8,0,12.53,6.13,12.53,14.12S97.62,45.74,89.84,45.74c-3.92,0-6.86-1.65-8.25-3.71V55.69ZM88.86,23.12c-4.23,0-7.27,3.35-7.27,8.4s3,8.5,7.27,8.5,7.26-3.35,7.26-8.5S93.19,23.12,88.86,23.12Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M131.83,37.39c-1.44,4.75-5.72,8.56-12.21,8.56-7.32,0-13.86-5.36-13.86-14.53,0-8.56,6.34-14.23,13.19-14.23,8.35,0,13.25,5.52,13.25,14.07a9.94,9.94,0,0,1-.16,2.06H112.25a7.25,7.25,0,0,0,7.42,7.06,6.57,6.57,0,0,0,6.81-4.74Zm-6.13-8.81c-.1-3.19-2.21-6-6.7-6a6.44,6.44,0,0,0-6.59,6Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M161.57,37.39c-1.45,4.75-5.72,8.56-12.22,8.56-7.31,0-13.86-5.36-13.86-14.53,0-8.56,6.34-14.23,13.19-14.23,8.35,0,13.25,5.52,13.25,14.07a9.94,9.94,0,0,1-.16,2.06H142a7.24,7.24,0,0,0,7.42,7.06,6.57,6.57,0,0,0,6.8-4.74Zm-6.19-8.81c-.1-3.19-2.21-6-6.7-6a6.44,6.44,0,0,0-6.59,6Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M182.18,24.41a15.57,15.57,0,0,0-2.11-.16c-5,0-7.27,2.89-7.27,7.94V45.07h-6.44V18h6.29V22.3a8.2,8.2,0,0,1,7.83-4.69,9.78,9.78,0,0,1,1.7.15Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M195.89,14.46V18h6.28v5.62h-6.28V45.12h-6.5V23.64h-4.74V18h4.74V14.31c0-6.08,3.92-9.74,9.48-9.74a8.61,8.61,0,0,1,3.41.57v5.51a9.35,9.35,0,0,0-2.32-.26C198.15,10.34,195.89,11.11,195.89,14.46Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M209.54,4.26a4.25,4.25,0,0,1,4.23,4.23,4.2,4.2,0,1,1-4.23-4.23Zm-3.19,40.86V18h6.39v27.1Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M227.68,18h5.62v5.72h-5.62V36.31c0,2.37,1.08,3.4,3.45,3.4a15.06,15.06,0,0,0,2.22-.2v5.36a10.63,10.63,0,0,1-4,.61c-5,0-8.14-3-8.14-8.09V23.74h-5V18h1.39c2.89,0,4.18-1.86,4.18-4.23V9.72h5.82V18Z"
                  ></path>
                  <path
                    className="cls-1"
                    d="M234.58,11.86a2.09,2.09,0,0,1,.17-.83,2,2,0,0,1,.46-.68,2.33,2.33,0,0,1,.68-.46,2.14,2.14,0,0,1,1.67,0,2.47,2.47,0,0,1,.68.46,2.15,2.15,0,0,1,.46.68,2.09,2.09,0,0,1,.17.83,2.12,2.12,0,0,1-.17.83,2.13,2.13,0,0,1-1.14,1.12,2.17,2.17,0,0,1-2.35-.45,2.06,2.06,0,0,1-.46-.67A2.12,2.12,0,0,1,234.58,11.86Zm.34,0a1.68,1.68,0,0,0,.14.7,1.73,1.73,0,0,0,.38.57,1.74,1.74,0,0,0,.58.39,1.73,1.73,0,0,0,1.41,0,1.87,1.87,0,0,0,1-1,1.85,1.85,0,0,0,0-1.41,1.61,1.61,0,0,0-.39-.57,1.92,1.92,0,0,0-.57-.39,1.85,1.85,0,0,0-1.41,0,2.11,2.11,0,0,0-.58.39,1.73,1.73,0,0,0-.38.57A1.7,1.7,0,0,0,234.92,11.86ZM237.24,13l-.52-.94h-.33V13h-.58V10.63h1l.33,0a1.08,1.08,0,0,1,.29.13.52.52,0,0,1,.21.23.74.74,0,0,1,.08.36.59.59,0,0,1-.14.4.76.76,0,0,1-.34.24l.64,1Zm-.41-1.35a.41.41,0,0,0,.24-.08.3.3,0,0,0,.11-.23.23.23,0,0,0-.13-.22.49.49,0,0,0-.22-.06h-.44v.59Z"
                  ></path>
                </svg>
              </Link>
            </div>
            <div
              className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-3']} ${footerStyles.col}`}
            >
              <div>
                <h3 className={linkListStyles.header}>
                  <span>Company</span>
                </h3>
                <Link className={linkListStyles.link} to={ABOUT_US}>
                  <span>About</span>
                </Link>
                <a
                  rel="noopener noreferrer"
                  className={linkListStyles.link}
                  target="_blank"
                  href="https://pulse.peerfit.com"
                >
                  <span>Blog</span>
                </a>
                <a
                  rel="noopener noreferrer"
                  className={linkListStyles.link}
                  target="_blank"
                  href="https://pulse.peerfit.com/media-room/"
                >
                  <span>Press</span>
                </a>
                <a
                  rel="noopener noreferrer"
                  className={linkListStyles.link}
                  target="_blank"
                  href="https://pulse.peerfit.com/hiring/"
                >
                  <span>Careers</span>
                </a>
                <a
                  className={linkListStyles.link}
                  href={process.env.REACT_APP_MOVE_URL}
                >
                  <span data-testid="move-link">Peerfit Move</span>
                </a>
              </div>
            </div>
            <div
              className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-3']} ${footerStyles.col}`}
            >
              <div>
                <h3 className={linkListStyles.header}>
                  <span>Discover</span>
                </h3>
                <Link className={linkListStyles.link} to={HOW_IT_WORKS}>
                  <span>How It Works</span>
                </Link>
                <Link
                  className={linkListStyles.link}
                  to={INDIVIDUAL_SUBSCRIPTION}
                >
                  <span>Individual Subscription</span>
                </Link>
                <Link className={linkListStyles.link} to={CORPORATE_WELLNESS}>
                  <span>Corporate Wellness</span>
                </Link>
                <Link className={linkListStyles.link} to={FITNESS_PARTNERS}>
                  <span>Fitness Partners</span>
                </Link>
              </div>
            </div>
            <div
              className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-3']} ${footerStyles.col}`}
            >
              <div>
                <h3 className={linkListStyles.header}>
                  <span>Support</span>
                </h3>
                {/* <Link
                  to={`${CONTACT_US}?from_source=${getSourceFromPathName()}`}
                  className={linkListStyles.link}
                >
                  <span>Contact Us</span>
                </Link> */}
                <a
                  rel="noopener noreferrer"
                  className={linkListStyles.link}
                  target="_blank"
                  href="https://fitonhealth.com/contact-us"
                >
                  <span>Contact Us</span>
                </a>
                <Link className={linkListStyles.link} to={PRIVACY_POLICY}>
                  <span>Privacy Policy</span>
                </Link>
                <Link className={linkListStyles.link} to={COOKIE_POLICY}>
                  <span>Cookie Policy</span>
                </Link>
                <Link className={linkListStyles.link} to={TERMS_OF_SERVICE}>
                  <span>Terms of Use</span>
                </Link>
                <Link className={linkListStyles.link} to={ACCESSIBILITY}>
                  <span>Accessibility</span>
                </Link>
                <a
                  rel="noopener noreferrer"
                  className={linkListStyles.link}
                  target="_blank"
                  href="https://www.fitonhealth.com/faq/commercial-members"
                  onClick={() => {
                    if (authState?.user?.id) {
                      logAmplitudeEvent('Support: FAQ', {
                        Source: getSourceFromPathName(),
                        URL: window.location.href,
                      });
                    }
                  }}
                >
                  <span>FAQ</span>
                </a>
              </div>
            </div>
          </div>

          <div className={`${footerStyles.inner} ${flexStyles.row}`}>
            <div
              className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-3']} ${footerStyles.col}`}
            >
              <h3 className={footerStyles.connectWithUs}>
                <span>Connect With Us</span>
              </h3>
              <div className={footerStyles.socialLinks}>
                <div className={socialStyles.container}>
                  <a
                    rel="noopener noreferrer"
                    className={`${socialStyles.icon} focus_outline`}
                    href="https://facebook.com/peerfit"
                    target="_blank"
                    aria-label="Peerfit on Facebook"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="Footer__icon"
                      viewBox="0 0 155.139 155.139"
                    >
                      <path d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184 c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452 v20.341H37.29v27.585h23.814v70.761H89.584z"></path>
                    </svg>
                  </a>
                  <a
                    rel="noopener noreferrer"
                    className={`${socialStyles.icon} focus_outline`}
                    href="https://instagram.com/peerfit"
                    target="_blank"
                    aria-label="Peerfit on Instagram"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="Footer__icon"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path>
                    </svg>
                  </a>
                  <a
                    rel="noopener noreferrer"
                    className={`${socialStyles.icon} focus_outline`}
                    href="https://twitter.com/peerfit"
                    target="_blank"
                    aria-label="Peerfit on Twitter"
                    data-testid="twitter-link"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="Footer__icon"
                      viewBox="0 0 300.00006 244.18703"
                    >
                      <g transform="translate(-539.17946,-568.85777)">
                        <path d="m 633.89823,812.04479 c 112.46038,0 173.95627,-93.16765 173.95627,-173.95625 0,-2.64628 -0.0539,-5.28062 -0.1726,-7.90305 11.93799,-8.63016 22.31446,-19.39999 30.49762,-31.65984 -10.95459,4.86937 -22.74358,8.14741 -35.11071,9.62551 12.62341,-7.56929 22.31446,-19.54304 26.88583,-33.81739 -11.81284,7.00307 -24.89517,12.09297 -38.82383,14.84055 -11.15723,-11.88436 -27.04079,-19.31655 -44.62892,-19.31655 -33.76374,0 -61.14426,27.38052 -61.14426,61.13233 0,4.79784 0.5364,9.46458 1.58538,13.94057 -50.81546,-2.55686 -95.87353,-26.88582 -126.02546,-63.87991 -5.25082,9.03545 -8.27852,19.53111 -8.27852,30.73006 0,21.21186 10.79366,39.93837 27.20766,50.89296 -10.03077,-0.30992 -19.45363,-3.06348 -27.69044,-7.64676 -0.009,0.25652 -0.009,0.50661 -0.009,0.78077 0,29.60957 21.07478,54.3319 49.0513,59.93435 -5.13757,1.40062 -10.54335,2.15158 -16.12196,2.15158 -3.93364,0 -7.76596,-0.38716 -11.49099,-1.1026 7.78383,24.2932 30.35457,41.97073 57.11525,42.46543 -20.92578,16.40207 -47.28712,26.17062 -75.93712,26.17062 -4.92898,0 -9.79834,-0.28036 -14.58427,-0.84634 27.05868,17.34379 59.18936,27.46396 93.72193,27.46396"></path>
                      </g>
                    </svg>
                  </a>
                  <a
                    rel="noopener noreferrer"
                    className={`${socialStyles.icon} focus_outline`}
                    href="https://www.linkedin.com/company/peerfit-inc"
                    target="_blank"
                    aria-label="Peerfit on LinkedIn"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className="Footer__icon"
                      viewBox="0 0 24 24"
                    >
                      <rect height="11" width="4" x="3" y="9"></rect>
                      <circle cx="5" cy="5" r="2"></circle>
                      <path d="M16.5,8.25A4.47251,4.47251,0,0,0,13,9.95343V9H9V20h4V13a2,2,0,0,1,4,0v7h4V12.75A4.5,4.5,0,0,0,16.5,8.25Z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={footerStyles.trademark}>
          <span>Peerfit, Peerfit Core,</span>{' '}
          <a
            className={footerStyles.moveLink}
            href={process.env.REACT_APP_MOVE_URL}
          >
            <span>Peerfit Move</span>
          </a>{' '}
          <span>
            and FitKit are the trademarks or registered trademarks of Peerfit,
            Inc.
          </span>
        </div>
        <span className={footerStyles.copyright}>
          Copyright © 2022 Peerfit, Inc.
        </span>
      </>
    </footer>
  );
}
