import { ClickAwayListener } from '@material-ui/core';
import React, { useContext, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { logAmplitudeEvent } from './api/integration';
import { AuthContext } from './contexts/AuthContext';
import { GlobalContext } from './contexts/GlobalContext';
import { SiteContext } from './contexts/SiteContext';
import { ACCOUNT, ADMIN, CREDITS, EXPLORE_STUDIOS, ROOT, STREAMING } from './navigation/CONSTANTS';
import { Notification } from './Notification';
import useWindowSize, { _LG, _MD } from './shared/hooks/useWindowSize';
import btnDefaultStyles from './styles/button/Default.module.css';
import dropdownDefaultStyles from './styles/dropdown/Default.module.css';
import headerStyles from './styles/Header.module.css';
import loadingStyles from './styles/Loading.module.css';
import svgIconStyles from './styles/SvgIcon.module.css';
import userStyles from './styles/User.module.css';
import userMenuStyles from './styles/UserMenu.module.css';
import { canAccessAdmin } from './utilities/jwt';
import { clearCookies } from './utilities/storage';

function HeaderAction() {
  const authContext = useContext(AuthContext);
  const globalContext = useContext(GlobalContext);
  const siteContext = useContext(SiteContext);
  const { userprofile } = authContext.authState.user || {};
  const user = authContext.authState.user;
  const isPFLogin = authContext.isPFLogin;
  const history = useHistory();
  const size = useWindowSize();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleClick = () => {
    // console.log("showing: ", setOpen);
    setOpen((prevState) => !prevState);
  };
  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      // console.log("clicked inside");
      return;
    }
    // console.log("clicked outside,", setOpen);
    setOpen(false);
  };

  const [openNotification, setOpenNotification] = useState(false);
  const anchorRefNotification = useRef(null);
  const handleClickNotification = () => {
    setOpenNotification((prevState) => !prevState);
  };
  const handleCloseNotification = (e) => {
    if (anchorRefNotification.current && anchorRefNotification.current.contains(e.target)) {
      return;
    }
    setOpenNotification(false);
  };

  const handleLogout = async () => {
    setOpen(false);
    try {
      await authContext.logout();
      console.log('logout success.');
    } catch (error) {
      console.log('error logging out.', error.response);
      clearCookies();
      sessionStorage.clear();
    }
    window.location.href = ROOT;
  };
  const hasAdminAccess = canAccessAdmin(authContext?.authState?.token);

  return (
    <div className={headerStyles.actions}>
      {authContext.authState.isAuthenticated ? (
        <>
          {size[3] <= _MD ? (
            ''
          ) : (
            <>
              <Link
                to={`${EXPLORE_STUDIOS}?${globalContext.exploreParams}&from_source=header`}
                className={`${headerStyles.linkButton} ${btnDefaultStyles.button} focus_outline`}
              >
                Explore
              </Link>
              {siteContext.isCoreSite && (
                <Link to={`${STREAMING}?from_source=header`} className={`${headerStyles.linkButton} ${btnDefaultStyles.button} focus_outline`}>
                  Digital
                </Link>
              )}
              {size[0] >= 1350 ? (
                <>
                  <Link to={`/user/${user.id}/upcoming?from_source=header`} className={`${headerStyles.linkButton} ${btnDefaultStyles.button} focus_outline`}>
                    Upcoming
                  </Link>
                  <Link
                    to={`/user/${user.id}/memberships?from_source=header`}
                    className={`${headerStyles.linkButton} ${btnDefaultStyles.button} focus_outline`}
                  >
                    Memberships
                  </Link>
                </>
              ) : (
                ''
              )}
            </>
          )}

          <Notification />
          <Link className={`${headerStyles.credits} focus_outline`} to={`${CREDITS}?from_source=header`}>
            <span className={headerStyles.creditCount} data-cy="header-available-credits">
              {user.credits}
            </span>
            <span className={headerStyles.creditDescription}>credits</span>
          </Link>

          <div className={`${userMenuStyles.container} ${dropdownDefaultStyles.dropdown}`} data-cy="usermenu" style={{ zIndex: 'inherit' }}>
            <button
              ref={anchorRef}
              className={`${userMenuStyles.dropdownLabel} ${dropdownDefaultStyles.label} focus_outline`}
              aria-haspopup="true"
              aria-expanded="false"
              aria-label="User Navigation Menu"
              onClick={handleClick}
            >
              <div className={dropdownDefaultStyles.icon}>
                <div className={headerStyles.avatarImg}>
                  {userprofile?.profile_image ? (
                    <div
                      className={userStyles.image}
                      style={{
                        backgroundImage: `url(${userprofile.profile_image})`,
                      }}
                    ></div>
                  ) : (
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className={`${userStyles.noImage} ${svgIconStyles.icon}`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20,19.5c-2,2.1-4.8,3.5-8,3.5c-3.1,0-6-1.3-8-3.5c0.1-1.3,0.4-2.1,2.1-2.5c2.2-0.5,4.5-1,3.4-2.9C6.4,8.3,8.6,5,12,5 c3.3,0,5.6,3.2,2.5,9.1c-1,2,1.1,2.4,3.4,2.9C19.6,17.5,19.9,18.2,20,19.5z M24,12c0,6.6-5.4,12-12,12c0,0,0,0,0,0 C5.4,24,0,18.6,0,12C0,5.4,5.4,0,12,0C18.6,0,24,5.4,24,12z M23,12c0-6.1-4.9-11-11-11S1,5.9,1,12c0,2.9,1.2,5.6,3,7.5 c2,2.1,4.8,3.5,8,3.5c3.1,0,6-1.3,8-3.5C21.8,17.6,23,14.9,23,12z"></path>
                    </svg>
                  )}
                </div>
              </div>
            </button>
            <ClickAwayListener onClickAway={handleClose}>
              <div
                className={dropdownDefaultStyles.dropdownContent}
                style={{
                  display: open ? 'block' : 'none',
                  left: 'auto',
                  right: '-5px',
                }}
              >
                <div className={`${dropdownDefaultStyles.rightIndicator} ${dropdownDefaultStyles.leftIndicator}`}>
                  <div className={dropdownDefaultStyles.indicator}></div>
                </div>

                {size[3] <= _LG ? (
                  <>
                    <Link onClick={handleClose} to={`/user/${user.id}/upcoming?from_source=header`} className={`${dropdownDefaultStyles.option} focus_outline`}>
                      <span>Upcoming</span>
                    </Link>
                    <Link
                      onClick={handleClose}
                      to={`/user/${user.id}/memberships?from_source=header`}
                      className={`${dropdownDefaultStyles.option} focus_outline`}
                    >
                      <span>Memberships</span>
                    </Link>
                  </>
                ) : (
                  ''
                )}

                <Link onClick={handleClose} to={`/user/${user.id}/saved?from_source=header`} className={`${dropdownDefaultStyles.option} focus_outline`}>
                  <span>Favorites</span>
                </Link>
                <Link onClick={handleClose} to={`${ACCOUNT}?from_source=header`} className={`${dropdownDefaultStyles.option} focus_outline`}>
                  <span>Settings</span>
                </Link>
                {siteContext.isCoreSite && (
                  <Link
                    onClick={handleClose}
                    to={`${STREAMING}?from_source=header`}
                    className={`${dropdownDefaultStyles.option} focus_outline`}
                    data-cy="usermenu-logout"
                  >
                    <span>Digital</span>
                  </Link>
                )}
                {hasAdminAccess && (
                  <Link
                    onClick={(event) => {
                      logAmplitudeEvent('Click: Main Menu - Admin');
                      handleClose(event);
                    }}
                    to={`${ADMIN}?from_source=header`}
                    className={`${dropdownDefaultStyles.option} focus_outline`}
                  >
                    <span>Admin</span>
                  </Link>
                )}

                <button onClick={handleLogout} className={`${dropdownDefaultStyles.option} focus_outline`} data-cy="usermenu-logout">
                  <span>Sign Out</span>
                </button>
              </div>
            </ClickAwayListener>
          </div>
          <div className={loadingStyles.hidden}></div>
        </>
      ) : (
        <>
          <button
            className={`${headerStyles.signup} ${btnDefaultStyles['secondary-button']} ${btnDefaultStyles.button} focus_outline`}
            onClick={() => history.push('/register?from_source=header')}
          >
            <span>Sign Up</span>
          </button>
          {isPFLogin !== undefined && (
            <button className={`${headerStyles.linkButton} ${btnDefaultStyles.button} focus_outline`} onClick={authContext.login}>
              <span>Sign In</span>
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default HeaderAction;
