import React from 'react';
import { Link } from 'react-router-dom';

import streamingHeaderImg from '../../Assets/streaming_header.png';
import recharjLogo from '../../Assets/recharj.png';
import conbodyLogo from '../../Assets/conbody.png';
import btoneLogo from '../../Assets/btone.png';
import headerStyles from '../../styles/streaming/Header.module.css';
import btnDefaultStyles from '../../styles/button/Default.module.css';
import { STREAMING_STUDIOS } from '../../navigation/CONSTANTS';

function HeaderSection() {
  return (
    <div className={headerStyles.container}>
      <div className={headerStyles.imageContainer}>
        <img src={streamingHeaderImg} alt="" />
      </div>
      <div className={headerStyles.copyContainer}>
        <h1>
          <span>Stream Live Workouts</span>
        </h1>
        <p>
          <span>
            Continue to support local studios. Join live and on-demand classes
            offered by our Fitness Partners and continue getting your sweat on
            by working out in the comfort of your home.
          </span>
        </p>
        <div className={headerStyles.studioLogos}>
          <div className={headerStyles.studioLogo}>
            <img src={recharjLogo} alt="" />
          </div>
          <div className={headerStyles.studioLogo}>
            <img src={conbodyLogo} alt="" />
          </div>
          <div className={headerStyles.studioLogo}>
            <img src={btoneLogo} alt="" />
          </div>
        </div>
        <Link
          to={`${STREAMING_STUDIOS}?from_source=Streaming`}
          className={`${headerStyles.viewStudiosBtn} ${btnDefaultStyles.button} focus_outline`}
        >
          View Studios
        </Link>
      </div>
    </div>
  );
}

export default HeaderSection;
