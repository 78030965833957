import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Formik, Form, useField } from 'formik';

import * as Yup from 'yup';
import nextId from 'react-id-generator';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { capitalize } from 'lodash';

import submitStyles from '../../styles/Submit.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import './LoginForm.scss';
import { logAmplitudeEvent, logBrazeEvent } from '../../api/integration';
import { useHistory } from 'react-router-dom';
import { DISCOVER, REGISTER_USER, REGISTER_CHECK_YOUR_EMAIL, REGISTER } from '../../navigation/CONSTANTS';
import { getFeatureFlag } from '../../api/config';

const CustomTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={`custom-input ${meta.touched && meta.error && 'error'}`}>
      <div className="custom-input-wrapper">
        <input aria-describedby={`${props.id}-2`} id={`${props.id}-1`} {...field} {...props} />
      </div>
      <div id={`${props.id}-1`} aria-live="polite" aria-atomic="true" role="status" className="error-info">
        {meta.touched && meta.error && (
          <>
            <span data-cy={`${label}-error`} aria-label={meta.error}>
              {meta.error}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default function LoginForm() {
  const { pfLogin, setRegisterUser, authState } = useAuth();
  const [newLoginEnabled, setNewLoginEnabled] = useState(undefined);
  const history = useHistory();
  const latestLoggedEmail = localStorage.getItem('PF_LATEST_LOGGED_EMAIL');

  const handleLogin = async (values, actions) => {
    try {
      const result = await pfLogin(values);
      if (result) {
        logAmplitudeEvent('Signin: Success', {
          Source: 'Signin Screen',
          'Login Type': result.isB2C ? 'B2C' : 'New',
        });
        logBrazeEvent('Signin: Success', { Source: 'Signin Screen' });
        if (result.registering === 'True') {
          setRegisterUser({
            email: result.email,
          });
          history.push(REGISTER_USER);
        }
        localStorage.setItem('PF_LATEST_LOGGED_EMAIL', values.email);
      }
    } catch (error) {
      const message = error?.response?.message || error;
      logAmplitudeEvent('View: Error Message', {
        Screen: 'Sign In',
        Message: message,
      });
      logAmplitudeEvent('Signin: Failure', {
        Source: 'Signin Screen',
        Message: message,
      });
      if (error.response.status === 401) {
        if (error.response?.data?.Code === 'UpdatePassword') {
          setRegisterUser({
            email: values.email,
          });
          history.push({
            pathname: REGISTER_CHECK_YOUR_EMAIL,
            search: '?from=forgot-password&type=unregistered',
          })
        }
        actions.setErrors({
          non_field_errors: 'Email or password is incorrect. Please try again',
        });
      } else {
        actions.setErrors({
          non_field_errors: error?.response?.data?.Message || error?.response?.message || error,
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const enabled = await getFeatureFlag('replace_b2c');
      setNewLoginEnabled(enabled);
    })();
  }, []);

  useEffect(() => {
    if (newLoginEnabled === false) {
      history.push(DISCOVER);
    }
  }, [newLoginEnabled]);

  if (authState?.user) {
    return <Redirect to={DISCOVER} />
  }

  if (newLoginEnabled === undefined) {
    return <div className={`${submitStyles.spinner} ${loadingStyles.spinner}`} />;
  }

  return (
    <div className="login-page-form-wrapper">
      <h2 className="title">
        <span>Sign In</span>
      </h2>
      <Formik
        initialValues={{
          email: latestLoggedEmail || '',
          password: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Invalid email address').required().label('Email'),
          password: Yup.string().required().label('Password'),
        })}
        onSubmit={async (values, actions) => {
          await handleLogin(values, actions);
        }}
      >
        {(props) => (
          <Form className="login-form">
            <fieldset>
              <div className="copy">Sign in with your email address</div>
              <CustomTextInput type="text" name="email" label="email" id={nextId('id-')} placeholder="Email Address" />
              <CustomTextInput label="password" name="password" type="password" id={nextId('id-')} placeholder="Password" />
              {props.errors.non_field_errors ? (
                <div className="error-info">
                  <span data-cy="password-error" aria-label={props.errors.non_field_errors}>
                    {capitalize(props.errors.non_field_errors)}
                  </span>
                </div>
              ) : null}
              <a
                className="forget-password"
                href="/forgot-password"
                rel="noopener noreferrer"
                onClick={() => {
                  logAmplitudeEvent('Click: Forgot Password');
                }}
              >
                Forgot your password?
              </a>
              <button data-cy="login-submit" type="submit" className="btn login-btn btn-default">
                <div className={`${props.isSubmitting ? [submitStyles.spinner, loadingStyles.spinner].join(' ') : loadingStyles.hidden}`}></div>
                <span className={props.isSubmitting ? submitStyles.invisible : submitStyles.visible}>
                  <span>Sign In</span>
                </span>
              </button>
            </fieldset>
          </Form>
        )}
      </Formik>
      <p className="sign-up-link">
        Don't have an account? <Link to={REGISTER}>Sign Up</Link>
      </p>
    </div>
  );
}
