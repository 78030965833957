import React from 'react';
import './index.scss';

export default function ContentLoading({ ...props }) {
  return (
    <div {...props} className="content-loading">
      <div className="spinner"></div>
    </div>
  );
}
