import React, { useEffect } from 'react';
import pageStyles from '../styles/register/Page.module.css';
import optionStyles from '../styles/register/RegisterOptions.module.css';
import titledFormStyles from '../styles/TitledForm.module.css';
import loadingStyles from '../styles/Loading.module.css';
import submitStyles from '../styles/Submit.module.css';
import registerBg from '../Assets/register-bg.png';
import pfMoveLogo from '../Assets/pf-move-logo.png';
import { NOMINATE_EMPLOYER, REGISTER_NEW } from '../navigation/CONSTANTS';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { logAmplitudeEvent, logBrazeEvent } from '../api/integration';
import { RegistrationType } from '../RegisterNew/CONSTANT';
import { useRegisterFlowType } from '../RegisterNew/useRegisterFlowType';

export const RegisterOptions = () => {
  const {
    login,
    register_sponsored,
    register_consumer,
    register_pmid,
    setRegisterUser,
  } = useAuth();

  const history = useHistory();
  const { shouldGoToNewAuthFlow } = useRegisterFlowType();
  const destination = shouldGoToNewAuthFlow ? 'New Login' : 'B2C Login';

  useEffect(() => {
    logAmplitudeEvent('Screen View: Signup 1 - Choose Plan');
  }, []);

  if (shouldGoToNewAuthFlow === undefined) {
    return (
      <div className={`${submitStyles.spinner} ${loadingStyles.spinner}`} />
    );
  }

  return (
    <>
      <div className={optionStyles.container}>
        <div
          className={`${optionStyles.optionsContainer} ${titledFormStyles.container}`}
        >
          <h2 className={optionStyles.title}>
            <span>Choose Your Plan</span>
          </h2>

          <button
            className={optionStyles.option}
            onClick={() => {
              logAmplitudeEvent('Click: Sign up - Choose Plan', {
                Plan: 'Core',
                Destination: destination,
              });

              if (shouldGoToNewAuthFlow) {
                logAmplitudeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'New',
                });
                logBrazeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'New',
                });
                setRegisterUser({
                  registrationType: RegistrationType.Core,
                });
                history.push({
                  pathname: REGISTER_NEW,
                });
              } else {
                logAmplitudeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'B2C',
                });
                logBrazeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'B2C',
                });
                register_sponsored();
              }
            }}
          >
            <h3 className={optionStyles.subtitle}>
              <span>I have an employer sponsor.</span>
            </h3>
            <p>
              <span>
                Create your Peerfit account through an existing employer
                sponsored program.
              </span>
            </p>
            <svg
              aria-hidden="true"
              focusable="false"
              className={optionStyles.arrow}
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M7.25476326,19.2868077 L14.5733516,11.932163 L7.2512152,4.48861023 C6.91232773,4.1441033 6.9168832,3.59010267 7.26139014,3.2512152 C7.60589707,2.91232773 8.1598977,2.9168832 8.49878517,3.26139014 L16.4280131,11.3221012 C16.7643441,11.6640093 16.7627617,12.2129441 16.424465,12.5529074 L8.49523712,20.5212001 C8.15436907,20.8637475 7.60035138,20.8651089 7.25780398,20.5242408 C6.91525659,20.1833728 6.91389521,19.6293551 7.25476326,19.2868077 Z"></path>
            </svg>
          </button>
          <button
            className={optionStyles.option}
            onClick={() => {
              logAmplitudeEvent('Click: Sign up - Choose Plan', {
                Plan: 'Unsponsored',
                Destination: destination,
              });

              if (shouldGoToNewAuthFlow) {
                logAmplitudeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'New',
                });
                logBrazeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'New',
                });
                setRegisterUser({
                  registrationType: RegistrationType.Consumer,
                });
                history.push({
                  pathname: REGISTER_NEW,
                });
              } else {
                logAmplitudeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'B2C',
                });
                logBrazeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'B2C',
                });
                register_consumer();
              }
            }}
          >
            <h3 className={optionStyles.subtitle}>
              <span>I don't have an employer sponsor.</span>
            </h3>
            <p>
              <span>
                Sign up and enjoy an individual Peerfit account without an
                employer sponsor.
              </span>
            </p>
            <svg
              aria-hidden="true"
              focusable="false"
              className={optionStyles.arrow}
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M7.25476326,19.2868077 L14.5733516,11.932163 L7.2512152,4.48861023 C6.91232773,4.1441033 6.9168832,3.59010267 7.26139014,3.2512152 C7.60589707,2.91232773 8.1598977,2.9168832 8.49878517,3.26139014 L16.4280131,11.3221012 C16.7643441,11.6640093 16.7627617,12.2129441 16.424465,12.5529074 L8.49523712,20.5212001 C8.15436907,20.8637475 7.60035138,20.8651089 7.25780398,20.5242408 C6.91525659,20.1833728 6.91389521,19.6293551 7.25476326,19.2868077 Z"></path>
            </svg>
          </button>
          <a
            rel="noopener noreferrer"
            className={optionStyles.nominateEmployer}
            href="https://wellness.peerfit.com/nominations"
            onClick={() => {
              logAmplitudeEvent('Click: Sign up - Choose Plan', {
                Plan: 'Nomination',
                Destination: destination,
              });
              logAmplitudeEvent('Nominate: Employer');
            }}
            target="_blank"
            aria-label="I want my company to pay for my fitness."
          >
            <span>I want my company to pay for my fitness.</span>
          </a>
          <button
            rel="noopener noreferrer"
            className={optionStyles.option}
            onClick={() => {
              logAmplitudeEvent('Click: Sign up - Choose Plan', {
                Plan: 'Move',
                Destination: destination,
              });

              if (shouldGoToNewAuthFlow) {
                logAmplitudeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'New',
                });
                logBrazeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'New',
                });
                setRegisterUser({
                  registrationType: RegistrationType.Move,
                });
                history.push({
                  pathname: REGISTER_NEW,
                });
              } else {
                logAmplitudeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'B2C',
                });
                logBrazeEvent('Signup: Start', {
                  Source: 'Website',
                  'Login Type': 'B2C',
                });
                register_pmid();
              }
            }}
          >
            <img
              className={optionStyles.moveLogo}
              src={pfMoveLogo}
              alt="Peerfit Move"
            />
            <h3
              className={`${optionStyles.moveSubtitle} ${optionStyles.subtitle}`}
            >
              <span>
                I have a Medicare Advantage or Medicare Supplement plan.
              </span>
            </h3>
            <svg
              aria-hidden="true"
              focusable="false"
              className={optionStyles.arrow}
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M7.25476326,19.2868077 L14.5733516,11.932163 L7.2512152,4.48861023 C6.91232773,4.1441033 6.9168832,3.59010267 7.26139014,3.2512152 C7.60589707,2.91232773 8.1598977,2.9168832 8.49878517,3.26139014 L16.4280131,11.3221012 C16.7643441,11.6640093 16.7627617,12.2129441 16.424465,12.5529074 L8.49523712,20.5212001 C8.15436907,20.8637475 7.60035138,20.8651089 7.25780398,20.5242408 C6.91525659,20.1833728 6.91389521,19.6293551 7.25476326,19.2868077 Z"></path>
            </svg>
          </button>
        </div>
        <div className={optionStyles.loginLinkContainer}>
          <span>Already Have a Peerfit Account?</span>
          <Link className={optionStyles.loginLink} to="#" onClick={login}>
            <span> Log In</span>
          </Link>
        </div>
      </div>
    </>
  );
};
