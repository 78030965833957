declare global {
  interface Window {
    ReactNativeWebView?: { postMessage: (message: string) => void };
    __PF_APP_VERSION__: string;
  }
}

export const isReactNative = !!window?.ReactNativeWebView?.postMessage;

type ReactNativeMessageTransferObject = {
  key: string;
  value?: any;
  messageId?: number;
  error?: string;
};

type PromiseOutlet = Promise<any> & {
  resolve: (res: any) => void;
  reject: (res: any) => void;
};

const CALL_RN_FUNCTION = 'CALL_RN_FUNCTION';

let promiseQueue: { messageId: number; promise: PromiseOutlet }[] = [];

window.addEventListener('message', (message) => {
  // check message origin for sonar scan secure reason
  if (message.origin && message?.origin !== window.location.origin) {
    return;
  }
  if (typeof message.data !== 'string') return;
  try {
    const data: ReactNativeMessageTransferObject = JSON.parse(message.data);
    if (data.key === CALL_RN_FUNCTION) {
      const item = promiseQueue.find((promise) => promise.messageId === data.messageId);
      if (item) {
        if (data?.error) {
          console.log('[callRNFunction] ${name} error: `', data.error);
          item.promise.reject(data.error);
        } else {
          console.log('[callRNFunction] ${name} res: `', data.value);
          item.promise.resolve(data.value);
        }
        promiseQueue = promiseQueue.filter((i) => i !== item);
      }
    }
  } catch (error) {}
});

export const callRNFunction = (name: string, ...args) => {
  console.log(`[callRNFunction] ${name}`, name, args);
  if (isReactNative) {
    const messageId = +new Date();
    let res, rej;
    const promise: PromiseOutlet = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    }) as PromiseOutlet;
    promise.resolve = res;
    promise.reject = rej;
    postMessageToRN({
      key: CALL_RN_FUNCTION,
      messageId,
      value: { name, args },
    });
    promiseQueue.push({ messageId, promise });
    return promise;
  }
  return Promise.resolve(true);
};

export const postMessageToRN = (object: ReactNativeMessageTransferObject) => {
  if (object.key && isReactNative) {
    window.ReactNativeWebView.postMessage(JSON.stringify(object));
    console.log('[postMessageToRN]', object);
  }
};
