import appboy from '@braze/web-sdk';
import amplitude from 'amplitude-js';

// given an object, purge empty properties
// https://dev.to/alwarg/purging-unwanted-properties-in-js-object-45ol
export const getPurgedObj = (obj) => {
  let stringfiedObj = JSON.stringify(obj, (key, value) => {
    return ['', null].includes(value) || (typeof value === 'object' && (value.length === 0 || Object.keys(value).length === 0)) ? undefined : value;
  });
  let resObj = JSON.parse(stringfiedObj);
  let isEmptyPropsPresent = ['{}', '[]', '""', 'null'].some((key) => stringfiedObj.includes(key));
  if (isEmptyPropsPresent) {
    return getPurgedObj(resObj);
  }
  return resObj;
};

export const logUserProfileUpdateEvent = (key, value) => {
  return;
  // const abUser = appboy.getUser();
  // const identify = new amplitude.Identify();

  // abUser.setCustomUserAttribute(key, value);
  // identify.set(key, value);

  // appboy.requestImmediateDataFlush();
  // amplitude.getInstance().identify(identify);
};
