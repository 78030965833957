import React, { useState } from 'react';
import paymentApi from '../../api/payment';
import { useAuth } from '../../contexts/AuthContext';
import { AddCardModal } from '../../shared/Modal/AddCardModal';
import useModal from '../../shared/Modal/useModal';
import creditCardStyles from '../../styles/account/payment/CreditCard.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import spinnerGroupStyles from '../../styles/spinner/Group.module.css';





export const StripeCard = ({ stripeCard }) => {
  const { isShowing, toggle, ref } = useModal();

  const { authState, verify } = useAuth();
  const { user } = authState;
  const { customer } = user;

  const [isLoading, setIsLoading] = useState(false);
  const handleUpdate = async () => toggle(); // setOpen(true);

  const handleRemove = async () => {
    setIsLoading(true);
    await paymentApi
      .DeletePaymentMethod(user.id, stripeCard.id)
      .then((res) => {
        verify();
      })
      .catch((error) => {
        console.log('Error:', error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  return (
    <>
      <div className={creditCardStyles.card}>
        <div
          style={{ background: '#adadad', height: '25px', marginTop: '20px' }}
        ></div>
        {isLoading ? (
          <>
            <div
              className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`}
            ></div>
            {/* <div className={`${isLoading ? `${submitStyles.spinner} ${loadingStyles.spinner}` : loadingStyles.hidden}`}></div> */}
          </>
        ) : (
          <>
            <div className={creditCardStyles.cardInfo}>
              <div className={creditCardStyles.cardDetail}>
                <div>XXXX-XXXX-XXXX-{stripeCard.last4}</div>
                <div>
                  {stripeCard.expmonth} / {stripeCard.expyear}
                </div>
                <div>{stripeCard.brand}</div>
              </div>
              <a
                onClick={handleUpdate}
                style={{
                  color: '#e61761',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  marginRight: '15px',
                }}
              >
                Update
              </a>
              <a
                onClick={handleRemove}
                style={{
                  color: '#e61761',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                Remove
              </a>
            </div>
          </>
        )}
      </div>
      <AddCardModal
        ref={ref}
        isShowing={isShowing}
        hide={toggle}
        onSuccess={verify}
      />
    </>
  );
};
