import { useEffect, useRef, useState } from 'react';

// https://upmostly.com/tutorials/modal-components-react-custom-hooks
const useModal = () => {
  const ref = useRef(null);
  const [isShowing, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShowing);
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsShowing(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key == 'Escape') setIsShowing(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleKeyPress, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeyPress, true);
    };
  }, [ref]);

  return {
    isShowing,
    toggle,
    ref,
  };
};

export default useModal;
