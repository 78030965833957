import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import controllerStyles from '../../styles/Controller.module.css';
import overlayDefaultStyles from '../../styles/overlay/Default.module.css';
import svgStyles from '../../styles/SvgIcon.module.css';
import studioBannerStyles from '../../styles/StudioBanner.module.css';
import reservationHeaderStyles from '../../styles/ReservationHeader.module.css';

import studioBannerImg from '../../Assets/studioBanner.jpg';

import btnDefaultStyles from '../../styles/button/Default.module.css';
import loginStyles from '../../styles/Login.module.css';
import fieldStyles from '../../styles/input/Field.module.css';
import styles from '../../styles/styles.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import submitStyles from '../../styles/Submit.module.css';
import checkboxStyles from '../../styles/Checkbox.module.css';
import errorFormStyles from '../../styles/error/Form.module.css';

import studioApi from '../../api/studio';
import { Link, useHistory } from 'react-router-dom';
import { DISCOVER, PAYMENT, REGISTER } from '../../navigation/CONSTANTS';
import { useAuth } from '../../contexts/AuthContext';
import { Form, Formik } from 'formik';
import {
  getPurchaseLogInfo,
  logAmplitudeEvent,
  logBrazeEvent,
} from '../../api/integration';
import { usePrevious } from '../../utilities/usePrevious';

const commonStyle = {
  width: '1px',
  height: '0px',
  padding: '0px',
  overflow: 'hidden',
  position: 'fixed',
  top: '1px',
  left: '1px',
};

// eslint-disable-next-line react/display-name
export const MembershipModal = React.forwardRef(
  ({ isShowing, hide, membership, studio }, ref) => {
    const { authState, verify, login, card } = useAuth();
    const history = useHistory();
    const purchaseLogInfo = getPurchaseLogInfo({
      membership,
      studio,
    });
    const isShowingBefore = usePrevious(isShowing);

    useEffect(() => {
      if (membership?.id && studio?.id) {
        if (isShowing) {
          logBrazeEvent('Click: Purchase', purchaseLogInfo);
          logAmplitudeEvent('Click: Purchase', purchaseLogInfo);
          logAmplitudeEvent('Screen View: Purchase', purchaseLogInfo);
          logAmplitudeEvent(
            'Screen View: Purchase Confirmation',
            purchaseLogInfo
          );
        }
        if (!isShowing && isShowingBefore) {
          logBrazeEvent('Click: Abandon Purchase', purchaseLogInfo);
          logAmplitudeEvent('Click: Abandon Purchase', purchaseLogInfo);
        }
      }
    }, [isShowing, membership?.id, studio?.id]);

    const handleSubmit = async (values, actions) => {
      logAmplitudeEvent('Click: Purchase Confirmation', purchaseLogInfo);
      logBrazeEvent('Click: Purchase Confirmation', purchaseLogInfo);

      await studioApi
        .createMembership(authState.user?.id, membership.id)
        .then((res) => {
          verify();
          history.push(`/user/${authState.user?.id}/memberships`);
        })
        .catch((error) => {
          console.log('ERROR ON CREATE MEMBERSHIP.', error);
          if (error.status == 400) {
            actions.setErrors({ summary: error.data.Message });
          } else {
            actions.setErrors({
              summary:
                'Unable to purchase membership at the moment. Please try again later.',
            });
          }
        });
    };

    return isShowing
      ? ReactDOM.createPortal(
          <React.Fragment>
            <div className={controllerStyles.overlay}>
              <div
                ref={ref}
                role="dialog"
                tabIndex="0"
                data-cy="Login"
                className={overlayDefaultStyles.dialog}
                aria-labelledby="reservation-header-title"
              >
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={commonStyle}
                ></div>
                <div
                  data-focus-guard="true"
                  tabIndex="1"
                  style={commonStyle}
                ></div>
                <div data-focus-lock-disabled="false">
                  <button
                    className={`${overlayDefaultStyles.close} focus_outline`}
                    aria-label="Close"
                    onClick={hide}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className={`${overlayDefaultStyles.closeIcon} ${svgStyles.icon}`}
                      viewBox="0 0 17 17"
                    >
                      <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z"></path>
                    </svg>
                  </button>
                  <div
                    className={reservationHeaderStyles.header}
                    style={{ backgroundImage: `url(${studioBannerImg})` }}
                  >
                    <div
                      data-cy="reservation-modal"
                      className={reservationHeaderStyles.content}
                    >
                      <div className={reservationHeaderStyles.textWrapper}>
                        <h3 id="reservation-header-title">
                          {authState.isAuthenticated ? (
                            <span>Membership</span>
                          ) : (
                            <span>Sign In to purchase membership</span>
                          )}
                        </h3>
                        <h4>
                          {membership?.description_title} at {studio?.name}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className={overlayDefaultStyles.content}>
                    <Formik
                      initialValues={{
                        note: '',
                      }}
                      onSubmit={async (values, actions) => {
                        await handleSubmit(values, actions);
                      }}
                    >
                      {(formikProps) => (
                        <Form className={loginStyles.form}>
                          <fieldset>
                            {authState.isAuthenticated ? (
                              <>
                                {formikProps.errors.summary && (
                                  <div
                                    className={errorFormStyles.error}
                                    tabIndex="-1"
                                  >
                                    <span
                                      aria-label={formikProps.errors.summary}
                                    >
                                      {formikProps.errors.summary}
                                    </span>
                                  </div>
                                )}
                                <div className={fieldStyles.field}>
                                  <div>
                                    <span style={{ float: 'right' }}>
                                      {authState.user.credits}
                                    </span>
                                    <span>Available credits</span>
                                  </div>
                                  <div>
                                    <span style={{ float: 'right' }}>
                                      {membership?.prorated_credits}
                                    </span>
                                    <span>
                                      {membership.prorated_credits <
                                      membership.credits
                                        ? '*Prorated '
                                        : ''}
                                      Credit cost
                                    </span>
                                  </div>

                                  {membership?.prorated_credits -
                                    authState.user.credits >
                                  0 ? (
                                    <div>
                                      <strong style={{ float: 'right' }}>
                                        $
                                        {(
                                          (membership?.prorated_credits -
                                            authState.user.credits) *
                                          3.5
                                        ).toFixed(2)}
                                      </strong>
                                      <strong>
                                        {membership?.prorated_credits -
                                          authState.user.credits}{' '}
                                        credits needed (1cr x $3.50)
                                      </strong>
                                    </div>
                                  ) : (
                                    <div>
                                      <strong style={{ float: 'right' }}>
                                        {authState.user.credits -
                                          membership?.prorated_credits}
                                      </strong>
                                      <strong>Credits Remaining</strong>
                                    </div>
                                  )}
                                </div>
                                {/*not enough credits and no card on file.*/}
                                {authState.user.credits <
                                  membership.prorated_credits && !card ? (
                                  <>
                                    <div className={loginStyles.copy}>
                                      <span>Not enough credits.</span>
                                      <Link
                                        className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`}
                                        to={PAYMENT}
                                      >
                                        <span>Add a Card</span>
                                      </Link>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    {/*has card on file*/}
                                    {authState.user.credits <
                                      membership.prorated_credits && card ? (
                                      <div className={fieldStyles.field}>
                                        <span>
                                          * The remainder will be charged to
                                          your card on file.
                                        </span>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <button
                                      type="submit"
                                      className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                      disabled={formikProps.isSubmitting}
                                    >
                                      <div
                                        className={
                                          formikProps.isSubmitting
                                            ? `${submitStyles.spinner} ${loadingStyles.spinner}`
                                            : loadingStyles.hidden
                                        }
                                      ></div>
                                      <span
                                        className={
                                          formikProps.isSubmitting
                                            ? submitStyles.invisible
                                            : submitStyles.visible
                                        }
                                      >
                                        <span>Purchase</span>
                                      </span>
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <div className={loginStyles.copy}>
                                  <span>Don't have a Peerfit account? </span>
                                  <Link
                                    className={`${loginStyles.signupLink} ${loginStyles.link} ${loginStyles.copy} focus_outline`}
                                    to={REGISTER}
                                  >
                                    <span>Sign Up</span>
                                  </Link>
                                </div>
                                <div className={loginStyles.copy}>
                                  <span>Have an account? </span>
                                  <button
                                    onClick={login}
                                    className={`${loginStyles.submit} ${btnDefaultStyles.button} focus_outline`}
                                  >
                                    <span>Sign In</span>
                                  </button>
                                </div>
                              </>
                            )}
                            {/* <FormikDebug /> */}
                          </fieldset>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={commonStyle}
                ></div>
              </div>
            </div>
          </React.Fragment>,
          document.body
        )
      : null;
  }
);
