import React, { useEffect } from 'react';
import discoverMoveBannerText from '../Assets/discover-move-banner-text@2x.png';
import FeaturedVirtualClasses from '../components/Discover/FeaturedVirtualClasses';
import GetDigitalSubscription from '../components/Discover/GetDigitalSubscription';
import WorkoutPreferences from '../components/Discover/WorkoutPreferences';
import { useSite } from '../contexts/SiteContext';
import { WorkoutStyles } from '../shared/WorkoutStyles';
import moveBannerStyles from '../styles/MoveBanner.module.css';
import pageStyles from '../styles/discover/Page.module.css';
import { logAmplitudeEvent } from '../api/integration';

function Discover() {
  const { isCoreSite, isMoveSite, isMobileApp } = useSite();
  useEffect(() => {
    window.scrollTo(0, 0);
    logAmplitudeEvent('Screen View: Discover');
  }, []);

  return (
    <div className={pageStyles.container} style={{ height: 'auto', position: 'relative', minHeight: '216px' }}>
      {isMoveSite ? (
        <div className={moveBannerStyles.container}>
          <h5 className={moveBannerStyles.copy}>
            <span>To get your Peerfit Member ID (PMID), please click the ‘Memberships’ link in the upper right corner of your screen</span>
          </h5>
        </div>
      ) : (
        ''
      )}

      {isCoreSite && <GetDigitalSubscription />}
      {isCoreSite && <WorkoutStyles title={'Workout  Styles'} />}
      {isCoreSite && <WorkoutPreferences />}

      {isMoveSite && (
        <div className={pageStyles.moveDiscoverBanner}>
          <img className={pageStyles.moveDiscoverBannerTextImage} src={discoverMoveBannerText} />
        </div>
      )}
      {isMoveSite && (
        <div className={pageStyles.discoverMoveGreyBackground}>
          <FeaturedVirtualClasses />
        </div>
      )}
      {isMoveSite && <GetDigitalSubscription style={{ marginTop: isMobileApp ? -40 : 0 }} />}
      {isMoveSite && <WorkoutStyles title={'Workout  Styles'} />}
    </div>
  );
}

export { Discover };
