import React from 'react';
import loadingStyles from '../styles/Loading.module.css';

export default function Loading({ className = '', ...props }) {
  return <div className={`${loadingStyles.spinner} ${className}`}></div>;
}

export function LoadingPlaceholder({ className = '', ...props }) {
  return (
    <div className={loadingStyles.placeholderContainer} {...props}>
      <div className={`${loadingStyles.spinner}`}></div>
    </div>
  );
}
