import React from 'react';
import customClassStyles from '../../../styles/CustomClass.module.css';
import selectStyles from '../../../styles/Select.module.css';
import fieldStyles from '../../../styles/input/Field.module.css';
import styles from '../../../styles/styles.module.css';
import { useField } from 'formik';

export default function Select({ label, options, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div
      className={`${customClassStyles.select} ${selectStyles.field} ${
        fieldStyles.field
      } ${customClassStyles.sharedFieldStyles} ${
        meta.touched && meta.error ? selectStyles.fieldError : ''
      }`}
    >
      <label
        className={`${selectStyles.label} ${fieldStyles.label} ${styles.container}`}
        htmlFor="id-7-1"
      >
        {label}
      </label>
      <select
        {...field}
        aria-label={label}
        id="id-7-1"
        className={`${
          meta.touched && meta.error ? selectStyles.errorSelect : ''
        } ${selectStyles.select}`}
        tabIndex="0"
      >
        {options.map((opt, index) => (
          <option key={index} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
      <svg
        aria-hidden="true"
        focusable="false"
        className={selectStyles.caretDelegateClick}
        viewBox="0 0 24 24"
      >
        <path d="M19.2868077,7.25476326 C19.6293551,6.91389521 20.1833728,6.91525659 20.5242408,7.25780398 C20.8651089,7.60035138 20.8637475,8.15436907 20.5212001,8.49523712 L12.5529074,16.424465 C12.2129441,16.7627617 11.6640093,16.7643441 11.3221012,16.4280131 L3.26139014,8.49878517 C2.9168832,8.1598977 2.91232773,7.60589707 3.2512152,7.26139014 C3.59010267,6.9168832 4.1441033,6.91232773 4.48861023,7.2512152 L11.932163,14.5733516 L19.2868077,7.25476326 Z"></path>
      </svg>
      {meta.touched && meta.error ? (
        <>
          <span className={fieldStyles.errorLabel}>{meta.error}</span>
          <div className={fieldStyles.errorIcon}>!</div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
