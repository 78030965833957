import React from 'react';
import { RegisterEmailPasswordForm } from '../components/Authentication/RegisterEmailPasswordForm';

export const RegisterEmailPassword = () => {
  return (
    <>

      <RegisterEmailPasswordForm />
    </>
  );
};
