import React, { useEffect } from 'react';
import styles from '../../styles/account/styles.module.css';
import Credit from './Credit';
import Subscription from './Subscription';
import { useAuth } from '../../contexts/AuthContext';
import { logAmplitudeEvent } from '../../api/integration';

function Credits() {
  const { authState } = useAuth();
  useEffect(() => {
    logAmplitudeEvent('Screen View: Settings - Credits');
  }, []);

  // if(authState.isLoading) return <></>;

  return (
    <div className={styles.container}>
      <Credit />
      {/* <Subscription /> */}
    </div>
  );
}

export { Credits };
