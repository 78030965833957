import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { HOME } from '../navigation/CONSTANTS';

export default function ProtectedRoute(props) {
  const { component: Component, ...rest } = props;
  const { authState } = useAuth();

  if (authState.isLoading) {
    // console.log("AUTHSTATE IS LOADING. (ProtectedRoute)");
    return <></>;
  }

  // if (!authState.isAuthenticated) return <NotAuthenticated />;
  // console.log("ATTEMPTING PROTECTED ROUTE.");

  return (
    <Route
      {...rest}
      render={(props) => {
        return authState.isAuthenticated ? (
          <Component {...rest} />
        ) : (
          <Redirect push to={HOME} />
        );
        // : (rest.path === LOGIN ? <Component {...props} /> : <Redirect push to={LOGIN} />)
      }}
    />
  );
}
