import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { format, isValid, parse } from 'date-fns';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  useField,
  useFormikContext,
} from 'formik';
import { Checkbox } from 'formik-material-ui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { getFeatureFlag } from '../../api/config';
import userApi from '../../api/user';
import { useAuth } from '../../contexts/AuthContext';
import { useSite } from '../../contexts/SiteContext';
import SubmitButton from '../../shared/Formik/SubmitButton';
import UpdateResults from './UpdateResults';
import { useAsync } from 'react-use';
import {
  logAmplitudeEvent,
  logCustomUserAttribute,
} from '../../api/integration';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import cardStyles from '../../styles/Card.module.css';
import errorFormStyles from '../../styles/error/Form.module.css';
import css from './register.module.css';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required()
    .matches(/^[a-z ,.'-]+$/i, 'Invalid Name Format')
    .label('First Name'),
  last_name: Yup.string()
    .required()
    .matches(/^[a-z ,.'-]+$/i, 'Invalid Name Format')
    .label('Last Name'),
  birth_date: Yup.date()
    .required()
    .typeError('Date of birth is a required field.')
    .min(new Date(1000, 1, 1), 'Invalid Date.')
    .max(
      moment().endOf('day').subtract(18, 'years'),
      'Must be at least 18 years of age.'
    )
    .label('Birth Date'),
  zipcode: Yup.string()
    .required()
    .matches(/^\d{5}$/, 'Enter a 5-digit zip code.')
    .label('Zip'),
  tcAgree: Yup.boolean()
    .required('')
    .oneOf([true], 'You must accept terms and conditions')
    .default(false),
});

export const PHITextField = (props) => {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;
  return (
    <TextField
      {...props}
      {...field}
      className={`${css.formItem} ${error && css.formError}`}
      error={!!error}
      helperText={error}
      data-testid="phi-last-name"
      variant="filled"
      InputProps={{ disableUnderline: true }}
    />
  );
};

export const PHIDatePicker = (props) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        {...field}
        className={`${css.formItem} ${error && css.formError}`}
        autoComplete="off"
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onChange={(_, str) => {
          setFieldValue(field.name, str);
        }}
        onBlur={field.onBlur}
        error={!!error}
        helperText={error}
        inputVariant="filled"
        InputProps={{ disableUnderline: true }}
        keyboardIcon={null}
      />
    </MuiPickersUtilsProvider>
  );
};

const PHIMigration = () => {
  const { isCoreSite } = useSite();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [autoFill, setAutoFill] = useState('');
  const {
    authState: { user },
    verify,
  } = useAuth();
  const { value: featureFlag } = useAsync(
    () => getFeatureFlag('phi_pii_migration'),
    []
  );

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (values, { setErrors }) => {
    const parsedDate = parse(values.birth_date, 'MM/dd/yyyy', new Date());
    if (!isValid(parsedDate)) {
      setErrors({
        summary: 'Invalid Date.',
      });
      return;
    }

    await userApi
      .updatePhi({ ...values, userId: user?.id })
      .then((res) => {
        closeModal();
        window.scrollTo(0, 0);
        setConfirmation(true);
        logCustomUserAttribute(
          'PHI_PII_Update',
          format(new Date(), 'MM/dd/yyyy')
        );
        // call verify() to reload userprofile.
        verify();
      })
      .catch((error) => {
        console.log('errorList:', error);
        if (error.status == 400) {
          setErrors({ summary: error.data.Errors[0].message });
        } else {
          setErrors({
            summary:
              "Can't update profile at this time. Please try again later.",
          });
        }
      });
  };

  useEffect(() => {
    const cutoff = '2022-02-15T00:00:00.00';
    if (user?.registered_on < cutoff && !user?.phiUpdatedDate && featureFlag) {
      openModal();
      logAmplitudeEvent('Screen View: PHI PII Consent', {
        Source: 'App Entry',
      });
    }
  }, [featureFlag, user]);

  if (!featureFlag) {
    return null;
  }

  return (
    <>
      {showModal || showConfirmation ? (
        <div data-testid="backdrop" className={css.backdrop}></div>
      ) : null}
      {showModal ? (
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            address: '',
            city: '',
            state: '',
            phone: '',
            birth_date: null,
            zipcode: '',
            tcAgree: true,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await handleSubmit(values, actions);
          }}
        >
          {(formik) => {
            const phiForm = (
              <div className={css.phiFormContent}>
                <div
                  id={css['top-name-container']}
                  className={css.nameContainer}
                >
                  <PHITextField
                    name="first_name"
                    label="First Name"
                    className={css.formItem}
                    data-testid="phi-first-name"
                  />
                  <PHITextField
                    name="last_name"
                    label="Last Name"
                    className={css.formItem}
                    data-testid="phi-last-name"
                  />
                </div>
                <div className={css.nameContainer}>
                  <PHITextField
                    name="zipcode"
                    type="text"
                    label="Zip"
                    className={css.formItem}
                    data-testid="phi-zip"
                  />
                  <PHIDatePicker
                    name="birth_date"
                    label="DOB (MM/DD/YYYY)"
                    data-testid="phi-core-dob"
                    format="MM/dd/yyyy"
                  />
                </div>
              </div>
            );
            return (
              <Form data-testid="phi-modal" className={css.phiForm}>
                <Card className={css.register}>
                  <div
                    className={isCoreSite ? css.coreLogo : css.moveLogo}
                  ></div>
                  <h3 data-testid="phi-card-title" className={css.title}>
                    We recently updated our Terms of Service and Privacy Policy.
                    Please update your information to continue using Peerfit.
                  </h3>
                  <CardContent
                    classes={{
                      root: css.content,
                    }}
                  >
                    <div className={cardStyles.section}>
                      <div className={cardStyles.sectionContent}>
                        {formik.errors.summary && (
                          <div className={errorFormStyles.error} tabIndex="-1">
                            <span aria-label={formik.errors.summary}>
                              {formik.errors.summary}
                            </span>
                          </div>
                        )}
                        <FormControl style={{ marginBottom: 10 }}>
                          <RadioGroup
                            name="radio-buttons-group"
                            className={css.radioGroup}
                            value={autoFill}
                            onChange={(event) => {
                              const autoFill = event.target.value;
                              setAutoFill(autoFill);
                              if (autoFill === 'auto') {
                                const parsedDate = parse(
                                  user.birth_date,
                                  'yyyy-dd-MM',
                                  new Date()
                                );
                                formik.setValues({
                                  ...formik.values,
                                  first_name: user.first_name || '',
                                  last_name: user.last_name || '',
                                  phone: user.phone || '',
                                  birth_date: isValid(parsedDate)
                                    ? format(parsedDate, 'MM/dd/yyyy')
                                    : null,
                                  mailing_address:
                                    user?.email_addresses?.email || '',
                                  city: user.city || '',
                                  state: user.state || '',
                                  zipcode: user.zipcode || '',
                                });

                                logAmplitudeEvent('Tap: PHI PII Consent', {
                                  Button: 'Keep Info',
                                  Source: 'App Entry',
                                });
                              } else if (autoFill === 'manual') {
                                const tcAgree = formik.values.tcAgree;
                                formik.resetForm();
                                formik.setFieldValue('tcAgree', tcAgree);

                                logAmplitudeEvent('Tap: PHI PII Consent', {
                                  Button: 'Re-enter Info',
                                  Source: 'App Entry',
                                });
                              }
                            }}
                          >
                            <FormControlLabel
                              value="auto"
                              control={<Radio />}
                              label="I want to keep using the same information I used when creating my account"
                            />
                            {autoFill === 'auto' && phiForm}
                            <FormControlLabel
                              value="manual"
                              control={<Radio />}
                              label="I want to manually re-enter my information"
                            />
                            {autoFill === 'manual' && phiForm}
                          </RadioGroup>
                        </FormControl>
                        <SubmitButton
                          disabled={
                            !formik.values.tcAgree ||
                            !autoFill ||
                            (autoFill === 'manual' &&
                              Object.keys(formik.touched).length === 0) ||
                            Object.keys(formik.errors).length
                          }
                          label="Submit"
                        />
                        <div className={css.tc_container}>
                          <Field
                            name="tcAgree"
                            type="checkbox"
                            component={Checkbox}
                            className={css.tc_input}
                          />
                          <label className={css.tc_label}>
                            I acknowledge that I agree to the&nbsp;
                            <a
                              href="https://peerfit.com/terms-of-service"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Terms of Use
                            </a>
                            &nbsp;and have read the&nbsp;
                            <a
                              href="https://peerfit.com/privacy-policy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Privacy Policy
                            </a>
                          </label>
                        </div>
                        {!formik.values.tcAgree ? (
                          <ErrorMessage
                            name="tcAgree"
                            component={() => {
                              return (
                                <div
                                  className={css.error}
                                  tabIndex="-1"
                                  style={{ marginTop: 10, padding: 10 }}
                                >
                                  You must accept the terms and conditions
                                </div>
                              );
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className={css.footerSeparator}>***</div>
                    <div className={css.anyQuestion}>
                      <div className={css.questionIcon}></div> Any questions?
                      Contact us at&nbsp;
                      {isCoreSite ? (
                        <a href={`mailto:core@peerfit.com`}>core@peerfit.com</a>
                      ) : (
                        <a href={`mailto:move@peerfit.com`}>move@peerfit.com</a>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Form>
            );
          }}
        </Formik>
      ) : null}
      {showConfirmation ? (
        <UpdateResults setConfirmation={setConfirmation} />
      ) : null}
    </>
  );
};

export default PHIMigration;
