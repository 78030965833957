import React, { useContext, useEffect, useState } from 'react';
import { logAmplitudeEvent, logBrazeEvent } from '../api/integration';
import studioApi from '../api/studio';
import FitOnStreamingService from '../components/streaming/FitOnStreamingService';
import HeaderSection from '../components/streaming/HeaderSection';
import { SiteContext } from '../contexts/SiteContext';
import { useGlobal } from '../contexts/GlobalContext';
import pageStyles from '../styles/streaming/Page.module.css';
import { useAuth } from '../contexts/AuthContext';

function Streaming() {
  const { authState } = useAuth();
  const [fitOn, setFitOn] = useState();
  const { isDigitalFitOnSurfacing } = useGlobal();

  const siteContext = useContext(SiteContext);

  if (siteContext.isMoveSite === true) {
    console.log('Checking for MOVE Redirect');
    window.location.replace(process.env.REACT_APP_MOVE_URL + '/discover');
  }

  useEffect(() => {
    if (authState.user) {
      studioApi.getDigitalProviders().then((res) => {
        const fitOn = res.results.find((x) => x.name == 'FITON');
        setFitOn(fitOn);
      });

      logBrazeEvent('Screen View: Streaming');
      logAmplitudeEvent('Screen View: Streaming');
    }
  }, [authState.user?.id]);

  return (
    <div
      className={pageStyles.container}
      style={{ height: 'auto', position: 'relative', minHeight: '140px' }}
    >
      <h2>
        <span>Digital Fitness Memberships</span>
      </h2>
      {isDigitalFitOnSurfacing && (
        <FitOnStreamingService digitalProvider={fitOn} />
      )}
      <HeaderSection />
    </div>
  );
}

export { Streaming };
