import React from 'react';
import { Link } from 'react-router-dom';

import goodCompanyStyles from '../styles/home/GoodCompany.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import utilities from '../styles/utilities.module.css';
import buyLineStyles from '../styles/home/BuyLine.module.css';
import heroStyles from '../styles/Hero.module.css';

import pureBarreLogo from '../Assets/34120b478c3230c26519a3e46baaedc9.png';
import titleBoxingLogo from '../Assets/886d18e646f6e53e80622319bbf719af.png';
import yogaWorksLogo from '../Assets/2f907a2daa6ee4f807e9dfe84acd1cc1.png';
import youFitLogo from '../Assets/00d8d0cb0829d813f49d3b49b9a735a0.png';
import wellnessProgramBg from '../Assets/9f6d3fa341990f5d00ab6963c5b355a4.jpg';

export default function FitnessFacilities() {
  return (
    <>
      <div>
        <div
          className={buyLineStyles.container}
          style={{ backgroundImage: `url(${wellnessProgramBg})` }}
          data-testid="fitness-facilites"
        >
          <div
            className={`${buyLineStyles.content} ${utilities['page-container']}`}
          >
            <div className={buyLineStyles.copy}>
              <h2 className={buyLineStyles.title}>
                <span>Reach more people with Peerfit.</span>
              </h2>
              <p>
                <span>
                  Team up with Peerfit and access the world of workplace
                  wellness through our partnerships with employers, brokers and
                  insurance carriers.
                </span>
              </p>
              <Link
                rel="noopener noreferrer"
                className={`${buyLineStyles.button} ${heroStyles.btn} ${btnDefaultStyles.button} focus_outline`}
                to="https://pulse.peerfit.com/studio/"
                target="_blank"
              >
                <span>Join the network for free</span>
              </Link>
            </div>
          </div>
        </div>
        <div className={goodCompanyStyles.container}>
          <div className={goodCompanyStyles.content}>
            <h2>
              <span>You're in Good Company</span>
            </h2>
            <img src={pureBarreLogo} height="32" alt="Pure Barre Logo" />
            <img src={titleBoxingLogo} height="32" alt="Title Boxing Logo" />
            <img src={yogaWorksLogo} height="32" alt="Yoga Works Logo" />
            <img src={youFitLogo} height="32" alt="YouFit Logo" />
          </div>
        </div>
      </div>
    </>
  );
}
