import React, { useEffect, useState } from 'react';
import heroStyles from '../styles/fitness-partners/Hero.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import accountTypesStyles from '../styles/how-it-works/AccountTypes.module.css';
import mobileStyles from '../styles/how-it-works/Mobile.module.css';
import svgStyles from '../styles/SvgIcon.module.css';
import utilities from '../styles/utilities.module.css';
import quotesStyles from '../styles/how-it-works/Quotes.module.css';
import workoutStyles from '../styles/how-it-works/Workouts.module.css';
import partnersStyles from '../styles/fitness-partners/Partners.module.css';
import referStyles from '../styles/individual-subscription/Refer.module.css';
import loadingStyles from '../styles/Loading.module.css';
import submitStyles from '../styles/Submit.module.css';

import headerStyles from '../styles/Header.module.css';
import logo from '../Assets/peerfit-core.png';
import logoBurst from '../Assets/burst-color.svg';

import useWindowSize, { _MD, _SM } from '../shared/hooks/useWindowSize';
import useModal from '../shared/Modal/useModal';
import { Link } from 'react-router-dom';
import { WorkoutStyles } from '../shared/WorkoutStyles';
import { EXPLORE, REGISTER, REGISTER_NEW } from '../navigation/CONSTANTS';
import { useRegisterFlowType } from '../RegisterNew/useRegisterFlowType';
import { RegistrationType } from '../RegisterNew/CONSTANT';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

export default function Aetna() {
  const { isShowing, toggle, ref } = useModal();
  const size = useWindowSize();
  const [activeTab, setActiveTab] = useState(1);
  const history = useHistory();

  const { register_sponsored, setRegisterUser } = useAuth();
  const { shouldGoToNewAuthFlow } = useRegisterFlowType();

  const handleCreateAccount = () => {
    if (shouldGoToNewAuthFlow) {
      setRegisterUser({
        registrationType: RegistrationType.Core
      });
      history.push({
        pathname: REGISTER_NEW
      });
    } else {
      register_sponsored();
    }
  };

  if (shouldGoToNewAuthFlow === undefined) {
    return (
      <div className={`${submitStyles.spinner} ${loadingStyles.spinner}`} />
    );
  }

  return (
    <div style={{ height: 'auto', position: 'relative', minHeight: '159px' }}>
      <div
        className={`${heroStyles.container}`}
        style={{
          paddingTop: '20px',
          backgroundImage: `${
            size[3] <= _SM
              ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), url("/herobg.png")'
              : 'url("/herobg.png")'
          }`,
          minHeight: '937px',
        }}
      >
        <div style={{ marginTop: '25px', marginBottom: '210px' }}>
          <img
            className={headerStyles.logo}
            src={size[3] <= _SM ? logoBurst : logo}
            alt=""
          />
        </div>
        <div
          className={heroStyles.content}
          style={{ color: 'white', textAlign: 'center' }}
        >
          <h3
            className={heroStyles.title}
            style={{
              color: 'white',
              textAlign: 'center',
              margin: 'auto',
              marginBottom: '1em',
            }}
          >
            <span>Register for your employer-sponsored Peerfit account</span>
          </h3>
          <div
            className={heroStyles.copy}
            style={{ margin: 'auto', marginBottom: '1em' }}
          >
            <img src="/logo_aetna.png" alt="..." />
          </div>
          <div
            className={heroStyles.copy}
            style={{ margin: 'auto', marginBottom: '1em' }}
          >
            <span>
              Get access to thousands of fitness experiences, from virtual
              fitness to boutique studios to gyms, fully funded by your Aetna
              insurance.
            </span>
          </div>
          <div
            className={heroStyles.copy}
            style={{ margin: 'auto', marginBottom: '1em' }}
          >
            <span>
              You’re already covered -- all you need to do is create your
              account!
            </span>
          </div>
          <div
            className={heroStyles.action}
            style={{ margin: 'auto', marginBottom: '1em' }}
          >
            <button
              className={`${heroStyles.btn} ${btnDefaultStyles.button} focus_outline`}
              onClick={handleCreateAccount}
            >
              <span>Create Account</span>
            </button>
          </div>
        </div>
      </div>
      <div className={accountTypesStyles.container}>
        <div className={accountTypesStyles.navContainer}></div>
        <div className={accountTypesStyles.content}>
          {(size[3] >= _MD || activeTab == 1) && (
            <div
              className={`${accountTypesStyles.corporateContent} ${accountTypesStyles.tab}`}
            >
              <img src="/graphic_fitnessonyourterms.png" alt="..." />
            </div>
          )}
          {(size[3] >= _MD || activeTab == 2) && (
            <div
              className={`${accountTypesStyles.corporateContent} ${accountTypesStyles.tab}`}
            >
              <h4>
                <span>Fitness, on your terms. </span>
              </h4>
              <p>
                <span>
                  Hop around to different fitness studios and gyms, go to your
                  favorite spot every day, or stream live and on-demand classes
                  and get fit in your living room.
                </span>
              </p>
              <p>
                <span>
                  Invite your friends and co-workers and build a healthy happy
                  hour routine. No commitment, no expense reports, no boredom.
                </span>
              </p>
            </div>
          )}
        </div>
        <div className={accountTypesStyles.ctaBlock}>
          <h4>
            <span>Reserve classes at participating locations like these</span>
          </h4>
          <div>
            <img
              src="/logo_9round.png"
              className={partnersStyles.studioLogo}
              alt="..."
            />
            <img
              src="/logo_yogaworks.png"
              className={partnersStyles.studioLogo}
              alt="..."
            />
            <img
              src="/logo_puregbarre.png"
              className={partnersStyles.studioLogo}
              alt="..."
            />
          </div>
        </div>
      </div>
      <div className={referStyles.container}>
        <div className={referStyles.content}>
          <h2>
            <span>Sign up to get wellness credits at no cost to you</span>
          </h2>
          <p>
            <span>Offer is only valid for eligible employees</span>
          </p>
          <div
            className={heroStyles.action}
            style={{ margin: 'auto', marginBottom: '1em' }}
          >
            <button
              className={`${heroStyles.btn} ${btnDefaultStyles.button} focus_outline`}
              onClick={handleCreateAccount}
            >
              <span>Create Account</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={mobileStyles.container}
        style={{
          backgroundImage: `url("/87a49a7c9c6a9c94adcc8bf9de96c5a3.png")`,
        }}
      >
        <div
          className={`${mobileStyles.content} ${utilities['page-container']}`}
        >
          <div className={mobileStyles.copy}>
            <h2>
              <span>Download the app and sign up to get started</span>
            </h2>
            <p>
              <span>
                Peerfit anywhere by downloading the mobile app and signing up
                for Peerfit.
              </span>
            </p>
            <a
              rel="noopener noreferrer"
              className={mobileStyles.download}
              href="https://itunes.apple.com/us/app/peerfit/id566352786?ls=1&amp;mt=8"
              target="_blank"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                className={svgStyles.icon}
                viewBox="0 0 100 32"
              >
                <path d="M95.175,1.175h-90.3c-2.2,0-4,1.8-4,4v21.7c0,2.2,1.8,4,4,4h90.3c2.2,0,4-1.8,4-4v-21.7 C99.175,2.975,97.375,1.175,95.175,1.175z M95.275,29.875h-90.4c-1.7,0-3-1.4-3-3v-21.7c0-1.7,1.4-3,3-3h90.3c1.7,0,3,1.4,3,3 l0.1,21.7C98.275,28.575,96.875,29.875,95.275,29.875z"></path>
                <path d="M20.501,8.907c0.635-0.794,1.069-1.874,0.949-2.97c-0.919,0.041-2.068,0.636-2.729,1.412 c-0.585,0.684-1.108,1.806-0.973,2.86C18.779,10.286,19.839,9.688,20.501,8.907z"></path>
                <path d="M22.825,15.791c-0.021-2.341,1.917-3.48,2.005-3.533c-1.097-1.6-2.799-1.819-3.396-1.836 c-1.429-0.15-2.814,0.855-3.542,0.855c-0.742,0-1.863-0.84-3.071-0.816c-1.554,0.024-3.008,0.924-3.806,2.321 c-1.646,2.849-0.418,7.036,1.158,9.34c0.789,1.128,1.71,2.387,2.917,2.343c1.18-0.049,1.621-0.752,3.045-0.752 c1.411,0,1.825,0.752,3.056,0.724c1.267-0.02,2.064-1.133,2.825-2.271c0.911-1.293,1.278-2.566,1.292-2.631 C25.279,19.524,22.849,18.596,22.825,15.791z"></path>
                <path d="M45.025,17.19c-1.052,0-1.835,0.415-2.352,1.248h-0.029l-0.086-1.105h-1.362c0.038,0.784,0.057,1.549,0.057,2.294v7.515 h1.548v-3.672h0.029c0.402,0.66,1.061,0.989,1.979,0.989c0.85,0,1.563-0.306,2.137-0.918c0.641-0.688,0.961-1.625,0.961-2.81 c0-1.061-0.275-1.917-0.825-2.568C46.533,17.515,45.847,17.19,45.025,17.19z M45.842,22.568c-0.325,0.449-0.779,0.674-1.362,0.674 c-0.497,0-0.901-0.174-1.211-0.524s-0.466-0.777-0.466-1.284v-1.162c0-0.123,0.024-0.28,0.072-0.472 c0.095-0.411,0.299-0.743,0.609-0.997c0.31-0.252,0.657-0.38,1.039-0.38c0.564,0,1.013,0.23,1.348,0.689 c0.307,0.44,0.459,0.999,0.459,1.678C46.33,21.526,46.167,22.118,45.842,22.568z"></path>
                <path d="M34.857,14.637l-3.112,9.666h1.606l0.861-2.839h3.141l0.904,2.839h1.649l-3.127-9.666H34.857z M34.485,20.273l0.803-2.524 c0.161-0.605,0.314-1.212,0.459-1.821h0.029c0.239,0.956,0.401,1.563,0.487,1.821l0.817,2.524H34.485z"></path>
                <path d="M62.464,18.742c-0.68-0.268-1.148-0.516-1.406-0.746c-0.296-0.248-0.444-0.564-0.444-0.946 c-0.005-0.334,0.137-0.654,0.387-0.875c0.305-0.267,0.731-0.402,1.275-0.402c0.689,0,1.305,0.148,1.85,0.444l0.387-1.262 c-0.583-0.306-1.314-0.459-2.193-0.459c-0.986,0-1.775,0.253-2.366,0.761c-0.593,0.507-0.89,1.158-0.89,1.953 c0,1.216,0.845,2.14,2.538,2.772c0.622,0.23,1.067,0.479,1.333,0.747c0.268,0.268,0.402,0.599,0.402,0.991 c0,0.44-0.162,0.793-0.487,1.056c-0.326,0.263-0.78,0.395-1.363,0.395c-0.813,0-1.559-0.206-2.239-0.617l-0.359,1.291 c0.631,0.402,1.465,0.602,2.505,0.602c1.127,0,2.003-0.282,2.633-0.846c0.573-0.516,0.858-1.185,0.858-2.008 c0-0.668-0.195-1.228-0.587-1.676C63.907,19.468,63.296,19.077,62.464,18.742z"></path>
                <path d="M74.35,17.191c-1.061,0-1.905,0.344-2.531,1.033c-0.627,0.688-0.939,1.572-0.939,2.652c0,1.032,0.304,1.888,0.911,2.567 c0.607,0.678,1.421,1.018,2.444,1.018c1.061,0,1.912-0.353,2.554-1.061c0.612-0.688,0.918-1.567,0.918-2.639 c0-1.042-0.296-1.893-0.89-2.552C76.196,17.53,75.373,17.191,74.35,17.191z M75.672,22.479c-0.335,0.565-0.799,0.848-1.39,0.848 c-0.604,0-1.071-0.277-1.406-0.834c-0.278-0.459-0.415-1.01-0.415-1.653c0-0.662,0.137-1.222,0.415-1.683 c0.325-0.556,0.797-0.834,1.42-0.834c0.601,0,1.065,0.277,1.39,0.834c0.278,0.46,0.415,1.011,0.415,1.654 C76.101,21.453,75.957,22.01,75.672,22.479z"></path>
                <path d="M68.3,15.597l-1.521,0.459v1.276h-1.017v1.162h1.017v3.513c0,0.88,0.173,1.506,0.516,1.879 c0.345,0.373,0.823,0.559,1.434,0.559c0.498,0,0.899-0.057,1.204-0.171l-0.043-1.176c-0.182,0.048-0.411,0.072-0.688,0.072 c-0.603,0-0.904-0.43-0.904-1.291v-3.384h1.707v-1.164H68.3V15.597z"></path>
                <path d="M81.234,17.564c-0.353,0.277-0.611,0.646-0.774,1.104h-0.043l-0.057-1.334h-1.349c0.039,0.66,0.058,1.391,0.058,2.194 l-0.015,4.775h1.548v-3.657c0-0.554,0.129-1.013,0.387-1.376c0.296-0.412,0.718-0.617,1.262-0.617 c0.164-0.001,0.327,0.014,0.488,0.043v-1.477c-0.115-0.018-0.244-0.028-0.387-0.028C81.95,17.191,81.558,17.322,81.234,17.564z"></path>
                <path d="M53.042,17.19c-1.052,0-1.836,0.415-2.352,1.248h-0.029l-0.086-1.105h-1.362c0.038,0.784,0.057,1.549,0.057,2.294v7.515 h1.548v-3.672h0.029c0.401,0.66,1.06,0.989,1.979,0.989c0.85,0,1.563-0.306,2.136-0.918c0.641-0.688,0.962-1.625,0.962-2.81 c0-1.061-0.275-1.917-0.824-2.568C54.55,17.515,53.863,17.19,53.042,17.19z M53.858,22.568c-0.325,0.449-0.779,0.674-1.362,0.674 c-0.498,0-0.902-0.175-1.213-0.524c-0.309-0.349-0.465-0.777-0.465-1.284v-1.162c0-0.123,0.025-0.28,0.072-0.472 c0.095-0.411,0.298-0.743,0.609-0.997c0.291-0.245,0.659-0.38,1.04-0.38c0.564,0,1.013,0.23,1.347,0.689 c0.307,0.44,0.46,0.999,0.46,1.678C54.346,21.526,54.184,22.118,53.858,22.568z"></path>
                <path d="M86.77,17.177c-1.023,0-1.835,0.373-2.437,1.118c-0.574,0.698-0.861,1.573-0.861,2.624c0,1.071,0.309,1.924,0.925,2.56 c0.617,0.636,1.466,0.954,2.546,0.954c0.898,0,1.677-0.143,2.337-0.431l-0.243-1.075c-0.564,0.22-1.192,0.33-1.88,0.33 c-0.622,0-1.128-0.163-1.519-0.487c-0.431-0.363-0.656-0.89-0.674-1.578h4.646c0.042-0.232,0.061-0.467,0.057-0.702 c0-0.869-0.206-1.602-0.614-2.193C88.535,17.55,87.775,17.177,86.77,17.177z M84.964,20.086c0.047-0.468,0.2-0.869,0.458-1.204 c0.315-0.421,0.726-0.631,1.233-0.631c0.554,0,0.966,0.215,1.234,0.646c0.21,0.335,0.311,0.731,0.301,1.19H84.964z"></path>
                <path d="M69.87,11.256c0.522,0,0.939-0.174,1.254-0.521c0.301-0.338,0.451-0.77,0.451-1.296c0-0.511-0.146-0.93-0.437-1.254 c-0.305-0.333-0.71-0.5-1.211-0.5c-0.522,0-0.936,0.169-1.244,0.507c-0.308,0.338-0.461,0.773-0.461,1.303 c0,0.507,0.149,0.927,0.447,1.261C68.968,11.089,69.368,11.256,69.87,11.256z M69.201,8.65c0.161-0.273,0.393-0.41,0.698-0.41 c0.296,0,0.523,0.137,0.683,0.41c0.136,0.226,0.204,0.497,0.204,0.813c0,0.316-0.071,0.589-0.211,0.819 c-0.164,0.278-0.393,0.417-0.683,0.417c-0.296,0-0.526-0.137-0.691-0.41c-0.136-0.226-0.204-0.497-0.204-0.813 C68.997,9.151,69.064,8.876,69.201,8.65z"></path>
                <rect x="51.814" y="6.183" width="0.76" height="4.995"></rect>
                <polygon points="46.53,7.754 46.53,7.755 46.53,7.755     "></polygon>
                <path d="M87.637,11.242c0.442,0,0.824-0.07,1.149-0.211l-0.119-0.529c-0.277,0.108-0.585,0.162-0.923,0.162 c-0.305,0-0.554-0.08-0.747-0.24c-0.211-0.179-0.32-0.437-0.33-0.775h2.283c0.018-0.094,0.028-0.208,0.028-0.345 c0-0.427-0.101-0.787-0.305-1.077c-0.253-0.366-0.627-0.549-1.119-0.549c-0.503,0-0.902,0.183-1.197,0.549 C86.073,8.57,85.933,9,85.933,9.516c0,0.526,0.15,0.945,0.454,1.257C86.689,11.086,87.107,11.242,87.637,11.242z M86.892,8.515 c0.138-0.198,0.365-0.314,0.606-0.31c0.272,0,0.474,0.106,0.606,0.317c0.107,0.176,0.158,0.379,0.147,0.585h-1.585 C86.684,8.893,86.763,8.688,86.892,8.515z"></path>
                <path d="M84.058,9.231v1.947h0.762V9.142c0-0.507-0.129-0.891-0.387-1.148c-0.207-0.207-0.468-0.31-0.783-0.31 c-0.445,0-0.789,0.187-1.029,0.563h-0.015V6.183h-0.76v4.994h0.76V9.118c-0.001-0.093,0.011-0.185,0.036-0.274 c0.132-0.356,0.373-0.534,0.726-0.534C83.829,8.31,84.058,8.618,84.058,9.231z"></path>
                <path d="M72.694,11.177h0.762V9.146c-0.004-0.215,0.072-0.424,0.211-0.587c0.135-0.164,0.337-0.256,0.549-0.249 c0.461,0,0.691,0.302,0.691,0.906v1.961h0.76V9.127c0-0.503-0.131-0.881-0.394-1.135c-0.211-0.207-0.477-0.31-0.796-0.31 c-0.253,0-0.484,0.065-0.691,0.192c-0.165,0.101-0.301,0.244-0.394,0.413h-0.021l-0.036-0.535h-0.668 c0.018,0.353,0.027,0.679,0.027,0.98V11.177z"></path>
                <path d="M43.282,11.178l0.471-1.487c0.118-0.362,0.21-0.731,0.275-1.106h0.014c0.073,0.373,0.165,0.742,0.275,1.106l0.444,1.487 h0.697l1.071-3.423h-0.747l-0.415,1.599c-0.103,0.399-0.181,0.775-0.232,1.127h-0.021c-0.07-0.362-0.162-0.737-0.275-1.127 l-0.466-1.599h-0.62l-0.486,1.634c-0.132,0.441-0.226,0.805-0.283,1.092h-0.014c-0.062-0.347-0.139-0.714-0.232-1.099l-0.387-1.628 h-0.782l1.007,3.424H43.282z"></path>
                <path d="M59.979,10.784H60l0.057,0.394h0.683c-0.033-0.202-0.049-0.474-0.049-0.816V9.101c0-0.944-0.449-1.416-1.344-1.416 c-0.463,0-0.856,0.099-1.174,0.296l0.155,0.5c0.257-0.169,0.555-0.253,0.893-0.253c0.474,0,0.711,0.225,0.711,0.676v0.064 c-0.634-0.005-1.126,0.103-1.477,0.324c-0.351,0.221-0.525,0.54-0.525,0.958c-0.007,0.259,0.089,0.51,0.268,0.697 c0.197,0.206,0.458,0.31,0.781,0.31C59.412,11.256,59.745,11.098,59.979,10.784z M58.69,10.163c0-0.474,0.42-0.705,1.259-0.69 v0.528c0.003,0.214-0.101,0.416-0.276,0.539c-0.142,0.105-0.299,0.158-0.466,0.158c-0.137,0.005-0.27-0.043-0.372-0.134 C58.738,10.476,58.69,10.342,58.69,10.163z"></path>
                <path d="M39.175,7.684c-0.521,0-0.935,0.169-1.243,0.507c-0.308,0.338-0.461,0.773-0.461,1.303c0,0.507,0.149,0.927,0.447,1.261 c0.299,0.334,0.699,0.5,1.201,0.5c0.521,0,0.939-0.174,1.254-0.521c0.301-0.338,0.451-0.77,0.451-1.296 c0-0.511-0.146-0.93-0.437-1.254C40.082,7.851,39.678,7.684,39.175,7.684z M39.823,10.282c-0.164,0.278-0.392,0.417-0.683,0.417 c-0.296,0-0.526-0.137-0.691-0.41c-0.136-0.226-0.204-0.497-0.204-0.813c0-0.325,0.068-0.601,0.204-0.827 c0.16-0.273,0.392-0.41,0.698-0.41c0.296,0,0.523,0.137,0.683,0.41c0.136,0.226,0.204,0.497,0.204,0.813 C40.034,9.778,39.964,10.051,39.823,10.282z"></path>
                <path d="M55.407,7.684c-0.521,0-0.935,0.169-1.243,0.507c-0.308,0.338-0.461,0.773-0.461,1.303c0,0.507,0.149,0.927,0.447,1.261 c0.298,0.334,0.698,0.5,1.201,0.5c0.521,0,0.939-0.174,1.254-0.521c0.301-0.338,0.451-0.77,0.451-1.296 c0-0.511-0.146-0.93-0.437-1.254C56.313,7.851,55.91,7.684,55.407,7.684z M56.055,10.282c-0.164,0.278-0.391,0.417-0.682,0.417 c-0.296,0-0.526-0.137-0.691-0.41c-0.136-0.226-0.204-0.497-0.204-0.813c0-0.325,0.068-0.601,0.204-0.827 c0.159-0.273,0.391-0.41,0.698-0.41c0.295,0,0.523,0.137,0.682,0.41c0.136,0.226,0.204,0.497,0.204,0.813 C56.266,9.778,56.196,10.051,56.055,10.282z"></path>
                <path d="M35.8,10.622c0.512-0.432,0.768-1.076,0.768-1.931c0-0.761-0.238-1.346-0.711-1.755c-0.423-0.362-1.05-0.543-1.881-0.543 c-0.471,0-0.906,0.033-1.311,0.099v4.671c0.31,0.038,0.681,0.057,1.113,0.057C34.652,11.22,35.326,11.02,35.8,10.622z M33.426,7.042c0.169-0.033,0.373-0.049,0.613-0.049c0.564,0,0.993,0.148,1.286,0.444c0.293,0.296,0.44,0.721,0.44,1.275 c0,0.62-0.16,1.092-0.476,1.416c-0.317,0.324-0.766,0.486-1.349,0.486c-0.249,0-0.421-0.007-0.514-0.021V7.042z"></path>
                <path d="M65.038,6.183h-0.762v1.965h-0.015c-0.183-0.31-0.505-0.465-0.965-0.465c-0.418,0-0.77,0.16-1.057,0.479 c-0.296,0.343-0.444,0.792-0.444,1.346c0,0.516,0.136,0.937,0.408,1.261c0.272,0.324,0.616,0.486,1.028,0.486 c0.516,0,0.884-0.209,1.1-0.628h0.021l0.036,0.55h0.675c-0.018-0.282-0.027-0.589-0.027-0.922V6.183z M64.276,9.734 c0,0.244-0.072,0.453-0.219,0.627c-0.152,0.188-0.352,0.282-0.601,0.282c-0.278,0-0.5-0.113-0.665-0.338 c-0.156-0.211-0.234-0.486-0.234-0.824c0-0.352,0.082-0.641,0.244-0.867c0.163-0.226,0.386-0.338,0.67-0.338 c0.187-0.004,0.369,0.063,0.509,0.187c0.201,0.176,0.311,0.434,0.296,0.701V9.734z"></path>
                <path d="M49.715,9.217v1.961h0.761V9.127c0-0.503-0.131-0.881-0.394-1.135c-0.211-0.207-0.477-0.31-0.797-0.31 c-0.253,0-0.484,0.065-0.69,0.192c-0.174,0.11-0.306,0.247-0.394,0.413h-0.021l-0.036-0.535h-0.669 c0.018,0.353,0.028,0.679,0.028,0.98v2.445h0.761V9.146c-0.003-0.215,0.072-0.423,0.211-0.587c0.135-0.163,0.338-0.255,0.55-0.249 C49.485,8.31,49.715,8.613,49.715,9.217z"></path>
                <path d="M79.206,10.051c0,0.432,0.084,0.74,0.254,0.923c0.169,0.183,0.403,0.275,0.704,0.275c0.243,0,0.441-0.028,0.591-0.085 l-0.02-0.577c-0.089,0.024-0.203,0.036-0.339,0.036c-0.295,0-0.444-0.211-0.444-0.634V8.324h0.837V7.753h-0.838V6.901l-0.746,0.225 v0.628h-0.5v0.57h0.5V10.051z"></path>
              </svg>
            </a>
            <a
              rel="noopener noreferrer"
              className={mobileStyles.download}
              href="https://play.google.com/store/apps/details?id=com.peerfit.app"
              target="_blank"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                className={svgStyles.icon}
                viewBox="0 0 100 32"
              >
                <path d="M95.029,1.104H4.707c-2.192,0-3.974,1.783-3.974,3.974v21.677c0,2.191,1.783,3.974,3.974,3.974h90.323 c2.191,0,3.974-1.783,3.974-3.974V5.077C99.003,2.886,97.22,1.104,95.029,1.104z M98.064,26.754c0,1.677-1.359,3.036-3.035,3.036 H4.707c-1.676,0-3.035-1.359-3.035-3.035V5.077c0-1.676,1.359-3.035,3.035-3.035h90.323c1.676,0,3.035,1.359,3.035,3.035V26.754z"></path>
                <polygon points="47.504,10.93 47.504,7.129 48.711,7.129 48.711,6.45 45.59,6.45 45.59,7.129 46.805,7.129 46.805,10.93"></polygon>
                <polygon points="56.342,7.577 58.412,10.896 58.434,10.93 59.123,10.93 59.123,6.45 58.424,6.45 58.424,9.645 56.425,6.45 55.635,6.45 55.635,10.93 56.342,10.93"></polygon>
                <polygon points="41.109,10.93 41.109,7.129 42.352,7.129 42.352,6.45 39.195,6.45 39.195,7.129 40.409,7.129 40.409,10.93"></polygon>
                <path d="M33.224,11.024c0.026,0.001,0.051,0.001,0.077,0.001c0.59,0,1.157-0.24,1.567-0.666c0.382-0.399,0.588-0.945,0.562-1.497 c0-0.115-0.01-0.227-0.03-0.336l-0.011-0.059h-2.235V9.13h1.563c-0.026,0.283-0.144,0.551-0.334,0.757 c-0.307,0.296-0.708,0.459-1.134,0.459c-0.008,0-0.016,0-0.024,0c-0.436-0.006-0.844-0.182-1.148-0.494 c-0.304-0.312-0.468-0.725-0.462-1.164c-0.001-0.022-0.001-0.043-0.001-0.064c0.004-0.882,0.723-1.596,1.609-1.596 c0.002,0,0.005,0,0.007,0c0.425-0.021,0.849,0.159,1.125,0.488l0.051,0.06l0.487-0.487l-0.044-0.051 c-0.193-0.221-0.436-0.394-0.705-0.503c-0.289-0.124-0.595-0.187-0.908-0.187c-0.003,0-0.006,0-0.008,0 c-0.616-0.006-1.219,0.231-1.65,0.671c-0.435,0.438-0.677,1.02-0.682,1.636c-0.005,0.628,0.235,1.219,0.675,1.666 C32.011,10.771,32.599,11.019,33.224,11.024z"></path>
                <rect x="44.334" y="6.45" width="0.7" height="4.48"></rect>
                <path d="M52.693,11.021c0.595,0,1.188-0.225,1.641-0.675l0.001-0.001c0.884-0.93,0.884-2.385,0-3.315l-0.013-0.013 c-0.436-0.433-1.014-0.671-1.629-0.671c-0.003,0-0.006,0-0.008,0c-0.619,0.002-1.197,0.244-1.633,0.684 c-0.879,0.93-0.879,2.385,0.001,3.316C51.505,10.795,52.098,11.021,52.693,11.021z M51.566,7.498 c0.622-0.619,1.633-0.618,2.251-0.002c0.618,0.681,0.618,1.708,0.003,2.385c-0.62,0.619-1.631,0.62-2.253,0.001 C50.951,9.202,50.952,8.177,51.566,7.498z"></path>
                <polygon points="38.74,10.251 36.789,10.251 36.789,9.023 38.567,9.023 38.567,8.357 36.789,8.357 36.789,7.129 38.74,7.129 38.74,6.45 36.067,6.45 36.067,10.93 38.74,10.93     "></polygon>
                <path d="M9.002,25.138c0.384,0.144,0.815,0.078,1.138-0.175l10.271-5.836l-2.707-2.693L9.002,25.138z"></path>
                <path d="M8.637,24.919l0.051,0.051l0.005-0.005c0.007,0.005,0.014,0.011,0.02,0.016l-0.129-0.129 C8.601,24.875,8.619,24.897,8.637,24.919z"></path>
                <path d="M20.41,12.708L10.133,6.87C9.797,6.606,9.347,6.546,8.953,6.71l8.75,8.704L20.41,12.708z"></path>
                <path d="M8.441,7.221C8.337,7.441,8.29,7.683,8.305,7.925v15.983c-0.016,0.259,0.039,0.517,0.158,0.748l8.73-8.73L8.441,7.221z"></path>
                <path d="M75.069,14.11c-0.134-0.01-0.268-0.01-0.403,0h-0.007H71.43v9.032h1.351v-3.425h1.885c1.548,0.111,2.894-1.053,3.005-2.602 C77.783,15.566,76.617,14.221,75.069,14.11z M74.688,18.467l-0.029-0.022h-1.885v-3.079h1.914c0.856,0,1.55,0.694,1.55,1.55 S75.544,18.467,74.688,18.467z"></path>
                <path d="M83.027,17.166l-0.036-0.022c-1.006-0.06-1.95,0.482-2.406,1.38l1.199,0.499c0.241-0.445,0.723-0.706,1.228-0.664 c0.713-0.084,1.36,0.427,1.443,1.141c0.001,0.007,0.002,0.015,0.002,0.022v0.094c-0.434-0.229-0.918-0.349-1.409-0.347 c-1.294,0-2.602,0.722-2.602,2.03c0.053,1.152,1.03,2.043,2.182,1.99c0.02-0.001,0.039-0.002,0.059-0.004 c0.693,0.05,1.358-0.282,1.734-0.867h0.043v0.722h1.301v-3.476C85.765,18.084,84.566,17.166,83.027,17.166z M82.86,22.116 l-0.036-0.022c-0.441,0-1.055-0.224-1.055-0.766c0-0.722,0.766-0.961,1.445-0.961c0.429-0.011,0.853,0.094,1.228,0.303 C84.35,21.48,83.674,22.096,82.86,22.116z"></path>
                <rect x="60.8" y="14.11" width="1.351" height="9.032"></rect>
                <rect x="78.359" y="14.11" width="1.351" height="9.032"></rect>
                <path d="M68.329,19.138c-0.386-1.135-1.433-1.914-2.63-1.958c-1.596-0.003-2.893,1.288-2.896,2.885 c0,0.062,0.002,0.125,0.006,0.187c-0.036,1.66,1.28,3.035,2.94,3.071c0.037,0.001,0.073,0.001,0.11,0 c1.027,0.006,1.987-0.505,2.558-1.359l-1.047-0.722c-0.318,0.531-0.892,0.854-1.51,0.853c-0.641,0.024-1.231-0.345-1.488-0.932 l4.111-1.698L68.329,19.138z M64.138,20.165c-0.061-0.928,0.642-1.729,1.57-1.79c0.011-0.001,0.023-0.001,0.034-0.002 c0.477-0.031,0.926,0.224,1.141,0.65L64.138,20.165z"></path>
                <path d="M58.589,17.868h-0.05c-0.412-0.461-1.001-0.723-1.618-0.722c-1.698,0.083-3.008,1.525-2.925,3.223 c0.077,1.583,1.343,2.849,2.925,2.925c0.62,0.01,1.212-0.254,1.618-0.722h0.043v0.441c0,1.178-0.628,1.807-1.64,1.807 c-0.689-0.016-1.3-0.447-1.546-1.091l-1.178,0.492c0.454,1.103,1.531,1.821,2.724,1.814c1.582,0,2.89-0.932,2.89-3.201v-5.47 h-1.243V17.868z M58.683,20.252c0.081,0.947-0.622,1.779-1.567,1.86c-0.025,0.002-0.05,0.004-0.074,0.005 c-1.029-0.092-1.789-1-1.698-2.028c0.08-0.904,0.796-1.62,1.7-1.7c0.957,0.049,1.692,0.864,1.643,1.821 C58.685,20.223,58.684,20.237,58.683,20.252z"></path>
                <path d="M24.216,14.876l-3.157-1.794l-2.842,2.842l2.848,2.832l3.152-1.792C25.227,16.386,25.227,15.446,24.216,14.876z"></path>
                <path d="M43.697,17.18c-0.032,0-0.063,0-0.095,0c-1.696,0.004-3.068,1.382-3.064,3.079c0.004,1.696,1.382,3.068,3.079,3.064 c1.693-0.004,3.064-1.378,3.064-3.071C46.705,18.58,45.369,17.205,43.697,17.18z M43.641,22.114 c-0.014,0.001-0.029,0.001-0.043,0.002c-1.025,0.074-1.916-0.696-1.992-1.722c-0.076-1.027,0.695-1.92,1.722-1.996 c1.026-0.076,1.92,0.695,1.996,1.722c0.003,0.044,0.005,0.088,0.005,0.133C45.378,21.232,44.622,22.066,43.641,22.114z"></path>
                <path d="M35.59,18.127v1.301h3.122c-0.049,0.613-0.303,1.19-0.722,1.64c-0.631,0.643-1.505,0.99-2.406,0.954 c-1.915,0-3.469-1.553-3.469-3.469s1.553-3.469,3.469-3.469c0.88-0.014,1.73,0.321,2.362,0.932l0.918-0.918 c-0.864-0.875-2.044-1.362-3.273-1.351c-2.636-0.103-4.855,1.951-4.958,4.587c-0.103,2.636,1.951,4.855,4.587,4.958 c0.124,0.005,0.247,0.005,0.371,0c1.251,0.057,2.466-0.431,3.331-1.337c0.757-0.832,1.161-1.925,1.127-3.05 c0.002-0.274-0.022-0.548-0.072-0.817L35.59,18.127z"></path>
                <path d="M50.432,17.18c-0.034,0-0.068,0-0.102,0c-1.696,0.008-3.065,1.39-3.057,3.086c0.008,1.696,1.39,3.065,3.086,3.057 c1.69-0.008,3.057-1.381,3.057-3.071C53.44,18.58,52.104,17.205,50.432,17.18z M50.376,22.114 c-0.015,0.001-0.031,0.001-0.046,0.002c-1.028,0.072-1.918-0.702-1.99-1.73c-0.072-1.028,0.702-1.918,1.73-1.99 c1.028-0.072,1.917,0.702,1.99,1.73c0.003,0.042,0.004,0.084,0.005,0.126C52.112,21.232,51.357,22.066,50.376,22.114z"></path>
                <polygon points="88.959,21.277 88.916,21.277 87.312,17.362 85.866,17.362 88.272,22.839 86.9,25.88 88.309,25.88 92.008,17.362 90.505,17.362     "></polygon>
              </svg>
            </a>
          </div>
          <img
            src="/7033a235e2a369e7d60c1fc1ba18801c.png"
            className={mobileStyles.phone}
            alt=""
          />
        </div>
      </div>
      {/* <div className={referStyles.container}>
                <div className={referStyles.content}>
                    <h2>
                        <span>Peerfitting is more fun with friends</span>
                    </h2>
                    <p>
                        <span>Have a friend or family member who you would like to join you? You don’t have an employer/carrier sponsor to participate. Refer your friends to </span>
                        <Link to={REGISTER}>peerfit.com/register</Link>
                        <span> to sign up.</span>
                    </p>
                    <button className={`${referStyles.button} ${btnDefaultStyles.button} focus_outline`} onClick={register_sponsored}>
                        <span>Create Account</span>
                    </button>
                </div>
            </div> */}

      <div className={quotesStyles.container}>
        <div className={quotesStyles.content}>
          <h3>
            “My favorite aspect of Peerfit is that my workouts are always fresh,
            and I get to experience them with great people!”
          </h3>
          <h3>NATALIE SMITH</h3>
          <h3>LandSouth Construction</h3>
        </div>
      </div>
      <div className={referStyles.container}>
        <div className={referStyles.content}>
          <h2>
            <span>Peerfitting is more fun with friends</span>
          </h2>
          <p>
            <span>
              Have a friend or family member who you would like to join you? You
              don’t have an employer/carrier sponsor to participate. Refer your
              friends to{' '}
            </span>
            <Link to={REGISTER}>peerfit.com/register</Link>
            <span> to sign up.</span>
          </p>
        </div>
      </div>
    </div>
  );
}
