import React from 'react';
import { Helmet } from 'react-helmet';

export const HomeHelmet = () => {
  return (
    <Helmet>
      <title>Corporate Wellness Program and Fitness Benefit | FitOn Health</title>
      <meta
        name="description"
        content="FitOn Health provides an innovative, best-in-class fitness program to compliment your benefits, HR, and corporate wellness packages."
      ></meta>
    </Helmet>
  );
};

export const ExploreStudiosHelmet = () => {
  return (
    <Helmet>
      <title>
        Explore Fitness Facilities, Boutique and Local Studios | Peerfit
      </title>
      <meta
        name="description"
        content="Explore the Peerfit Network! Some partners include big box gyms like Crunch and YouFit, boutique studios like Orangetheory and The Bar Method in addition to locally owned fitness and wellness studios."
      ></meta>
    </Helmet>
  );
};

export const CorporateWellnessHelmet = () => {
  return (
    <Helmet>
      <title>Workplace Wellness Benefit and Fitness Program | Peerfit</title>
      <meta
        name="description"
        content="Peerfit's platform is built for organizations, to supplement, replace, or begin exisiting benefit offerings as fitness and wellness benefits offered through wellness dollars or insurance packages."
      ></meta>
    </Helmet>
  );
};

export const FitnessPartnersHelmet = () => {
  return (
    <Helmet>
      <title>
        Fitness Studio and Gym Facility Network | Become a Peerfit Partner
      </title>
      <meta
        name="description"
        content="Join the Peerfit network and get access to health plan and wellness dollars through our partnerships with national health insurance carriers and employers. "
      ></meta>
    </Helmet>
  );
};

export const AboutUsHelmet = () => {
  return (
    <Helmet>
      <title>
        Meet the Peerfit Team | Corporate Wellness Program and Benefit
      </title>
      <meta
        name="description"
        content="Peerfit is a digital fitness platform that gives employers, their employees, and Medicare Advantage members access to a variety of fitness studios, gyms, and digitally streamed workouts."
      ></meta>
    </Helmet>
  );
};

export const HowItWorksHelmet = () => {
  return (
    <Helmet>
      <title>Fitness Subscriptions through Peerfit | How It Works</title>
      <meta
        name="description"
        content="Learn about the difference offerings Peerfit provides to get access to a large network of fitness partners, gyms, streaming services and more!  "
      ></meta>
    </Helmet>
  );
};

export const IndividualSubscriptionHelmet = () => {
  return (
    <Helmet>
      <title>
        How to Increase Participation in Corporate Wellness Programs | Corporate
        Wellness | Peerfit Pulse
      </title>
      <meta
        name="description"
        content="Peerfit Pulse helps uncover the dos and don'ts on how to enhance and improve participation in your organization's corporate wellness program."
      ></meta>
    </Helmet>
  );
};

export const ContactUsHelmet = () => {
  return (
    <Helmet>
      <title>Contact Us and Get In Touch | Peerfit Team</title>
      <meta
        name="description"
        content="We love to chat. Hit us up here or at support@peerfit.com."
      ></meta>
    </Helmet>
  );
};

export const PrivacyPolicyHelmet = () => {
  return (
    <Helmet>
      <title>Privacy Policy | Peerfit Corporate Fitness Benefit</title>
      <meta
        name="description"
        content="This Privacy Policy describes the information gathering and dissemination practices for the Service and the choices you have with respect to this information."
      ></meta>
    </Helmet>
  );
};

export const CookiePolicyHelmet = () => {
  return (
    <Helmet>
      <title>Cookie Policy | Peerfit Well-Being Benefit</title>
      <meta
        name="description"
        content="Yummy. At Peerfit, we believe in being transparent about how we collect and use data. This policy provides information about how and when we use cookies for these purposes. "
      ></meta>
    </Helmet>
  );
};

export const TermsOfServiceHelmet = () => {
  return (
    <Helmet>
      <title>
        Terms of Use and Service Policy | Peerfit Workplace Wellness Program
      </title>
      <meta
        name="description"
        content="Through our platform and mobile application, you gain easy access to a variety of services and experiences, empowering you to personalize your journey to wellness, so you can lead a more active and healthier lifestyle. "
      ></meta>
    </Helmet>
  );
};

export const AccessibilityHelmet = () => {
  return (
    <Helmet>
      <title>
        Web Accessibility Statement | Peerfit Corporate Wellness Benefit
      </title>
      <meta
        name="description"
        content="Peerfit is commited to providing equal access to all individuals seeking information on our site and using our platform. We follow Web Content Accessibility Guidelines (WCAG) 2.0 Level AA Standards. "
      ></meta>
    </Helmet>
  );
};

export const AetnaHelmet = () => {
  return (
    <Helmet>
      <title>
        Made Available Through Aetna Health Insurance Plans | Peerfit
      </title>
      <meta
        name="description"
        content="Get access to tens of thousands of fitness experiences, from virtual fitness to boutique studios to gyms, fully funded by your Aetna insurance."
      ></meta>
    </Helmet>
  );
};

export const StreamingHelmet = () => {
  return (
    <Helmet>
      <title>
        Peerfit is now part of FitOn Digital Fitness App | Corporate Wellness
        Benefit
      </title>
      <meta
        name="description"
        content="Check out FitOn, Peerfit's digital fitness partner and hundreds of studios offering digital streaming classes. "
      ></meta>
    </Helmet>
  );
};

export const StreamingStudiosHelmet = () => {
  return (
    <Helmet>
      <title>
        Peerfit Network of Studios offering Digital Classes | Corporate Wellness
        Benefit
      </title>
      <meta
        name="description"
        content="Check out Peerfit's nationwide network of fitness studios offering digital streaming and LIVE classes through your Peerfit account. "
      ></meta>
    </Helmet>
  );
};

export const RegisterHelmet = () => {
  return (
    <Helmet>
      <title>
        Register for your Peerfit Account | Your Corporate Wellness Benefit
      </title>
      <meta
        name="description"
        content="Made available through your employer, register for your Peerfit fitness benefit and get access to a nationwide network of fitness studios, gyms, and unlimited access to FitOn, the #1 downloaded fitness app. "
      ></meta>
    </Helmet>
  );
};

export const ForWellnessProgramsHelmet = () => {
  return (
    <Helmet>
      <title>
        Peerfit | Workplace Wellness Benefit Built for Organizations
      </title>
      <meta
        name="description"
        content="Peerfit's can supplement, replace, or begin benefit offerings at any organization. Bring wellness to your workplace and give your employees and benefit they will use and love. "
      ></meta>
    </Helmet>
  );
};

export const ForFitnessFacilitiesHelmet = () => {
  return (
    <Helmet>
      <title>
        Become a Peerfit Fitness Partner | Third-Party Wellness Vendor
      </title>
      <meta
        name="description"
        content="By joining the Peerfit network, you get access to health plan and wellness dollars through our partnerships with national health insurance carriers and employers. Free to join and we offer competitive reimbursements. "
      ></meta>
    </Helmet>
  );
};

export const StudioByIdHelmet = ({ name, city, state }) => {
  if (!name) return null;
  return (
    <Helmet>
      <title>
        {name} in {city} | Fitness Classes & Studios | Peerfit Network
      </title>
      <meta
        name="description"
        content={`From yoga to barre, CrossFit to bootcamp, Peerfitters can enjoy a wide range of fitness classes through the Peerfit Network in ${city}, ${state}.`}
      ></meta>
    </Helmet>
  );
};
export const AdminHelmet = () => {
  return (
    <Helmet>
      <title>Admin | FitOn Health</title>
      <link rel="shortcut icon" href="/fiton_icon.svg" />
    </Helmet>
  );
};
