import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from '../contexts/GlobalContext';
import { useSearch } from '../contexts/SearchContext';
import { STREAMING_STUDIOS } from '../navigation/CONSTANTS';
import useWindowSize, { _MD, _SM, _XS } from '../shared/hooks/useWindowSize';
import Loading from '../shared/Loading';
import classListStyles from '../styles/ClassList.module.css';
import classTableStyles from '../styles/ClassTable.module.css';
import dateSelectorStyles from '../styles/DateSelector.module.css';
import emptyStyles from '../styles/Empty.module.css';
import linkStyles from '../styles/Link.module.css';
import { AddDays } from '../utilities/date';
import DigitalFitnessBanner from './DigitalFitnessBanner';
import FitOnFitnessBanner from './FitOnFitnessBanner';
import SessionCard from './SessionCard';
import { useSite } from '../contexts/SiteContext';

export default function Classes() {
  const [startIndex, setStartIndex] = useState(0);
  const [dateList, setDateList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const {
    searchState: { date },
    setSearchDate,
    sessionsLoading,
    sessionList,
  } = useSearch();
  const size = useWindowSize();
  const { isDigitalFitOnSurfacing } = useGlobal();
  const { isMobileApp } = useSite();

  useEffect(() => {
    const d = [...Array(14)].map((_, i) => {
      return {
        date: format(AddDays(new Date(), i), 'yyyy-MM-dd'),
        dateString: format(AddDays(new Date(), i), 'E, LLL dd'),
      };
    });
    setDateList(d);
  }, []);

  useEffect(() => {
    if (dateList.length > 0) {
      const index = dateList.findIndex((x) => x.date == date);
      if (index < 0) {
        console.log('date not found. using today as default.');

        setSearchDate(dateList[0].date);

        handleDateSelect(dateList[0].date);
      } else {
        console.log('using date ', date);
        handleDateSelect(date);
      }
    }
  }, [dateList]);

  const handleDateSelect = (date) => {
    if (date) {
      console.log('handleDateSelect: ', date, dateList);
      if (dateList.findIndex((x) => x.date == date) >= 0) {
        const daysToShow = size[3] == _SM ? 3 : size[3] == _XS ? 1 : 5;
        const daysToOffset = size[3] == _SM ? 1 : size[3] == _XS ? 0 : 2;
        setListSize(daysToShow);

        console.log('handleDateSelect: ', date, dateList);
        const index = dateList.findIndex((x) => x.date == date);
        let startIndex = 0;
        if (dateList.length > daysToShow)
          startIndex = index < daysToOffset ? 0 : index > dateList.length - (daysToShow - daysToOffset) ? dateList.length - daysToShow : index - daysToOffset;
        // startIndex = index < 2 ? 0 : (index > dateList.length - 3 ? dateList.length - daysToShow : index - 2);
        setStartIndex(startIndex);
        setSelectedIndex(index);

        setSearchDate(date);

        console.log('StartIndex: ', index);
        console.log('StartIndex: ', startIndex, dateList.length - 1);
        console.log('DaysToShow:', daysToShow, daysToOffset);
      }
    }
  };

  useEffect(() => {
    console.log('selectedIndex:', selectedIndex);
  }, [selectedIndex]);

  const [listSize, setListSize] = useState(5);
  // const [daysToOffset, setDaysToOffset] = useState();
  // const adjustDaysToShow = () => {
  //     if (size[3] == _SM) {
  //         setDaysToShow(3);
  //         setDaysToOffset(1)
  //     }
  //     else if (size[3] == _XS) {
  //         setDaysToShow(1);
  //         setDaysToOffset(0);
  //     }
  //     else {
  //         setDaysToShow(5);
  //         setDaysToOffset(2);
  //     }
  // }
  // useEffect(() => {
  //     console.log("SIZE: ", size);
  //     adjustDaysToShow();
  // }, [size[3]])

  // useEffect(() => {
  //     handleDateSelect(dateList[selectedIndex]);
  // }, [daysToShow, daysToOffset])

  useEffect(() => {
    console.log('SIZE UPDATED:', size);
    handleDateSelect(dateList[selectedIndex]);
  }, [size[3]]);

  useEffect(() => {
    console.log('ListSize:', listSize);
    handleDateSelect(dateList[selectedIndex]);
  }, [listSize]);

  return (
    <div className="">
      <div className={`${dateSelectorStyles.dateSelector} ${dateSelectorStyles.strip}`}>
        <button
          onClick={() => {
            handleDateSelect(dateList[selectedIndex <= 0 ? 0 : selectedIndex - 1].date);
          }}
          className={`${dateSelectorStyles.arrowContainerLeft} ${dateSelectorStyles.arrowContainer}`}
          data-cy="dateselector-goback"
          aria-label={`Show Schedule for ${dateList[selectedIndex - 1]?.dateString ?? ''}`}
          style={{ cursor: 'initial' }}
        >
          <svg aria-hidden="true" focusable="false" className={dateSelectorStyles.arrow} width="24" height="24" viewBox="0 0 24 24">
            <path d="M16.4244649,19.2868077 C16.7653329,19.6293551 16.7639715,20.1833728 16.4214241,20.5242408 C16.0788767,20.8651089 15.524859,20.8637475 15.183991,20.5212001 L7.25476309,12.5529074 C6.9164664,12.2129441 6.91488398,11.6640093 7.25121504,11.3221012 L15.1804429,3.26139014 C15.5193304,2.9168832 16.073331,2.91232773 16.417838,3.2512152 C16.7623449,3.59010267 16.7669004,4.1441033 16.4280129,4.48861023 L9.10587647,11.932163 L16.4244649,19.2868077 Z"></path>
          </svg>
        </button>
        <div className={dateSelectorStyles.datesContainer} data-cy="dateselector-container">
          {dateList.slice(startIndex, startIndex + listSize).map((d, idx) => (
            <button
              key={idx + startIndex}
              onClick={() => {
                handleDateSelect(d.date);
              }}
              className={`${idx + startIndex == selectedIndex ? dateSelectorStyles.currentDateOption : ''} ${dateSelectorStyles.dateOption}`}
              data-cy="dateselector-day"
              aria-label={`Show Schedule for ${d.dateString}`}
            >
              <span>{d.dateString}</span>
            </button>
          ))}
        </div>
        <button
          onClick={() => {
            handleDateSelect(dateList[selectedIndex >= dateList.length - 1 ? selectedIndex : selectedIndex + 1].date);
          }}
          className={`${dateSelectorStyles.arrowContainerRight} ${dateSelectorStyles.arrowContainer}`}
          data-cy="dateselector-goforward"
          aria-label={dateList[selectedIndex + 1]?.dateString}
          style={{ cursor: 'initial' }}
        >
          <svg aria-hidden="true" focusable="false" className={dateSelectorStyles.arrow} width="24" height="24" viewBox="0 0 24 24">
            <path d="M7.25476326,19.2868077 L14.5733516,11.932163 L7.2512152,4.48861023 C6.91232773,4.1441033 6.9168832,3.59010267 7.26139014,3.2512152 C7.60589707,2.91232773 8.1598977,2.9168832 8.49878517,3.26139014 L16.4280131,11.3221012 C16.7643441,11.6640093 16.7627617,12.2129441 16.424465,12.5529074 L8.49523712,20.5212001 C8.15436907,20.8637475 7.60035138,20.8651089 7.25780398,20.5242408 C6.91525659,20.1833728 6.91389521,19.6293551 7.25476326,19.2868077 Z"></path>
          </svg>
        </button>
      </div>
      {!isMobileApp && (
        <div className={size[3] <= _MD ? classListStyles.mobileBanner : ''}>{isDigitalFitOnSurfacing ? <FitOnFitnessBanner /> : <DigitalFitnessBanner />}</div>
      )}

      {sessionsLoading ? (
        <Loading className={classTableStyles.spinner} />
      ) : (
        <>
          {sessionList?.length > 0 ? (
            <div data-cy="classes-list">
              <table>
                <thead>
                  <tr>
                    <th>
                      <span>Time</span>
                    </th>
                    <th>
                      <span>Class</span>
                    </th>
                    <th>
                      <span>Spots Left</span>
                    </th>
                    <th>
                      <span>Reserve</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sessionList?.map((session) => (
                    <SessionCard key={session.id} session={session} />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={emptyStyles.container}>
              <h3>
                <span>No Classes Available</span>
              </h3>
            </div>
          )}
        </>
      )}
    </div>
  );
}
