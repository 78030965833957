import { get } from 'lodash';
import apiInstance from './api';

export const getFeatureFlag = async featureName => {
  const api = await apiInstance.getApi(true);

  return api.get(`/feature-flag/${featureName}`).then(res => {
    return get(res, 'data.enabled') === 1;
  });
};
