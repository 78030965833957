import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import controllerStyles from '../../styles/Controller.module.css';
import overlayDefaultStyles from '../../styles/overlay/Default.module.css';
import svgStyles from '../../styles/SvgIcon.module.css';
import bioStyles from '../../styles/about-us/Bio.module.css';

export const TeamMemberModal = React.forwardRef(
  ({ isShowing, hide, member }, ref) => {
    return isShowing
      ? ReactDOM.createPortal(
          <React.Fragment>
            <div className={controllerStyles.overlay}>
              <div
                ref={ref}
                role="dialog"
                tabIndex="0"
                data-cy="Bio"
                className={overlayDefaultStyles.dialog}
                aria-labelledby="bio-title"
              >
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
                <div
                  data-focus-guard="true"
                  tabIndex="1"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
                <div data-focus-lock-disabled="false">
                  <button
                    onClick={hide}
                    className={`${overlayDefaultStyles.close} focus_outline`}
                    aria-label="Close"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      className={`${overlayDefaultStyles.closeIcon} ${svgStyles.icon}`}
                      viewBox="0 0 17 17"
                    >
                      <path d="M8 6.8L14.4 0.4C14.8 0 15.3 0 15.7 0.4 16 0.7 16 1.3 15.7 1.6L9.3 8 15.7 14.4C16 14.7 16 15.3 15.7 15.6 15.3 16 14.8 16 14.4 15.6L8 9.2 1.6 15.6C1.3 16 0.7 16 0.4 15.6 0 15.3 0 14.7 0.4 14.4L6.8 8 0.4 1.6C0 1.3 0 0.7 0.4 0.4 0.7 0 1.3 0 1.6 0.4L8 6.8Z"></path>
                    </svg>
                  </button>
                  <div className={overlayDefaultStyles.content}>
                    <div className={bioStyles.container}>
                      <div className={bioStyles.userImage}>
                        <img
                          src={member.imgPath}
                          width="120"
                          height="120"
                          alt="Photo of Emma Maurer Vice President of Business Development and Partnerships"
                        />
                      </div>
                      <h3 id="bio-title" className={bioStyles.personName}>
                        {member.name}
                      </h3>
                      <h4 className={bioStyles.personTitle}>
                        {member.subtitle}
                      </h4>
                      <p>{member.description}</p>
                    </div>
                  </div>
                </div>
                <div
                  data-focus-guard="true"
                  tabIndex="0"
                  style={{
                    width: '1px',
                    height: '0px',
                    padding: '0px',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: '1px',
                    left: '1px',
                  }}
                ></div>
              </div>
            </div>
          </React.Fragment>,
          document.body
        )
      : null;
  }
);
