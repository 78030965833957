import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import studioApi from '../api/studio';

export const StudioContext = createContext();

const StudioContextProvider = (props) => {

    const [filters, setFilters] = useState([]);
    const [categoryFilters, setCategoryFilters] = useState([]);

    const [page, setPage] = useState(1);

    const [studios, setStudios] = useState([{ id: 1 }]);
    const [loading, setLoading] = useState(true);
    const [pageInfo, setPageInfo] = useState({ page: 0, totalPages: 0 });

    useEffect(() => {
        console.log("calling getStudios()", filters, categoryFilters, page);
        getStudios();
    }, [filters, categoryFilters, page])

    const getStudios = async () => {

        setLoading(true);
        const tagsParam = filters?.join(",");
        const categoriesParam = categoryFilters?.join(",");
        var p = {
            categories: categoriesParam,
            tags: tagsParam,
            has_digital: true,
            page: page,
        }

        // console.log("BANANANANANANANANANA", filters, p)
        await studioApi.getStudioList(p)
            .then(res => {
                setStudios(res.results);
                setPageInfo({
                    page: page,
                    totalPages: (Math.ceil(res.count / 100))
                });
                console.log(res.results);
                console.log(`${pageInfo.page} out of ${pageInfo.totalPages} pages.`);
            })
            .catch(error => {
                console.log("error: ", error);
            })
            .finally(() => {
                setLoading(false);
            })

        return;
    }

    return (
        <StudioContext.Provider value={{
            setFilters,
            setPage,
            studios,
            getStudios,
            loading,
            pageInfo,
            setCategoryFilters
        }}>
            {props.children}
        </StudioContext.Provider>
    );
}
export default StudioContextProvider;