import { getDate } from 'date-fns';
import moment from 'moment-timezone';

export const AddDays = (date, days) => {
  const copy = new Date(date);
  copy.setDate(date.getDate() + days);
  return copy;
};

// Monday is start
export const GetStartOfWeek_Monday = (date) => {
  var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  var d = new Date(date.setDate(diff));
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

// Sunday is start
export const GetStartOfWeek_Sunday = (date) => {
  var diff = date.getDate() - date.getDay();
  var d = new Date(date.setDate(diff));
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export const GetUTCDate = () => {
  var date = new Date();
  var utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
  return utcDate;
};

export const GetDate = (dateString) => {
  // console.log("GETDATE:", dateString);
  return new Date(dateString + '+00:00');
};

export const convertTZ = (dateString, timezoneId) => {
  if (timezoneId == null) return getDate(); // if no timezone, default to local timezone.
  return new Date(
    (typeof dateString === 'string'
      ? new Date(dateString)
      : dateString
    ).toLocaleString('en-US', { timeZone: timezoneId })
  );
};

export const HoursUntil = (dateString) => {
  var refDate = new Date();
  var date = GetDate(dateString);
  var hoursUntil = (date - refDate) / 36e5;
  // console.log("TIME_DIFF_REF:", refDate);
  // console.log("TIME_DIFF_DATE:", date);
  // console.log("TIME_DIFF_HOURS:", hoursUntil);
  return hoursUntil;
};

// format doc: https://momentjs.com/docs/
export const ToTimezone = (date, tzString, formatTemplate) => {
  const source = moment.utc(date).format();
  const dest = moment.tz(source, tzString);

  if (!tzString) console.log('**No timezone specified.');

  // console.log("  ORIG:", date);
  // console.log("SOURCE:", source);
  // console.log("  DEST:", dest.format(), tzString);
  // console.log("FORMAT:", dest.format(formatTemplate), formatTemplate);
  // console.log("-------------------------------------------");

  return dest.format(formatTemplate);
};
