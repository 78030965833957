import React from 'react';
import { Link } from 'react-router-dom';
import { PRIVACY_POLICY } from '../navigation/CONSTANTS';
import pageStyles from '../styles/terms-of-use/Page.module.css';
import utilities from '../styles/utilities.module.css';
import { useSite } from '../contexts/SiteContext/SiteContext';

export default function TermsOfService() {
  const { isMoveSite, isCoreSite } = useSite();


  return (
    <>
      {isCoreSite && (
        <div
          className={`${pageStyles.page} ${utilities.page}`}
          style={{ height: 'auto', position: 'relative', minHeight: '216px' }}
        >
          <div className={pageStyles.container}>
            <div>
              <p>Last modified June 14, 2021. Effective June 14, 2021.</p>
              <h2 data-cy="terms-of-use-header">Peerfit Terms Of Use</h2>
              <p>
                Peerfit is a universal account for wellness. Through our
                platform and mobile application, you gain easy access to a
                variety of services and experiences, empowering you to
                personalize your journey to wellness, so you can lead a more
                active and healthier lifestyle. Peerfit, the market leader in
                connecting employers and carriers with flexible fitness
                experiences, has committed to redefine wellness by giving access
                to a variety of personalized wellness and fitness experiences
                all under one account, making wellness more accessible and
                enjoyable.
              </p>
              <p>
                The Peerfit website peerfit.com (the “Site”) and the Peerfit
                mobile application (the “App”) are provided by Peerfit, Inc.
                (“Peerfit”). You may access and use certain portions of the Site
                without registering as a Member, but the App and certain
                functionality of the Site is limited to Members. By downloading
                the App, registering to become a Member or otherwise using the
                Site, you agree to be bound by these Terms of Service (the
                “Agreement”). If you wish to become a Member and communicate
                with other Members and make use of the Peerfit service through
                the Site or the App (the “Service”), you must read these Terms
                of Service and indicate your acceptance of them by following the
                instructions in the Sign Up page. If you do not agree to these
                Terms of Use, you may not use the App, the Site or Service, and
                you should immediately discontinue any further use.
              </p>
              <p>
                Please note that Peerfit is not a health plan, health plan
                provider or a health insurance company. Employers and health
                plans do offer Peerfit Services to their employees and members.
                Should you have any questions about your benefits including
                health benefits or other employer offered benefits, please
                direct your questions to your employer or your health plan
                provider.{' '}
              </p>
              <p>
                This Agreement, including Peerfit’s{' '}
                <Link to={PRIVACY_POLICY}>Privacy Policy</Link>, sets out the
                legally binding terms of your use of the App, the Site and the
                Service. This Agreement may be amended by Peerfit from
                time-to-time. In the event of a material alteration to the terms
                of this Agreement, as determined in Peerfit’s good-faith and
                reasonable judgment, Peerfit will provide notice to you and your
                acknowledgement and continued use of the App, the Site or the
                Service after such notice will indicate your agreement to the
                amended terms. The latest Agreement will be posted on the Site,
                and you should review this Agreement prior to using the
                Services. You acknowledge and agree that the specific offerings
                of the Service may change from time-to-time without prior notice
                to you and Peerfit shall have no liability to you for the
                discontinuance of any specific aspect of the Service. Moreover,
                Peerfit may deny, revoke, or otherwise restrict the access
                privileges of you or any Member at any time fails to comply with
                this Agreement.
              </p>
              <ul>
                <li>
                  1. <b>Eligibility</b>. You must be eighteen or over to
                  register as a member of Peerfit or use the Service. The
                  Service is not intended for use by children under eighteen; if
                  you are under eighteen years of age, please do not use the
                  Service or register as a Member. Membership in the Service is
                  void where prohibited.
                </li>
                <li>
                  2. <b>Registration</b>. In order to use the Service, you must
                  register as a member (herein a “Member”) and provide to
                  Peerfit certain basic information about yourself, which may
                  include personal data such as your city, state and zip code,
                  date of birth, gender, first and last name and a valid email
                  address. You may also register with the Service through one of
                  our social networking partners, such as Facebook. If you
                  register through one of these partner websites, your personal
                  information will be provided to Peerfit directly from your
                  user account with such partner websites. By registering as a
                  Member in the Service, you represent and warrant that (a) you
                  have the right, authority, and capacity to enter into this
                  Agreement and to abide by all of the terms and conditions of
                  this Agreement; (b) all registration information and Content
                  (as defined below) you submit is truthful and accurate; (c)
                  you will maintain the accuracy of such information; and (d)
                  your use of the Service does not violate any applicable law or
                  regulation. If any information provided by you is untrue,
                  inaccurate or not current, Peerfit reserves the right to
                  terminate your membership and use of the Service. As part of
                  the registration process, you will be asked to provide your
                  email address, which address will act as your log-in for the
                  Service. Please note that Peerfit may refuse use of any email,
                  username or password for any reason in its sole discretion,
                  including without limitation, if such email, user name or
                  password contains illegal, obscene or offensive language or is
                  protected by trademark or other intellectual property rights
                  or may cause confusion. You are solely responsible for the
                  activity that occurs through the use of your email and account
                  with Peerfit. You must notify us immediately of any breach of
                  security or unauthorized use of your account or use of your
                  password.
                </li>
                <li>
                  3. <b>Term</b>. This Agreement will remain in full force and
                  effect while you use the App, or the Site, and are a Member.
                  You may terminate your membership at any time, for any reason
                  by following the instructions for Deleting Account on the Edit
                  Profile page. Peerfit may terminate your membership and
                  suspend or terminate your use of the Service for any reason,
                  at any time, including, without limitation, if Peerfit
                  believes that you are under thirteen years of age or you are
                  in breach of any representation, warranty or covenant
                  contained herein or in order to protect the App, the Site or
                  other users from harm.
                </li>
                <li>
                  4. <b>Payment</b>. For Peerfit and Peerfit members, you may
                  purchase additional credits outside of your monthly allotted
                  credits. Certain Services may also be made available through
                  the Site or App for a fee. All pricing information and payment
                  terms will be communicated to you through the Site or App.
                  Peerfit reserves the right to institute, or modify, applicable
                  fees, including registration, membership or other fees at any
                  time at its sole discretion, either for continued use of the
                  Service or for any specific functionality. If Peerfit
                  institutes any such fees, Your access to the Service or the
                  specific functionality may be terminated or restricted if You
                  do not choose to pay such registration or other fees. Peerfit
                  is not a bank, money services business, or a payment
                  processor. All payments for use of the Services are processed
                  through a third-party payment services provider. Your
                  relationship with Peerfit is separate from your relationship
                  with the third-party payment services provider and the payment
                  of any funds shall be governed by the terms of services of the
                  third-party payment services provider.
                  <ul>
                    <li>
                      a. <b>Third-Party Provided Plan</b>. Enterprise,
                      employer-provided, and/or Insurance wellness plan members
                      may be subject to different payment terms, or other terms
                      outside of this agreement. Refer to your benefits
                      administrator or insurance wellness program documents.
                    </li>
                    <li>
                      b. <b>Individual Subscription Plan</b>. The Peerfit
                      Individual Subscription Plan and Monthly Membership Fee
                      begin at the moment of purchase and automatically renew
                      each month on the monthly anniversary date of your initial
                      purchase. The Peerfit Individual Subscription Plan gives
                      you access to the studios, gyms, and experiences within
                      the Peerfit network and the ability to purchase credits
                      that you can use to reserve classes. The number of classes
                      reservable within the subscription time period is
                      dependent on the number of credits purchased at the time
                      of reservation. Once a class is reserved, payment may be
                      required for the reservation and your credit card or
                      method of payment may be charged, or a hold placed on
                      funds within the account at the time of booking.
                      Reservations may be cancelled up to the time of the class,
                      however, any cancellation less than 12 hours before the
                      class will result in the user being charged for the
                      reservation.
                    </li>
                    <li>
                      c. <b>Memberships</b>. The cost (credits used) of gym or
                      studio membership and/or digital streaming fitness
                      subscriptions is not refundable. You can change, update,
                      or cancel your auto-renewal membership plan in the
                      settings section of your account, and can cancel at any
                      time. From the time of cancellation, your membership to
                      the applicable gym or studio will remain active until your
                      next renewal date. Classes can be taken, or reserved after
                      a cancel request, as long as it falls before the renewal
                      date of the following month. We may revoke or modify this
                      offer at any time. Peerfit reserves the right to cancel
                      any ineligible memberships, digital streaming fitness
                      subscriptions or accounts being used outside of the
                      intended design. For additional terms, see our{' '}
                      <Link to={PRIVACY_POLICY}>Privacy Policy</Link>, and for
                      more information, visit our FAQs. Other restrictions may
                      apply.
                    </li>
                    <li>
                      d. <b>Reservation Cancellation</b>. Reservations may be
                      cancelled up to twelve (12) hours before the start of the
                      class. Reservations cancelled within twelve (12) hours of
                      the start of the class will be considered a late cancel
                      and result in the forfeiture of the credits used to
                      reserve that class. In some circumstances depending on the
                      individual gym or studio’s policy, you will be unable to
                      cancel a reservation and you will not be refunded nor
                      receive any credits back. Peerfit will use commercially
                      reasonable attempts to make you aware of such
                      circumstances. For clarity, Peerfit will not refund any
                      credits or any additional credits purchased as a result of
                      a late cancel.
                    </li>
                  </ul>
                </li>
                <li>
                  5. <b>Use of the Site and Service</b>. In accessing the
                  Services, you agree that: (i) if you register as a Facility or
                  Instructor, you may use the Service solely for your internal
                  business purposes as permitted on the Service; or (ii) if you
                  register as an individual Member or a Group, you may use the
                  Service solely for your own personal non-commercial use. Use
                  of the Services, materials, or information provided through
                  the Services for any other purposes is prohibited without
                  Peerfit’s prior written consent. Illegal and/or unauthorized
                  uses of the App, the Site or the Services, including
                  collecting usernames and/or email addresses of members by
                  electronic or other means for the purpose of sending
                  unsolicited email and unauthorized framing of or linking will
                  be investigated, and appropriate legal action will be taken,
                  including without limitation, civil, criminal, and injunctive
                  redress.
                  <ul>
                    <li>
                      a. You acknowledge and agree that the Service, the Site,
                      and the App may include advertisements, which
                      advertisements may be targeted based on your personal
                      preferences and activities as provided to the Service.
                      Peerfit will not, however, provide your name or personally
                      identifiable information to any such advertiser without
                      your permission (See Privacy.) You agree that such
                      advertisements are a necessary part of the Service.
                    </li>
                    <li>
                      b. You are expressly prohibited from copying, modifying,
                      distributing, transmitting, displaying, reproducing,
                      publishing, licensing, creating derivative works from,
                      linking to or framing on another website, using on any
                      other website, or transferring or selling the Site or its
                      content without Peerfit’s prior written permission. This
                      prohibition expressly includes, but is not limited to, the
                      practices of “web scraping”, “screen scraping” and “data
                      mining.” Furthermore, You are prohibited from using the
                      content for any public or commercial purpose. You may not
                      reverse engineer, disassemble, decompile, or otherwise
                      attempt to derive source code from the Site or its
                      content.
                    </li>
                  </ul>
                </li>
                <li>
                  6.{' '}
                  <b>
                    Intellectual Property Rights in the Service, the Site, and
                    the App.
                  </b>
                  Except for the limited rights of access granted herein,
                  Peerfit owns and retains all rights, title and interest in the
                  App, the Site and the Services, including without limitation,
                  all content, graphics, video, sound, logos, software,
                  trademarks, service marks and other material therein (the
                  “Content”) and all intellectual property rights associated
                  with the App, the Site, the Services and Content. Except for
                  that information or content which is in the public domain or
                  for which you have been given written permission, you may not
                  copy, modify, publish, republish, mirror, frame, transmit,
                  distribute, perform, display, sell, or otherwise make
                  available to third-parties the App, the Site, the Service or
                  any Content. Except as expressly set forth herein, your use of
                  the App, the Site or Service does not create any license or
                  any other rights in Peerfit's or any third-party's copyrights,
                  trademarks or any other intellectual property or proprietary
                  rights. All product names, logos, trademarks and service marks
                  appearing on the App or the Site are the property of Peerfit
                  or other third-parties. Nothing in these terms of use shall be
                  construed to grant you any right or license in any of these
                  names, logos, trademarks or service marks without the prior
                  written consent of the owner. All rights in and to the Service
                  and the Site not expressly granted herein are reserved by
                  Peerfit.
                </li>
                <li>
                  7. <b>User Content</b>.
                  <ul type="disc">
                    <li>
                      a. You may provide certain personal information, including
                      personal images, and other material or content to Peerfit
                      through and for use on the Service (“User Content”) and
                      create your own profile page. You may not post, transmit,
                      upload or publish any User Content that you did not create
                      or have permission to post. You also consent to the use of
                      your image(s) if included in the User Content and
                      represent that you have obtained the consent of every
                      individual who may appear in your User Content. Please
                      note that even if you restrict access to your profile page
                      or make your profile page a private page, your name will
                      appear as a Member of Peerfit.
                    </li>
                    <li>
                      b. You understand and agree that Peerfit, may review and
                      delete any User Content that in the sole judgment of
                      Peerfit, violates this Agreement or which might be
                      offensive, illegal, or that might violate the rights,
                      harm, or threaten the safety of Members.
                    </li>
                    <li>
                      c. YYou are solely responsible for the User Content that
                      you upload, publish or display (hereinafter, "post") on
                      the Service, or transmit to other Members.
                    </li>
                    <li>
                      d. You will retain ownership rights in your User Content.
                      However, by posting User Content to the Service, you
                      automatically grant, and you represent and warrant that
                      you have the right to grant, to Peerfit, an irrevocable,
                      perpetual, non-exclusive, fully paid, worldwide license in
                      perpetuity, to use, copy, perform, display, and distribute
                      such information and content and to prepare derivative
                      works of, or incorporate into other works, such
                      information and content, and to grant and authorize
                      sublicenses of the foregoing as necessary for the
                      provision of the Service and Peerfit’s related business
                      purposes.
                    </li>
                    <li>
                      e. You are solely responsible for your use of the Service.
                      Peerfit shall have no obligation to monitor User Content,
                      but reserves the right to do so and to investigate and
                      take appropriate action, including legal action, in its
                      sole discretion, including without limitation, removing
                      the offending communication from the App, the Site or the
                      Service and terminating the membership of such violators.
                      You agree that you will not provide User Content or
                      otherwise use the Services in such a way that:
                      <ul type="circle">
                        <li>
                          i. involves the transmission of "junk mail", "chain
                          letters", or unsolicited mass mailing or "spamming";
                        </li>
                        <li>
                          ii. promotes information that you know is false or
                          misleading
                        </li>
                        <li>iii. promotes illegal activities or conduct </li>
                        <li>
                          iv. constitutes stalking of any other user or is
                          bullying, abusive, threatening, obscene, pornographic,
                          defamatory or libelous or violates the rights of any
                          third-party, including a party’s right of privacy or
                          publicity;
                        </li>
                        <li>
                          v. provides instructional information about illegal
                          activities such as making or buying illegal weapons or
                          providing or creating computer viruses; and
                        </li>
                        <li>
                          vi. solicits or collects passwords or personal
                          identifying information for commercial or unlawful
                          purposes from other users.
                        </li>
                        <li>
                          vii. interfere with, or attempt to interfere with, the
                          normal operations of the App, the Site, the Services
                          or any other user’s use of the same, including by
                          deleting, altering or blocking another user’s
                          postings, deliberately providing false information,
                          overloading, flooding, spamming or crashing the
                          Services or its underlying systems or by altering any
                          profile or other information provided by any user;
                        </li>
                        <li>
                          viii. post, share, link to or submit any content or
                          material that is the protected health information of
                          another as that term is defined by the Health
                          Insurance Portability and Accountability Act;
                        </li>
                        <li>
                          ix. use the Services for illegal file sharing or post,
                          upload, distribute or share any User Content or other
                          content that infringes the intellectual or other
                          proprietary rights of any third-party.
                        </li>
                      </ul>
                    </li>
                    <li>
                      f. Even though all of the above is strictly prohibited,
                      there is a chance that you might become exposed to other
                      User Content that you find objectionable or offensive and
                      you further waive your right to any damages (from any
                      party) related to such exposure.
                    </li>
                    <li>
                      g. You must use the Service in a manner consistent with
                      any and all applicable laws and regulations.
                    </li>
                  </ul>
                </li>
                <li>
                  8. <b>Member Disputes</b>. You are solely responsible for your
                  interactions with other Peerfit Members. Peerfit reserves the
                  right, but has no obligation, to monitor disputes between you
                  and other Members.
                </li>
                <li>
                  9. <b>System Requirements</b>
                  <ul>
                    <li>
                      a. Peerfit supports these modern browsers for desktop:
                      <ul>
                        <li>i. Chrome - Latest stable release</li>
                        <li>ii. Safari - Latest stable release</li>
                        <li>iii. Firefox - Latest stable release</li>
                        <li>iv. Edge - Latest stable release</li>
                      </ul>
                    </li>
                    <li>
                      b. The Peerfit app is available for these mobile
                      platforms:
                      <ul>
                        <li>i. iOS - Version 10 or higher</li>
                        <li>
                          ii. Android - Version 5.0 or higher with Google Play
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  10. <b>Privacy</b>. Use of the Site and/or the Service is also
                  governed by our{' '}
                  <Link to={PRIVACY_POLICY}>Privacy Policy</Link>.{' '}
                </li>
                <li>
                  11. <b>Disclaimers</b>.
                  <ul>
                    <li>
                      a. <u>Health and Exercise Disclaimers</u>. We urge you to
                      consult your physician or other health care professional
                      before starting any exercise or fitness program to
                      determine if it is right for your needs. This is
                      particularly true if you (or your family) have a history
                      of high blood pressure or heart disease, or if you have
                      ever experienced chest pain when exercising or have
                      experienced chest pain in the past month when not engaged
                      in physical activity, smoke, have high cholesterol, are
                      obese, or have a bone or joint problem that could be made
                      worse by a change in physical activity. Do not start this
                      or any other fitness program if your physician or health
                      care provider advises against it.
                    </li>
                    <li>
                      b. Please note that we do not review, qualify or warrant
                      any workout program or materials provided on the Service,
                      whether from a Facility, Instructor or other Member. You
                      are solely responsible for your use of any such program or
                      materials. Regardless of the workout program, you should
                      warm up for a few minutes prior to starting any exercise.
                      Always exercise at a level at which you feel comfortable.
                      If you experience discomfort, faintness, dizziness, pain
                      or shortness of breath at any time while exercising you
                      should stop immediately.
                    </li>
                    <li>
                      c. The recommendations, instructions and advice contained
                      on the Site or through the Service are in no way intended
                      to replace or to be construed as medical advice, diagnosis
                      or treatment, and are offered for informational and
                      entertainment purposes only. If you have any concerns or
                      questions about your health, you should always consult
                      with a physician or other health care professional. Do not
                      disregard, avoid or delay obtaining medical or health
                      related advice from your healthcare professional because
                      of any content you may obtain through the Site or Service.
                      The use of any information provided on the Site or through
                      the Service is solely at your own risk.
                    </li>
                    <li>
                      d. You acknowledge that there is a risk of injury
                      associated with any exercise activity. Always use safe
                      techniques and protective equipment when engaging in
                      exercise. If you are unfamiliar with or do not understand
                      the proper techniques and/or use of equipment for the
                      exercises you intend to perform, please seek advice and
                      assistance before performing them. Peerfit is not
                      responsible or liable for any injury that may result from
                      improper use of any workout programs provided on the
                      Service or any exercise program or activity in which you
                      participate arising from the use of the App, the Site or
                      Service. By using the App, the Site and/or Services, you
                      agree to accept all risks associated with any workout
                      programs or materials you obtain or participate in and
                      that you will not hold Peerfit liable for any injury or
                      illness that may occur.
                    </li>
                    <li>
                      e. If you are in the United States and think you are
                      having a medical or health emergency, call your health
                      care professional, or 911, immediately.
                    </li>
                    <li>
                      f. <u>General Disclaimers</u>. THE SITE AND SERVICE MAY
                      CONTAINS INFORMATION, FACTS, OPINIONS, AND RECOMMENDATIONS
                      OF VARIOUS INDIVIDUALS AND ORGANIZATIONS REGARDING GYMS,
                      WORKOUT FACILITIES, INSTRUCTORS, SPORTS, HEALTH, AND
                      FITNESS. PEERFIT AND ITS AFFILIATES, AGENTS AND LICENSORS
                      CANNOT, AND DO NOT, GUARANTEE THE ACCURACY, COMPLETENESS,
                      TIMELINESS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                      PURPOSE OF, OR OTHERWISE ENDORSE, ANY OPINIONS OR
                      RECOMMENDATIONS OR ANY INSTRUCTORS OR FACILITIES THAT MAY
                      PARTICIPATE IN OR BE LISTED ON THE SITE OR SERVICE.
                    </li>
                    <li>
                      g. PEERFIT IS NOT A HEALTH CARE PROVIDER. PEERFIT DOES NOT
                      OFFER ANY HEALTH RELATED SERVICES THROUGH ANY OF OUR
                      SERVICE OFFERINGS NOR DOES PEERFIT PROVIDE MEDICAL ADVICE.
                      NONE OF THE INFORMATION ON THE SITE SHOULD BE MISCONSTRUED
                      AS PROFESSIONAL MEDICAL ADVICE OR SUBSTITUTED FOR
                      PROFESSIONAL MEDICAL ADVICE. SHOULD YOU HAVE ANY QUESTIONS
                      ABOUT YOUR HEALTH OR MEDICAL CONDITION PLEASE CONTACT YOUR
                      HEALTH CARE PROVIDER. IN AN EMERGENCY, YOU SHOULD CONTACT
                      911 OR YOUR LOCAL EMERGENCY ASSISTANCE NUMBER IMMEDIATELY.
                    </li>
                    <li>
                      h. PEERFIT IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER
                      ONLINE OR OFFLINE, OF ANY USER OF THE SITE OR MEMBER OF
                      THE SERVICE. PEERFIT ASSUMES NO RESPONSIBILITY FOR ANY
                      ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN
                      OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE,
                      THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR
                      ALTERATION OF, USER OR MEMBER COMMUNICATIONS. PEERFIT IS
                      NOT RESPONSIBLE FOR ANY PROBLEMS OR TECHNICAL MALFUNCTION
                      OF ANY TELEPHONE NETWORK OR LINES, COMPUTER ONLINE
                      SYSTEMS, SERVERS OR PROVIDERS, COMPUTER EQUIPMENT,
                      SOFTWARE, FAILURE OF EMAIL OR PLAYERS ON ACCOUNT OF
                      TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET
                      OR AT ANY SITE OR COMBINATION THEREOF, INCLUDING INJURY OR
                      DAMAGE TO USERS AND/OR MEMBERS OR TO ANY OTHER PERSON'S
                      COMPUTER RELATED TO OR RESULTING FROM PARTICIPATING OR
                      DOWNLOADING MATERIALS IN CONNECTION WITH THE SITE AND/OR
                      IN CONNECTION WITH THE SERVICE.
                    </li>
                    <li>
                      i. THE SERVICE AND ALL CONTENT AND INFORMATION INCLUDED IN
                      OR AVAILABLE THROUGH THE SITE OR SERVICE ARE PROVIDED "AS
                      IS" FOR YOUR USE WITHOUT WARRANTY OF ANY KIND, EITHER
                      EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY
                      LAW, PEERFIT DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR
                      IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY.
                      PEERFIT CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC
                      RESULTS FROM USE OF THE SITE AND/OR THE SERVICE. PEERFIT
                      DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE
                      USE OF THE MATERIALS AND INFORMATION ON THIS SITE OR ANY
                      WEBSITE LINKED TO THIS SITE. NOR DOES PEERFIT WARRANT THAT
                      THE SITE WILL BE ACCESSIBLE AT ALL TIMES OR IN ALL
                      GEOGRAPHIC AREAS. THE SERVICE MAY BE TEMPORARILY
                      UNAVAILABLE FROM TIME TO TIME FOR MAINTENANCE OR OTHER
                      REASONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                      WRITTEN, OBTAINED BY USER FROM PEERFIT OR THROUGH OR FROM
                      THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED
                      HEREIN. FURTHER, PEERFIT DOES NOT WARRANT THAT THIS SITE
                      OR THE SERVICE ARFE FREE FROM COMPUTER VIRUSES, WORMS OR
                      OTHER HARMFUL EFFECTS. YOUR USE OF THE SITE AND SERVICE IS
                      SOLELY AT YOUR OWN RISK.
                    </li>
                  </ul>
                </li>
                <li>
                  12. <b>Limitation on Liability</b>. EXCEPT IN JURISDICTIONS
                  WHERE SUCH PROVISIONS ARE RESTRICTED, UNDER NO CIRCUMSTANCES
                  SHALL PEERFIT BE LIABLE FOR ANY DIRECT, INDIRECT, ACTUAL,
                  SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
                  INCLUDING, BUT NOT LIMITED TO, DAMAGES ATTRIBUTABLE TO ANY
                  PERSONAL INJURY OR DEATH, LOSS OF DATA, LOSS OF BUSINESS, LOST
                  PROFITS OR LOSS OF USE, ARISING OUT OF THE USE OR INABILITY TO
                  USE THE SERVICES OR THE SITE (OR WEBSITES LINKED TO THE SITE),
                  EVEN IF PEERFIT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                  HEREIN, PEERFIT'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER,
                  AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                  LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO PEERFIT FOR THE
                  SERVICE DURING THE TERM OF MEMBERSHIP.
                </li>
                <li>
                  13.{' '}
                  <b>
                    Facilities and Instructors; Third-Party Services and
                    Websites.
                  </b>
                  Peerfit does not qualify, endorse, investigate or approve any
                  Facilities or Instructors listed on the Service. You are
                  solely responsible for ensuring that any such Facilities and
                  Instructors from whom you purchase materials, such as workout
                  materials, or services are qualified or meet your needs. In no
                  event will Peerfit be responsible or liable for any materials
                  or services provided by such Facilities or Instructors or for
                  their failure to provide any such materials or services. The
                  Site and Service may contain links to third-party websites
                  that are not owned or controlled by Peerfit. Peerfit has no
                  control over, and assumes no responsibility for, the content,
                  privacy policies, products, services or practices of any
                  third-party websites. In addition, Peerfit will not and cannot
                  censor or edit the content of any third-party websites. By
                  using the Service, you expressly relieve Peerfit from any and
                  all liability arising from or in connection with your use of
                  any Facility, Instructor or third-party websites. Facilities
                  may, at any time, alter, amend or cancel their scheduled
                  offerings without notice. As a result, a reserved class may be
                  cancelled without notice to the subscriber. Peerfit accepts no
                  liability for this cancellation beyond crediting your Peerfit
                  account with credits equivalent to the value of the cost of
                  the reservation.
                </li>
                <li>
                  14. <b>Disputes</b>. If there is any dispute about or
                  involving the Site and/or the Service, by using the Site, you
                  agree that the dispute will be governed by the laws of the
                  State of Florida without regard to its conflict of law
                  provisions. You agree to personal jurisdiction by and venue in
                  the state and federal courts of the State of Florida.
                </li>
                <li>
                  15. <b>Indemnity</b>. You agree to indemnify and hold Peerfit,
                  its subsidiaries, affiliates, and their respective officers,
                  agents, representatives and other partners and employees,
                  harmless from any loss, liability, claim, or demand, including
                  reasonable attorney's fees, due to or arising out of your use
                  of the App, the Site or the Services and/or arising from a
                  breach of this Agreement and/or any breach of your
                  representations and warranties set forth above.
                </li>
                <li>
                  16. <b>No Agency</b>. There is no agency, partnership, joint
                  venture, employee-employer or franchisor-franchisee
                  relationship between Peerfit and any User of the Service.
                </li>
                <li>
                  17. <b>Location of Service</b>. The Service and Site is
                  controlled and offered by Peerfit from its facilities in the
                  United States of America. If you reside outside of the United
                  States, please note that U.S. law governs Your use of the App,
                  the Site and the Services and the sharing of information
                  within the App, the Site and Services. Without limiting the
                  generality of the foregoing, Peerfit is based within the
                  United States and does not offer goods or services to
                  individuals in the European Union or monitor behavior of
                  individuals within the European Union.
                </li>
                <li>
                  18. <b>Feedback</b>. You understand and agree that any ideas
                  or suggestions for improvements to the Site or Service,
                  including without limitation, enhancements, new functionality
                  or concepts, shall be owned by Peerfit. Peerfit shall have the
                  right to use any such ideas or suggestions in its sole
                  discretion and with no payment or consideration to You.
                </li>
                <li>
                  19. <b>Assignment</b>. These Terms of Use, and any rights and
                  licenses granted hereunder, may not be transferred or assigned
                  by You, but may be assigned by Peerfit without restriction.
                </li>
                <li>
                  20. <b>Other</b>. This Agreement, including the{' '}
                  <Link to={PRIVACY_POLICY}>Privacy Policy</Link>, accepted upon
                  use of the App, the Site and further affirmed by becoming a
                  Member of the Service, contains the entire agreement between
                  you and Peerfit regarding the use of the Site and/or the
                  Service. If any terms and conditions of this Agreement are not
                  accepted by you in full, you do not have permission to access
                  the contents of the Site or use the Service and therefore
                  should cease using this Site immediately. The failure of
                  Peerfit to exercise or enforce any right or provision of these
                  Terms of Use shall not constitute a waiver of such right or
                  provision. If any provision of this Agreement is held invalid,
                  the remainder of this Agreement shall continue in full force
                  and effect.
                </li>
              </ul>
              <p>
                Please contact us with any questions regarding these Terms of
                Use at <a href="mailto:info@peerfit.com">info@peerfit.com</a>;
                or 1060 Woodcock Rd Ste 128 #96128, Orlando, FL 32803-3607
              </p>
            </div>
          </div>
        </div>
      )}
      {isMoveSite && (
        <div
          className={`${pageStyles.page} ${utilities.page}`}
          style={{ height: 'auto', position: 'relative', minHeight: '216px' }}
        >
          <div className={pageStyles.container}>
            <div>
              <p>Last modified June 14, 2021. Effective June 14, 2021.</p>
              <h2 data-cy="terms-of-use-header">Peerfit Move Terms Of Use</h2>
              <p>
                Peerfit is a universal account for wellness. Through our
                platform and mobile application, you gain easy access to a
                variety of services and experiences, empowering you to
                personalize your journey to wellness, so you can lead a more
                active and healthier lifestyle. Peerfit, the market leader in
                connecting employers and carriers with flexible fitness
                experiences, has committed to redefine wellness by giving access
                to a variety of personalized wellness and fitness experiences
                all under one account, making wellness more accessible and
                enjoyable.
              </p>
              <p>
                The Peerfit Move website peerfitmove.com, (the “Site”) and the
                Peerfit Move mobile application (the “App”) are provided by
                Peerfit, Inc. (“Peerfit”). You may access and use certain
                portions of the Site without registering as a Member, but the
                App and certain functionality of the Site is limited to Members.
                By downloading the App, registering to become a Member or
                otherwise using the Site, you agree to be bound by these Terms
                of Service (the “Agreement”). If you wish to become a Member and
                communicate with other Members and make use of the Peerfit
                service directly through the Site or the App or indirectly,
                through our phone center (the “Service”), you must read these
                Terms of Use and your continued use of the Site, the App, or
                Service will constitute acceptance of these Terms of Use. If you
                do not agree to these Terms of Use, you may not use the App, the
                Site or Service, and you should immediately discontinue any
                further use.
              </p>
              <p>
                Please note that Peerfit is not a health plan, health plan
                provider or a health insurance company. Employers and health
                plans do offer Peerfit Services to their employees and members.
                Should you have any questions about your benefits including
                health benefits or other employer offered benefits, please
                direct your questions to your employer or your health plan
                provider.{' '}
              </p>
              <p>
                This Agreement, including Peerfit’s{' '}
                <Link to={PRIVACY_POLICY}>Privacy Policy</Link>, sets out the
                legally binding terms of your use of the App, the Site and the
                Service. This Agreement may be amended by Peerfit from
                time-to-time. In the event of a material alteration to the terms
                of this Agreement, as determined in Peerfit’s good-faith and
                reasonable judgment, Peerfit will provide notice to you and your
                acknowledgement and continued use of the App, the Site or the
                Service after such notice will indicate your agreement to the
                amended terms. The latest Agreement will be posted on the Site,
                and you should review this Agreement prior to using the
                Services. You acknowledge and agree that the specific offerings
                of the Service may change from time-to-time without prior notice
                to you and Peerfit shall have no liability to you for the
                discontinuance of any specific aspect of the Service. Moreover,
                Peerfit may deny, revoke, or otherwise restrict the access
                privileges of you or any Member at any time fails to comply with
                this Agreement.
              </p>
              <ul>
                <li>
                  1. <b>Eligibility</b>. Members are eligible for Peerfit Move
                  through their participation in their health plan. Members who
                  leave their health plan for any reason, will also lose their
                  eligibility for Peerfit Move. Members who lose eligibility for
                  Peerfit Move through loss of an affiliated health plan, may
                  enroll in an Individual Subscription Plan. Pricing, payment
                  and the Individual Subscription Terms of Service found on
                  Peerfit.com will apply. You must be eighteen or over to
                  register as a member of Peerfit or use the Service. The
                  Service is not intended for use by children under eighteen; if
                  you are under eighteen years of age, please do not use the
                  Service or register as a Member. Membership in the Service is
                  void where prohibited.
                </li>
                <li>
                  2. <b>Registration</b>. In order to use the Service, you must
                  register as a member (herein a “Member”) and provide to
                  Peerfit certain basic information about yourself, which may
                  include personal data such as your city, state and zip code,
                  date of birth, gender, first and last name and a valid email
                  address. You may also register with the Service through one of
                  our social networking partners, such as Facebook. If you
                  register through one of these partner websites, your personal
                  information will be provided to Peerfit directly from your
                  user account with such partner websites. By registering as a
                  Member in the Service, you represent and warrant that (a) you
                  have the right, authority, and capacity to enter into this
                  Agreement and to abide by all of the terms and conditions of
                  this Agreement; (b) all registration information and Content
                  (as defined below) you submit is truthful and accurate; (c)
                  you will maintain the accuracy of such information; and (d)
                  your use of the Service does not violate any applicable law or
                  regulation. If any information provided by you is untrue,
                  inaccurate or not current, Peerfit reserves the right to
                  terminate your membership and use of the Service. As part of
                  the registration process, you will be asked to provide your
                  email address, which address will act as your log-in for the
                  Service. Please note that Peerfit may refuse use of any email,
                  username or password for any reason in its sole discretion,
                  including without limitation, if such email, user name or
                  password contains illegal, obscene or offensive language or is
                  protected by trademark or other intellectual property rights
                  or may cause confusion. You are solely responsible for the
                  activity that occurs through the use of your email and account
                  with Peerfit. You must notify us immediately of any breach of
                  security or unauthorized use of your account or use of your
                  password.
                </li>
                <li>
                  3. <b>Term</b>. This Agreement will remain in full force and
                  effect while you use the App, or the Site, and are a Member.
                  You may terminate your membership at any time, for any reason
                  by following the instructions for Deleting Account on the Edit
                  Profile page. Peerfit may terminate your membership and
                  suspend or terminate your use of the Service for any reason,
                  at any time, including, without limitation, if Peerfit
                  believes that you are under eighteen years of age or you are
                  in breach of any representation, warranty or covenant
                  contained herein or in order to protect the App, the Site or
                  other users from harm.
                </li>
                <li>
                  4.{' '}
                  <b>Payment for Purchase of Additional Credits or Services</b>.
                  Peerfit Move members may purchase additional credits outside
                  of your health plan provided, monthly allotted credits.
                  Certain additional Services may also be made available through
                  the Site, App, or via phone for a fee which are in addition to
                  the benefit provided by health plan. All pricing information
                  and payment terms for these additional credits or additional
                  Services will be communicated to you through the Site, App, or
                  phone. Peerfit reserves the right to institute, or modify,
                  applicable fees associated with the purchase of additional
                  credits or Services, at any time at its sole discretion. If
                  Peerfit institutes any such fees, Your access to the
                  additional Services or the specific functionality may be
                  terminated or restricted if You do not choose to pay such
                  fees. For clarity, Peerfit will not hold you liable for any
                  fees that are the legal obligation of your health plan .
                  Peerfit is not a bank, a money services business, or a payment
                  processor. All payments for use of the Services are processed
                  through a third-party payment services provider. Your
                  relationship with Peerfit is separate from your relationship
                  with the third-party payment services provider and the payment
                  of any funds shall be governed by the terms of services of the
                  third-party payment services provider.
                  <ul>
                    

                    <li>
                      a. <b>Memberships</b>. The cost (credits used) of gym or
                      studio membership and/or digital streaming fitness
                      subscriptions is not refundable. You can change, update,
                      or cancel your auto-renewal membership plan in the
                      settings section of your account, and can cancel at any
                      time. From the time of cancellation, your membership to
                      the applicable gym or studio will remain active until your
                      next renewal date. Classes can be taken, or reserved after
                      a cancel request, as long as it falls before the renewal
                      date of the following month. We may revoke or modify this
                      offer at any time. Peerfit reserves the right to cancel
                      any ineligible memberships, digital streaming fitness
                      subscriptions or accounts being used outside of the
                      intended design. For additional terms, see our{' '}
                      <Link to={PRIVACY_POLICY}>Privacy Policy</Link>, and for
                      more information, visit our FAQs. Other restrictions may
                      apply.
                    </li>
                    <li>
                      b. <b>Reservation Cancellation</b>. Reservations may be
                      cancelled up to twelve (12) hours before the start of the
                      class. Reservations cancelled within twelve (12) hours of
                      the start of the class will be considered a late cancel
                      and result in the forfeiture of the credits used to
                      reserve that class. In some circumstances depending on the
                      individual gym or studio’s policy, you will be unable to
                      cancel a reservation and you will not be refunded nor
                      receive any credits back. Peerfit will use commercially
                      reasonable attempts to make you aware of such
                      circumstances. For clarity, Peerfit will not refund any
                      credits or any additional credits purchased as a result of
                      a late cancel.
                    </li>
                  </ul>
                </li>
                <li>
                  5. <b>Use of the Site and Service</b>. In accessing the
                  Services, you agree that: (i) if you register as a Facility or
                  Instructor, you may use the Service solely for your internal
                  business purposes as permitted on the Service; or (ii) if you
                  register as an individual Member or a Group, you may use the
                  Service solely for your own personal non-commercial use. Use
                  of the Services, materials, or information provided through
                  the Services for any other purposes is prohibited without
                  Peerfit’s prior written consent. Illegal and/or unauthorized
                  uses of the App, the Site or the Services, including
                  collecting usernames and/or email addresses of members by
                  electronic or other means for the purpose of sending
                  unsolicited email and unauthorized framing of or linking will
                  be investigated, and appropriate legal action will be taken,
                  including without limitation, civil, criminal, and injunctive
                  redress.
                  <ul>
                    <li>
                      a. You acknowledge and agree that the Service, the Site,
                      and the App may include advertisements, which
                      advertisements may be targeted based on your personal
                      preferences and activities as provided to the Service.
                      Peerfit will not, however, provide your name or personally
                      identifiable information to any such advertiser without
                      your permission (See our Privacy Policy.) You agree that
                      such advertisements are a necessary part of the Service.
                    </li>
                    <li>
                      b. You are expressly prohibited from copying, modifying,
                      distributing, transmitting, displaying, reproducing,
                      publishing, licensing, creating derivative works from,
                      linking to or framing on another website, using on any
                      other website, or transferring or selling the Site or its
                      content without Peerfit’s prior written permission. This
                      prohibition expressly includes, but is not limited to, the
                      practices of “web scraping”, “screen scraping” and “data
                      mining.” Furthermore, You are prohibited from using the
                      content for any public or commercial purpose. You may not
                      reverse engineer, disassemble, decompile, or otherwise
                      attempt to derive source code from the Site or its
                      content.
                    </li>
                  </ul>
                </li>
                <li>
                  6. <b>Intellectual Property Rights</b>{' '}
                  <ul type="disc">
                    <li>
                      a. Except for the limited rights of access granted herein,
                      Peerfit owns and retains all rights, title and interest in
                      the App, the Site and the Services, including without
                      limitation, all content, graphics, video, sound, logos,
                      software, trademarks, service marks and other material
                      therein (the “Content”) and all intellectual property
                      rights associated with the App, the Site, the Services and
                      Content. Except for that information or content which is
                      in the public domain or for which you have been given
                      written permission, you may not copy, modify, publish,
                      republish, mirror, frame, transmit, distribute, perform,
                      display, sell, or otherwise make available to
                      third-parties the App, the Site, the Service or any
                      Content. Except as expressly set forth herein, your use of
                      the App, the Site or Service does not create any license
                      or any other rights in Peerfit's or any third-party's
                      copyrights, trademarks or any other intellectual property
                      or proprietary rights. All product names, logos,
                      trademarks and service marks appearing on the App or the
                      Site are the property of Peerfit or other third-parties.
                      Nothing in these terms of use shall be construed to grant
                      you any right or license in any of these names, logos,
                      trademarks or service marks without the prior written
                      consent of the owner. All rights in and to the Service and
                      the Site not expressly granted herein are reserved by
                      Peerfit.
                    </li>
                    <li>
                      b. Peerfit or its third party vendors own or have licensed
                      the copyright and other intellectual property rights in
                      and to certain video materials, including DVDs, that may
                      be provided to, or purchased by, you in connection with
                      your use of the Service, such as the Peerfit FitKit. These
                      materials are intended only for the private and domestic
                      use of you as a Peerfit Move Member. Any other use of the
                      whole or any part of the material (including adapting,
                      copying, issuing copies, renting, lending, performing,
                      broadcasting, or making the same available to or via the
                      internet or authorizing any of the foregoing) is strictly
                      prohibited.
                    </li>
                  </ul>
                </li>
                <li>
                  7. <b>User Content</b>.
                  <ul type="disc">
                    <li>
                      a. You may provide certain personal information, including
                      personal images, and other material or content to Peerfit
                      through and for use on the Service (“User Content”) and
                      create your own profile page. You may not post, transmit,
                      upload or publish any User Content that you did not create
                      or have permission to post. You also consent to the use of
                      your image(s) if included in the User Content and
                      represent that you have obtained the consent of every
                      individual who may appear in your User Content. Please
                      note that even if you restrict access to your profile page
                      or make your profile page a private page, your name will
                      appear as a Member of Peerfit.
                    </li>
                    <li>
                      b. You understand and agree that Peerfit, may review and
                      delete any User Content that in the sole judgment of
                      Peerfit, violates this Agreement or which might be
                      offensive, illegal, or that might violate the rights,
                      harm, or threaten the safety of Members.
                    </li>
                    <li>
                      c. You are solely responsible for the User Content that
                      you upload, publish or display (hereinafter, "post") on
                      the Service, or transmit to other Members.
                    </li>
                    <li>
                      d. you automatically grant, and you represent and warrant
                      that you have the right to grant, to Peerfit, an
                      irrevocable, perpetual, non-exclusive, fully paid,
                      worldwide license in perpetuity, to use, copy, perform,
                      display, and distribute such information and content and
                      to prepare derivative works of, or incorporate into other
                      works, such information and content, and to grant and
                      authorize sublicenses of the foregoing as necessary for
                      the provision of the Service and Peerfit’s related
                      business purposes.
                    </li>
                    <li>
                      e. You are solely responsible for your use of the Service.
                      Peerfit shall have no obligation to monitor User Content,
                      but reserves the right to do so and to investigate and
                      take appropriate action, including legal action, in its
                      sole discretion, including without limitation, removing
                      the offending communication from the App, the Site or the
                      Service and terminating the membership of such violators.
                      You agree that you will not provide User Content or
                      otherwise use the Services in such a way that:
                      <ul type="circle">
                        <li>
                          i. involves the transmission of "junk mail", "chain
                          letters," or unsolicited mass mailing or "spamming";
                        </li>
                        <li>
                          ii. promotes information that you know is false or
                          misleading
                        </li>
                        <li>iii. promotes illegal activities or conduct</li>
                        <li>
                          iv. Constitutes stalking of any other user or is
                          bullying, abusive, threatening, obscene, pornographic,
                          defamatory or libelous or violates the rights of any
                          third-party, including a party’s right of privacy or
                          publicity;
                        </li>
                        <li>
                          v. provides instructional information about illegal
                          activities such as making or buying illegal weapons or
                          providing or creating computer viruses; and
                        </li>
                        <li>
                          vi. solicits or collects passwords or personal
                          identifying information for commercial or unlawful
                          purposes from other users.
                        </li>
                        <li>
                          vii. interfere with, or attempt to interfere with, the
                          normal operations of the App, the Site, the Services
                          or any other user’s use of the same, including by
                          deleting, altering or blocking another user’s
                          postings, deliberately providing false information,
                          overloading, flooding, spamming or crashing the
                          Services or its underlying systems or by altering any
                          profile or other information provided by any user;
                        </li>
                        <li>
                          viii. post, share, link to or submit any content or
                          material that is the protected health information of
                          another as that term is defined by the Health
                          Insurance Portability and Accountability Act;
                        </li>
                        <li>
                          ix. use the Services for illegal file sharing or post,
                          upload, distribute or share any User Content or other
                          content that infringes the intellectual or other
                          proprietary rights of any third-party.
                        </li>
                      </ul>
                    </li>
                    <li>
                      f. Even though all of the above is strictly prohibited,
                      there is a chance that you might become exposed to other
                      User Content that you find objectionable or offensive and
                      you further waive your right to any damages (from any
                      party) related to such exposure.
                    </li>
                    <li>
                      g. You must use the Service in a manner consistent with
                      any and all applicable laws and regulations.
                    </li>
                  </ul>
                </li>
                <li>
                  8. <b>Member Disputes</b>. You are solely responsible for your
                  interactions with other Peerfit Members. Peerfit reserves the
                  right, but has no obligation, to monitor disputes between you
                  and other Members.
                </li>
                <li>
                  9. <b>System Requirements</b>
                  <ul>
                    <li>
                      a. Peerfit supports these modern browsers for desktop:
                      <ul>
                        <li>i. Chrome - Latest stable release</li>
                        <li>ii. Safari - Latest stable release</li>
                        <li>iii. Firefox - Latest stable release</li>
                        <li>iv. Edge - Latest stable release</li>
                      </ul>
                    </li>
                    <li>
                      b. The Peerfit app is available for these mobile
                      platforms:
                      <ul>
                        <li>i. iOS - Version 10 or higher</li>
                        <li>
                          ii. Android - Version 5.0 or higher with Google Play
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  10. <b>Privacy</b>. Use of the Site and/or the Service is also
                  governed by our{' '}
                  <Link to={PRIVACY_POLICY}>Privacy Policy</Link>.{' '}
                </li>
                <li>
                  11. <b>Disclaimers</b>.
                  <ul>
                    <li>
                      a. <u>Health and Exercise Disclaimers</u>. We urge you to
                      consult your physician or other health care professional
                      before starting any exercise or fitness program to
                      determine if it is right for your needs. This is
                      particularly true if you (or your family) have a history
                      of high blood pressure or heart disease, or if you have
                      ever experienced chest pain when exercising or have
                      experienced chest pain in the past month when not engaged
                      in physical activity, smoke, have high cholesterol, are
                      obese, or have a bone or joint problem that could be made
                      worse by a change in physical activity. Do not start this
                      or any other fitness program if your physician or health
                      care provider advises against it.
                    </li>
                    <li>
                      b. Please note that we do not review, qualify or warrant
                      any workout program or materials provided on the Service,
                      whether from a Facility, Instructor or other Member. You
                      are solely responsible for your use of any such program or
                      materials. Regardless of the workout program, you should
                      warm up for a few minutes prior to starting any exercise.
                      Always exercise at a level at which you feel comfortable.
                      If you experience discomfort, faintness, dizziness, pain
                      or shortness of breath at any time while exercising you
                      should stop immediately.
                    </li>
                    <li>
                      c. The recommendations, instructions and advice contained
                      on the Site or through the Service are in no way intended
                      to replace or to be construed as medical advice, diagnosis
                      or treatment, and are offered for informational and
                      entertainment purposes only. If you have any concerns or
                      questions about your health, you should always consult
                      with a physician or other health care professional. Do not
                      disregard, avoid or delay obtaining medical or health
                      related advice from your healthcare professional because
                      of any content you may obtain through the Site or Service.
                      The use of any information provided on the Site or through
                      the Service is solely at your own risk.
                    </li>
                    <li>
                      d. You acknowledge that there is a risk of injury
                      associated with any exercise activity. Always use safe
                      techniques and protective equipment when engaging in
                      exercise. If you are unfamiliar with or do not understand
                      the proper techniques and/or use of equipment for the
                      exercises you intend to perform, please seek advice and
                      assistance before performing them. Peerfit is not
                      responsible or liable for any injury that may result from
                      improper use of any workout programs provided on the
                      Service or any exercise program or activity in which you
                      participate arising from the use of the App, the Site or
                      Service. By using the App, the Site and/or Services, you
                      agree to accept all risks associated with any workout
                      programs or materials you obtain or participate in and
                      that you will not hold Peerfit liable for any injury or
                      illness that may occur.
                    </li>
                    <li>
                      e. If you are in the United States and think you are
                      having a medical or health emergency, call your health
                      care professional, or 911, immediately.
                    </li>
                    <li>
                      f. <u>General Disclaimers</u>. THE SITE AND SERVICE MAY
                      CONTAINS INFORMATION, FACTS, OPINIONS, AND RECOMMENDATIONS
                      OF VARIOUS INDIVIDUALS AND ORGANIZATIONS REGARDING GYMS,
                      WORKOUT FACILITIES, INSTRUCTORS, SPORTS, HEALTH, AND
                      FITNESS. PEERFIT AND ITS AFFILIATES, AGENTS AND LICENSORS
                      CANNOT, AND DO NOT, GUARANTEE THE ACCURACY, COMPLETENESS,
                      TIMELINESS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                      PURPOSE OF, OR OTHERWISE ENDORSE, ANY OPINIONS OR
                      RECOMMENDATIONS OR ANY INSTRUCTORS OR FACILITIES THAT MAY
                      PARTICIPATE IN OR BE LISTED ON THE SITE OR SERVICE.
                    </li>
                    <li>
                      g. PEERFIT IS NOT A HEALTH CARE PROVIDER. PEERFIT DOES NOT
                      OFFER ANY HEALTH RELATED SERVICES THROUGH ANY OF OUR
                      SERVICE OFFERINGS NOR DOES PEERFIT PROVIDE MEDICAL ADVICE.
                      NONE OF THE INFORMATION ON THE SITE SHOULD BE MISCONSTRUED
                      AS PROFESSIONAL MEDICAL ADVICE OR SUBSTITUTED FOR
                      PROFESSIONAL MEDICAL ADVICE. SHOULD YOU HAVE ANY QUESTIONS
                      ABOUT YOUR HEALTH OR MEDICAL CONDITION PLEASE CONTACT YOUR
                      HEALTH CARE PROVIDER. IN AN EMERGENCY, YOU SHOULD CONTACT
                      911 OR YOUR LOCAL EMERGENCY ASSISTANCE NUMBER IMMEDIATELY.
                    </li>
                    <li>
                      h. PEERFIT IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER
                      ONLINE OR OFFLINE, OF ANY USER OF THE SITE OR MEMBER OF
                      THE SERVICE. PEERFIT ASSUMES NO RESPONSIBILITY FOR ANY
                      ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN
                      OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE,
                      THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR
                      ALTERATION OF, USER OR MEMBER COMMUNICATIONS. PEERFIT IS
                      NOT RESPONSIBLE FOR ANY PROBLEMS OR TECHNICAL MALFUNCTION
                      OF ANY TELEPHONE NETWORK OR LINES, COMPUTER ONLINE
                      SYSTEMS, SERVERS OR PROVIDERS, COMPUTER EQUIPMENT,
                      SOFTWARE, FAILURE OF EMAIL OR PLAYERS ON ACCOUNT OF
                      TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET
                      OR AT ANY SITE OR COMBINATION THEREOF, INCLUDING INJURY OR
                      DAMAGE TO USERS AND/OR MEMBERS OR TO ANY OTHER PERSON'S
                      COMPUTER RELATED TO OR RESULTING FROM PARTICIPATING OR
                      DOWNLOADING MATERIALS IN CONNECTION WITH THE SITE AND/OR
                      IN CONNECTION WITH THE SERVICE.
                    </li>
                    <li>
                      i. THE SERVICE AND ALL CONTENT AND INFORMATION INCLUDED IN
                      OR AVAILABLE THROUGH THE SITE OR SERVICE ARE PROVIDED "AS
                      IS" FOR YOUR USE WITHOUT WARRANTY OF ANY KIND, EITHER
                      EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY
                      LAW, PEERFIT DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR
                      IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY.
                      PEERFIT CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC
                      RESULTS FROM USE OF THE SITE AND/OR THE SERVICE. PEERFIT
                      DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE
                      USE OF THE MATERIALS AND INFORMATION ON THIS SITE OR ANY
                      WEBSITES LINKED TO THIS SITE. NOR DOES PEERFIT WARRANT
                      THAT THE SITE WILL BE ACCESSIBLE AT ALL TIMES OR IN ALL
                      GEOGRAPHIC AREAS. THE SERVICE MAY BE TEMPORARILY
                      UNAVAILABLE FROM TIME TO TIME FOR MAINTENANCE OR OTHER
                      REASONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                      WRITTEN, OBTAINED BY USER FROM PEERFIT OR THROUGH OR FROM
                      THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED
                      HEREIN. FURTHER, PEERFIT DOES NOT WARRANT THAT THIS SITE
                      OR THE SERVICE ARE FREE FROM COMPUTER VIRUSES, WORMS OR
                      OTHER HARMFUL EFFECTS. YOUR USE OF THE SITE AND SERVICE IS
                      SOLELY AT YOUR OWN RISK.
                    </li>
                  </ul>
                </li>
                <li>
                  12. <b>Limitation on Liability</b>. EXCEPT IN JURISDICTIONS
                  WHERE SUCH PROVISIONS ARE RESTRICTED, UNDER NO CIRCUMSTANCES
                  SHALL PEERFIT BE LIABLE FOR ANY DIRECT, INDIRECT, ACTUAL,
                  SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
                  INCLUDING, BUT NOT LIMITED TO, DAMAGES ATTRIBUTABLE TO ANY
                  PERSONAL INJURY OR DEATH, LOSS OF DATA, LOSS OF BUSINESS, LOST
                  PROFITS OR LOSS OF USE, ARISING OUT OF THE USE OR INABILITY TO
                  USE THE SERVICES OR THE SITE (OR WEBSITES LINKED TO THE SITE),
                  EVEN IF PEERFIT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                  HEREIN, PEERFIT'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER,
                  AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                  LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO PEERFIT FOR THE
                  SERVICE DURING THE TERM OF MEMBERSHIP.
                </li>
                <li>
                  13.{' '}
                  <b>
                    Facilities and Instructors; Third-Party Services and
                    Websites
                  </b>
                  . Peerfit does not qualify, endorse, investigate or approve
                  any Facilities or Instructors listed on the Service. You are
                  solely responsible for ensuring that any such Facilities and
                  Instructors from whom you purchase materials, such as workout
                  materials, or services are qualified or meet your needs. In no
                  event will Peerfit be responsible or liable for any materials
                  or services provided by such Facilities or Instructors or for
                  their failure to provide any such materials or services. The
                  Site and Service may contain links to third-party websites
                  that are not owned or controlled by Peerfit. Peerfit has no
                  control over, and assumes no responsibility for, the content,
                  privacy policies, products, services or practices of any
                  third-party websites. In addition, Peerfit will not and cannot
                  censor or edit the content of any third-party websites. By
                  using the Service, you expressly relieve Peerfit from any and
                  all liability arising from or in connection with your use of
                  any Facility, Instructor or third-party websites. Facilities
                  may, at any time, alter, amend or cancel their scheduled
                  offerings without notice. As a result, a reserved class may be
                  cancelled without notice to the subscriber. Peerfit accepts no
                  liability for this cancellation beyond crediting your Peerfit
                  account with credits equivalent to the value of the cost of
                  the reservation.
                </li>
                <li>
                  14. <b>Disputes</b>. If there is any dispute about or
                  involving the Site and/or the Service, by using the Site, you
                  agree that the dispute will be governed by the laws of the
                  State of Florida without regard to its conflict of law
                  provisions. You agree to personal jurisdiction by and venue in
                  the state and federal courts of the State of Florida.
                </li>
                <li>
                  15. <b>Indemnity</b>. You agree to indemnify and hold Peerfit,
                  its subsidiaries, affiliates, and their respective officers,
                  agents, representatives and other partners and employees,
                  harmless from any loss, liability, claim, or demand, including
                  reasonable attorney's fees, due to or arising out of your use
                  of the App, the Site or the Services and/or arising from a
                  breach of this Agreement and/or any breach of your
                  representations and warranties set forth above.
                </li>
                <li>
                  16. <b>No Agency</b>. There is no agency, partnership, joint
                  venture, employee-employer or franchisor-franchisee
                  relationship between Peerfit and any User of the Service.
                </li>
                <li>
                  17. <b>Location of Service</b>. The Service and Site is
                  controlled and offered by Peerfit from its facilities in the
                  United States of America. If you reside outside of the United
                  States, please note that U.S. law governs Your use of the App,
                  the Site and the Services and the sharing of information
                  within the App, the Site and Services. Without limiting the
                  generality of the foregoing, Peerfit is based within the
                  United States and does not offer goods or services to
                  individuals in the European Union or monitor behavior of
                  individuals within the European Union.
                </li>
                <li>
                  18. <b>Feedback</b>. You understand and agree that any ideas
                  or suggestions for improvements to the Site or Service,
                  including without limitation, enhancements, new functionality
                  or concepts, shall be owned by Peerfit. Peerfit shall have the
                  right to use any such ideas or suggestions in its sole
                  discretion and with no payment or consideration to You.
                </li>
                <li>
                  19. <b>Assignment</b>. These Terms of Use, and any rights and
                  licenses granted hereunder, may not be transferred or assigned
                  by You, but may be assigned by Peerfit without restriction.
                </li>
                <li>
                  20. <b>Other</b>. This Agreement, including the{' '}
                  <Link to={PRIVACY_POLICY}>Privacy Policy</Link>, accepted upon
                  use of the App, the Site and further affirmed by becoming a
                  Member of the Service, contains the entire agreement between
                  you and Peerfit regarding the use of the Site and/or the
                  Service. If any terms and conditions of this Agreement are not
                  accepted by you in full, you do not have permission to access
                  the contents of the Site or use the Service and therefore
                  should cease using this Site immediately. The failure of
                  Peerfit to exercise or enforce any right or provision of these
                  Terms of Use shall not constitute a waiver of such right or
                  provision. If any provision of this Agreement is held invalid,
                  the remainder of this Agreement shall continue in full force
                  and effect.
                </li>
              </ul>
              <p>
                Please contact us with any questions regarding these Terms of
                Use at{' '}
                <a href="mailto:info@peerfitmove.com">info@peerfitmove.com</a>;
                or 1060 Woodcock Rd Ste 128 #96128, Orlando, FL 32803-3607
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
