import React from 'react';
import { Link } from 'react-router-dom';
import { getSourceFromPathName, logAmplitudeEvent } from '../api/integration';
import { useAuth } from '../contexts/AuthContext';
import {
  ABOUT_US,
  ACCESSIBILITY,
  CONTACT_US,
  COOKIE_POLICY,
  PRIVACY_POLICY,
  ROOT,
  TERMS_OF_SERVICE,
} from '../navigation/CONSTANTS';
import moveLogo from '../Assets/peerfit-move.png'
//styles

import css from './moveFooter.module.css';

export default function MoveFooter(props) {
  const { authState } = useAuth();
  return (
    <footer id="footer" data-testid="move-footer" className={css.moveFooter}>
      <div className={css.inner}>
        <div className={css.col}>
          <Link className={css['footer-logo']} to={ROOT}>
            <img
              src={moveLogo}
              alt="Peerfit logo"
              className={css['img-fluid']}
            />
          </Link>
        </div>
        <div>
          <div className={css.col}>
            <h3 className={css['section-title']}>
              <span>Company</span>
            </h3>
            <Link
              className={css.link}
              to={ABOUT_US}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>About Us</span>
            </Link>
            <a
              rel="noopener noreferrer"
              className={css.link}
              target="_blank"
              href="https://momentum.peerfitmove.com"
            >
              <span>Blog</span>
            </a>
            <a
              rel="noopener noreferrer"
              className={css.link}
              target="_blank"
              href="https://pulse.peerfit.com/media-room/"
            >
              <span>Press</span>
            </a>
            <a
              rel="noopener noreferrer"
              className={css.link}
              target="_blank"
              href="https://pulse.peerfit.com/hiring/"
            >
              <span>Careers</span>
            </a>
            <div className={css['facebook-container']}>
              <h3 className={css['fb-text']}>
                <span>Connect With Us: </span>
              </h3>
              <div>
                <div className={css['icon-container']}>
                  <a
                    rel="noopener noreferrer"
                    className={css['icon']}
                    href="https://www.facebook.com/peerfitmove"
                    target="_blank"
                    aria-label="Peerfit on Facebook"
                  >
                    <svg
                      className={css['fb-svg']}
                      aria-hidden="true"
                      focusable="false"
                      viewBox="0 0 155.139 155.139"
                    >
                      <path d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184 c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452 v20.341H37.29v27.585h23.814v70.761H89.584z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={css.col}>
          <h3 className={css['section-title']}>
            <span>Discover</span>
          </h3>
          <a
            className={css.link}
            href="https://www.peerfitmove.com/members"
            rel="noopener noreferer"
            target="_blank"
          >
            <span>Members</span>
          </a>
          <a
            className={css.link}
            href="https://www.peerfitmove.com/brokers"
            rel="noopener noreferer"
            target="_blank"
          >
            <span>Brokers</span>
          </a>

          <a
            className={css.link}
            href="https://www.peerfitmove.com/facilities"
            rel="noopener noreferer"
            target="_blank"
          >
            <span>Facilities</span>
          </a>
        </div>

        <div>
          <div className={css.col}>
            <h3 className={css['section-title']}>
              <span>Support</span>
            </h3>

            <Link className={css.link} to={PRIVACY_POLICY}>
              <span>Privacy Policy</span>
            </Link>
            <Link className={css.link} to={COOKIE_POLICY}>
              <span>Cookie Policy</span>
            </Link>
            <Link className={css.link} to={TERMS_OF_SERVICE}>
              <span>Terms</span>
            </Link>
            <Link className={css.link} to={ACCESSIBILITY}>
              <span>Accessibility</span>
            </Link>
            <a
              rel="noopener noreferrer"
              className={css.link}
              target="_blank"
              href="https://www.fitonhealth.com/faq/medicare-members"
              onClick={() => {
                if (authState?.user?.id) {
                  logAmplitudeEvent('Support: FAQ', {
                    Source: getSourceFromPathName(),
                    URL: window.location.href,
                  });
                }
              }}
            >
              <span>FAQ</span>
            </a>
            <a
              rel="noopener noreferrer"
              className={css.link}
              href="https://www.peerfitmove.com/contact-us"
            >
              <span>Contact Us</span>
            </a>
            {/* <Link
              to={`${CONTACT_US}?from_source=${getSourceFromPathName()}`}
              className={css.link}
            >
              <button className={css['contact-us']}>Contact Us</button>
            </Link> */}
          </div>
        </div>
      </div>
      <div className={css['mobile-contact']}>
        {/* <Link
          to={`${CONTACT_US}?from_source=${getSourceFromPathName()}`}
          className={css['fb-text']}
        >
          <button className={css['contact-us-mobile']}>Contact Us</button>
        </Link> */}
        <a
          rel="noopener noreferrer"
          className={css.link}
          href="https://www.peerfitmove.com/contact-us"
        >
          <span>Contact Us</span>
        </a>
        <div className={css['facebook-container-mobile']}>
          <h3 className={css['fb-text']}>
            <span>Connect With Us: </span>
          </h3>
          <div>
            <div className={css['icon-container']}>
              <a
                rel="noopener noreferrer"
                className={css['icon']}
                href="https://facebook.com/peerfit"
                target="_blank"
                aria-label="Peerfit on Facebook"
              >
                <svg
                  className={css['fb-svg']}
                  aria-hidden="true"
                  focusable="false"
                  viewBox="0 0 155.139 155.139"
                >
                  <path d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184 c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452 v20.341H37.29v27.585h23.814v70.761H89.584z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={css.trademark}>
        <p className={css['disclaimer-text']}>
          The Peerfit service provides access to third-party gyms, facilities,
          and workout materials (“Fitness Partners”). Peerfit does not own,
          operate or control any of the Fitness Partners. Use of any Fitness
          Partner is subject to the terms and policies of the Fitness Partner.
          Only you can decide if a Fitness Partner, or any workout materials,
          are right for you. You should always consult with your physician or
          other healthcare providers before you begin any exercise activity or
          program. Peerfit, Peerfit Core, Peerfit Move, and FitKit are the
          trademarks or registered trademarks of Peerfit, Inc.
          <br></br>
          Peerfit, Peerfit Core, Peerfit Move and FitKit are the trademarks or
          registered trademarks of Peerfit, Inc.
        </p>
        <span></span>{' '}
        <div className={css['copyright']}>
          <span>©</span>
          <a
            className={css['copyright-link']}
            href="https://www.peerfit.com"
            rel="noopener noreferer"
            target="_blank"
          >
            Peerfit,Inc.
          </a>
          <span> 2022. All rights reserved. </span>
        </div>
      </div>
    </footer>
  );
}
