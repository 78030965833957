import React, { useEffect, useState } from 'react';
import pageStyles from '../../../styles/user/Page.module.css';
import utilities from '../../../styles/utilities.module.css';
import flexStyles from '../../../styles/Flex.module.css';
import favoritesStyles from '../../../styles/user/Favorites.module.css';

import userApi from '../../../api/user';
import { useAuth } from '../../../contexts/AuthContext';
import { FavoriteCard } from './FavoriteCard';
import {
  getSourceFromParams,
  logAmplitudeEvent,
} from '../../../api/integration';
import MobilePage from './MobilePage';
import { useGoBackAccount } from '../../../utilities/navigation';

function Favorites() {
  // [GET] user/[userId]/studios/
  // - get list of favorite studios.

  // [DELETE] user/[userId]/studios/[studioId]
  // - unfavorite studio
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  const { authState } = useAuth();
  const { user } = authState;

  const getFavorites = async () => {
    try {
      await userApi.getFavorites(user.id).then((res) => {
        console.log('results:', res.results);
        setFavorites(res.results);
      });
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFavorites();
  }, [user]);

  useEffect(() => {
    logAmplitudeEvent('Screen View: Profile - Favorites', {
      Source: getSourceFromParams(),
    });
  }, []);

  const backToAccountPage = useGoBackAccount();
  return (
    <MobilePage title="Favorites" onBack={backToAccountPage} loading={loading}>
      <div className={pageStyles.container}>
        <div
          className={`${favoritesStyles.container} ${utilities['page-container']}`}
        >
          {!favorites?.length ? (
            <div className={favoritesStyles.empty}>
              <svg
                aria-hidden="true"
                focusable="false"
                className={favoritesStyles.heart}
                viewBox="0 0 24 24"
              >
                <path d="M7.71075,4.00001328 C5.32351048,3.99460728 3,5.64026328 3,8.64401328 C3,12.1397633 10.2404741,19.986625 12,19.986625 C13.7595259,19.986625 21,12.1397633 21,8.64401328 C21,5.63576328 18.6772423,4.01294709 16.2945,4.00751328 C13.0057278,4.00001328 12.4403428,6.27840859 12,6.27840859 C11.5596572,6.27840859 11.02268,4.00751328 7.71075,4.00001328 Z"></path>
              </svg>
              <h3>
                <span>
                  Save Studio Locations
                  <br /> For Future Reference
                </span>
              </h3>
              <h4>
                <span>
                  Just click on the heart icon and
                  <br /> it will be saved here
                </span>
              </h4>
            </div>
          ) : (
            <div className={`undefined ${flexStyles.row}`}>
              {favorites?.map((studio, i) => (
                <FavoriteCard key={i} studio={studio} reload={getFavorites} />
              ))}
            </div>
          )}
        </div>
      </div>
    </MobilePage>
  );
}

export { Favorites };
