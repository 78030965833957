import React, { useState, useMemo } from 'react';
import { Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import loginFormStyles from '../styles/login/Form.module.css';
import loginStyles from '../styles/Login.module.css';
import checkboxStyles from '../styles/Checkbox.module.css';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import nextId from 'react-id-generator';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import { DISCOVER, GUEST_USER } from '../navigation/CONSTANTS';
import CheckActiveIcon from '../Assets/check_active.png';
import CheckInActiveIcon from '../Assets/check_inactive.png';
import _ from 'lodash';
import { CustomTextInput } from '../components/CustomTextInput';
import { SubmitButton } from '../components/SubmitButton';
import { TitleContent } from '../components/TitleContent';
import { setPFToken } from '../utilities/TokenHelper';
import { logAmplitudeEvent } from '../api/integration';
import { ErrorBox } from '../components/ErrorBox';
import { RegisterPageContainer } from '../components/RegisterPageContainer';
import { parseJwt } from '../utilities/jwt';
import { RegistrationType } from './CONSTANT';
import useQuery from '../shared/hooks/useQuery';

const shadowSize = '5px';
const shadowColor = '#ccc';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1,
  },
  box: {
    position: 'relative',
    minWidth: 256,
    padding: 16,
    boxShadow: `0 0 ${shadowSize} #ccc`,
    backgroundColor: '#fff',
    borderRadius: 4,
    marginTop: 10,
    marginBottom: 30,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '17%',
      right: 0,
      width: 10,
      height: 10,
      backgroundColor: '#fff',
      boxShadow: `0 0 ${shadowSize} ${shadowColor}`,
      transform: 'translate(-1260%, -300%) rotate(-45deg)',
      clipPath: `polygon(-${shadowSize} -${shadowSize}, calc(100% + ${shadowSize}) -${shadowSize}, calc(100% + ${shadowSize}) calc(100% + ${shadowSize}))`,
    },
  },
  title: {
    padding: 0,
    margin: 0,
    color: '#222B56',
    fontSize: 14,
    fontWeight: 500,
  },
  text: {
    padding: 0,
    margin: 0,
    color: '#757575',
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    pointerEvents: 'none',
    backgroundColor: '#D0D0D0',
  },
  link: {
    color: '#538FE9',
  },
}));

const CheckIcon = ({ isActive = false }) => {
  return <img style={{ width: 15, height: 15, marginRight: 8 }} src={isActive ? CheckActiveIcon : CheckInActiveIcon} />;
};

export const GuestEmailAndPassword: React.FC<any> = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopper, setShowPopper] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [apiError, setApiError] = useState('');
  const query = useQuery();
  const sponsorId = useMemo(() => query.get('sponsor'), [query]);
  const inviteId = useMemo(() => query.get('inviteId'), [query]);

  const classes = useStyles();

  const history = useHistory();

  const { pfPreRegister, verifyPFToken, setRegisterUser } = useAuth();

  const handleSubmit = async (values) => {
    logAmplitudeEvent('Guest Signup: Account', {
      Email: values.email,
    });
    try {
      setApiError('');
      const res = await pfPreRegister({ ...values, registrationType: RegistrationType.Core });
      if (res.status === 200) {
        setPFToken(res?.data?.token);
        const decoded = parseJwt(res?.data?.token) as any;
        if (decoded?.registering === 'True') {
          setRegisterUser({
            email: values.email,
            registrationType: RegistrationType.Consumer,
            sponsorId,
            inviteId,
          });

          history.push(GUEST_USER);
        } else {
          await verifyPFToken(true);
          history.push(DISCOVER);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        setApiError('Password is incorrect. Please try again.');
      } else if (error.response.status === 400) {
        setApiError(error?.response?.data?.Errors?.[0]?.message || error?.response?.data?.Message || error.message || 'Bad request');
      } else {
        setApiError(error?.response?.data?.Errors?.[0]?.message || error?.response?.data?.Message || error.message || 'Unhandled error encountered.');
      }
    }
  };

  return (
    <RegisterPageContainer>
      <TitleContent title="Sign Up" subTitle="Please enter your email and a password to create your account." />
      <Formik
        validateOnChange
        initialValues={{
          email: '',
          password: '',
          acknowledge: true,
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Invalid email address').required().label('Email'),
          password: Yup.string()
            .required()
            .test('isValidPass', 'Please use a stronger password', (value) => {
              const validateMinLength = value?.length >= 8;
              const validateHasUpperCase = /[A-Z]/.test(value);
              const validateHasLowerCase = value && /[a-z]/.test(value);
              const validateHasSymbol = /[!@#$^%&*]/.test(value);
              let validConditions = 0;
              const numberOfMustBeValidConditions = 4;
              const conditions = [validateMinLength, validateHasLowerCase, validateHasUpperCase, validateHasSymbol];
              conditions.forEach((condition) => (condition ? validConditions++ : null));
              setIsMinLength(validateMinLength);
              setHasUpperCase(validateHasUpperCase);
              setHasLowerCase(validateHasLowerCase);
              setHasSymbol(validateHasSymbol);
              if (validConditions >= numberOfMustBeValidConditions) {
                return true;
              }
              return false;
            })
            .label('Password'),
        })}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
      >
        {(props) => {
          const disabledButton = !props.values.acknowledge || !props.values.email || !props.values.password || !_.isEmpty(props.errors);
          return (
            <Form className={`${loginFormStyles.form} ${loginStyles.form}`}>
              <fieldset>
                <CustomTextInput
                  type="text"
                  name="email"
                  label="email"
                  id={nextId('id-')}
                  placeholder="Email"
                  onFocus={(e) => (e.target.placeholder = 'example@email.com')}
                  onBlur={(e) => {
                    e.target.placeholder = 'Email';
                    props.setFieldTouched('email');
                  }}
                />
                <CustomTextInput
                  label="password"
                  name="password"
                  type="password"
                  id={nextId('id-')}
                  placeholder="Password"
                  showIcon={true}
                  onFocus={(e) => {
                    setAnchorEl(e.currentTarget);
                    setShowPopper(true);
                    props.setFieldTouched('password', false);
                  }}
                  onBlur={() => {
                    setAnchorEl(null);
                    setShowPopper(false);
                    props.setFieldTouched('password');
                  }}
                />
                <ErrorBox show={!!apiError} title={apiError} />
                <Popper
                  open={showPopper && !!props.errors.password}
                  anchorEl={anchorEl}
                  className={classes.popper}
                  placement="bottom"
                  modifiers={{
                    flip: {
                      enabled: true,
                    },
                  }}
                >
                  <div className={classes.box}>
                    <p className={classes.title}>Your password must have:</p>
                    <p className={classes.text}>
                      <CheckIcon isActive={isMinLength} />
                      at least 8 characters
                    </p>
                    <p className={classes.text}>
                      <CheckIcon isActive={hasUpperCase} />
                      at least 1 capital letter
                    </p>
                    <p className={classes.text}>
                      <CheckIcon isActive={hasLowerCase} />
                      at least 1 lowercase letter
                    </p>
                    <p className={classes.text}>
                      <CheckIcon isActive={hasSymbol} />
                      at least 1 special character
                    </p>
                  </div>
                </Popper>
                <div className={loginStyles.subActions} style={{ marginBottom: 16 }}>
                  <div className={loginStyles.remember} style={{ width: '100%' }}>
                    <input
                      id="id-5-1"
                      role="checkbox"
                      type="checkbox"
                      name="acknowledge"
                      aria-checked="false"
                      tabIndex={0}
                      className={checkboxStyles.input}
                      onClick={(e) => {
                        props.setFieldValue('acknowledge', !props.values.acknowledge);
                        e.preventDefault();
                      }}
                    />
                    <label htmlFor="id-5-1" className={checkboxStyles.labelContainer}>
                      <span className={checkboxStyles.checkbox}>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          className={props.values.acknowledge ? checkboxStyles.check : checkboxStyles.noCheck}
                          style={{
                            background: props.values.acknowledge ? '#D91E61' : 'unset',
                          }}
                          viewBox="0 0 24 24"
                        >
                          <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z"></path>
                        </svg>
                      </span>
                      <span className={checkboxStyles.label} style={{ fontSize: 14 }}>
                        <span>
                          I acknowledge that I agree to the{' '}
                          <Link className={classes.link} to="/terms-of-service">
                            Terms of Use
                          </Link>{' '}
                          and have read the{' '}
                          <Link className={classes.link} to="/privacy-policy">
                            Privacy Policy
                          </Link>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <SubmitButton disabled={disabledButton} isRegister={true} isSubmitting={props.isSubmitting}>
                  Next
                </SubmitButton>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </RegisterPageContainer>
  );
};
