import React, { useEffect, useState } from 'react';
import checkoutStyles from '../styles/checkout/Checkout.module.css';
import pageStyles from '../styles/checkout/Page.module.css';
import loginImg from '../Assets/loginImg.jpg';

import fieldStyles from '../styles/input/Field.module.css';
import btnDefaultStyles from '../styles/button/Default.module.css';
import submitStyles from '../styles/Submit.module.css';
import loadingStyles from '../styles/Loading.module.css';
import loginStyles from '../styles/Login.module.css';
import stripeStyles from '../styles/StripeCard.module.css';

import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useAuth } from '../contexts/AuthContext';
import paymentApi from '../api/payment';
import * as Yup from 'yup';

import { Formik, Form } from 'formik';
import { TextField } from '../shared/Formik/TextField1';
import errorFormStyles from '../styles/error/Form.module.css';
import SubmitButton from '../shared/Formik/SubmitButton';
import { useHistory, useLocation } from 'react-router';
import useQuery from '../shared/hooks/useQuery';
import userApi from '../api/user';

import spinnerGroupStyles from '../styles/spinner/Group.module.css';
import { CREDITS, DISCOVER } from '../navigation/CONSTANTS';

export const Checkout = () => {
  // SCROLL TO TOP.
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const query = useQuery();
  const history = useHistory();
  const [planDetail, setPlanDetail] = useState({});

  const stripe = useStripe();
  const elements = useElements();
  const { authState, card, verify } = useAuth();
  const { user } = authState || {};
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const plan_id = query.get('plan_id');
    if (!plan_id) {
      console.log('no plan_id. go to CREDITS.');
      history.replace(CREDITS);
    }
    if (user.consumer_subscription) {
      console.log('user already has a consumer_subscription. go to CREDITS.');
      history.replace(CREDITS);
    }

    userApi
      .getConsumerPlanDetail(plan_id)
      .then((res) => {
        setPlanDetail(res);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('error:', error);
        if (error.status == 404) history.replace(CREDITS);
      });
  }, []);

  const handleSubmit = async (values, { setErrors }) => {
    console.log('BEGIN PURCHASE.');
    let token_id;
    let doPurchase = true;
    if (!card) {
      doPurchase = false;
      console.log('starting STRIPE:', stripe);
      console.log('stripe Info:', elements.getElement(CardNumberElement));
      const { error, token } = await stripe.createToken(
        elements.getElement(CardNumberElement)
      );
      if (error) {
        console.log('stripe error:', error);
        setErrors({ summary: error.message });
        return;
      }
      token_id = token.id;

      await paymentApi
        .AddPaymentMethod(user.id, token_id)
        .then((res) => {
          console.log('successfully added stripe paymentMethod.');
          console.log('res:', res);

          // CONTINUE ON TO PURCHASE
          doPurchase = true;
        })
        .catch((error) => {
          console.log('error:', error);
          setErrors({ summary: error.data.message });
        });
    }

    if (doPurchase) {
      await paymentApi
        .purchaseConsumerSubscription(user.id, planDetail.id)
        .then((res) => {
          verify();
          history.push(CREDITS);
        })
        .catch((error) => {
          if (error.status == 400) setErrors({ summary: error.data.Message });
          else
            setErrors({
              summary:
                'Unable to create subscription at the moment. Please try again later.',
            });
        });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Your Name'),
  });

  return (
    <>
      <div
        style={{
          height: '822px',
          position: 'relative',
          minHeight: '822px',
          backgroundImage: `url(${loginImg})`,
        }}
        className={pageStyles.page}
      >
        <div className={checkoutStyles.page}>
          {isLoading ? (
            <>
              <div className={checkoutStyles.content}>
                <div
                  className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`}
                ></div>
              </div>
            </>
          ) : (
            <>
              <h2>
                <span>Subscribe to Peerfit</span>
              </h2>
              <div className={checkoutStyles.content}>
                <div className={checkoutStyles.preview}>
                  <h3>${planDetail.amount}</h3> /<span>Month</span> *<br />
                  <span>{planDetail.name}</span>
                </div>
                <p className={checkoutStyles.payInfo}>
                  *{' '}
                  <span>
                    Access our network of fitness experiences and competitive
                    rates. Pay for credits as you go when you reserve a class
                    for only $3.50 per credit.
                  </span>
                </p>

                <Formik
                  initialValues={{
                    name: '',
                  }}
                  validationSchema={card ? '' : validationSchema}
                  onSubmit={async (values, actions) => {
                    console.log('submit.');
                    await handleSubmit(values, actions);
                  }}
                >
                  {(formikProps) => (
                    <Form autoComplete="on" noValidate="">
                      <fieldset>
                        {formikProps.errors.summary && (
                          <div className={errorFormStyles.error} tabIndex="-1">
                            <span aria-label={formikProps.errors.summary}>
                              {formikProps.errors.summary}
                            </span>
                          </div>
                        )}
                        {card ? (
                          <h3>
                            <span>Card on file will be charged.</span>
                          </h3>
                        ) : (
                          <>
                            <TextField
                              name="name"
                              label="Your Name"
                              placeholder="Your Name"
                            />
                            <CardNumberElement />
                            <div
                              className={`${stripeStyles.cardDetailContainer}`}
                            >
                              <CardExpiryElement />
                              <CardCvcElement />
                            </div>
                          </>
                        )}

                        <SubmitButton label="Subscribe to Peerfit" />
                      </fieldset>
                    </Form>
                  )}
                </Formik>
                <button
                  onClick={() => history.push(DISCOVER)}
                  className={`${checkoutStyles.cancel} ${btnDefaultStyles['secondary-button']} ${btnDefaultStyles.button} focus_outline`}
                  data-cy="checkout-skip-for-now"
                >
                  <span>Skip for now</span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
