import React, { useEffect, useState } from 'react';
import attendedStyles from '../../../styles/user/Attended.module.css';
import upcomingStyles from '../../../styles/user/Upcoming.module.css';
import utilities from '../../../styles/utilities.module.css';
import emptyCardStyles from '../../../styles/user/EmptyCard.module.css';
import roundedButtonStyle from '../../../styles/button/Rounded.module.css';
import flexStyles from '../../../styles/Flex.module.css';
import noClassesStyles from '../../../styles/user/NoClasses.module.css';
import reservationsStyles from '../../../styles/user/Reservations.module.css';
import reservationStyles from '../../../styles/user/Reservation.module.css';
import defaultStudioImg from '../../../Assets/defaultStudio.jpg';
import bannerStyles from '../../../styles/Banner.module.css';
import { EXPLORE } from '../../../navigation/CONSTANTS';
import { format } from 'date-fns';
import userApi from '../../../api/user';
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { AttendedCard } from './AttendedCard';
import {
  getSourceFromParams,
  logAmplitudeEvent,
} from '../../../api/integration';
import MobilePage from './MobilePage';
import { useGoBackAccount } from '../../../utilities/navigation';

function Attended() {
  // recent studios  not sure where this is displayed.
  // https://apipeerfit.cm/users/78913/recent_studios/?is_hidden=false

  const [attended, setAttended] = useState([]);
  const { authState } = useAuth();
  useEffect(() => {
    userApi.getPastReservations(authState.user?.id).then((res) => {
      console.log('getPastReservations:', res.results);
      setAttended(res.results);
    });
    logAmplitudeEvent('Screen View: Profile - Past', {
      Source: getSourceFromParams(),
    });
  }, []);

  // get all past classes
  // [GET] https://apipeerfit.cm/reservations/?is_canceled=false&ordering=-session__start_datetime&timeframe=past&user_id=78913

  const backToAccountPage = useGoBackAccount();
  return (
    <MobilePage title="Past Classes" onBack={backToAccountPage}>
      <div className={attendedStyles.container}>
        <div className={reservationsStyles.container}>
          <div
            className={`${reservationsStyles.inner} ${utilities['page-container']}`}
          >
            <h3 className={reservationsStyles.title}>
              <span>Classes Attended</span>
            </h3>
            <div className={`undefined ${flexStyles['row']}`}>
              {attended.map((studioClass, i) => (
                <AttendedCard key={i} attended={studioClass} />
              ))}

              <div
                className={`${flexStyles['col-lg-3']} ${flexStyles['col-md-4']} ${flexStyles['col-sm-6']} ${upcomingStyles.card} ${reservationsStyles.card}`}
              >
                <div
                  className={`${noClassesStyles.container} ${utilities['page-container']}`}
                >
                  <div className={emptyCardStyles.content}>
                    <div className={emptyCardStyles.infoWrapper}>
                      <div className={emptyCardStyles.info}>
                        <div className={noClassesStyles.message}>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            className={noClassesStyles.icon}
                            viewBox="0 0 24 24"
                          >
                            <path d="M4,5 L7.25143433,5 C7.24594771,4.33333333 7.24594771,4 7.25143433,4 C7.25143433,3.55845642 7.62863159,3.25088078 8,3.25088078 C8.37136841,3.25088078 8.74992928,3.55039978 8.74992928,4 L8.74992928,5 L15.2498627,5 L15.2498627,4 C15.2498627,3.55992126 15.6178284,3.25088078 16,3.25088078 C16.3821716,3.25088078 16.7448425,3.55992126 16.7448425,4 L16.7448425,5 L20,5 C20.5522847,5 21,5.44771525 21,6 L21,19 C21,19.5522847 20.5522847,20 20,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,6 C3,5.44771525 3.44771525,5 4,5 Z M4.51090207,11.0330303 L4.51090207,18.4965464 L19.5089129,18.4965464 L19.5089129,11.0105164 L4.51090207,11.0330303 Z M4.50861539,9.49358392 L19.5086154,9.51949685 L19.5086154,6.50835262 L16.7448425,6.50835262 C16.7448425,6.8385415 16.7448425,7.00363594 16.7448425,7.00363594 C16.7448425,7.44895935 16.3736934,7.74893188 16,7.74893188 C15.6263066,7.74893188 15.2498627,7.44895935 15.2498627,7.00363594 C15.2498627,7.00363594 15.2498627,6.8385415 15.2498627,6.50835262 L8.74992928,6.50835262 C8.74992928,6.8385415 8.74992928,7.00363594 8.74992928,7.00363594 C8.74992928,7.43164062 8.38760918,7.74893188 8,7.74893188 C7.61239082,7.74893188 7.25143433,7.43086243 7.25143433,7.00363594 C7.25143433,7.00363594 7.25143433,6.8385415 7.25143433,6.50835262 L4.50861539,6.50835262 L4.50861539,9.49358392 Z"></path>
                          </svg>
                          <h3 className={noClassesStyles.titleWithSub}>
                            <span>Plan Ahead!</span>
                          </h3>
                          <p className={noClassesStyles.subtitle}>
                            <span>Secure your spot in another class.</span>
                          </p>
                        </div>
                        <Link
                          className={`${noClassesStyles.btn} ${roundedButtonStyle['secondary-button']} ${roundedButtonStyle.button} focus_outline`}
                          to={EXPLORE}
                        >
                          <span>View Studios</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MobilePage>
  );
}

export { Attended };
