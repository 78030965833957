import React, { ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useSite } from '../../contexts/SiteContext';
import LoginLogo from '../../Assets/peerfit-core.png';
import useQuery from '../../shared/hooks/useQuery';
import { HOME } from '../../navigation/CONSTANTS';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#edf1f7',
    },
  },
  header: {
    padding: '27px 33px',
    width: '100%',
    position: 'fixed',
    top: 0,
  },
  mobileHeader: {
    padding: '46px 19px 12px',
    width: '100%',
    borderBottom: '1px solid #f0f0f0',
    position: 'fixed',
    top: 0,
  },
  image: {
    height: 27,
  },
  imageLogo: {
    width: 160,
  },
  main: {
    width: '40%',
    maxWidth: 790,
    minWidth: 390,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#fff',
    padding: '0 24px 24px',
    borderRadius: 4,
  },
}));

export const RegisterPageContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const { isMobileApp } = useSite();
  const query = useQuery();
  const type = useMemo(() => query.get('type'), [query]);
  return (
    <div className={classes.container}>
      <div className={isMobileApp ? classes.mobileHeader : classes.header}>
        {isMobileApp && type !== 'unregistered' && (
          <div
            style={{
              padding: 12,
              fontSize: 16,
              fontWeight: 'bold',
              position: 'absolute',
              top: 0,
              left: 8,
            }}
          >
            <div
              onClick={() => {
                history.goBack();
              }}
            >
              <span>&#x3c;</span> Back
            </div>
          </div>
        )}
        <a href={HOME}>
          <img className={isMobileApp ? classes.image : classes.imageLogo} src={LoginLogo} />
        </a>
      </div>
      <div className={classes.main}>{children}</div>
    </div>
  );
};
