import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import pageStyles from '../../styles/studios/Page.module.css';
import checkboxStyles from '../../styles/Checkbox.module.css';
import checkboxFieldStyles from '../../styles/checkbox/Field.module.css';
import tagFiltersStyles from '../../styles/TagFilters.module.css';
import tagStyles from '../../styles/Tag.module.css';
import loadingStyles from '../../styles/Loading.module.css';
import spinnerGroupStyles from '../../styles/spinner/Group.module.css';

import { StudioContext } from '../../contexts/StudioContext';
import studioApi from '../../api/studio';
import { useGlobal } from '../../contexts/GlobalContext';
import { useSite } from '../../contexts/SiteContext/SiteContext';
import { getSourceFromParams, logAmplitudeEvent } from '../../api/integration';

function StudioFilterForm(props) {
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(true);
  const { isMoveSite } = useSite();

  useEffect(() => {
    isMoveSite ? getCategories() : getTags();
  }, []);

  const getTags = async () => {
    studioApi
      .getTags()
      .then((res) => {
        const newData = res.map((item) => ({ ...item, checked: false }));
        // console.log("getTags: ",newData);
        setTags(newData);
      })
      .catch((error) => {
        console.log('error:', error.message);
      })
      .finally(() => {
        setTagsLoading(false);
      });
  };

  const getCategories = async () => {
    studioApi
      .getCategories()
      .then((res) => {
        console.log('getCategories:', res);
        const newData = res.results.map((item) => ({
          ...item,
          checked: false,
        }));
        console.log('getCategories: ', newData);
        setCategories(newData);
      })
      .catch((error) => {
        console.log('error:', error.message);
      })
      .finally(() => {
        setTagsLoading(false);
      });
  };

  const { setFilters, setCategoryFilters } = useContext(StudioContext);
  function checkFilter(e) {
    console.log(e.target.name + '-' + e.target.checked);
    let name = e.target.name;
    var newData = tags.map((item) => {
      if (item.name === name) {
        item.checked = !item.checked;
      }
      return item;
    });
    // console.log(newData);
    setTags(newData);
    var filters = tags.filter((tag) => tag.checked === true).map((a) => a.name);
    setFilters(filters);

    logAmplitudeEvent('Screen View: Streaming - Studios', {
      Category: filters,
      Source: getSourceFromParams(),
    });
  }

  function checkCategoryFilter(e) {
    console.log(e.target.name + '-' + e.target.checked);
    let name = e.target.name;
    var newData = categories.map((item) => {
      if (item.name === name) {
        item.checked = !item.checked;
      }
      return item;
    });
    // console.log(newData);
    setCategories(newData);
    var filters = categories
      .filter((cat) => cat.checked === true)
      .map((a) => a.name);
    setCategoryFilters(filters);
  }

  return (
    <form autoComplete="on" noValidate="">
      <fieldset>
        {tagsLoading ? (
          <div
            className={`${spinnerGroupStyles.spinner} ${loadingStyles.spinner}`}
          ></div>
        ) : (
          <fieldset className={tagFiltersStyles.pillGroup}>
            {isMoveSite
              ? categories.map((category, index) => {
                  const id = `id-${index + 2}-1`;
                  const categoryName = category.name;
                  return (
                    <div key={id} className={checkboxFieldStyles.pill}>
                      <div
                        className={`${
                          category.checked
                            ? tagFiltersStyles.pillToggleOn
                            : tagFiltersStyles.pillToggle
                        } ${category.checked ? tagStyles.toggleOn : ''} ${
                          tagStyles.toggle
                        } `}
                      >
                        <input
                          name={categoryName}
                          type="checkbox"
                          className={tagStyles.input}
                          id={id}
                          onChange={checkCategoryFilter}
                          checked={category.checked}
                        />
                        <label htmlFor={id} className={tagStyles.label}>
                          <span>{categoryName}</span>
                        </label>
                      </div>
                    </div>
                  );
                })
              : tags.map((tag, index) => {
                  const id = `id-${index + 2}-1`;
                  const tagName = tag.name;
                  return (
                    <div key={id} className={checkboxFieldStyles.pill}>
                      <div
                        className={`${
                          tag.checked
                            ? tagFiltersStyles.pillToggleOn
                            : tagFiltersStyles.pillToggle
                        } ${tag.checked ? tagStyles.toggleOn : ''} ${
                          tagStyles.toggle
                        } `}
                      >
                        <input
                          name={tagName}
                          type="checkbox"
                          className={tagStyles.input}
                          id={id}
                          onChange={checkFilter}
                          checked={tag.checked}
                        />
                        <label htmlFor={id} className={tagStyles.label}>
                          <span>{tagName}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
          </fieldset>
        )}
      </fieldset>
    </form>
  );
}

export default StudioFilterForm;
