import { useField } from 'formik';
import React, { useEffect, useState } from 'react'
import { useGlobal } from '../../contexts/GlobalContext';

import pageStyles from '../../styles/account/preferences/Page.module.css';
import fieldInputStyles from '../../styles/input/Field.module.css';

import checkboxStyles from '../../styles/Checkbox.module.css';
import checkboxFieldStyles from '../../styles/checkbox/Field.module.css';

import loadingStyles from '../../styles/Loading.module.css';
import groupSpinnerStyles from '../../styles/spinner/Group.module.css';


export const UserTags = ({ ...props }) => {
   const [field, meta] = useField(props);
   const { tags } = useGlobal();
   // const {tags, setTags} = useState([]);

   return (
      <>
         {!tags ?
            <div class={`${groupSpinnerStyles.spinner} ${loadingStyles.spinner}`}></div> :

            <fieldset className={pageStyles.workoutCheckboxes}>
               {tags.map((tag, index) => {
                  const selected = field?.value.indexOf(tag.name) > -1;
                  return (
                     <div key={index} className={`${checkboxFieldStyles.checkbox} ${checkboxStyles.field} ${fieldInputStyles.field}`}>
                        <input {...field} value={tag.name} id={`id-${index}-1`}
                           // role="checkbox" 
                           type="checkbox" aria-checked={selected} data-cy="workout-preferences-checkbox" tabIndex="0" className={checkboxStyles.input} />
                        <label htmlFor={`id-${index}-1`} className={checkboxStyles.labelContainer}>
                           <span className={checkboxStyles.checkbox}>
                              <svg aria-hidden="true" focusable="false" className={selected ? checkboxStyles.check : checkboxStyles.noCheck} viewBox="0 0 24 24">
                                 <path d="M19.3797883,5.38277847 C19.7206703,5.040245 20.274688,5.03890624 20.6172215,5.37978827 C20.959755,5.72067031 20.9610938,6.27468805 20.6202117,6.61722153 L9.64747314,17.6431189 C9.30604121,17.9862049 8.75093334,17.9869132 8.40862693,17.6446997 L3.38136552,12.6188024 C3.03961041,12.27714 3.03953523,11.7231206 3.38119762,11.3813655 C3.72286001,11.0396104 4.27687936,11.0395352 4.61863448,11.3811976 L9.02568055,15.7870479 L19.3797883,5.38277847 Z">
                                 </path>
                              </svg>
                           </span>
                           <span className={checkboxStyles.label}>
                              <span>{tag.name}</span>
                           </span>
                        </label>
                     </div>)
               }
               )}
            </fieldset>
         }
      </>
   )

}
