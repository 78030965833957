import apiInstance from './api';


const GetStripeCards = async (userId) => {
  
  const api = await apiInstance.getApi();
  return api
    .get(`/users/${userId}/stripe/cards`)
    .then((res) => {
      return res.data.results;
    })
    .catch((error) => {
      console.log('GetStripeCards Error:', error.response);
    });
};

const AddPaymentMethod = async (userId, paymentMethodId) => {
  console.log('Adding Stripe PaymentMethodId');
  const api = await apiInstance.getApi();
  return api
    .post(`/users/${userId}/stripe/payment-methods/create`, {
      source: paymentMethodId,
    })
    .then((res) => {
      console.log('CreatePaymentMethod: ', res.data);
    })
    .catch((error) => {
      console.log('AddPaymentMethod Error: ', error.response);
      throw error.response;
    });
};

const DeletePaymentMethod = async (userId, paymentMethodId) => {
  console.log('Deleting Stripe Card:', paymentMethodId);
  // return sleep(3000);
  const api = await apiInstance.getApi();
  return api
    .delete(`/users/${userId}/stripe/payment-methods/${paymentMethodId}/delete`)
    .then((res) => {
      console.log('DeletePaymentMethod Success:', res.data);
    })
    .catch((error) => {
      console.log('DeletePaymentMethod Error: ', error.response);
    });
};

const SetCardAsPrimary = async (userId, paymentMethodId) => {
  console.log('Setting card as primary:', paymentMethodId);
  // return sleep(3000);
  const api = await apiInstance.getApi();
  return api
    .post(`/users/${userId}/stripe/payment-methods/${paymentMethodId}/primary`)
    .then((res) => {
      console.log('SetCardAsPrimary Success:', res.data);
    })
    .catch((error) => {
      console.log('SetCardAsPrimary Error: ', error.response);
    });
};

const purchaseConsumerSubscription = async (
  userId,
  planId,
  paymentMethodId
) => {
  const api = await apiInstance.getApi();
  return api
    .post(`/consumer/subscription`, {
      user_id: userId,
      plan_id: planId,
      source: paymentMethodId,
    })
    .then((res) => {
      console.log('purchaseConsumerSubscription Success:', res);
    })
    .catch((error) => {
      console.log('purchaseConsumerSubscription Error:', error.response);
      throw error.response;
    });
};

export default {
  GetStripeCards,
  AddPaymentMethod,
  DeletePaymentMethod,
  SetCardAsPrimary,
  purchaseConsumerSubscription,
};
